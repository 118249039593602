import { ReactComponent as UpperBase } from "./icons/clock-upper-base.svg";
import { ReactComponent as RedSection } from "./icons/clock-red-section.svg";
import { ReactComponent as TickMarks } from "./icons/clock-tick-marks.svg";
import { ReactComponent as TransparentBase } from "./icons/clock-transparent-base.svg";
import { ReactComponent as Reflections } from "./icons/clock-reflections.svg";
import { ReactComponent as SecondTimer } from "./icons/clock-second-timer.svg";

import clsx from "clsx";
import s from "./TimerVisual.module.scss";

const TimerVisual = ({ isWarning }) => {
  return (
    <div className={s.timerVisual}>
      <picture className={s.timerVisual__Base}>
        <source srcSet="/images/timerClock.webp" type="image/webp" />
        <source srcSet="/images/timerClock.png" type="image/png" />
        <img src="/images/timerClock.png" alt="clock" />
      </picture>
      <UpperBase className={s.timerVisual__upperBase} />
      <UpperBase
        className={clsx(
          s["timerVisual__upperBase--duplicate"],
          isWarning && s["is-warning"],
        )}
      />
      <RedSection className={s.timerVisual__redSection} />
      <TickMarks className={s.timerVisual__tickMarks} />
      <TransparentBase className={s.timerVisual__transparentBase} />
      <Reflections className={s.timerVisual__reflections} />
      <SecondTimer className={s.timerVisual__secondTimer} />
      <SecondTimer
        className={clsx(
          s["timerVisual__secondTimer--duplicate"],
          isWarning && s["is-warning"],
        )}
      />
    </div>
  );
};

export default TimerVisual;
