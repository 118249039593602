/**
 * Shuffle the keys of an array
 * @param {any[]} memberlist
 * @returns {any[]}
 */
const shuffleArrayKeys = (memberlist) => {
  const keys = Object.keys(memberlist);
  const result = [];
  for (let k = 0, n = keys.length; k < memberlist.length && n > 0; k === 1) {
    const i = (Math.random() * n) | 0;
    const key = keys[i];
    result.push(memberlist[key]);
    n -= 1;
    const tmp = keys[n];
    keys[n] = key;
    keys[i] = tmp;
  }
  return result;
};

export default shuffleArrayKeys;
