import convertObjToArr from "@bd-utils/convertObjToArr";
import useStore from "./useStore";

const useDerivedTutorial = () => {
  const { gameProgress, gameData, currentPlayer, firebase, userProgress } =
    useStore();
  const { tutorials_v2 } = gameData.config;
  const tutorialDataArr = convertObjToArr(tutorials_v2);
  const isAllowed =
    firebase.isConnected &&
    gameProgress.roundProgress.playersSeenRoundIntro.includes(
      currentPlayer.userId,
    ) &&
    !gameProgress.players?.every(
      (p) => p.currentTutorialIndex === tutorialDataArr.length,
    );
  // !(userProgress.currentTutorialIndex === tutorialDataArr.length)
  /**
   * @param {ApiTutorialType} tutorial
   */
  const finder = (tutorial) => {
    return tutorialDataArr.findIndex((t) => t.id === tutorial?.id);
  };

  /**
   * If there are multiple `is_group` tutorials, this will be the tutorial with
   * the lowest index of its kind. If none, this will be undefined
   * @type {ApiTutorialType}
   */
  const lowestGroup = tutorialDataArr.filter(
    (tutorial) => tutorial.is_group,
  )?.[0];
  const lowestGroupIndex = tutorialDataArr.findIndex(
    (tutorial) => tutorial.is_group,
  );

  /**
   * @type {ApiTutorialType | undefined}
   */
  let prevTutorial;

  /**
   * Current tutorial ID that should be shown into current user
   * @type {ApiTutorialType | undefined}
   */
  let current;

  /**
   * Whether current user is waiting other to see the group tutorials or not
   * @type {boolean}
   */
  let isWaitingGroupTutorial = false;

  /**
   * Whether current user has seen all the individual tutorials or not
   * @type {boolean}
   */
  let hasSeenIndividual = false;

  /**
   * Whether all the users has seen individual tutorials or not
   * @type {boolean}
   */
  let hasAllSeenIndividual = false;

  let isDisablePrev = false;

  /**
   * @param {boolean} hasSeenIndividual
   * @param {ApiTutorialType} groupCheckpoint
   * @param {ApiTutorialType} prevTutorial
   */
  const getIsDisablePrev = (
    hasSeenIndividual,
    groupCheckpoint,
    prevTutorial,
  ) => {
    if (!prevTutorial) return true;

    const indexGroup = finder(groupCheckpoint);
    const indexPrevTutorial = finder(prevTutorial);
    const isDisablePrev = hasSeenIndividual && indexGroup > indexPrevTutorial;
    return isDisablePrev;
  };

  /**
   * Whether current user has seen all the tutorials or not
   * @type {boolean}
   */
  const hasSeenAllTutorial = tutorialDataArr.every((tutorial, index) => {
    const seenPlayers = gameProgress.seenTutorials?.[tutorial.id] ?? [];
    const hasUserSeenThis = seenPlayers.includes(currentPlayer.userId);
    const hasAllSeenThis = seenPlayers.length === gameProgress.players.length;
    const isLast = index === tutorialDataArr.length - 1;
    const isGroupCheckpoint =
      tutorial.is_group && lowestGroup.id === tutorial.id;

    const nextTutorial = tutorialDataArr?.[index + 1];
    const isNextGroupCheckpoint = nextTutorial
      ? nextTutorial.is_group && lowestGroup.id === nextTutorial.id
      : false;

    if (hasUserSeenThis && isNextGroupCheckpoint && !hasAllSeenThis) {
      hasSeenIndividual = true;
      isWaitingGroupTutorial = true;
    }

    if (isGroupCheckpoint) {
      hasSeenIndividual = true;
    }

    if (isGroupCheckpoint && hasAllSeenThis) {
      hasSeenIndividual = true;
      hasAllSeenIndividual = true;
    }

    if (isLast && hasUserSeenThis && !lowestGroup) {
      hasSeenIndividual = true;
      current = undefined;
    }

    if (hasUserSeenThis) {
      prevTutorial = tutorialDataArr?.[index - 1];
      isDisablePrev = getIsDisablePrev(
        hasSeenIndividual,
        lowestGroup,
        prevTutorial,
      );
      return true;
    }

    prevTutorial = tutorialDataArr?.[index - 1];
    current = tutorial;
    isDisablePrev = getIsDisablePrev(
      hasSeenIndividual,
      lowestGroup,
      prevTutorial,
    );
    return false;
  });

  // new tutorial waiting logic
  const checkWaitingGroup = () => {
    const finishedPlayer = gameProgress.players?.find(
      (p) => p.currentTutorialIndex === tutorialDataArr.length,
    );
    if (finishedPlayer) {
      const isAllAtSameIndex = gameProgress.players?.every(
        (p) => p.currentTutorialIndex === tutorialDataArr.length,
      );
      if (
        !isAllAtSameIndex &&
        userProgress.currentTutorialIndex === tutorialDataArr.length
      ) {
        return true;
      }
    }

    if (gameProgress.players?.find((p) => p.currentTutorialIndex > 2)) {
      return false;
    }
    const firstDragPlayer = gameProgress.players?.find(
      (p) => p.currentTutorialIndex === 2,
    );

    if (firstDragPlayer) {
      const isAllAtDrag = gameProgress.players?.every(
        (p) => p.currentTutorialIndex === 2,
      );
      if (!isAllAtDrag && userProgress.currentTutorialIndex === 2) {
        return true;
      }
    }

    return false;
  };

  isWaitingGroupTutorial = checkWaitingGroup();

  const currentIndex = finder(current);

  const totalIndividual =
    lowestGroupIndex === -1
      ? tutorialDataArr.length
      : tutorialDataArr.slice(0, lowestGroupIndex).length;

  const isActive = typeof current !== "undefined";

  const tutorialData = tutorials_v2;

  return {
    isActive,
    isAllowed,
    totalIndividual,
    lowestGroup,
    current,
    currentIndex,
    prevTutorial,
    hasSeenIndividual,
    hasSeenAllTutorial,
    hasAllSeenIndividual,
    isDisablePrev,
    isWaitingGroupTutorial,
    tutorialData,
  };
};

export default useDerivedTutorial;
