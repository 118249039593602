import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import { setCurrentLanguage, setLanguageOptions } from "@bd-redux/language";
import { markConnected, setIsConnecting } from "@bd-redux/firebase";
import { setChangingLanguage } from "@bd-redux/language";
import { setFirebaseToken } from "@bd-redux/user";
import { init } from "@bd-redux/game-data";
import { initFirebase } from "@bd-utils/firebase";
import getAvailableLanguages from "@bd-apis/getAvailableLanguages";
import getGameData from "@bd-apis/getGameData";
import goAsync from "@bd-utils/goAsync";
import useStore from "./useStore";
import useFirebaseListener from "./useFirebaseListener";
import getTranslatedRoles from "@bd-utils/getTranslatedRoles";

const useInitializeGame = () => {
  useFirebaseListener();
  const location = useLocation();
  const dispatch = useDispatch();
  const { userProgress, gameProgress, language, firebase } = useStore();
  const isLoading = !firebase.isConnected;
  const _setFirebaseToken = useCallback(
    (token) => dispatch(setFirebaseToken(token)),
    [dispatch],
  );
  const languageCode = language.current.value;

  const initGame = useCallback(async () => {
    if (!firebase.isConnecting) dispatch(setIsConnecting(true));

    const [languagesRes, languagesErr] = await goAsync(getAvailableLanguages());

    if (languagesErr) {
      console.error(languagesErr);
      Promise.reject(languagesErr);
      return;
    }

    dispatch(setLanguageOptions(languagesRes.data.data));

    // If user have not set a language, select the first option as default
    if (!language.current) {
      dispatch(setCurrentLanguage(languagesRes.data.data[0]));
    }

    const [gameDataRes, gameDataErr] = await goAsync(getGameData(languageCode));
    const hasInitializeFirebaseApp = firebase.isConnected;

    if (gameDataErr) {
      console.error(gameDataErr);
      Promise.reject(gameDataErr);
      return;
    }

    dispatch(
      init({
        ...gameDataRes.data.data,
        roles: getTranslatedRoles(gameDataRes.data.data.roles, language),
        rawRoles: gameDataRes.data.data.roles,
      }),
    );

    if (!hasInitializeFirebaseApp) {
      // Only init firebase app if we haven't done that in the current session
      await initFirebase({
        config: gameDataRes.data.data.config.firebase,
        token: userProgress.firebaseToken,
        userId: userProgress.userId,
        teamId: gameProgress.teamId,
        wpToken: userProgress.wpToken,
        onNewToken: _setFirebaseToken,
      });

      dispatch(markConnected());
    } else {
      // Otherwise it probably mean the user is changing the language, thus we
      // only need to update `setIsConnecting` to tell that game-data with
      // selected language has been successfully setup in the store
      dispatch(setIsConnecting(false));
      dispatch(setChangingLanguage(false));
    }

    // Note: we intentionally ignore the `isConnected` from this deps array,
    // although it makes its way into the use-effect block .. we only care
    // about the `languageCode` and re-init gameData when it happen, but do
    // nothing on `isConnected` change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    _setFirebaseToken,
    gameProgress.teamId,
    userProgress.firebaseToken,
    userProgress.userId,
    userProgress.wpToken,
    languageCode,
  ]);

  useEffect(
    () => {
      const isResetPage =
        location.pathname === "/reset" || location.pathname === "/reset-game";
      if (isResetPage) return dispatch(setIsConnecting(false));
      if (!userProgress.userId) return dispatch(setIsConnecting(false));
      if (!gameProgress.teamCode) return;

      initGame();
    },
    // eslint-disable-next-line
    [userProgress.userId, gameProgress.teamCode, dispatch, initGame],
  );

  return { isLoading };
};

export default useInitializeGame;
