import { createSlice } from "@reduxjs/toolkit";

/**
 * @type {StoreFirebaseType}
 */
const INITIAL_STATE = {
  isConnecting: true,
  isConnected: false,
  useMyInitialLanguage: false,
  language: undefined,
};

const firebaseSlice = createSlice({
  name: "firebase",
  initialState: INITIAL_STATE,
  reducers: {
    /** @type {UtilReducer<StoreFirebaseType, StoreFirebaseType["isConnecting"]>} */
    setIsConnecting: (state, action) => {
      state.isConnecting = action.payload;
      return state;
    },

    /** @type {UtilReducer<StoreFirebaseType, StoreFirebaseType["isConnected"]>} */
    setIsConnected: (state, action) => {
      state.isConnected = action.payload;
      return state;
    },

    /**
     * Combine `setIsConnecting(false)` and `setIsConnected(true)` in one go,
     * for cleaner redux devtools log
     *
     * @type {UtilReducer<StoreFirebaseType, null>}
     */
    markConnected: (state, action) => ({
      ...state,
      isConnected: true,
      isConnecting: false,
    }),

    /** @type {UtilReducer<StoreFirebaseType, StoreFirebaseType["useMyInitialLanguage"]>} */
    setUseMyInitialLanguage: (state, action) => ({
      ...state,
      useMyInitialLanguage: action.payload,
    }),

    /** @type {UtilReducer<StoreFirebaseType, StoreFirebaseType["language"]>} */
    setFirebaseLanguage: (state, action) => ({
      ...state,
      language: action.payload,
    }),

    /** @type {UtilReducer<StoreFirebaseType, null>} */
    reset: (state, action) => {
      // Why not return INITIAL_STATE directly? because surely when user reset
      // everything it means they logout from the user session too, therefore
      // it loses firebase token and db, thus we don't try to make firebase
      // connection again at that point
      const newState = { ...INITIAL_STATE };
      newState.isConnecting = false;
      return newState;
    },
  },
});

export const {
  setIsConnecting,
  setIsConnected,
  markConnected,
  setUseMyInitialLanguage,
  setFirebaseLanguage,
  reset,
} = firebaseSlice.actions;
export default firebaseSlice.reducer;
