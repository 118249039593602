import React, { useMemo } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import s from "./Fieldset.module.scss";
import { stylesCombinerFn } from "@bd-utils/stylesCombiner";

/**
 * @type {React.FC<{
 *  className: string,
 *  label: string,
 *  variant: string,
 *  classNames: {
 *    fieldset: string,
 *    fieldset__label: string
 *  },
 *  legendTestId?: string
 * }>}
 */
const Fieldset = ({
  className,
  classNames = {},
  label,
  variant,
  children,
  legendTestId,
}) => {
  const c = stylesCombinerFn(s, classNames);
  const isContainScroll = useMemo(() => {
    let result = false;
    React.Children.forEach(children, (child) => {
      if (child && child.type === Scroll) {
        result = true;
      }
    });
    return result;
  }, [children]);

  return (
    <fieldset
      className={c("fieldset", isContainScroll && "is-with-scroll", className)}
    >
      {label ? (
        <legend className={c("fieldset__label")} data-testid={legendTestId}>
          {label}
        </legend>
      ) : null}
      {children}
    </fieldset>
  );
};

Fieldset.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
};

const Content = ({
  className,
  lineVariant,
  children,
  dangerouslySetInnerHTML,
}) => {
  return (
    <div
      className={clsx(s.content, className)}
      style={{
        backgroundImage: `url('/images/fieldset-content${
          lineVariant ? `--${lineVariant}` : ""
        }.svg')`,
      }}
      dangerouslySetInnerHTML={dangerouslySetInnerHTML}
    >
      {children}
    </div>
  );
};

const Scroll = ({ className, classNames = {}, children }) => {
  const c = stylesCombinerFn(s, classNames);
  return (
    <div className={c("scroll", className)}>
      <div className={c("scroll__inner")}>{children}</div>
    </div>
  );
};

Fieldset.Content = Content;
Fieldset.Scroll = Scroll;
export default Fieldset;
