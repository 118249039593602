import React, { forwardRef, useEffect, useRef, useState } from "react";
import { usePrevious } from "react-use";

import s from "./VideoPlayer.module.scss";

const VideoPlayer = forwardRef(
  (
    {
      url,
      disablePlay,
      autoplay = true,
      controls = true,
      type,
      onCanPlayThrough,
      onEnded,
      playOrPauseTestId = "c_VideoPlayer_playOrPause",
    },
    ref,
  ) => {
    const refVideo = useRef();
    const prevUrl = usePrevious(url);
    const prevDisablePlay = usePrevious(disablePlay);
    const [isReady, setIsReady] = useState(false);

    /**
     * @type {import("react").Ref<videojs.Player | null>}
     */
    const refVideoPlayer = useRef();

    const initVideoPlayerEffect = () => {
      if (!refVideo.current) return;
      if (!url) return;
      if (url === prevUrl) return;
      if (disablePlay) return;

      import("video.js").then(async ({ default: videojs }) => {
        const player = (refVideoPlayer.current = videojs(
          refVideo.current,
          {
            autoplay: autoplay,
            preload: "auto",
            controls: controls,
            controlBar: {
              volumePanel: true,
              pictureInPictureToggle: false,
            },
          },
          () => {
            setIsReady(true);
            if (onEnded) player.on("ended", onEnded);
          },
        ));
      });

      return () => {
        if (!refVideoPlayer.current) return;
        if (onEnded) refVideoPlayer.current.off("ended", onEnded);
        refVideoPlayer.current?.dispose();
      };
    };

    const onClickOverlay = (e) => {
      if (!refVideoPlayer.current) return;
      if (refVideoPlayer.current.paused()) {
        refVideoPlayer.current.play();
      } else {
        refVideoPlayer.current.pause();
      }
    };

    // eslint-disable-next-line
    useEffect(initVideoPlayerEffect, [url]);

    useEffect(() => {
      if (disablePlay === prevDisablePlay) return;
      if (!refVideoPlayer.current) return;
      if (url !== prevUrl) return;
      refVideoPlayer.current.pause();
    }, [disablePlay, prevDisablePlay, url, prevUrl]);

    return (
      <div className={s.videoPlayer}>
        <div className={s.videoPlayer__ruler}>
          <div
            className="video-js vjs-default-skin vjs-fill"
            style={{ opacity: isReady ? 1 : 0 }}
            data-vjs-player
          >
            <video
              ref={(node) => {
                refVideo.current = node;
                if (ref) ref.current = node;
              }}
              onCanPlayThrough={onCanPlayThrough}
              preload="auto"
            >
              <source src={url} type={type} />
              <p className="vjs-no-js">
                To view this video please enable JavaScript, and consider
                upgrading to a web browser that
                <a
                  href="https://videojs.com/html5-video-support/"
                  target="_blank"
                  rel="noreferrer"
                >
                  supports HTML5 video
                </a>
              </p>
            </video>

            {/* This is to make the UX better, so whenever user hit an area
          inside video area but somehow also outside videojs area, then 
          overlay will be clicked instead */}
            <div
              data-testid={playOrPauseTestId}
              className={s.videoPlayer__overlay}
              onClick={onClickOverlay}
            ></div>
          </div>
        </div>
      </div>
    );
  },
);

export default VideoPlayer;
