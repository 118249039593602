import s from "./WheelArrow.module.scss";
import clsx from "clsx";

const markbaseUrl = `/images/markbase.webp`;
const spinmarkerUrl = `/images/spinmarker.webp`;

const WheelArrow = ({ isSpinning }) => {
  return (
    <>
      <div className={s.WheelArrow}>
        <div className={s.WheelArrow__base}>
          <svg
            className={clsx(s["WheelArrow__base--overlay"], s.highlight)}
            width="293"
            height="294"
            viewBox="0 0 293 294"
            fill="#ffffff"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M140 -0.000168121C62.0882 3.51094 0 67.788 0 146.567C0 225.137 61.7606 289.282 139.383 293.104C132.3 245.959 128.629 197.696 128.629 148.576C128.629 98.0495 132.514 48.4299 140 -0.000168121Z"
              fill="currentColor"
            />
          </svg>

          <svg
            className={clsx(s["WheelArrow__base--overlay"], s.color)}
            width="293"
            height="294"
            viewBox="0 0 293 294"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M140 0C62.0882 3.51111 0 67.7882 0 146.567C0 225.137 61.7606 289.283 139.383 293.104C132.3 245.959 128.629 197.696 128.629 148.577C128.629 98.0497 132.514 48.43 140 0Z"
              fill="currentColor"
            />
          </svg>
          <picture>
            <source srcSet={markbaseUrl} type="image/webp" />
            <img src={markbaseUrl} alt="markbase" />
          </picture>
        </div>
      </div>
      <div className={clsx(s.WheelArrow__marker, isSpinning && s.active)}>
        <svg
          className={clsx(s["WheelArrow__marker--overlay"], s.highlight)}
          width="74"
          height="35"
          viewBox="0 0 74 35"
          fill="#ffffff"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.5 4C17.2922 4 18.9887 4.40999 20.5008 5.14128L62 11V11.0275C64.25 11.2762 66 13.1837 66 15.5C66 17.8163 64.25 19.7238 62 19.9725V20L20.5008 25.8587C18.9887 26.59 17.2922 27 15.5 27C9.14873 27 4 21.8513 4 15.5C4 9.14873 9.14873 4 15.5 4Z"
            fill="currentColor"
          />
        </svg>

        <svg
          className={clsx(s["WheelArrow__marker--overlay"], s.color)}
          width="74"
          height="35"
          viewBox="0 0 74 35"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.5 4C17.2922 4 18.9887 4.40999 20.5008 5.14128L62 11V11.0275C64.25 11.2762 66 13.1837 66 15.5C66 17.8163 64.25 19.7238 62 19.9725V20L20.5008 25.8587C18.9887 26.59 17.2922 27 15.5 27C9.14873 27 4 21.8513 4 15.5C4 9.14873 9.14873 4 15.5 4Z"
            fill="currentColor"
          />
        </svg>

        <source srcSet={spinmarkerUrl} type="image/webp" />
        <img src={spinmarkerUrl} alt="spin marker" />
      </div>
    </>
  );
};

export default WheelArrow;
