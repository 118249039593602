import { appRoutes } from "@bd-constants/routes";
import useRequirementHelpers from "./useRequirementHelpers";

const useRoundIntroVideoRequirement = () => {
  const { hasLoggedIn, hasStartedGame, hasSeenRoundIntroVideo } =
    useRequirementHelpers();

  return {
    allowed: hasLoggedIn && hasStartedGame && !hasSeenRoundIntroVideo,
    redirect: !hasLoggedIn
      ? appRoutes.welcome
      : !hasStartedGame
      ? appRoutes.welcome
      : hasSeenRoundIntroVideo
      ? appRoutes.home
      : null,
  };
};

export default useRoundIntroVideoRequirement;
