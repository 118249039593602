import PropTypes from "prop-types";

export const SourceShape = PropTypes.shape({
  id: PropTypes.number,
  photo: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  label: PropTypes.string,
});

export const BiasShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  title: PropTypes.string,
});

export const RoleOptionsShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  avatar: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  name: PropTypes.string,
  bias: BiasShape,
  bio: PropTypes.string,
});
