import s from "./WheelSpinner.module.scss";
import clsx from "clsx";
import { Stage, Layer, Group } from "react-konva";
// import WheelLamp from "@bd-components/WheelLamp";

const centerspotUrl = `/images/centerspot.webp`;
const spinbaseUrl = `/images/spinbase.webp`;
const neckUrl = `/images/Neck.webp`;

const WheelSpinner = ({
  diameter,
  // isSpinning,
  animateLayer,
  radius,
  animateGroup,
  renderWheelItems,
}) => {
  return (
    <div className={s.WheelSpinner}>
      <div className={s.WheelSpinner__center}>
        <picture>
          <source srcSet={centerspotUrl} type="image/webp" />
          <img src={centerspotUrl} alt="Center Spot" />
        </picture>
      </div>
      <div className={s.WheelSpinner__base} style={{ width: diameter }}>
        <div className={clsx(s["WheelSpinner__base--overlay"], s.highlight)} />
        <div className={clsx(s["WheelSpinner__base--overlay"], s.color)} />
        <picture>
          <source srcSet={spinbaseUrl} type="image/webp" />
          <img src={spinbaseUrl} alt="Union" />
        </picture>
      </div>
      {/* <WheelLamp isSpinning={isSpinning} diameter={diameter} /> */}
      <div style={{ zIndex: 10 }}>
        <Stage
          width={diameter}
          height={diameter}
          /**
           * NOTE: it's important to add preventDefault to all konva
           * component to not cancel all the default behaviour (such as
           * scrolling), unless if you litening to specific event.
           *
           * @link https://github.com/konvajs/konva/issues/260#issuecomment-526684995
           */
          preventDefault={false}
        >
          <Layer ref={animateLayer} preventDefault={false}>
            <Group
              y={radius}
              x={radius}
              offset={{ x: radius, y: radius }}
              ref={animateGroup}
              preventDefault={false}
            >
              {renderWheelItems}
            </Group>
          </Layer>
        </Stage>
      </div>
      <div className={s.WheelStand}>
        <svg
          className={clsx(s.WheelStand__overlay, s.highlight)}
          width="243"
          height="124"
          viewBox="0 0 243 124"
          fill="currentColor"
        >
          <path d="M25 0H218L243 124H0L25 0Z" fill="currentColor" />
        </svg>

        <svg
          className={clsx(s.WheelStand__overlay, s.color)}
          width="243"
          height="124"
          viewBox="0 0 243 124"
          fill="currentColor"
        >
          <path d="M25 0H218L243 124H0L25 0Z" fill="currentColor" />
        </svg>
        <picture>
          <source srcSet={neckUrl} type="image/png" />
          <img src={neckUrl} alt="Union" />
        </picture>
      </div>
      <div className={s.Wheel__gradient} />
    </div>
  );
};

export default WheelSpinner;
