/**
 * Truncate string and replace sliced characters with ellipsis if string exceeds max length
 * @param {String} string
 * @param {Number} max
 * @returns {String}
 */
const truncateString = (string, max) => {
  return string.length > max ? string.substring(0, max) + "..." : string;
};

export default truncateString;
