import parseHTML from "./parseHTML";

/**
 * Extract sentence from string that contains specific word.
 *
 * @param {String} word
 * @param {String} text
 */
const getSentenceFromInput = (word, text) => {
  // sanitize text to make sure it doesn't contain HTML tags
  const sanitizedText = parseHTML(text);

  const sentenceArray = sanitizedText
    .replace(/([.?!])\s*(?=[A-Z])/g, "$1|")
    .split("|");

  // lowercase matching just in case word in sentence is mixed or uppercase
  return sentenceArray.filter(
    (sentence) => sentence.toLowerCase() /*.includes(word.toLowerCase()) */,
  );
};

export default getSentenceFromInput;
