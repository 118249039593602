import { useState } from "react";
import { useDispatch } from "react-redux";
import { logEvent } from "firebase/analytics";
import { useTranslation } from "react-i18next";
import axios from "axios";

import useStore from "@bd-hooks/useStore";
import useSnackbarState from "@bd-hooks/useSnackbarState";
import useFirebaseActions from "@bd-hooks/useFirebaseActions";
import useFirebaseAnalytics from "@bd-hooks/useFirebaseAnalytics";
import { setIsBackEditingSetupName } from "@bd-redux/temporal";

const useStateSetupProfile = () => {
  const { t } = useTranslation();
  const store = useStore();
  const dispatch = useDispatch();
  const snackbar = useSnackbarState();
  const analytics = useFirebaseAnalytics();
  const [name, setName] = useState(store.currentPlayer?.name ?? "");
  const [nameErr, setNameErr] = useState(null);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const { updateName } = useFirebaseActions();

  const onChangeName = (e) => {
    if (isSubmiting) return;
    setNameErr(null);
    setName(e.target.value);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (!name) return setNameErr(t("Please fill your name"));

    if (analytics) {
      logEvent(analytics, "setup_name_submited");
    }

    setIsSubmiting(true);
    snackbar.close();
    updateName(name).finally(() => setIsSubmiting(false));
    dispatch(setIsBackEditingSetupName(false));

    /** @type {UtilAxiosPost<ApiRequestSetupProfile, ApiResponseSetupProfile>} */
    const request = axios.patch;
    request("/member/update-player-name", { name: name }).then((res) => {
      if (!res.data.success) {
        snackbar.error({ message: res.data.error });
        return false;
      }
    });
  };

  return {
    name,
    onChangeName,
    nameErr,
    isSubmiting,
    onSubmit,
    snackbar,
  };
};

export default useStateSetupProfile;
