import convertObjToArr from "@bd-utils/convertObjToArr";
import useStore from "./useStore";

const useDerivedWarning = () => {
  const { gameData } = useStore();
  const { warnings } = gameData.config;

  const warningData = convertObjToArr(warnings);

  return {
    warningData,
  };
};

export default useDerivedWarning;
