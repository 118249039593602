import { useState, useEffect } from "react";
import shuffleArrayKeys from "@bd-utils/shuffleArrayKeys";
import useStore from "@bd-hooks/useStore";
import clsx from "clsx";
import s from "./NameRoulette.module.scss";

const NameRoulette = ({ data, isSpinning, duration }) => {
  const [randomList, setRandomList] = useState([]);
  const store = useStore();
  const players = store.gameProgress.players;
  let playerNames = [];
  const winner = players.find((p) => p.userId === data?.userId);
  players.forEach((player) => {
    playerNames.push(player.name);
  });
  const memberListMultiplied = Array.from(
    { length: 55 },
    (_, index) => playerNames[index % playerNames.length],
  );

  useEffect(() => {
    const randomList = shuffleArrayKeys(memberListMultiplied);
    setRandomList(randomList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSpinning]);

  const displayListName = (isSpinning) => (
    <div
      className={clsx(
        s["NameRoulette__item-container"],
        isSpinning ? s["is-spinning"] : s["is-not-spinning"],
      )}
      style={{
        transition: `all ${isSpinning ? duration : 0}ms ease-in-out`,
      }}
    >
      {isSpinning ? (
        randomList.map((member, index) => {
          return <p key={index}>{member}</p>;
        })
      ) : (
        <p>{winner?.name}</p>
      )}
    </div>
  );

  return (
    <div className={s.NameRoulette}>
      <div className={clsx(s.NameRoulette__arrow, isSpinning && s.spinning)}>
        <span />
      </div>
      <div className={s.NameRoulette__box}>{displayListName(isSpinning)}</div>
      <div className={clsx(s.NameRoulette__arrow, isSpinning && s.spinning)}>
        <span />
      </div>
    </div>
  );
};

export default NameRoulette;
