import React, { useLayoutEffect, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { useWindowSize } from "react-use";
import { logEvent } from "firebase/analytics";
import {
  useFloating,
  autoPlacement,
  shift,
  offset,
} from "@floating-ui/react-dom";
import s from "./Tutorial.module.scss";
import Popup from "@bd-components/Popup";
import WaitingChecklist from "@bd-components/WaitingChecklist";
import useStore from "@bd-hooks/useStore";
import useDerivedLoading from "@bd-hooks/useDerivedLoading";
import useDerivedTutorial from "@bd-hooks/useDerivedTutorial";
import useFirebaseAnalytics from "@bd-hooks/useFirebaseAnalytics";
import { generateDimmedClasses } from "./generateDimmedClasses";
import Watson from "@bd-components/Watson";
import useFirebaseActions from "@bd-hooks/useFirebaseActions";
import convertObjToArr from "@bd-utils/convertObjToArr";
import { useTranslation } from "react-i18next";
import useSound from "use-sound";

const Tutorial = ({ children }) => {
  const { t } = useTranslation();
  const { height, width } = useWindowSize();
  const { reference } = useFloating({
    middleware: [
      autoPlacement({ autoAlignment: true }),
      offset(8),
      shift({ padding: 16 }),
    ],
  });

  const { gameData, gameProgress, userProgress, combineHistory } = useStore();
  const { current, isAllowed, isWaitingGroupTutorial, tutorialData } =
    useDerivedTutorial();
  const { game_audio } = gameData.config;

  const [currentIndex, setCurrentIndex] = useState(
    userProgress.currentTutorialIndex ?? 0,
  );
  const [currentLine, setCurrentLine] = useState(0);
  const [topPos, setTopPos] = useState(0);
  const [leftPos, setLeftPos] = useState(0);
  const [canContinue, setCanContinue] = useState(true);
  const [placement, setPlacement] = useState("left");
  const [isDynamic, setIsDynamic] = useState(false);
  const [isTutorialEnd, setIsTutorialEnd] = useState(false);
  const [selectors, setSelectors] = useState(undefined);
  const [isTutorialRequireAction, setIsTutorialRequireAction] = useState(false);
  const [dimmed, setDimmed] = useState({});
  const tuts = gameData.config.tutorials;
  const analytics = useFirebaseAnalytics();
  const isLoading = useDerivedLoading();
  const enabled = isAllowed && !isLoading;
  const [play] = useSound(game_audio?.notification_watson, {
    volume: 0.5,
  });

  const tutorialDataArr = convertObjToArr(tutorialData);

  const allSelectors = Object.values(dimmed)
    .filter((c) => c?.length > 0)
    .join(", ");

  const isRootInAll = /(#root$|#root,)/gm.test(allSelectors);
  const isRootInCurrent = /(#root$|#root,)/gm.test(selectors);
  const isDimmedRootOnly = isRootInAll && isRootInCurrent;

  const { setCurrentTutorialIndex } = useFirebaseActions();

  const textLimit =
    currentLine >
    tutorialDataArr[currentIndex]?.text.match(/\S[^.?!]*[.?!]/g)?.length - 2;

  const next = () => {
    if (
      (combineHistory.data.length === 1 &&
        userProgress.hasSeenFirstClue === false) ||
      currentIndex >= 4
    ) {
      return;
    }

    if (analytics) logEvent(analytics, "tutorial_next");

    const goToNextTutorialSlide = () => {
      setCurrentLine(0);
      setCurrentIndex(currentIndex + 1);
      setCurrentTutorialIndex(currentIndex + 1);
    };

    if (textLimit) {
      if (currentIndex === 3) {
        setIsTutorialEnd(true);
        setTimeout(() => {
          goToNextTutorialSlide();
        }, 425);
      } else {
        play();
        goToNextTutorialSlide();
      }
    } else {
      setCurrentLine(currentLine + 1);
    }
  };

  /**
   * (Future Steps Index = frooti)
   * List of next step indices
   * @type {number[]}
   */
  const fstepsi = Object.keys(dimmed)
    .filter((i) => parseInt(i) > currentIndex)
    .map(parseInt);

  /**
   * (Future Root Index = frooti)
   * List of next step indices that doesn't have a target
   */
  const frooti = fstepsi.filter((i) => !tuts[i]?.html_selector);

  /**
   * Set floating reference
   */
  useLayoutEffect(() => {
    // The fallback selector will not be used in the positioning, because we
    // will setup the float element in the center of the page if no target
    // selected
    const target = current?.html_selector
      ? document.querySelector(current.html_selector)
      : document.querySelector("body");
    reference(target);
  }, [reference, current, height, width]);

  /**
   * Setting blinking state for the first card
   */
  useEffect(() => {
    const firstCardEl = document.querySelectorAll(
      ".Draggable-module__draggable",
    )[0];
    if (currentLine >= 2) {
      firstCardEl?.classList.add("Gameplay_BoardPlay-module__is-blinking");
    }
  }, [currentIndex, currentLine]);

  /**
   * Set the dimmed element
   */
  useEffect(() => {
    let currentDimmed = "";
    if (currentIndex === 2 && userProgress.hasSeenFirstClue) {
      next();
      return;
    }

    switch (currentIndex) {
      case 1:
        let timerEl = document.querySelector(
          ".TimerStatic-module__timerStatic",
        );
        let rect = timerEl?.getBoundingClientRect();
        timerEl?.classList.remove("display-none");
        setTopPos(rect?.top);
        setLeftPos(rect?.left);
        setPlacement("right");
        setIsDynamic(true);
        currentDimmed = generateDimmedClasses(
          ".TimerStatic-module__timerStatic",
        );
        break;
      case 2:
        const boardRulerEl = document.querySelector(
          ".Board-module__board__ruler",
        );
        boardRulerEl?.classList.remove("display-none");
        setPlacement("left");
        setIsDynamic(false);
        setCanContinue(combineHistory.data.length > 0);
        setIsTutorialRequireAction(combineHistory.data.length < 1);
        currentDimmed = generateDimmedClasses(".Board-module__board");
        break;
      case 3:
        const intelEl = document.querySelector(".IntelButton-module__wrapper");
        intelEl?.classList.remove("display-none");
        setPlacement("right");
        setIsDynamic(false);
        currentDimmed = generateDimmedClasses(".IntelButton-module__wrapper");
        break;
      default:
        setTopPos(0);
        setLeftPos(0);
        setPlacement("left");
        setIsDynamic(false);
        currentDimmed = "#root";
    }

    // const currentDimmed = current?.html_selector
    //   ? generateDimmedClasses(current.html_selector)
    //   : "#root";

    setDimmed((prev) => {
      // Remove all value for next steps that doesn't have a target, so root
      // is not dimmed if user go to previous step that does have a target
      const override = frooti.reduce((a, c) => ({ ...a, [c]: undefined }), {});

      return {
        ...prev,
        ...override,
        [currentIndex]: currentDimmed,
      };
    });

    setSelectors(currentDimmed);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex, combineHistory.data.length, userProgress.hasSeenFirstClue]);

  // if (!current) return null;

  const isClueShown =
    combineHistory.data.length === 1 && userProgress.hasSeenFirstClue === false;

  let readyUsers = gameProgress.players?.filter(
    (p) => p.currentTutorialIndex === 2, // first drag tutorial
  );
  if (currentIndex > 2) {
    readyUsers = gameProgress.players?.filter(
      (p) => p.currentTutorialIndex === tutorialDataArr.length,
    );
  }

  return enabled
    ? createPortal(
        <>
          <Popup isOpen={isWaitingGroupTutorial}>
            <WaitingChecklist
              // readyUserIds={gameProgress.seenTutorials[prevTutorial?.id]}
              readyUserIds={readyUsers.map((q) => q.userId)}
            />
          </Popup>

          {!isWaitingGroupTutorial && !isClueShown ? (
            <style>
              {/*
                If current step doesn't have any target, then we will dim the
                #root only, why exclude anything else? to make sure the 
                brightness effect is not overlapped from one element to the other
                otherwise, it will result in unbalance dim effect where some spot
                is darker than the other element
              */}
              {`${isDimmedRootOnly ? "#root" : selectors} {
                filter: brightness(0.2);
                pointer-events: none;
              }`}
            </style>
          ) : null}

          {!isWaitingGroupTutorial && !isClueShown && (
            <button
              className={s.Tutorial__next}
              type="button"
              onClick={next}
              disabled={isTutorialRequireAction && textLimit}
            />
          )}

          {!isWaitingGroupTutorial && !isClueShown
            ? tutorialDataArr.map((tutorial, index) =>
                currentIndex === index ? (
                  <Watson
                    key={tutorial.id}
                    title={tutorial.title}
                    text={tutorial.text.match(/\S[^.?!]*[.?!]/g)?.[currentLine]} // this complicated regex code just means to separate a string by delimiters (but keep them instead of removing)
                    placement={placement}
                    isDynamic={isDynamic}
                    isEnd={isTutorialEnd}
                    top={topPos}
                    left={leftPos}
                  />
                ) : null,
              )
            : null}

          {!isWaitingGroupTutorial && currentIndex <= 4 && !isClueShown && (
            <div
              className={s.Tutorial__actions}
              // style={{ display: !canContinue && "none" }}
            >
              <p
                style={{ display: !canContinue && textLimit && "none" }}
                onClick={next}
              >
                {t("Click anywhere to continue")}
              </p>
            </div>
          )}
        </>,
        document.getElementById("tutorialRoot"),
      )
    : null;
};

export default Tutorial;
