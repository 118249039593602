import { useEffect, useState } from "react";

import setCurrentPage from "@bd-apis/setCurrentPage";
import useFirebaseActions from "@bd-hooks/useFirebaseActions";
import useNextRound from "@bd-hooks/useNextRound";
import useHelpers from "@bd-hooks/useHelpers";
import usePopup from "@bd-hooks/usePopup";
import useStore from "@bd-hooks/useStore";

const useLobbyState = () => {
  const { setStartedEntireGame } = useFirebaseActions();
  const { gameProgress, currentPlayer, temporal, userProgress, gameData } =
    useStore();
  const { players } = gameProgress;
  const totalPlayers = players.length;
  const readyPlayers = players
    .filter((p) => {
      return p.hasSeenIntro && p.hasCompletedMantapPreGame;
    })
    .map((p) => p.userId);

  const totalReadyPlayers = readyPlayers.length;
  const colors = ["#6DC8D8", "#FDA754", "#78D864", "#FA6258"];
  const isCurrentPlayerReady = readyPlayers.includes(currentPlayer?.userId);
  const isForceable = totalPlayers === totalReadyPlayers;
  const forcePopup = usePopup();
  const nextRound = useNextRound();
  const isMantapEnabled =
    !gameProgress.disabledMantap && userProgress.mantapUrl?.length > 0;
  const helpers = useHelpers();
  const banner = gameData.config.pages.waiting_room.banner_image;

  const removePlayerPopup = usePopup();
  const [isTryingToContinue, setIsTryingToContinue] = useState(false);

  const onContinue = () => {
    setIsTryingToContinue(true);
    helpers.setRound(nextRound);
    setStartedEntireGame();
  };

  useEffect(() => {
    if (!temporal.isInterceptorReady) return;
    setCurrentPage("Waiting Room", gameProgress.teamId);
  }, [gameProgress.teamId, temporal.isInterceptorReady]);

  return {
    players,
    readyPlayers,
    colors,
    totalPlayers,
    totalReadyPlayers,
    onContinue,
    isForceable,
    isCurrentPlayerReady,
    currentPlayer,
    forcePopup,
    removePlayerPopup,
    banner,
    isMantapEnabled,
    isTryingToContinue,
  };
};

export default useLobbyState;
