import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { setInitializedLanguage } from "@bd-redux/language";
import useStore from "./useStore";

/**
 * Manage language selection between i18n and language preference that is
 * stored inside redux
 */
const useLanguage = () => {
  const { language } = useStore();
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const languageCode = language.current.value;
  const isInitialized = language.initialized;

  /**
   * Controller that decide whether to trigger i18n to change language or not
   * based on the stored state and current used language
   */
  useEffect(
    () => {
      if (!i18n.language) return;

      const shouldUpdate = i18n.language !== languageCode;
      if (!shouldUpdate) return;

      i18n.changeLanguage(languageCode);
    },
    // eslint-disable-next-line
    [languageCode],
  );

  /**
   * Set initialized to true whenever the language is ready to use
   */
  useEffect(() => {
    if (!i18n.language) return;
    if (i18n.language !== languageCode) return;
    if (isInitialized) return;
    dispatch(setInitializedLanguage(true));
  }, [i18n.language, isInitialized, languageCode, dispatch]);
};

export default useLanguage;
