import { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";

import Wheel from "@bd-components/Wheel";
import Button from "@bd-components/Button";
import WallBackground from "@bd-components/WallBackground";
import PagePermission from "@bd-components/PagePermission";
import DebriefingWaiting from "@bd-components/DebriefingWaiting";
import DebriefingQuestion from "@bd-components/DebriefingQuestion";
import useStore from "@bd-hooks/useStore";
import useFirebaseActions from "@bd-hooks/useFirebaseActions";
import getNowEpoch from "@bd-utils/getNowEpoch";
import randomMinMax from "@bd-utils/randomMinMax";

import s from "./Debriefing.module.scss";
import getNextQuestions from "./getNextQuestions";
import usePermissionDebriefing from "./usePermissionDebriefing";

const Debriefing = () => {
  const { t } = useTranslation();

  const {
    setSeenDebriefing,
    setReadyDebriefing,
    addTeamConnectorHistory,
    forceFinishTeamConnectorHistory,
  } = useFirebaseActions();

  /**
   * @type {UtilState<(TeamConnectorHistoryItem & {
   *  categoryIndex?: number,
   *  spinDuration?: number,
   *  index?: number
   *  isOpenQuestion?: boolean
   * }) | undefined>}
   */
  const [currentHistory, setCurrentHistory] = useState();
  const [level, setLevel] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const store = useStore();
  const tcHistory = store.gameProgress.teamConnectorHistory;

  const leverForceRef = useRef();

  const tcQuestions = store.gameData.config.team_connector.categories
    .map((c) => c.questions.map((q) => ({ ...q, categoryId: c.cat_id })))
    .flat();

  const nextQuestionIds = getNextQuestions(store.gameData, store.gameProgress);

  const readyDebriefing = store.gameProgress.playersReadyDebriefing;
  const amIReady = readyDebriefing.includes(store.currentPlayer.userId);
  const isEverybodyReady =
    readyDebriefing.length >= store.gameProgress.players.length;

  const categories = store.gameData.config.team_connector.categories.map(
    (c) => ({
      icon: c.attr.icon,
      color: c.attr.color,
      text_color: c.attr.text_color,
      category_id: c.cat_id,
      category_name: c.cat_name,
    }),
  );

  const onContinue = () => {
    setIsLoading(true);
    setSeenDebriefing();
  };

  const onReady = () => {
    setIsLoading(true);
    setReadyDebriefing(true).finally(() => setIsLoading(false));
  };

  const onCancelReady = () => {
    setIsLoading(true);
    setReadyDebriefing(false).finally(() => setIsLoading(false));
  };

  const teamConnectorHistoryLength = tcHistory.length;
  const current = tcHistory[tcHistory.length - 1];

  /**
   * Get the very last spin from Team Connector history (whether it's active or
   * not)
   *
   * @returns {{
   *  data: TeamConnectorHistoryItem | undefined,
   *  isStillRunning: boolean
   * }}
   */
  const getLastSpin = () => {
    if (tcHistory.length < 1) {
      return {
        data: undefined,
        isStillRunning: false,
      };
    }

    const { shouldFinishQuestionAt, forceFinished } = current;

    const now = getNowEpoch();
    const remainingQuestionDuration = shouldFinishQuestionAt - now;
    const isStillRunning = !forceFinished && remainingQuestionDuration > 0;

    return {
      data: current,
      isStillRunning,
    };
  };

  const onClickSpin = () => {
    const last = getLastSpin();
    if (last.isStillRunning) return;

    const offsetLeft = leverForceRef.current.offsetLeft;
    let forcePower;

    if (offsetLeft <= 5) {
      forcePower = 0.2;
      setLevel("LOW");
    } else if (offsetLeft >= 6 && offsetLeft <= 20) {
      forcePower = 0.5;
      setLevel("MEDIUM");
    } else if (offsetLeft >= 21 && offsetLeft <= 40) {
      forcePower = 1;
      setLevel("HIGH");
    } else if (offsetLeft >= 41 && offsetLeft <= 50) {
      forcePower = 0.5;
      setLevel("MEDIUM");
    } else if (offsetLeft >= 50) {
      forcePower = 0.2;
      setLevel("LOW");
    }

    const speedForce = 5 + 10 * forcePower;
    const randomDuration = speedForce / 2;
    const targetDuration = Math.max(0.3, Math.min(randomDuration, 5));

    /**
     * Randomize next data
     */

    const min = 0;
    const max = nextQuestionIds.length - 1;
    const players = store.gameProgress.players.filter(
      (p) => !store.gameProgress.playersSeenDebriefing.includes(p.userId),
    );

    const questionIndex = randomMinMax(min, max);
    const questionId = nextQuestionIds[questionIndex];
    const categoryId = tcQuestions.find((q) => q.id === questionId).categoryId;

    const userIndex = randomMinMax(0, players.length - 1);
    const userId = players[userIndex].userId;
    const spinDurationInSecond = targetDuration;
    const settings = store.gameData.config.team_connector.settings;
    const questionDurationInMin = settings.popup_timer;
    const questionDurationInSecond = questionDurationInMin * 60;

    addTeamConnectorHistory({
      index: store.gameProgress.teamConnectorHistory.length,
      categoryId,
      questionId,
      userId,
      spinDuration: spinDurationInSecond,
      questionDuration: questionDurationInSecond,
    }).catch((err) => {
      if (err?.code === "PERMISSION_DENIED") {
        console.error("Denied, someone already spin the wheel");
        return;
      }

      console.error("error to spin the wheel in the firebase", {
        err,
        code: err?.code,
      });
    });
  };

  const onForceFinish = () => {
    forceFinishTeamConnectorHistory(tcHistory.length - 1).finally(() => {
      setCurrentHistory((prev) => ({
        ...prev,
        forceFinished: true,
        isOpenQuestion: false,
      }));
    });
  };

  const resetCurrentHistory = () => setCurrentHistory(undefined);

  const onStopSpin = () => {
    setCurrentHistory((prev) => ({ ...prev, isOpenQuestion: true }));
  };

  useEffect(() => {
    if (tcHistory.length < 1) return;

    const isAnswering = current?.shouldFinishQuestionAt
      ? current?.shouldFinishQuestionAt > getNowEpoch()
      : false;
    const hasStoppedSpin = current?.shouldFinishSpinAt - 0.2 <= getNowEpoch();
    const isOpenQuestion =
      hasStoppedSpin && isAnswering && !current.forceFinished;

    const shouldUpdateIsOpenQuestion =
      currentHistory?.isOpenQuestion && !isOpenQuestion;

    if (tcHistory.length - 1 === currentHistory?.index) {
      if (shouldUpdateIsOpenQuestion)
        setCurrentHistory((prev) => ({ ...prev, isOpenQuestion }));
      return;
    }

    const categoryIndex = categories.findIndex(
      (o) => o.category_id === current.categoryId,
    );

    const spinDuration =
      typeof current?.shouldFinishSpinAt === "number"
        ? (current.shouldFinishSpinAt - getNowEpoch()) * 1000
        : current?.shouldFinishSpinAt;

    const index = tcHistory.length - 1;

    setCurrentHistory({
      ...current,
      categoryIndex,
      spinDuration,
      index,
      isOpenQuestion,
    });

    // eslint-disable-next-line
  }, [teamConnectorHistoryLength, current?.forceFinished]);

  return (
    <WallBackground>
      <div className={s.Debriefing__container}>
        <Wheel
          data={categories}
          onSelect={onStopSpin}
          textStyle={{ fontFamily: '"Scripto", sans-serif' }}
          onClickSpin={onClickSpin}
          value={currentHistory?.categoryIndex}
          duration={currentHistory?.spinDuration}
          shouldFinishSpinAt={currentHistory?.shouldFinishSpinAt}
          isOpenQuestion={currentHistory?.isOpenQuestion}
          historyData={currentHistory}
          isLevel={level}
          leverForceRef={leverForceRef}
        />
      </div>
      <Button
        className={s.Debriefing__button}
        size="medium"
        onClick={onContinue}
        isLoading={isLoading}
        text={t("Finish TeamConnector")}
      ></Button>
      <DebriefingWaiting
        isOpen={!isEverybodyReady}
        amIReady={amIReady}
        onReady={onReady}
        onCancelReady={onCancelReady}
        isLoading={isLoading}
      />
      <DebriefingQuestion
        isOpen={currentHistory?.isOpenQuestion}
        currentHistoryIndex={currentHistory?.index}
        setCurrentHistoryIndex={resetCurrentHistory}
        data={currentHistory}
        onForceFinish={onForceFinish}
      />
    </WallBackground>
  );
};

export default PagePermission(usePermissionDebriefing, Debriefing);
