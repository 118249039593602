import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { logEvent } from "firebase/analytics";
import { useDispatch } from "react-redux";
import axios from "axios";

import { TOTAL_PLAYER_REQUIRED } from "@bd-constants/config";
import { getUserByUserId } from "@bd-utils/users";
import { reset, setSnapshot } from "@bd-redux/kicked-snapshot";
import getNowEpoch from "@bd-utils/getNowEpoch";
import useStore from "./useStore";
import usePopup from "./usePopup";
import useAuthProcess from "./useAuthProcess";
import useSnackbarState from "./useSnackbarState";
import useFirebaseActions from "./useFirebaseActions";
import useFirebaseAnalytics from "./useFirebaseAnalytics";
import useNextRound from "./useNextRound";
// import useHelpers from "./useHelpers";

const useLobbyState = () => {
  const { t } = useTranslation();

  const {
    updateReadiness,
    updateStartedAt,
    updateStartedCountdownAt,
    removePlayer: fbRemovePlayer,
  } = useFirebaseActions();

  const {
    gameProgress: realGameProgress,
    firebase,
    currentPlayer: realCurrentPlayer,
    userProgress,
    kickedSnapshot,
  } = useStore();

  const gameProgress = realGameProgress.teamId
    ? realGameProgress
    : kickedSnapshot.gameProgress;

  const currentPlayer = realCurrentPlayer
    ? realCurrentPlayer
    : { ...kickedSnapshot.user, ready: false };

  const { players } = gameProgress;
  const totalPlayers = players.length;
  const readyPlayers = players.filter((p) => p.ready).map((p) => p.userId);
  const totalReadyPlayers = readyPlayers.length;
  const colors = ["#6DC8D8", "#FDA754", "#78D864", "#FA6258"];
  const totalPlaceholder = TOTAL_PLAYER_REQUIRED - players.length;
  const placeholders = new Array(totalPlaceholder).fill({});
  const isCurrentPlayerReady = readyPlayers.includes(currentPlayer?.userId);
  const isForceable =
    totalPlayers === totalReadyPlayers || currentPlayer.isRejoinUser;
  const isMultilanguage =
    realGameProgress.teamId && !realGameProgress.disabledLanguageSwitcher;
  // const isAutoStart = TOTAL_PLAYER_REQUIRED === totalReadyPlayers;
  const forcePopup = usePopup();
  const exitPopup = usePopup();
  const dispatch = useDispatch();
  const snackbar = useSnackbarState();
  const snackbarError = snackbar.error;
  const analytics = useFirebaseAnalytics();
  const nextRound = useNextRound();
  // const helpers = useHelpers();

  const [isTryingToStart, setIsTryingToStart] = useState(false);
  const [isTryingToExit, setIsTryingToExit] = useState(false);

  const removePlayerPopup = usePopup();
  const [playerWillBeRemoved, setPlayerWillBeRemoved] = useState({
    name: null,
    userId: null,
  });

  /**
   * @type {ApiPlayerType[]}
   */
  const playersToRender = [...players, ...placeholders];

  const onToggleReady = () => {
    if (analytics) {
      logEvent(analytics, "ready_button_clicked", {
        actor: currentPlayer.userId,
      });
    }
    updateReadiness(!currentPlayer.ready);
  };

  const start = useCallback(() => {
    /** @type {UtilAxiosPost<ApiRequestSetGameStarted, ApiResponseSetGameStarted>}*/
    const request = axios.post;
    const startedAt = getNowEpoch();
    setIsTryingToStart(true);

    if (analytics) {
      logEvent(analytics, "start_game_clicked", {
        actor: currentPlayer.userId,
      });
    }

    request("/game/set-game-started", { team_id: gameProgress.teamId }).then(
      (res) => {
        if (!res.data.success) {
          console.error("Failed to start game in the server");
          snackbarError({ message: res.data.error });
          return;
        }

        if (!nextRound) {
          console.error("No playable rounds");
          return;
        }

        // helpers.setRound(nextRound);
        updateStartedAt(startedAt).finally(() => setIsTryingToStart(false));
      },
    );

    // eslint-disable-next-line
  }, [firebase.teamPath, gameProgress.teamId, snackbarError]);

  const startCountdown = () => {
    const startedCountdownAt = getNowEpoch();

    updateStartedCountdownAt(startedCountdownAt);
  };

  const removePlayer = () => {
    const userId = playerWillBeRemoved.userId;
    removePlayerPopup.close();
    fbRemovePlayer(userId);

    if (analytics) {
      logEvent(analytics, "remove_player", {
        actor: currentPlayer.userId,
        victim: userId,
      });
    }

    /** @type {UtilAxiosPost<ApiRequestRemovePlayer, ApiResponseRemovePlayer>}*/
    const request = axios.post;
    request("/member/remove-player", {
      team_id: gameProgress.teamId,
      user_id: userId,
    }).then((res) => {
      if (!res.data.success) {
        console.error("Failed to remove user in the server");
      }
    });
  };

  /**
   * @param {ApiPlayerType["userId"]} userId
   */
  const onRemovePlayer = (userId) => {
    setPlayerWillBeRemoved(getUserByUserId(userId, players));
    removePlayerPopup.open();
  };

  const onForceStart = () => {
    forcePopup.open();
  };

  const onExit = () => {
    exitPopup.open();
  };

  const exitGame = () => {
    const { userId } = currentPlayer;
    exitPopup.close();
    setIsTryingToExit(true);

    if (analytics) {
      logEvent(analytics, "exit_game_clicked", {
        actor: currentPlayer.userId,
      });
    }

    /** @type {UtilAxiosPost<ApiRequestRemovePlayer, ApiResponseRemovePlayer>}*/
    const request = axios.post;
    request("/member/remove-player", {
      team_id: gameProgress.teamId,
      user_id: userId,
    }).then((res) => {
      if (!res.data.success) {
        console.error("Failed to remove user in the server");
        snackbar.error({ message: res.data.error });
        return;
      }

      fbRemovePlayer(userId)
        .then(() => authProcess.logout())
        .finally(() => setIsTryingToExit(false));
    });
  };

  /**
   * Auto-start effect
   */
  // useEffect(() => {
  //   if (isAutoStart) start();
  // }, [isAutoStart, start]);

  const authProcess = useAuthProcess();
  const snackbarOnClose = () => {
    dispatch(reset());
    snackbar.close();
  };

  useEffect(
    () => {
      if (!kickedSnapshot.notify) return;
      snackbar.error({ message: t("You've been removed from the game") });
    },
    // eslint-disable-next-line
    [],
  );

  useEffect(
    () => {
      if (userProgress.hasBeenKicked) {
        snackbar.error({ message: t("You've been removed from the game") });
        dispatch(
          setSnapshot({
            gameProgress: realGameProgress,
            user: userProgress,
            notify: true,
          }),
        );
        authProcess.logout();
      }
    },
    // eslint-disable-next-line
    [userProgress.hasBeenKicked],
  );

  /**
   * Auto-hide forcePopup on isForceable change to false
   */
  useEffect(() => {
    if (!isForceable && forcePopup.isOpen) forcePopup.close();
  }, [isForceable, forcePopup]);

  const isRejoinUser = userProgress.isRejoinUser;

  return {
    players: playersToRender,
    readyPlayers,
    colors,
    totalPlayers,
    totalReadyPlayers,
    requiredPlayers: TOTAL_PLAYER_REQUIRED,
    removePlayer,
    onToggleReady,
    onRemovePlayer,
    onForceStart,
    start,
    startCountdown,
    isForceable,
    isCurrentPlayerReady,
    currentPlayer,
    forcePopup,
    removePlayerPopup,
    playerWillBeRemoved,
    isTryingToStart,
    isTryingToExit,
    isMultilanguage,
    onExit,
    exitPopup,
    exitGame,
    snackbar: {
      ...snackbar,
      close: snackbarOnClose,
    },
    isRejoinUser,
  };
};

export default useLobbyState;
