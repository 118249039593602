import { getAnalytics } from "firebase/analytics";
import { useIsFirebaseConected } from "./useFirebaseListener";

/**
 * @returns {import("firebase/analytics").Analytics | undefined}
 */
const useFirebaseAnalytics = () => {
  const isConnected = useIsFirebaseConected();
  const analytics = isConnected ? getAnalytics() : undefined;
  return analytics;
};

export default useFirebaseAnalytics;
