/**
 * Get roleId (NOT role data) that is played by particular user, will return
 * false if turns out the user has not selected a role.
 * @param {ApiPlayerType["userId"]} userId
 * @param {StoreGameProgressType['players']} players
 */
export const getRoleIdByUserId = (userId, players) => {
  if (!userId) return false;
  const roleId =
    players.filter((p) => p.userId === userId)?.[0]?.roleId || false;
  return roleId;
};

/**
 * Get role data by particular roleId.
 * @param {ApiRoleType["id"]} roleId
 * @param {StoreGameDataType["roles"]} roles
 */
export const getRoleByRoleId = (roleId, roles) => {
  const role = roles.filter((r) => r.id === roleId)?.[0] || false;
  const roleByParentId =
    roles.filter((r) => r.parent_id === roleId)?.[0] || false;

  return role || roleByParentId;
};

/**
 * Get role data that is played by particular user, will return false if user
 * has not selected a role.
 * @param {ApiPlayerType["userId"]} userId
 * @param {StoreGameProgressType['players']} players
 * @param {StoreGameDataType['roles']} roles
 */
export const getRoleByUserId = (userId, players, roles) => {
  const roleId = getRoleIdByUserId(userId, players);
  if (!roleId) return false;

  const role = getRoleByRoleId(roleId, roles);
  if (!role) return false;

  return role;
};
