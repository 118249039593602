import { appRoutes } from "@bd-constants/routes";
import useRequirementHelpers from "./useRequirementHelpers";

const useIntroRequirement = () => {
  const { hasLoggedIn, hasSeenIntro } = useRequirementHelpers();

  return {
    allowed: hasLoggedIn && !hasSeenIntro,
    redirect: !hasLoggedIn
      ? appRoutes.welcome
      : hasSeenIntro
      ? appRoutes.waitingRoom
      : null,
  };
};

export default useIntroRequirement;
