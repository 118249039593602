import axios from "axios";
import goAsync from "@bd-utils/goAsync";

/**
 * Get available language from the backend
 */
const getAvailableLanguages = async () => {
  /**
   * @type {UtilAxiosPost<ApiResponsePublicLang>}
   */
  const request = axios.post;
  const [response, err] = await goAsync(request("/public/lang"));

  if (err) {
    console.error("Failed to get available languages", { err });
    return Promise.reject();
  }

  return response;
};

export default getAvailableLanguages;
