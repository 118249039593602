import useActiveRoundData from "./useActiveRoundData";
import useStore from "./useStore";

const useNextRound = () => {
  const { gameData } = useStore();
  const currentRound = useActiveRoundData();
  const currentRoundIndex = currentRound?.index ?? 0;
  const _next = gameData.rounds.filter(
    (r) => r.index === currentRoundIndex + 1,
  );
  const nextRound = _next.length > 0 ? _next[0] : false;

  return nextRound;
};

export default useNextRound;
