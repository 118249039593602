/**
 * Copy string to clipboard
 * @param {String} text
 * @returns {Boolean}
 */
const copyToClipboard = async (text) => {
  try {
    if (window.isSecureContext && navigator.clipboard) {
      await navigator.clipboard.writeText(text);
    } else {
      unsecureCopyToClipboard(text);
    }
    return true;
  } catch (err) {
    console.error("Failed to copy: ", err);
    return false;
  }
};

/**
 * Same function for HTTP instead of HTTPS
 * The regular function does not work if the origin isn't secure
 * @param {String} text
 */
const unsecureCopyToClipboard = (text) => {
  const body = document.body;
  const textArea = document.createElement("textarea");
  body.appendChild(textArea);

  textArea.style.position = "absolute";
  textArea.style.opacity = 0;
  textArea.value = text;
  textArea.focus();
  textArea.select();

  try {
    document.execCommand("copy");
  } catch (err) {
    console.error("Failed to copy: ", err);
  }

  body.removeChild(textArea);
};

export default copyToClipboard;
