import { createSlice } from "@reduxjs/toolkit";

/**
 * NOTE: What is this?
 * This is only a temporary data store to hold last data state before user has
 * been kicked out of the game. The reason is to keep user in the lobby screen
 * without holding the actual data nor connected to firebase, it's only for the
 * rendering purpose. So user does not immediately go into welcome screen, and
 * we can animate / play around with the data for a session
 *
 * Ofcourse not all of the data is being used, it's just easier to manage and
 * to expect if we have the same structure with the actual data, so please keep
 * it identical with Root store
 */

/**
 * @type {StoreKickedSnapshotType}
 */
const INITIAL_STATE = {
  gameProgress: {
    teamId: null,
    teamCode: null,
    players: [],
    roundProgress: null,
    completedRound: [],
    startedAt: null,
    startedGameAt: null,
    finishedGameAt: null,
    finishedReflectionAt: null,
  },
  user: {
    roleId: null,
    userId: null,
    wpToken: null,
    firebaseToken: null,
    hasBeenKicked: false,
    hasBeenRegistered: false,
  },
  notify: false,
};

const userSlice = createSlice({
  name: "kickedSnapshot",
  initialState: INITIAL_STATE,
  reducers: {
    /** @type {UtilReducer<StoreKickedSnapshotType, StoreKickedSnapshotType>} */
    setSnapshot: (state, action) => {
      return action.payload;
    },

    /** @type {UtilReducer<StoreKickedSnapshotType, null>} */
    reset: (state, action) => {
      return INITIAL_STATE;
    },
  },
});

export const { setSnapshot, reset } = userSlice.actions;
export default userSlice.reducer;
