
/* eslint-disable */
console.log("---------------- APP INFO --------------");
console.log({
  lastBuild: "2024-01-16T10:38:18.619Z",
  lastHash: "085b60bff9f461dd8319c41012f63d1591444265",
  appVersion: "4.1.4",
  storeVersion: "6",
});
console.log("----------------------------------------");
/* eslint-enable */
