import React, { useEffect } from "react";
import { Widget } from "@typeform/embed-react";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import { useMeasure } from "react-use";

import s from "./Reflection.module.scss";
import WallBackground from "@bd-components/WallBackground";
import useReflectionRequirement from "@bd-hooks/useReflectionRequirement";
import useStore from "@bd-hooks/useStore";
import setCurrentPage from "@bd-apis/setCurrentPage";
import QuestionaireOverlay from "@bd-components/QuestionaireOverlay";

const ReflectionChecker = () => {
  const status = useReflectionRequirement();
  if (!status.allowed) return <Redirect to={status.redirect} />;
  return <Reflection />;
};

const Paper = () => {
  const { gameData } = useStore();
  const [ref, { width, height }] = useMeasure();
  const typeformId = gameData.config.typeform_url;

  if (!typeformId) {
    return null;
  }

  return (
    <div className={s.paper}>
      <div className={s.paper__heightRuler}>
        <picture>
          <source srcSet="/images/discussion-paper.webp" type="image/webp" />
          <source srcSet="/images/discussion-paper.png" type="image/png" />
          <img
            ref={ref}
            className={s.paper__img}
            src="/images/discussion-paper.png"
            alt="Discussion paper background"
          />
        </picture>
        <div
          className={s.paper__ruler}
          style={{
            width: `${width}px`,
            height: `${height}px`,
          }}
        >
          <div className={s.paper__inner}>
            <Widget id={typeformId} className={s.paper__typeform} />
            <div
              data-tf-widget={typeformId}
              data-tf-opacity="25"
              data-tf-hide-headers
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Reflection = () => {
  const { t } = useTranslation();
  const { gameProgress, temporal, userProgress, gameData } = useStore();
  const typeformId = gameData.config.typeform_url;

  useEffect(() => {
    if (!temporal.isInterceptorReady) return;
    setCurrentPage(`Reflection`, gameProgress.teamId);
  }, [gameProgress.teamId, temporal.isInterceptorReady]);

  return (
    <WallBackground className={s.reflection}>
      {gameProgress.disabledMantap}
      <div className={s.reflection__wrapper}>
        <h3 className={s.reflection__title} data-testid="reflection_title">
          {!gameProgress.disabledTypeform && typeformId
            ? t("Discussion")
            : t("Thank you")}
        </h3>
        <div className={s.reflection__subtitle}>
          {!gameProgress.disabledTypeform && typeformId
            ? t("Now discuss your answer among each other")
            : t("Thank you for playing the game")}
        </div>
        {!gameProgress.disabledTypeform ? (
          <div className={s.reflection__paper}>
            <Paper />
          </div>
        ) : (
          ""
        )}
      </div>
      {!gameProgress.disabledMantapPostGame &&
      userProgress.mantapUrl?.length > 0 ? (
        <QuestionaireOverlay />
      ) : null}
    </WallBackground>
  );
};

export default ReflectionChecker;
