import React, { useRef } from "react";
import clsx from "clsx";
import { CSSTransition } from "react-transition-group";

import s from "./WelcomeTransition.module.scss";
import csstmap from "@bd-utils/csstmap";

/**
 * @type {import("./WelcomeTransition").WelcomeTransitionType}
 */
const WelcomeTransition = ({
  className,
  elementType,
  elementProps,
  isOpen,
  isInverted,
  children,
}) => {
  const ref = useRef();
  const classes = csstmap(s, isInverted ? "-inverted" : undefined);
  const Element = elementType ?? "div";

  return (
    <CSSTransition
      nodeRef={ref}
      classNames={classes}
      className={clsx(
        s.WelcomeTransition,
        isInverted && s["is-inverted"],
        className,
      )}
      in={isOpen}
      timeout={275}
      unmountOnExit={true}
    >
      <Element ref={ref} {...elementProps}>
        {children}
      </Element>
    </CSSTransition>
  );
};

export default WelcomeTransition;
