import { useState, useEffect, forwardRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import s from "./WheelLever.module.scss";
import clsx from "clsx";

const rectangleUrl = `/images/Rectangle-45.webp`;
const leverhouse2Url = `/images/leverhouse2.webp`;
const pointerUrl = `/images/Pointer.webp`;
const sparkle1Url = `/images/Sparkle-1.webp`;
const sparkle2Url = `/images/Sparkle-2.webp`;
const sparkle3Url = `/images/Sparkle-3.webp`;
const leverhandleOverlayUrl = `/images/leverhandle-overlay.webp`;
const paceIndikatorUrl = `/images/Pace-Indicator.webp`;

/**
 * Button Component with color and background customization
 */
const WheelLever = forwardRef(
  (
    {
      diameter,
      onClick = () => {},
      className,
      disabled = false,
      type,
      isSpinning,
      isLevel,
      isOpenQuestion,
    },
    ref,
  ) => {
    const buttonStyle = {
      width: diameter,
    };

    const leverText = "Time to wheel";

    const [levelMessage, setLevelMessage] = useState("Time to wheel");

    useEffect(() => {
      if (isLevel === "LOW") {
        setLevelMessage("");
      }

      if (isLevel === "MEDIUM") {
        setLevelMessage("");
      }

      if (isLevel === "HIGH") {
        setLevelMessage("Nice Spin!");
      }
    }, [isLevel]);

    const taglineFeedback = (isLevel) => {
      if (isLevel === "HIGH") {
        return (
          <FontAwesomeIcon
            className={s["WheelLever__feedback-icon"]}
            icon={faCheck}
            style={{ color: "#fff" }}
          />
        );
      }
    };

    return (
      <div className={s.WheelLever}>
        <div className={s.WheelLever__container}>
          <div className={s.WheelLever__connector}>
            <picture>
              <source srcSet={rectangleUrl} type="image/webp" />
              <img src={rectangleUrl} alt="Union" />
            </picture>
          </div>
          <div className={s.WheelLever__house}>
            <picture>
              <source srcSet={leverhouse2Url} type="image/webp" />
              <img src={leverhouse2Url} alt="Union" />
            </picture>
            <div className={s.WheelLever__tagline}>
              {isSpinning || isOpenQuestion ? (
                taglineFeedback(isLevel)
              ) : (
                <span className={s["WheelLever__tagline--arrow"]} />
              )}

              {isSpinning ? (
                <p className={s["WheelLever__tagline--message"]}>
                  {levelMessage}
                </p>
              ) : (
                <p className={s["WheelLever__tagline--yellow"]}>{leverText}</p>
              )}
            </div>
            <div
              className={clsx(
                s.WheelLever__handle,
                isSpinning | isOpenQuestion && s.active,
              )}
              style={buttonStyle}
              onClick={onClick}
              disabled={disabled}
              type={type}
            >
              <picture
                className={clsx(
                  s.pointer,
                  isSpinning | isOpenQuestion && s.active,
                )}
              >
                <img
                  src={pointerUrl}
                  className={s.pointer__icon}
                  alt="pointer"
                />
              </picture>
              <picture
                className={clsx(
                  s.sparkle,
                  isSpinning | isOpenQuestion && s.active,
                )}
              >
                <img
                  src={sparkle1Url}
                  alt="sparkle"
                  className={s["sparkle-1"]}
                />
                <img
                  src={sparkle2Url}
                  alt="sparkle"
                  className={s["sparkle-2"]}
                />
                <img
                  src={sparkle3Url}
                  alt="sparkle"
                  className={s["sparkle-3"]}
                />
              </picture>

              <svg
                className={s["WheelLever__handle--base"]}
                width="43"
                height="148"
                viewBox="0 0 43 148"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M21.5 35C31.165 35 39 27.165 39 17.5C39 7.83502 31.165 0 21.5 0C11.835 0 4 7.83502 4 17.5C4 27.165 11.835 35 21.5 35ZM15.5 37.5001C14.4894 37.2113 13.594 36.7663 12.8029 36.2131C10.7286 46.5416 7.05805 64.7812 6.13551 69.1285C4.82992 75.2808 13.3505 77.7128 14.8851 70.8943C15.9702 66.0732 19.5778 48.0079 21.5983 37.8377C19.6486 38.1571 17.5528 38.0866 15.5 37.5001Z"
                  fill="currentColor"
                />
              </svg>

              <picture className={s["WheelLever__handle--overlay"]}>
                <source srcSet={leverhandleOverlayUrl} type="image/webp" />
                <img src={leverhandleOverlayUrl} alt="Union" />
              </picture>
            </div>

            <div className={s.WheelLever__gauge}>
              <div className={s["WheelLever__gauge--bar"]} />
              <div
                ref={ref}
                className={clsx(
                  s["WheelLever__gauge--indicator"],
                  isSpinning | isOpenQuestion && s.active,
                )}
              >
                <picture>
                  <source srcSet={paceIndikatorUrl} type="image/webp" />
                  <img src={paceIndikatorUrl} alt="Union" />
                </picture>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  },
);

export default WheelLever;
