import useStore from "./useStore";
import useRequirementHelpers from "./useRequirementHelpers";
import { appRoutes } from "@bd-constants/routes";

const useReflectionRequirement = () => {
  const { hasLoggedIn, hasFinishedGame, hasFinishedDebriefing } =
    useRequirementHelpers();
  const { kickedSnapshot } = useStore();
  const hasKickedSnapshot = kickedSnapshot.user.userId;

  return {
    allowed:
      (hasLoggedIn && hasFinishedGame && hasFinishedDebriefing) ||
      hasKickedSnapshot,
    redirect: !hasLoggedIn
      ? appRoutes.welcome
      : !hasFinishedGame
      ? appRoutes.finalQuestion
      : !hasFinishedDebriefing
      ? appRoutes.debriefing
      : null,
  };
};

export default useReflectionRequirement;
