import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { useWindowSize } from "react-use";

import s from "./WallBackground.module.scss";
import { percentToValue } from "@bd-utils/percentage";

const WallBackground = ({ className, classNames = {}, children }) => {
  const windowSize = useWindowSize();
  const isHeightBigger = windowSize.height - windowSize.width > 0;
  const overlaySize = isHeightBigger
    ? percentToValue(110, windowSize.height)
    : percentToValue(110, windowSize.width);

  return (
    <div className={clsx(s.wall, className)}>
      <div
        className={s.wall__background}
        style={{ backgroundImage: "url('/images/wall.jpg')" }}
      ></div>
      <picture className={s.wall__plant}>
        <source srcSet="/images/plant.webp" type="image/webp" />
        <source srcSet="/images/plant.png" type="image/png" />
        <img src="/images/plant.png" alt="plant" />
      </picture>
      <div
        className={s.wall__overlay}
        style={{ height: `${overlaySize}px`, width: `${overlaySize}px` }}
      ></div>
      <div className={clsx(s.wall__inner, classNames.inner)}>{children}</div>
    </div>
  );
};

WallBackground.propTypes = {
  className: PropTypes.string,
};

export default WallBackground;
