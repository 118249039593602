import { useState } from "react";
import { useTranslation } from "react-i18next";
import useIntuitions from "@bd-hooks/useIntuitions";
import Intuition from "@bd-components/Intuition";
import Button from "@bd-components/Button";
import Warning from "@bd-components/Warning";
import { ReactComponent as Arrow } from "@bd-icons/arrow-right.svg";

import clsx from "clsx";
import s from "./Gameplay_Intuitions.module.scss";
import useActiveRoundData from "@bd-hooks/useActiveRoundData";

/**
 * @type {import("./Gameplay_Intuitions").IntuitionsType}
 */
const Intuitions = (props) => {
  const { t } = useTranslation();
  const state = useIntuitions();
  const round = useActiveRoundData();
  const { isOpen, onClose, index, isFirstTime } = props;

  const [isWarningOpen, setIsWarningOpen] = useState(isFirstTime);

  return (
    <div className={clsx(s.intuitions, isOpen && s["is-open"])}>
      <Intuition
        className={s.intuitions__item}
        label={state.intuitions[index].title}
        content={state.intuitions[index].content}
        onChange={() => state.toggleIntuition(state.intuitions[index].id)}
      />
      {isWarningOpen && round.index === 1 ? null : (
        <Button
          className={s.intuitions__close}
          text={t("Close")}
          onClick={onClose}
          size="normal"
          iconEnd={Arrow}
        />
      )}

      {isFirstTime && isOpen && round.index === 1 && (
        <Warning
          warningClass=".Gameplay_Intuitions-module__intuitions"
          warningKey="bias_intel"
          placement="left"
          onClick={() => setIsWarningOpen(false)}
        />
      )}
    </div>
  );
};

export default Intuitions;
