import React from "react";
import { useTranslation } from "react-i18next";
import { logEvent } from "firebase/analytics";

import s from "./SmallFolderPopup.module.scss";
import Folder from "@bd-components/Folder";
import Popup from "@bd-components/Popup";
import Fieldset from "@bd-components/Fieldset";
import Button from "@bd-components/Button";
import useFirebaseAnalytics from "@bd-hooks/useFirebaseAnalytics";

const SmallFolderPopup = ({
  title,
  content,
  label,
  fieldsetLabel,
  buttonText,
  isOpen,
  onClose: propOnClose,
  analyticEventName,
  titleTestId,
  buttonTestId,
}) => {
  const { t } = useTranslation();
  const analytics = useFirebaseAnalytics();

  const onClose = () => {
    if (analytics && analyticEventName) logEvent(analytics, analyticEventName);
    propOnClose();
  };

  return (
    <Popup className={s.smallFolderPopup} isOpen={isOpen}>
      <Folder size="small">
        <Folder.Inner>
          <div className={s.smallFolderPopup__flex}>
            <div className={s.smallFolderPopup__flexItem}>
              <h1
                className={s.smallFolderPopup__title}
                data-testid={titleTestId}
              >
                {title}
              </h1>
            </div>
            <div className={s.smallFolderPopup__flexItem}>
              <Fieldset
                classNames={s}
                label={fieldsetLabel ? fieldsetLabel : t("Instructions")}
              >
                <Fieldset.Scroll>
                  <Fieldset.Content
                    className={s.fieldsetContent}
                    dangerouslySetInnerHTML={{ __html: content }}
                  />
                </Fieldset.Scroll>
              </Fieldset>
            </div>
            <div className={s.smallFolderPopup__flexItem}>
              <Button
                testId={buttonTestId}
                className={s.smallFolderPopup__button}
                text={buttonText ? buttonText : t("Continue")}
                size="jumbo"
                onClick={onClose}
              />
            </div>
          </div>
        </Folder.Inner>
        <Folder.Label>{label}</Folder.Label>
      </Folder>
    </Popup>
  );
};

export default SmallFolderPopup;
