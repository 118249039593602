import axios from "axios";

/**
 * Set the game to be finished for game progress in the backend
 *
 * @param {StoreGameProgressType['teamId']} teamId
 */
const setGameFinished = (teamId) =>
  axios
    .post("/game/set-game-finished", { team_id: teamId })
    .catch((err) => console.error("Can't update finished game", { err }));

export default setGameFinished;
