import { useCallback, useEffect } from "react";
import { logEvent } from "firebase/analytics";

import useStore from "@bd-hooks/useStore";
import useHelpers from "@bd-hooks/useHelpers";
import useNextRound from "@bd-hooks/useNextRound";
import useActiveRoundData from "@bd-hooks/useActiveRoundData";
import useFirebaseActions from "@bd-hooks/useFirebaseActions";
import useFirebaseAnalytics from "@bd-hooks/useFirebaseAnalytics";
import { getRoleByUserId } from "@bd-utils/roles";
import setGameFinished from "@bd-apis/setGameFinished";

const useRoundOutro = () => {
  const { gameProgress, gameData, userProgress, firebase } = useStore();
  const { setFinishedOutro, setFinishedEntireGame, setPlayersSeenOutro } =
    useFirebaseActions();
  const { players } = gameProgress;
  const { roles } = gameData;
  const { playersSeenOutro } = gameProgress.roundProgress;
  const round = useActiveRoundData();
  const nextRound = useNextRound();
  const helpers = useHelpers();
  const analytics = useFirebaseAnalytics();

  const getRole = (userId) => getRoleByUserId(userId, players, roles);

  const hasAnsweredFinalQuestion =
    gameProgress.roundProgress.finalAnswer.length > 0;

  const response = {
    answerFeedbacks: getAnswersFeedback(
      gameProgress.roundProgress.finalAnswer,
      round.final_question.questions,
    ),
    generalFeedback: round.final_question.general_feedback,
  };

  const allFeedback = getAllFeedback(round.final_question.questions);

  const lastFeedbackIndex = response?.answerFeedbacks.length ?? 0;
  const readyPlayerIds = playersSeenOutro?.[lastFeedbackIndex]
    ? playersSeenOutro[lastFeedbackIndex]
    : [];

  const currentOpenDisplayIndex = (() => {
    let currentIndex = 0;
    if (!playersSeenOutro) return currentIndex;

    Object.keys(playersSeenOutro).every((_keyIndex) => {
      const keyIndex = parseInt(_keyIndex);
      const { userId } = userProgress;
      const isExist = playersSeenOutro[keyIndex].includes(userId);
      if (!isExist) return false;

      currentIndex = keyIndex + 1;
      return true;
    });

    return currentIndex;
  })();

  const getDisplayState = (index) => {
    if (currentOpenDisplayIndex === index) return "open";
    if (currentOpenDisplayIndex > index) return "closed";
    if (currentOpenDisplayIndex < index) return "untouched";
  };

  /**
   * @type {DisplayStateEnum[]}
   */
  const answerFeedbacksDisplay = response?.answerFeedbacks
    ? response?.answerFeedbacks?.map((item, index) => getDisplayState(index))
    : [];

  /**
   * @type {DisplayStateEnum[]}
   */
  const displayStates = [
    ...answerFeedbacksDisplay,

    // The last one is for general_feedback
    getDisplayState(answerFeedbacksDisplay.length),
  ];

  const isWaiting = displayStates[displayStates.length - 1] === "closed";

  const closeItem = (index) => {
    if (analytics) {
      logEvent(analytics, "final_question_response_close", {
        actor: userProgress.userId,
        round_id: round.id,
        team_id: gameProgress.teamId,
        response_index: index,
      });
    }

    setPlayersSeenOutro(index);
  };

  const gotoNext = useCallback(() => {
    if (nextRound) {
      // Go to next round
      setFinishedOutro();
      helpers.setRound(nextRound);
    } else {
      // Finish game
      setFinishedEntireGame();
      setGameFinished(gameProgress.teamId);
    }
    // eslint-disable-next-line
  }, [helpers, nextRound, gameProgress.teamId]);

  useEffect(() => {
    if (!firebase.isConnected) return;
    if (gameProgress.finishedGameAt) return;
    if (readyPlayerIds.length !== players.length) return;

    gotoNext();
  }, [
    firebase.isConnected,
    gameProgress,
    gotoNext,
    players.length,
    readyPlayerIds.length,
  ]);

  return {
    response,
    displayStates,
    getRole,
    players,
    readyPlayerIds,
    isWaiting,
    closeItem,
    hasAnsweredFinalQuestion,
    allFeedback,
  };
};

/**
 * Get particular question from questions list
 *
 * @param {FinalSourceQuestionType['id'] | FinalActionQuestionType['id']} questionId
 * @param {ApiRoundType['final_question']['questions']} questions
 */
const getQuestion = (questionId, questions) =>
  questions.find((question) => question.id === questionId);

/**
 * Get particular answer from answers list
 *
 * @param {string} answerId
 * @param {FinalSourceQuestionType['sources'] | FinalActionQuestionType['actions']} answers
 * @returns {FinalSourceQuestionType['sources'][0] | FinalActionQuestionType['actions'][0]}
 */
const getAnswer = (answerId, answers) =>
  answers.find((answer) => answer.id === answerId);

/**
 * Get correct answer from answers list
 *
 * @param {FinalSourceQuestionType['sources'] | FinalActionQuestionType['actions']} answers
 * @returns {FinalSourceQuestionType['sources'][0] | FinalActionQuestionType['actions'][0]}
 */
const getCorrectAnswer = (answers) =>
  answers.find((answer) => answer.is_correct === true);

/**
 * Get answers feedback from raw answer id & question id
 *
 * @param {StoreRoundProgressType['finalAnswer']} answers
 * @param {ApiRoundType['final_question']['questions']} questions
 */
const getAnswersFeedback = (answers, questions) =>
  answers.map((answer) => {
    const question = getQuestion(answer.questionId, questions);
    const answerOptions =
      question.type === "action_question" ? question.actions : question.sources;

    const feedback = getAnswer(answer.answerId, answerOptions).feedback;
    const is_correct = getAnswer(answer.answerId, answerOptions).is_correct;
    const correctAnswer = getCorrectAnswer(answerOptions);
    const answerData = {
      name: getAnswer(answer.answerId, answerOptions).name,
      image: getAnswer(answer.answerId, answerOptions).image,
    };

    return { ...answer, is_correct, feedback, correctAnswer, answerData };
  });

const getAllFeedback = (questions) => {
  const feedbacks = questions.map((question) => {
    const answerOptions =
      question.type === "action_question" ? question.actions : question.sources;

    return answerOptions;
  });

  return feedbacks?.[0];
};

export default useRoundOutro;
