import { useEffect } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";

import useStore from "./useStore";
import useAuthProcess from "./useAuthProcess";
import { setIsInterceptorReady } from "@bd-redux/temporal";

const useAxiosInterceptors = () => {
  const { userProgress } = useStore();
  const { logout } = useAuthProcess();
  const dispatch = useDispatch();

  useEffect(() => {
    const requestInterceptor = axios.interceptors.request.use(
      (config) => {
        if (userProgress.wpToken) {
          config.headers["Authorization"] = `Bearer ${userProgress.wpToken}`;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      },
    );

    const responseInterceptor = axios.interceptors.response.use(
      (response) => response,
      (err) => {
        if (err.response.status === 403 || err.response.status === 401) {
          /**
           * it's indicate `wp_token` is expired, then user should be logged
           * out. This is NOT related to firebase token, see initFirebase.js
           * for firebase token handling.
           *
           * Why 403 || 401? I'm not sure, but that's what happen. TODO: do
           * investigation for this cases
           */
          logout();
        }
        return Promise.reject(err);
      },
    );

    dispatch(setIsInterceptorReady(true));

    // TODO: refresh_token
    // https://medium.com/swlh/handling-access-and-refresh-tokens-using-axios-interceptors-3970b601a5da
    // axios.interceptors.response.use(
    //   (response) => {
    //     return response;
    //   },
    //   (error) => {
    //     const originalRequest = error.config;
    //     if (error.response.status === 401 && !originalRequest._retry) {
    //       originalRequest._retry = true;
    //       const data = { refresh_token: null };

    //       return axios.post("/public/token", data).then((res) => {
    //         if (res.status === 201) {
    //           // TODO: update redux/user.wpToken before retry request
    //           return axios(originalRequest);
    //         }
    //       });
    //     }
    //   },
    // );

    return () => {
      axios.interceptors.request.eject(requestInterceptor);
      axios.interceptors.response.eject(responseInterceptor);
    };
    // eslint-disable-next-line
  }, [userProgress.wpToken, logout]);
};

export default useAxiosInterceptors;
