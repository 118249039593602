import { useHistory } from "react-router-dom";
import { logEvent } from "firebase/analytics";
import useStore from "./useStore";
import useFirebaseAnalytics from "./useFirebaseAnalytics";
import useMultipleKeydown from "./useMultipleKeydown";

const useResetCheat = () => {
  const history = useHistory();
  const analytics = useFirebaseAnalytics();
  const { currentPlayer, gameData, gameProgress } = useStore();

  /**
   * Hit ALT + SHIFT + R to reset game
   */
  useMultipleKeydown(
    (snapshot) => {
      const alt = snapshot[18];
      const shift = snapshot[16];
      const r = snapshot[82];
      const isPressedAll = alt && shift && r;
      if (!isPressedAll) return;

      if (analytics) {
        logEvent(analytics, "cheat_reset_triggered", {
          actor: currentPlayer.userId,
        });
      }

      history.push("/reset");
    },
    [currentPlayer?.userId],
  );

  /**
   * Hit ALT + SHIFT + O + F to view firebase database for this game
   */
  useMultipleKeydown(
    (snapshot) => {
      const alt = snapshot[18];
      const shift = snapshot[16];
      const o = snapshot[79];
      const f = snapshot[70];
      const isPressedAll = alt && shift && o && f;
      if (!isPressedAll) return;
      window.open(
        `https://console.firebase.google.com/u/1/project/${gameData.config.firebase.project_id}/database/${gameData.config.firebase.project_id}-default-rtdb/data/~2F${gameProgress.teamCode}`,
      );
    },
    [gameProgress.teamCode, gameData.config.firebase.project_id],
  );
};

export default useResetCheat;
