import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { logEvent } from "firebase/analytics";

import useStore from "./useStore";
import useSnackbarState from "./useSnackbarState";
import useActiveRoundData from "./useActiveRoundData";
import useFirebaseActions from "./useFirebaseActions";
import useFirebaseAnalytics from "./useFirebaseAnalytics";
import sortSources from "@bd-utils/sortSources";
import combineSource from "@bd-apis/combineSource";
import checkCombinable from "@bd-utils/checkCombinable";
import { getUserByUserId } from "@bd-utils/users";
import calcDistance from "@bd-utils/calcDistance";
import calcAngle from "@bd-utils/calcAngle";

/**
 * @param {import("./useBoardPlay").useBoardPlayParams} props
 */
const useBoardPlay = ({ onCombineSucceeded }) => {
  const {
    gameProgress,
    userProgress,
    dragging,
    currentPlayer,
    combineHistory,
  } = useStore();
  const { t } = useTranslation();
  const { startDragging, stopDragging } = useFirebaseActions();
  const round = useActiveRoundData();
  const snackbar = useSnackbarState();
  const analytics = useFirebaseAnalytics();

  const [disabled, setDisabled] = useState(false);
  const disable = () => setDisabled(true);

  const [isCombining, setCombining] = useState(false);
  const sources = sortSources(round.sources);
  const remainingCombinability = gameProgress.roundProgress.combinationCounter;

  const [boardStringValues, setBoardStringValues] = useState({});

  /**
   * @type {UtilState<import("@dnd-kit/core").ViewRect | null>}
   */
  const [activeNodeRect, setActiveNode] = useState(null);
  const [forbiddenTargetsIds, setForbiddenTargetsIds] = useState([]);

  const onDragStart = (e) => {
    const { userId } = userProgress;

    if (analytics) {
      logEvent(analytics, "source_drag_start", {
        actor: userId,
        source: e.active.id,
      });
    }

    const newForbiddenTargetsIds = combineHistory.data
      .filter((h) => h.from === e.active.id)
      .map((h) => h.to);

    const currentSource = sources.find((s) => s.id === e.active.id);
    const forbiddenPeopleTargetIds =
      currentSource.type === "document"
        ? sources.filter((s) => s.type === "people").map((s) => s.id)
        : [];
    const forbiddenDocumentTargetIds =
      currentSource.type === "people"
        ? sources.filter((s) => s.type === "document").map((s) => s.id)
        : [];

    setForbiddenTargetsIds([
      ...newForbiddenTargetsIds,
      ...forbiddenPeopleTargetIds,
      ...forbiddenDocumentTargetIds,
    ]);
    startDragging(userId);
  };

  const combine = (activeId, overId) => {
    setCombining(true);
    combineSource({
      source_id_1: activeId,
      source_id_2: overId,
      team_id: gameProgress.teamId,
      round_id: round.id,
    })
      .then((data) => {
        setCombining(false);

        if (!data.is_new) return;

        onCombineSucceeded({
          from: activeId,
          to: overId,
          clue: data.clue,
          remaining: data.combination_left,
          status: data.combine_status,
        });
      })
      .catch((err) => {
        setCombining(false);
        snackbar.error();
      });
  };

  /**
   * @param {import("@dnd-kit/core").DragEndEvent} e
   */
  const onDragEnd = (e) => {
    setForbiddenTargetsIds([]);
    if (draggingState.isOtherDragging) return;

    stopDragging();

    if (disabled) return;
    if (!e.over) return;
    if (e.active.id === e.over.id) return;
    if (forbiddenTargetsIds.includes(e.over.id)) return;

    const activeRect = {
      ...activeNodeRect,
      offsetTop: activeNodeRect.offsetTop + e.delta.y,
      offsetLeft: activeNodeRect.offsetLeft + e.delta.x,
    };

    const shouldCombine = checkCombinable(activeRect, e.over.rect);

    if (!shouldCombine) return;

    if (analytics) {
      logEvent(analytics, "source_combine", {
        actor: currentPlayer.userId,
        source: e.active.id,
        target: e.over.id,
      });
    }

    if (remainingCombinability === 0) return;

    const activeRectPos = {
      x: activeNodeRect.offsetLeft + activeNodeRect.width / 2,
      y: activeNodeRect.offsetTop + 20,
    };

    const overRectPos = {
      x: e.over.rect.offsetLeft + 75,
      y: e.over.rect.offsetTop + 20,
    };

    const distance = calcDistance(activeRectPos, overRectPos);
    const angle = calcAngle(activeRectPos, overRectPos);

    setBoardStringValues({
      distance: distance,
      angle: angle,
      leftPos: activeRectPos.x,
      topPos: activeRectPos.y,
      overLeftPos: overRectPos.x,
      overTopPos: overRectPos.y,
      activeIden: e.active.id,
      overIden: e.over.id,
    });

    combine(e.active.id, e.over.id);

    setActiveNode(null);
  };

  // IMPORTANT NOTE:
  // Why store draggingUser data in a state? why not directly call
  // `getUserByUserId(dragging.user, gameProgress)` to get the user data ?
  // to prevent playerName glitch, otherwise it will shows undefined for a
  // split seconds in the draggingText, which is not good
  const [draggingUser, setDraggingUser] = useState(
    getUserByUserId(dragging.user, gameProgress.players),
  );
  const draggingText = t("{player_name} is moving an object");
  const draggingState = {
    isOtherDragging: dragging.user && dragging.user !== currentPlayer.userId,
    text: draggingText.replace("{player_name}", draggingUser?.name),
  };

  /**
   * Reset activeNode if turns out there's other player dragging the board, to
   * avoid
   */
  useEffect(() => {
    if (draggingState.isOtherDragging && activeNodeRect) {
      console.error("setActiveNode there's other player dragging the board");
      setActiveNode(null);
    }
  }, [draggingState.isOtherDragging, activeNodeRect]);

  /**
   * Change the dragging user only if there's a value change (not undefined or
   * null or falsy value), to avoid playerName glitch
   */
  useEffect(() => {
    const newUser = getUserByUserId(dragging.user, gameProgress.players);
    if (newUser) setDraggingUser(newUser);
  }, [dragging.user, gameProgress.players]);

  return {
    sources,
    onDragStart,
    onDragEnd,
    setActiveNode,
    remainingCombinability,
    isCombining,
    dragging: draggingState,
    disable,
    isDisabled: disabled,
    forbiddenTargetsIds,
    snackbar,
    combine,
    boardStringValues,
  };
};

export default useBoardPlay;
