import { useTranslation } from "react-i18next";
import clsx from "clsx";

import Popup from "@bd-components/Popup";
import Button from "@bd-components/Button";
import RoleAvatar from "@bd-components/RoleAvatar";
import useStore from "@bd-hooks/useStore";
import { ReactComponent as IconCheck } from "@bd-icons/icon-check.svg";

import s from "./DebriefingWaiting.module.scss";

/**
 * @type {import("./DebriefingWaiting").DebriefingWaitingType}
 */
const DebriefingWaiting = ({
  isOpen,
  amIReady,
  onReady,
  onCancelReady,
  isLoading,
}) => {
  const { t } = useTranslation();
  const store = useStore();
  const readyDebriefing = store.gameProgress.playersReadyDebriefing;
  const settings = store.gameData.config.team_connector.settings;

  return (
    <Popup isOpen={isOpen} className={s.DebriefingWaiting}>
      <div className={s.DebriefingWaiting__box}>
        {settings.title ? (
          <h3 className={s.DebriefingWaiting__title}>{settings.title}</h3>
        ) : null}

        {settings.description ? (
          <div className={s.DebriefingWaiting__description}>
            {settings.description}
          </div>
        ) : null}

        <div className={s.DebriefingWaiting__players}>
          {store.gameProgress.players.map((p) => {
            const isReady = readyDebriefing.includes(p.userId);

            return (
              <div
                key={p.userId}
                className={clsx(
                  s.DebriefingWaiting__player,
                  isReady && s.isReady,
                )}
              >
                <RoleAvatar player={p} />
                <div
                  className={clsx(
                    s.DebriefingWaiting__player__checkStatus,
                    isReady && s.isReady,
                  )}
                >
                  <IconCheck
                    className={s.DebriefingWaiting__player__checkStatus__svg}
                  />
                </div>
              </div>
            );
          })}
        </div>
        <div className={s.DebriefingWaiting__action}>
          {amIReady ? (
            <Button
              className={s.DebriefingWaiting__button}
              size="medium"
              onClick={onCancelReady}
              isLoading={isLoading}
              text={t("Cancel Ready")}
            ></Button>
          ) : (
            <Button
              className={s.DebriefingWaiting__button}
              size="medium"
              onClick={onReady}
              isLoading={isLoading}
              text={settings.button_text ?? t("Ready")}
            ></Button>
          )}
        </div>
      </div>
    </Popup>
  );
};

export default DebriefingWaiting;
