import React from "react";
import TimerVisual from "./TimerVisual";

import clsx from "clsx";
import s from "./TimerStatic.module.scss";
import millisecondsToTimeObject from "@bd-utils/millisecondsToTimeObject";

const TimerStatic = () => {
  const time = millisecondsToTimeObject(999999680);
  return (
    <div className={clsx(s.timerStatic, "display-none")}>
      <TimerVisual />
      <div className={s.timerStatic__value}>
        <span>{time.m}</span>
        <span>{time.s}</span>
      </div>
    </div>
  );
};

export default TimerStatic;
