import { useMemo, useRef } from "react";
import { useDndMonitor, useDraggable } from "@dnd-kit/core";
import useSound from "use-sound";
import PropTypes from "prop-types";
import clsx from "clsx";

import s from "./Draggable.module.scss";
import Avatar from "@bd-components/Avatar";
import checkCombinable from "@bd-utils/checkCombinable";
import { SourceShape } from "@bd-utils/globalPropTypes";
import useStore from "@bd-hooks/useStore";

/**
 * @type {import("react").FC<{
 *  className: String,
 *  source: ApiSourceType,
 *  setActiveNode: (activeNodeRect: import("@dnd-kit/core").ViewRect) => void,
 *  isForbidden: Boolean,
 *  hasName: Boolean,
 *  onMouseDown: (e: React.MouseEvent<HTMLElement>) => void,
 *  onMouseUp: (e: React.MouseEvent<HTMLElement>) => void,
 * }>}
 */
const Draggable = ({
  className,
  source,
  setActiveNode = () => {},
  disabled,
  isForbidden,
  hasName,
  onMouseDown,
  onMouseUp,
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    over,
    active,
    activeNodeRect,
  } = useDraggable({
    id: source.id,
    disabled,
  });

  const isDraggingMe = active && !disabled ? active.id === source.id : false;
  const isTargetMe = over ? over.id === source.id : false;
  const deltaRef = useRef({ x: 0, y: 0 });

  const { gameData } = useStore();
  const { game_audio } = gameData.config;

  const forbiddenTargetsIds = useStore()
    .combineHistory.data.filter((h) => h.from === active?.id)
    .map((h) => h.to);

  const [play, { stop }] = useSound(game_audio?.paper_4, {
    volume: 0.5,
  });

  useDndMonitor({
    onDragStart: (e) => {
      if (!active?.id) return;
      if (active.id !== source.id) return;
      setActiveNode(activeNodeRect);
    },
    onDragMove: (e) => {
      deltaRef.current = e.delta;
    },
  });

  const isCombinableMemo = useMemo(
    () => {
      if (disabled) return false;
      if (!isDraggingMe) return false;
      if (isTargetMe) return false;
      if (!over) return false;
      if (!deltaRef.current) return false;
      if (forbiddenTargetsIds.includes(over.id)) return false;
      const activeRect = {
        ...activeNodeRect,
        offsetTop: activeNodeRect.offsetTop + deltaRef.current.y,
        offsetLeft: activeNodeRect.offsetLeft + deltaRef.current.x,
      };
      return checkCombinable(activeRect, over.rect);
    },
    // eslint-disable-next-line
    [
      activeNodeRect,
      over,
      isDraggingMe,
      isTargetMe,
      deltaRef.current,
      forbiddenTargetsIds,
    ],
  );

  const isCombinableTargetMemo = useMemo(
    () => {
      if (isDraggingMe) return false;
      if (!isTargetMe) return false;
      if (!over) return false;
      if (!deltaRef.current) return false;
      const activeRect = {
        ...activeNodeRect,
        offsetTop: activeNodeRect.offsetTop + deltaRef.current.y,
        offsetLeft: activeNodeRect.offsetLeft + deltaRef.current.x,
      };
      return checkCombinable(activeRect, over.rect);
    },
    // eslint-disable-next-line
    [activeNodeRect, over, isDraggingMe, isTargetMe, deltaRef.current],
  );

  const style = transform
    ? { transform: `translate(${transform.x}px, ${transform.y}px)` }
    : null;

  return (
    <div
      ref={setNodeRef}
      style={disabled ? null : style}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onMouseEnter={() => !disabled && !isForbidden && play()}
      onMouseLeave={() => stop()}
      className={clsx(
        { [className]: className },
        s.draggable,
        isDraggingMe && s["is-dragging"],
        isCombinableMemo && s["is-combinable"],
        isCombinableTargetMemo && s["is-combinable-target"],
        disabled && s["is-disabled"],
        isForbidden && s["is-forbidden"],
      )}
      {...attributes}
      {...listeners}
    >
      <div className={s.draggable__allowance}>
        <Avatar
          url={source.photo}
          name={source.name && hasName ? source.name : source.label}
          label={source.type === "people" ? source.label : ""}
          classNames={{
            avatar: s.avatar,
            avatar__inner: s.avatar__inner,
            avatar__label: s.avatar__label,
            avatar__label__shape: s.avatar__label__shape,
            avatar__name: hasName && s.avatar__name,
          }}
        />
      </div>
    </div>
  );
};

Draggable.propTypes = {
  className: PropTypes.string,
  source: SourceShape,
  setActiveNode: PropTypes.func,
  disabled: PropTypes.bool,
};

export default Draggable;
