import useRequirementHelpers from "./useRequirementHelpers";
import { appRoutes } from "@bd-constants/routes";

const useWaitingRoomRequirement = () => {
  const { hasLoggedIn, hasSeenIntro, hasStartedGame } = useRequirementHelpers();

  return {
    allowed: hasLoggedIn && hasSeenIntro && !hasStartedGame,
    redirect: !hasLoggedIn
      ? appRoutes.welcome
      : !hasSeenIntro
      ? appRoutes.levelIntro
      : hasStartedGame
      ? appRoutes.levelIntro
      : null,
  };
};

export default useWaitingRoomRequirement;
