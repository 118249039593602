/**
 * @example
 * const object = {
 *  1: { name: "Foo" },
 *  2: { name: "Bar" }
 * };
 * const result = indexedObjectToArray(object);
 * console.log(result);
 * // output: [{ name: "Foo" }, { name: "Bar" }]
 * @param {Object} obj
 */
const indexedObjectToArray = (obj) => {
  if (!obj) return [];
  if (typeof obj !== "object") return [];
  return Object.keys(obj).map((key) => obj[key]);
};

// TODO: perhaps move it somewhere? or remove default export and make it object
// instead?
export const indexedObjectToArrayKeys = (obj, checkTruthy = false) => {
  if (!obj) return [];
  if (typeof obj !== "object") return [];

  let objKeys = Object.keys(obj);
  if (checkTruthy) objKeys = objKeys.filter((key) => obj[key]);

  return objKeys.map((key) => parseInt(key));
};

export default indexedObjectToArray;
