import { useRouteMatch } from "react-router";
import { appRoutes } from "@bd-constants/routes";
import useRequirementHelpers from "../useRequirementHelpers";

export const continuousWelcomeRequirement = ({
  hasLoggedIn,
  hasStarted,
  isLoginPage,
  isRejoinUser,
}) => ({
  allowed:
    (hasLoggedIn && !hasStarted && !isLoginPage) ||
    !hasLoggedIn ||
    isRejoinUser,
  redirect:
    hasLoggedIn && hasStarted
      ? appRoutes.gameIntro
      : hasLoggedIn && !hasStarted && isLoginPage
      ? appRoutes.welcome
      : null,
});

const useContinuousWelcomeRequirement = () => {
  const { hasLoggedIn, hasStarted, isRejoinUser } = useRequirementHelpers();
  const isLoginPage = Boolean(useRouteMatch(appRoutes.login));

  return continuousWelcomeRequirement({
    hasLoggedIn,
    hasStarted,
    isLoginPage,
    isRejoinUser,
  });
};

export default useContinuousWelcomeRequirement;
