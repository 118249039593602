import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import s from "../ForceReset/ForceReset.module.scss";
import useStore from "@bd-hooks/useStore";
import Button from "@bd-components/Button";
import useFirebaseActions from "@bd-hooks/useFirebaseActions";
import axios from "axios";
// import { useDispatch } from "react-redux";
// import { reset } from "@bd-redux/combine-history";

const ResetLevel = () => {
  const { gameProgress, firebase } = useStore();
  const { resetLevel: resetLevelFb } = useFirebaseActions();
  // const dispatch = useDispatch();

  useEffect(
    () => {
      if (!firebase.isConnected || !gameProgress.roundProgress) {
        return;
      }

      const resetLevel = async () => {
        const request = axios.post;
        await request("/game/reset-level", {
          team_id: gameProgress.teamId,
          round_id: gameProgress.roundProgress.roundId,
        }).then((res) => {
          if (!res.data.success) {
            console.error("Failed to reset game");
          }
        });

        resetLevelFb();
        // dispatch(reset());
      };

      resetLevel();
    },
    // eslint-disable-next-line
    [firebase.isConnected, gameProgress.roundProgress],
  );

  return (
    <div className={s.forceReset}>
      <div className={s.forceReset__content}>
        Reset has been completed successfully
      </div>
      <Button
        className={s.forceReset__btn}
        text="Start Game"
        as={Link}
        to="/"
      />
    </div>
  );
};

export default ResetLevel;
