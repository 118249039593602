import { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";

import useStore from "@bd-hooks/useStore";

import FAQ from "@bd-components/FAQ";
import Timer from "@bd-components/Timer";
import Tooltip from "@bd-components/Tooltip";
import GamePlayers from "./GamePlay_Players";
import TimerStatic from "@bd-components/TimerStatic";

import { ReactComponent as IconHelp } from "@bd-icons/icon-help.svg";
import { ReactComponent as IconUsers } from "@bd-icons/icon-users.svg";
import { ReactComponent as IconCross } from "@bd-icons/icon-times-solid.svg";

import getNowEpoch from "@bd-utils/getNowEpoch";
import truncateString from "@bd-utils/truncateString";

import clsx from "clsx";
import s from "./Gameplay_Actions.module.scss";

/**
 * @type {import("./Gameplay_Actions").GameplayActionsType}
 */
const Actions = (props) => {
  const { t } = useTranslation();
  const { actionCount, initialActionCount, isFinish, onTimeout, sources } =
    props;
  const { gameProgress, combineHistory, gameData } = useStore();
  const [isHelpOpen, setIsHelpOpen] = useState(false);
  const [isLogOpen, setIsLogOpen] = useState(false);
  const [hasSeenChange, setHasSeenChange] = useState(false);
  const hasStartedTimer = Boolean(gameProgress.roundProgress.shouldFinishAt);

  const actionLogRef = useRef(null);
  const actionLogButtonRef = useRef(null);

  const currentRoundSources = gameData.rounds
    .find((r) => r.id === gameProgress.roundProgress.roundId)
    ?.sources?.map((s) => s.id);

  const combineHistoryArr = [...combineHistory?.data]
    .filter((h) => currentRoundSources.includes(h.from))
    ?.sort((a, b) => a.timestamp - b.timestamp)
    .splice(-3, 3);

  const handleActionLog = () => {
    setHasSeenChange(true);
    setIsLogOpen(true);
  };

  const closeActionLog = (e) => {
    if (
      isLogOpen &&
      !actionLogRef.current.contains(e.target) &&
      !actionLogButtonRef.current.contains(e.target)
    ) {
      setIsLogOpen(false);
    }
  };

  useEffect(() => {
    setHasSeenChange(false);
  }, [combineHistory]);

  useEffect(() => {
    document.addEventListener("mousedown", closeActionLog);
    return () => document.removeEventListener("mousedown", closeActionLog);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionLogRef, isLogOpen]);

  return (
    <div className={s.actions}>
      <picture className={s["actions--bg"]}>
        <source srcSet="/images/table.webp" type="image/webp" />
        <source srcSet="/images/table.png" type="image/png" />
        <img src="/images/table.png" alt="Table" />
      </picture>

      <button
        className={s["actions--help"]}
        onClick={() => setIsHelpOpen(true)}
      >
        <IconHelp />
      </button>

      <FAQ isOpen={isHelpOpen} onClick={() => setIsHelpOpen(false)} />

      <GamePlayers />

      <div className={s["actions--counter"]}>
        <div
          className={clsx(s["actions--log"], isLogOpen && s["is-open"])}
          ref={actionLogRef}
        >
          <button
            className={s["actions--log__close"]}
            onClick={() => setIsLogOpen(false)}
          >
            <IconCross />
          </button>
          <ul className={s["actions--log__list"]}>
            {/** Sort by timestamp since original array sorts by 'FROM' ID */}
            {combineHistoryArr.map((record) => (
              <li
                className={s["actions--log__list--item"]}
                key={record.timestamp}
              >
                <i className={s.image}>
                  <picture className={s["image--from"]}>
                    <img
                      src={sources?.find((o) => o.id === record.from)?.photo}
                      alt={sources?.find((o) => o.id === record.from)?.name}
                    />
                  </picture>
                  <picture className={s["image--to"]}>
                    <img
                      src={sources?.find((o) => o.id === record.to)?.photo}
                      alt={sources?.find((o) => o.id === record.to)?.name}
                    />
                  </picture>
                </i>
                <div className={s.player}>
                  <span className={s.name}>
                    {truncateString(record.player.name, 25)}
                  </span>
                  <span>{t("Combined cards")}</span>
                </div>
              </li>
            ))}
          </ul>
        </div>
        <Tooltip
          text={
            actionCount === 1
              ? actionCount + " " + t("Action Left")
              : actionCount + " " + t("Actions Left")
          }
          top="0%"
          left="100%"
          hoverDirection="right"
        />
        <div className={s["actions--bar"]}>
          <span
            className={s["actions--bar-foot"]}
            style={{ backgroundImage: 'url("/images/actionfoot-l.png")' }}
          ></span>
          <span
            className={s["actions--bar-foot"]}
            style={{ backgroundImage: 'url("/images/actionfoot-r.png")' }}
          ></span>
          <div className={s["actions--bar-wrapper"]}>
            {Array.from({ length: initialActionCount }, (_, i) => (
              <i
                key={i}
                className={clsx(
                  s["actions--count"],
                  initialActionCount - actionCount > i && s["is-inactive"],
                )}
              >
                <img
                  src="/images/action-count-green.png"
                  alt="action count"
                  className={s.active}
                />
                <img
                  src="/images/action-count.png"
                  alt="action count"
                  className={s.inactive}
                />
              </i>
            ))}
            <button
              className={clsx(
                s["actions--bar-button--bg"],
                !hasSeenChange && s["is-changed"],
              )}
              onClick={handleActionLog}
              ref={actionLogButtonRef}
            >
              <div className={s["actions--bar-button"]}>
                <i className={s["actions--bar-button--icon"]}>
                  <IconUsers />
                </i>
              </div>
            </button>
          </div>
        </div>
      </div>

      {hasStartedTimer ? (
        <div className={clsx(s["actions--timer"], isFinish && s["is-hidden"])}>
          <Timer
            duration={
              (gameProgress.roundProgress.shouldFinishAt - getNowEpoch()) * 1000
            }
            onTimeout={onTimeout}
          />
        </div>
      ) : (
        <div className={clsx(s["actions--timer"], isFinish && s["is-hidden"])}>
          <TimerStatic />
        </div>
      )}
    </div>
  );
};

export default Actions;
