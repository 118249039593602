import { useEffect, useRef } from "react";
import { useLocation } from "react-router";
import { useDispatch } from "react-redux";
import { appRoutes } from "@bd-constants/routes";
import { setIsConnecting } from "@bd-redux/firebase";
import useStore from "./useStore";
import useFirebaseActions from "./useFirebaseActions";

const useDerivedLoading = () => {
  const { setLanguage } = useFirebaseActions();
  const { gameProgress, firebase, language, userProgress } = useStore();
  const location = useLocation();
  const dispatch = useDispatch();

  /**
   * This is only for checking purpose, to make sure that syncing language is
   * not taking forever to finish, if that the case, then probably admin hasn't
   * configured the site yet
   */
  const refIsSyncingLanguage = useRef(false);

  const isSyncingLanguage = Boolean(gameProgress.teamId)
    ? language.current.value !== firebase.language
    : false;

  refIsSyncingLanguage.current = isSyncingLanguage;

  const isRegistering = Boolean(
    userProgress?.userId && !userProgress?.hasBeenRegistered,
  );

  const isResetPage = location.pathname === appRoutes.reset;

  /**
   * This is only for checking purpose, to make sure that syncing language is
   * not taking forever to finish, if that the case, then probably admin hasn't
   * configured the site yet
   */
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (refIsSyncingLanguage.current) {
        /**
         * Force to set language on firebase if syncing is still happening
         * probably the language is empty so we force to set it again
         */
        setLanguage(language.current.value, gameProgress.teamCode).catch(() =>
          console.error(
            `Looks like the language hasn't been set yet (${firebase.language})`,
          ),
        );
      }
    }, 10000);

    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameProgress.teamId, firebase.language]);

  const isLoading = isResetPage
    ? false
    : firebase.isConnecting ||
      !language.initialized ||
      isSyncingLanguage ||
      isRegistering;

  /**
   * If user choose language manually for the first time, and the chosen lang
   * is the same with the default lang, it will force the state of
   * `isConnecting` to true for a split second, therefore we need this hooks to
   * stop the loading when `hasChosenLanguageManually` is true
   */
  useEffect(
    () => {
      if (language.hasChosenLanguageManually && firebase.isConnecting) {
        dispatch(setIsConnecting(false));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [language.hasChosenLanguageManually],
  );

  return isLoading;
};

export default useDerivedLoading;
