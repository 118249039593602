/**
 * Convert milliseconds to time object (seconds, minutes, hours)
 * @param {Number} time
 * @returns {{
 *  h: Number,
 *  m: Number,
 *  s: Number,
 * }}
 */
const millisecondsToTimeObject = (time) => {
  const hours = Math.floor((time / (1000 * 60 * 60)) % 24);
  const minutes = Math.floor((time / 1000 / 60) % 60);
  const seconds = Math.floor((time / 1000) % 60);

  return {
    h: hours.toString().padStart(2, "0"),
    m: minutes.toString().padStart(2, "0"),
    s: seconds.toString().padStart(2, "0"),
  };
};

export default millisecondsToTimeObject;
