import React, { useRef } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Button from "@bd-components/Button";
import Snackbar from "@bd-components/Snackbar";
import TextField from "@bd-components/TextField";
import WelcomeTransition from "@bd-components/WelcomeTransition";
import { appRoutes } from "@bd-constants/routes";
import { ReactComponent as IconChevronRight } from "@bd-icons/icon-chevron-right-solid.svg";

import sj from "@bd-components/JoinTeam/JoinTeam.module.scss";
import s from "./CreateTeam.module.scss";
import useStateCreateTeam from "./useStateCreateTeam";

const CreateTeam = ({ isOpen, isInverted }) => {
  const { t } = useTranslation();
  const state = useStateCreateTeam();
  const ref = useRef();

  return (
    <WelcomeTransition
      className={sj.root}
      nodeRef={ref}
      isOpen={isOpen}
      isInverted={isInverted}
    >
      <form ref={ref} onSubmit={state.onSubmit}>
        <h3 className={sj.heading}>{t("Create your own team")}</h3>
        <div className={sj.description}>
          {t(
            "Insert your email address to create a new game and invite your friends",
          )}
        </div>

        <TextField
          className={sj.textField}
          classNames={s}
          color="black"
          name="email"
          placeholder={t("Email")}
          autoComplete="email"
          value={state.email}
          error={state.err}
          onChange={state.onChangeEmail}
        />

        <Button
          className={sj.button}
          type="submit"
          variant="contained"
          color="yellow"
          size="medium"
          text={t("Create Game")}
          iconEnd={IconChevronRight}
          isLoading={state.isSubmiting}
        />

        <div className={sj.option}>
          {t("Or")}{" "}
          <Link className={sj.link} to={appRoutes.welcome}>
            {t("Join A Team")}
          </Link>
        </div>
        <Snackbar
          isUsingPortal
          title={state.snackbar.title}
          message={state.snackbar.message}
          status={state.snackbar.status}
          onClose={state.snackbar.close}
          isOpen={state.snackbar.isOpen && isOpen}
        />
      </form>
    </WelcomeTransition>
  );
};

export default CreateTeam;
