import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { DndContext } from "@dnd-kit/core";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

import s from "./Gameplay_BoardPlay.module.scss";
import Board from "@bd-components/Board";
import Droppable from "@bd-components/Droppable";
import Draggable from "@bd-components/Draggable";
import AnnouncerPopup from "@bd-components/AnnouncerPopup";
import useStore from "@bd-hooks/useStore";
import { SourceShape } from "@bd-utils/globalPropTypes";
import getAllQueryString from "@bd-utils/getAllQueryString";
import getPossibleCombinations from "@bd-utils/getPossibleCombinations";
import StringClue from "@bd-components/StringClue";
import useClues from "@bd-hooks/useClues";
import Avatar from "@bd-components/Avatar";
import Button from "@bd-components/Button";
import { ReactComponent as Arrow } from "@bd-icons/arrow-right.svg";
import calcDistance from "@bd-utils/calcDistance";
import calcAngle from "@bd-utils/calcAngle";
import Warning from "@bd-components/Warning";
import useActiveRoundData from "@bd-hooks/useActiveRoundData";

import useFirebaseActions from "@bd-hooks/useFirebaseActions";

import useWindowSize from "@bd-hooks/useWindowSize";

/**
 * @type {React.FC<import("@bd-hooks/useBoardPlay").useBoardPlayType>}
 */
const BoardPlay = ({
  sources,
  onDragEnd,
  onDragStart,
  setActiveNode,
  forbiddenTargetsIds,
  isCombining,
  isOtherDragging,
  combine,
  boardStringValues,
  activeId,
  overId,
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const keys = Object.keys(getAllQueryString(location.search));
  const isTesting = keys.includes("test-y0u-d0nt-kn0w");
  const { gameProgress, combineHistory } = useStore();
  const [stringValues, setStringValues] = useState([]);
  const [stringPositions, setStringPositions] = useState([]);
  const [isStringDuplicate, setIsStringDuplicate] = useState([]);
  const [isClueModalOpen, setIsClueModalOpen] = useState(false);
  const [clueData, setClueData] = useState(null);
  const [isHolding, setIsHolding] = useState(false);
  const actionCount = gameProgress.roundProgress.combinationCounter;
  const clues = useClues();
  const round = useActiveRoundData();
  const [hasSeenFirstClue, setHasSeenFirstClue] = useState(false);
  const [isWarningOpen, setIsWarningOpen] = useState(false);
  const toSource = sources.find((source) => source.id === clueData?.to);
  const fromSource = sources.find((source) => source.id === clueData?.from);
  const suspectCount = sources.filter(
    (source) => source.type === ("people" || "document"),
  );
  const windowSize = useWindowSize();

  const { setHasSeenFirstClue: setSeenFirstClue } = useFirebaseActions();

  /**
   * Selects random combinable card and gives it a blue glow if player is idle for 2 minutes
   */
  useEffect(() => {
    const displayHint = () => {
      const possibleCombination = getPossibleCombinations(sources);
      const randomCombination =
        possibleCombination[
          Math.floor(Math.random() * possibleCombination.length)
        ];
      const randNum = randomCombination.split("-", 1);
      removeHints();
      document
        .querySelector(
          '[dataid="' + randNum + '"] .Draggable-module__draggable',
        )
        ?.classList.add("Gameplay_BoardPlay-module__is-hint");
    };

    let time = setTimeout(displayHint, 120000);

    const removeHints = () => {
      document
        .querySelectorAll(".Draggable-module__draggable")
        ?.forEach((el) => {
          el.classList.remove("Gameplay_BoardPlay-module__is-hint");
        });
    };

    const resetTimer = () => {
      clearTimeout(time);
      removeHints();
      time = setTimeout(displayHint, 120000);
    };

    document.onmousedown = resetTimer;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCardHold = () => {
    setIsHolding(true);
  };

  const onCardRelease = () => {
    setIsHolding(false);
  };

  useEffect(() => {
    if (clues.totalClue === 1 && round.index === 1) {
      setIsWarningOpen(true);
    } else {
      setIsWarningOpen(false);
    }
  }, [clues.totalClue, round.index]);

  // const isTutorialOpenRaw =
  //   gameProgress.roundProgress.playersSeenRoundIntro.includes(
  //     currentPlayer.userId,
  //   ) && combineHistory.data.length < 1;

  // const { isAllowed, current } = useDerivedTutorial(
  //   gameData.config.tutorials,
  //   isTutorialOpenRaw,
  // );

  const isDisabled = false;

  // // TODO: fix bug on `boardStringValues'
  // // currently it only works on the dragger side and it's not persistent
  // // need to change to another persistent entity
  // useEffect(() => {
  //   if (clues.allData.length === 0) return;
  //   setStringValues((stringValues) => [...stringValues, boardStringValues]);
  //   stringValues.forEach((value) => {
  //     if (value.leftPos && value.overLeftPos) {
  //       setStringPositions((stringPositions) => [
  //         ...stringPositions,
  //         [value.leftPos, value.overLeftPos],
  //       ]);
  //     }
  //   });

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [clues.allData.length]);

  useEffect(() => {
    if (
      clues.allData.length === 0 ||
      clues.allData.length > 1 ||
      round.index !== 1
    )
      return;

    openClueModal(clues.allData[0]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clues.allData.length]);

  useEffect(() => {
    if (stringPositions) {
      stringValues.forEach((value, i) => {
        if (stringPositions[i - 1]) {
          if (
            stringPositions[i - 1].toString() ===
            [value.leftPos, value.overLeftPos].reverse().toString()
          ) {
            setIsStringDuplicate((isStringDuplicate) => [
              ...isStringDuplicate,
              true,
            ]);
          } else {
            setIsStringDuplicate((isStringDuplicate) => [
              ...isStringDuplicate,
              false,
            ]);
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stringPositions]);

  const openClueModal = (value) => {
    setClueData(value);
    setIsClueModalOpen(true);
  };

  const closeClueModal = () => {
    setIsClueModalOpen(false);
    !hasSeenFirstClue && setHasSeenFirstClue(true);
    setSeenFirstClue(true);
  };

  const DragTesting = () => {
    const possibleCombinations = getPossibleCombinations(sources);

    return (
      <div className={s.boardPlay__testing}>
        {possibleCombinations.map((key) => {
          const from = key.split("-")[0];
          const to = key.split("-")[1];

          return (
            <button
              key={key}
              id={`test-dragging-${key}`}
              data-testid={`game_dragging_${key}`}
              onClick={() => combine(from, to)}
            >
              Test dragging {key}
            </button>
          );
        })}

        {/* Render all combined history for testing purpose, so we can make
        sure those combination has been saved into firebase */}
        {isTesting ? (
          <div className="onlytest">
            {combineHistory.data.map((history) => (
              <div
                data-testid={`test_combine-${history.from}-${history.to}_succeed`}
              />
            ))}
          </div>
        ) : null}
      </div>
    );
  };

  /**
   * Added by Prast to hot fix breaking game : when the action in the tutorial is performed by one player all other players screen went blank
   * NOTE : This may not be the best practice, please improve this functionality
   */

  const [currentRoundHistory, setCurrentRoundHistory] = useState([]);
  const dndRef = useRef();

  function getOffset(el) {
    const rect = el.getBoundingClientRect();
    return {
      left: rect.left + window.scrollX,
      top: rect.top + window.scrollY,
    };
  }

  const recalculateStringPositions = () => {
    console.log("resizing...");
    if (!currentRoundHistory.length) {
      return;
    }

    const overRectXPos =
      windowSize.width > 1400
        ? 75
        : windowSize.width < 1200 || windowSize.height < 800
        ? 25
        : 55;

    let stringConnectors = [];
    currentRoundHistory.forEach((value) => {
      const elFrom = dndRef.current.querySelector(
        '[dataid="' + value.from + '"]',
      );
      const elTo = dndRef.current.querySelector('[dataid="' + value.to + '"]');

      const activeRectPos = {
        x: getOffset(elFrom).left + elFrom.offsetWidth / 2,
        y: getOffset(elFrom).top + 20,
      };

      const overRectPos = {
        x: getOffset(elTo).left + overRectXPos,
        y: getOffset(elTo).top + 20,
      };

      const distance = calcDistance(activeRectPos, overRectPos);
      const angle = calcAngle(activeRectPos, overRectPos);

      stringConnectors.push({
        distance: distance,
        angle: angle,
        leftPos: activeRectPos.x,
        topPos: activeRectPos.y,
        overLeftPos: overRectPos.x,
        overTopPos: overRectPos.y,
        activeIden: value.from,
        overIden: value.to,
      });
    });

    setStringValues(stringConnectors);
    stringConnectors.forEach((value) => {
      if (value.leftPos && value.overLeftPos) {
        setStringPositions((stringPositions) => [
          ...stringPositions,
          [value.leftPos, value.overLeftPos],
        ]);
      }
    });
  };

  useEffect(() => {
    const histories = combineHistory.data.filter((history) => {
      return sources.some((source) => {
        return source.id === history.from;
      });
    });

    setCurrentRoundHistory(() => {
      return histories;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [combineHistory]);

  useEffect(() => {
    recalculateStringPositions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRoundHistory, windowSize]);

  return (
    <>
      <div
        className={clsx(
          s["boardPlay__modal--overlay"],
          isClueModalOpen && s["is-open"],
        )}
      >
        <div className={s.boardPlay__modal}>
          <img src="/images/clue-background.webp" alt="Clue Background" />
          <div
            className={s["boardPlay__modal--clue"]}
            style={{ backgroundImage: 'url("/images/clue-paper.webp")' }}
          >
            <h2 className={s["boardPlay__modal--title"]}>
              {t("Report of Investigation")}
            </h2>
            <img
              className={s["boardPlay__modal--classified"]}
              src="/images/classified.webp"
              alt="Classified"
            />
            <hr />
            <div className={s["boardPlay__modal--content"]}>
              <div className={s["boardPlay__modal--images"]}>
                <Avatar
                  url={fromSource?.photo}
                  name={
                    fromSource?.type === "object"
                      ? fromSource?.label
                      : fromSource?.name
                  }
                  label={fromSource?.type === "people" ? fromSource?.label : ""}
                  classNames={{
                    avatar: s["boardPlay__modal--avatar"],
                    avatar__inner: s["boardPlay__modal--avatar__inner"],
                    avatar__label: s["boardPlay__modal--avatar__label"],
                    avatar__label__shape:
                      s["boardPlay__modal--avatar__label--shape"],
                    avatar__name: s["boardPlay__modal--avatar__name"],
                  }}
                />
                <Avatar
                  url={toSource?.photo}
                  name={
                    toSource?.type === "object"
                      ? toSource?.label
                      : toSource?.name
                  }
                  label={toSource?.type === "people" ? toSource?.label : ""}
                  classNames={{
                    avatar: s["boardPlay__modal--avatar-2"],
                    avatar__inner: s["boardPlay__modal--avatar__inner"],
                    avatar__label: s["boardPlay__modal--avatar__label"],
                    avatar__label__shape:
                      s["boardPlay__modal--avatar__label--shape"],
                    avatar__name: s["boardPlay__modal--avatar__name"],
                  }}
                />
              </div>
              <div
                className={s["boardPlay__modal--text"]}
                dangerouslySetInnerHTML={{
                  __html:
                    clueData?.description ??
                    "<span style='padding-left: 30px;'>" +
                      t("[No clue found]") +
                      "</span>",
                }}
              />
            </div>
          </div>
          {isWarningOpen ? null : (
            <Button
              className={s["boardPlay__modal--close"]}
              text={t("Close")}
              onClick={closeClueModal}
              size="normal"
              iconEnd={Arrow}
            />
          )}
        </div>
      </div>
      <Board isOtherDragging={isOtherDragging}>
        {stringValues.map((value, i) => {
          return (
            <StringClue
              key={i}
              distance={value.distance}
              angle={value.angle}
              topPos={value.topPos}
              leftPos={value.leftPos}
              transparent={isStringDuplicate && isStringDuplicate[i]}
              activeSource={
                sources?.find((o) => o.id === value.activeIden)?.photo
              }
              overSource={sources?.find((o) => o.id === value.overIden)?.photo}
              activeName={
                sources?.find((o) => o.id === value.activeIden)?.type ===
                "people"
                  ? sources?.find((o) => o.id === value.activeIden)?.name
                  : sources?.find((o) => o.id === value.activeIden)?.label
              }
              overName={
                sources?.find((o) => o.id === value.overIden)?.type === "people"
                  ? sources?.find((o) => o.id === value.overIden)?.name
                  : sources?.find((o) => o.id === value.overIden)?.label
              }
              // onClick={() => openClueModal(clues.allData[i]?.description)}
              onClick={() =>
                openClueModal(
                  clues.allData.find((clue) => {
                    return (
                      clue.from === value.activeIden &&
                      clue.to === value.overIden
                    );
                  }) ?? { from: value.activeIden, to: value.overIden },
                )
              }
              className={clsx(s.boardPlay__string, isHolding && s["card-hold"])}
            />
          );
        })}
        <DndContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
          <div style={{ display: "contents" }} ref={dndRef}>
            <span className={s.boardPlay__label}>{t("Suspects")}</span>
            <div className={clsx(s.boardPlay__section, s.people)}>
              {sources && sources.length
                ? sources.map((source, i) => {
                    return (
                      (source.type === "people" ||
                        source.type === "document") && (
                        <Droppable
                          key={source.id}
                          source={source}
                          className={clsx(
                            s.boardPlay__coordinate,
                            suspectCount.length < 4 &&
                              s["boardPlay__coordinate--small"],
                          )}
                          classNames={{
                            "droppable--t-people": s["is-people"],
                          }}
                          dataID={source.id}
                        >
                          <div className={s.boardPlay__naturalization}>
                            <Draggable
                              source={source}
                              setActiveNode={setActiveNode}
                              disabled={
                                isOtherDragging ||
                                isDisabled ||
                                actionCount === 0
                              }
                              isForbidden={forbiddenTargetsIds.includes(
                                source.id,
                              )}
                              className={clsx(
                                s.boardPlay__draggable,
                                isHolding && s["is-holding"],
                              )}
                              hasName={true}
                              onMouseDown={actionCount && onCardHold}
                              onMouseUp={onCardRelease}
                            />
                          </div>
                        </Droppable>
                      )
                    );
                  })
                : null}
            </div>
            {sources.find((source) => source.type === "object") && (
              <span className={s.boardPlay__label} style={{ marginTop: "5em" }}>
                {t("Evidence")}
              </span>
            )}
            <div className={s.boardPlay__section}>
              {sources && sources.length
                ? sources.map((source) => {
                    return (
                      source.type === "object" && (
                        <Droppable
                          key={source.id}
                          source={source}
                          className={s.boardPlay__coordinate}
                          classNames={{
                            "droppable--t-object": s["is-object"],
                          }}
                        >
                          <div className={s.boardPlay__naturalization}>
                            <Draggable
                              source={source}
                              setActiveNode={setActiveNode}
                              disabled={true}
                              isForbidden={forbiddenTargetsIds.includes(
                                source.id,
                              )}
                              className={clsx(
                                s.boardPlay__draggable,
                                isHolding && s["is-holding"],
                              )}
                              hasName={false}
                            />
                          </div>
                        </Droppable>
                      )
                    );
                  })
                : null}
            </div>
          </div>
        </DndContext>
        <AnnouncerPopup
          isOpen={isCombining}
          content={t("Searching for a clue") + "..."}
        />
      </Board>
      {isTesting ? <DragTesting /> : null}
      {/* Warning */}
      {clues.totalClue === 1 &&
        isClueModalOpen &&
        round.index === 1 &&
        !hasSeenFirstClue && (
          <Warning
            warningClass=".Gameplay_BoardPlay-module__boardPlay__modal"
            warningKey="clue"
            placement="right"
            onClick={() => setIsWarningOpen(false)}
          />
        )}
    </>
  );
};

Board.propTypes = {
  sources: PropTypes.arrayOf(SourceShape),
  onDragEnd: PropTypes.func,
  setActiveNode: PropTypes.func,
  isCombining: PropTypes.bool,
  isDejavu: PropTypes.bool,
};

export default BoardPlay;
