import { createSlice } from "@reduxjs/toolkit";

/**
 * @type {StoreUserType}
 */
const INITIAL_STATE = {
  userId: null,
  wpToken: null,
  firebaseToken: null,
  hasBeenKicked: false,
  hasBeenRegistered: false,
  mantapUrl: null,
  hasCompletedMantapPreGame: false,
  hasCompletedMantapPostGame: false,
  currentTutorialIndex: 0,
  hasSeenFirstClue: false,
  isRejoinUser: false,
  reJoinAtRound: 0,
};

const userSlice = createSlice({
  name: "user",
  initialState: INITIAL_STATE,
  reducers: {
    /** @type {UtilReducer<StoreUserType, StoreUserType>} */
    init: (state, action) => {
      return action.payload;
    },

    /** @type {UtilReducer<StoreUserType, Omit<StoreUserType, "hasBeenKicked" | "hasBeenRegistered">>} */
    login: (state, action) => {
      return { ...state, ...action.payload };
    },

    /** @type {UtilReducer<StoreUserType, StoreUserType["firebaseToken"]>} */
    setFirebaseToken: (state, action) => {
      state.firebaseToken = action.payload;
      return state;
    },

    /** @type {UtilReducer<StoreUserType, StoreUserType["hasBeenRegistered"]>} */
    setHasBeenRegistered: (state, action) => {
      state.hasBeenRegistered = true;
      return state;
    },

    /** @type {UtilReducer<StoreUserType, StoreUserType["hasBeenKicked"]>} */
    setHasBeenKicked: (state, action) => {
      state.hasBeenKicked = action.payload;
      return state;
    },

    /** @type {UtilReducer<StoreUserType, StoreUserType["hasCompletedMantapPreGame"]>} */
    setHasCompletedMantapPreGame: (state, action) => {
      state.hasCompletedMantapPreGame = action.payload;
      return state;
    },

    /** @type {UtilReducer<StoreUserType, StoreUserType["hasCompletedMantapPostGame"]>} */
    setHasCompletedMantapPostGame: (state, action) => {
      state.hasCompletedMantapPostGame = action.payload;
      return state;
    },

    /** @type {UtilReducer<StoreUserType, StoreUserType["currentTutorialIndex"]>} */
    setCurrentTutorialIndex: (state, action) => {
      state.currentTutorialIndex = action.payload;
      return state;
    },

    /** @type {UtilReducer<StoreUserType, StoreUserType["hasSeenFirstClue"]>} */
    setHasSeenFirstClue: (state, action) => {
      state.hasSeenFirstClue = action.payload;
      return state;
    },

    rejoin: (state, action) => {
      return { ...state, ...action.payload };
    },

    /** @type {UtilReducer<StoreUserType, null>} */
    reset: (state, action) => {
      return INITIAL_STATE;
    },
  },
});

export const {
  init,
  login,
  setFirebaseToken,
  setHasBeenRegistered,
  setHasBeenKicked,
  setHasCompletedMantapPreGame,
  setHasCompletedMantapPostGame,
  setCurrentTutorialIndex,
  setHasSeenFirstClue,
  reset,
  rejoin,
} = userSlice.actions;
export default userSlice.reducer;
