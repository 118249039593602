import React from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";

import s from "./SuspectQuestion.module.scss";
import Fieldset from "@bd-components/Fieldset";
import Avatar from "@bd-components/Avatar";
import Checkbox from "@bd-components/Checkbox";
import SeekAnswer from "@bd-components/SeekAnswer";
import usePlayersFinalAnswer from "@bd-hooks/usePlayersFinalAnswer";
import useStore from "@bd-hooks/useStore";
import getAllQueryString from "@bd-utils/getAllQueryString";
import { getRoleByUserId } from "@bd-utils/roles";

/**
 * @type {React.FC<{
 *  index: number,
 *  questionId: FinalQuestionItemType['id'],
 *  title: string,
 *  subtitle: string,
 *  isLoading: boolean,
 *  options: FinalSourceQuestionType["sources"],
 *  onChange: (id: ApiSourceType["id"]) => void,
 *  value: ApiSourceType['id']
 * }>}
 */
const SuspectQuestion = ({
  index,
  questionId,
  title,
  subtitle,
  options,
  onChange,
  value,
  isLoading,
}) => {
  const location = useLocation();
  const keys = Object.keys(getAllQueryString(location.search));
  const isTesting = keys.includes("test-y0u-d0nt-kn0w");

  const answers = usePlayersFinalAnswer();
  const { gameProgress, gameData } = useStore();
  const { players } = gameProgress;
  const { roles } = gameData;
  const getRole = (userId) => getRoleByUserId(userId, players, roles);
  const isMultiPlayer = gameProgress.players.length > 1;

  const getColorsForValue = (value) => {
    const colors = Object.keys(answers?.[questionId] ?? {})
      .filter((userId) => answers?.[questionId]?.[userId] === value)
      .map((userId) => getRole(parseInt(userId)).color);
    return colors;
  };

  return (
    <div className={s.question}>
      {title ? (
        <h1
          className={s.question__title}
          data-testid={
            isTesting ? `finalQuestion_question-${questionId}_title` : undefined
          }
        >
          {title}
        </h1>
      ) : null}
      {subtitle ? (
        <div
          className={s.question__subtitle}
          data-testid={
            isTesting
              ? `finalQuestion_question-${questionId}_description`
              : undefined
          }
        >
          {subtitle}
        </div>
      ) : null}
      <Fieldset className={s.question__fieldset}>
        <div className={s.question__options}>
          {options.map((option) => (
            <div key={option.id} className={s.item}>
              {isMultiPlayer ? (
                <SeekAnswer
                  className={s.seekAnswer}
                  colors={getColorsForValue(option.id)}
                />
              ) : null}
              <Avatar
                classNames={{
                  avatar: s.item__avatar,
                  avatar__inner: s.item__avatarInner,
                  avatar__label: s.item__avatarLabel,
                }}
                url={option.image}
                name={option.name}
                label={option.label}
              />
              <button
                data-testid={
                  isTesting
                    ? `finalQuestion_question-${questionId}_answer-${option.id}`
                    : undefined
                }
                className={s.item__checkbox}
                disabled={isLoading}
                onClick={() => onChange(option)}
              >
                <Checkbox.Core
                  className={s.item__checkboxCore}
                  isActive={value === option.id}
                />
              </button>
            </div>
          ))}
        </div>
      </Fieldset>
    </div>
  );
};

SuspectQuestion.propTypes = {
  title: PropTypes.string,
  options: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
};

export default SuspectQuestion;
