import { useCallback } from "react";
import { useDispatch } from "react-redux";

import parseJwt from "@bd-utils/parseJwt";
import {
  login as loginUser,
  reset as resetUser,
  setFirebaseToken,
  setHasBeenRegistered,
  setHasCompletedMantapPreGame,
  rejoin,
} from "@bd-redux/user";
import { setTeam, reset as resetGameProgress } from "@bd-redux/game-progress";
import { reset as resetCombineHistory } from "@bd-redux/combine-history";
import { init, reset as resetGameData } from "@bd-redux/game-data";
import {
  setDisabledMantap,
  setDisabledMantapPostGame,
  setDisabledTypeform,
} from "@bd-redux/game-progress";
import { reset as resetLanguage } from "@bd-redux/language";
import {
  markConnected,
  reset as resetFirebase,
  setUseMyInitialLanguage,
} from "@bd-redux/firebase";
import { setIsConnecting } from "@bd-redux/firebase";
import { initFirebase } from "@bd-utils/firebase";
import getGameData from "@bd-apis/getGameData";
import goAsync from "@bd-utils/goAsync";
import getAvailableLanguages from "@bd-apis/getAvailableLanguages";
import { setLanguageOptions } from "@bd-redux/language";
import useFirebaseActions from "@bd-hooks/useFirebaseActions";
import useStore from "@bd-hooks/useStore";
import getTranslatedRoles from "@bd-utils/getTranslatedRoles";

/**
 * Process authentication data inside redux storage
 */
const useAuthProcess = () => {
  const dispatch = useDispatch();
  const {
    registerUser,
    setInitLanguage,
    destroy,
    setInitHasCompletedMantapPreGame,
    updateRejoinUser,
  } = useFirebaseActions();
  const { language } = useStore();

  const _setFirebaseToken = useCallback(
    (token) => dispatch(setFirebaseToken(token)),
    [dispatch],
  );

  /**
   * Save auth data into redux storage
   * @param {ApiResponseLogin["data"]} data
   * @param {string} [initLang]
   */
  const login = async (data, initLang = undefined, isRejoinUser = false) => {
    /**
     * @type {WpTokenData}
     */
    const wpTokenData = parseJwt(data.wp_token);

    dispatch(setIsConnecting(true));

    const [languagesRes, languagesErr] = await goAsync(getAvailableLanguages());

    if (languagesErr) {
      console.error(languagesErr);
      Promise.reject(languagesErr);
      return;
    }

    dispatch(setLanguageOptions(languagesRes.data.data));

    const languageCode =
      language.current?.value ?? languagesRes.data.data[0].value;

    const [gameDataRes, gameDataErr] = await goAsync(
      getGameData(languageCode, {
        headers: { Authorization: `Bearer ${data.wp_token}` },
      }),
    );

    if (gameDataErr) {
      console.error(gameDataErr);
      Promise.reject(gameDataErr);
      return;
    }

    await initFirebase({
      config: gameDataRes.data.data.config.firebase,
      token: data.fb_token,
      userId: wpTokenData.data.user.id,
      teamId: data.team.id,
      wpToken: data.wp_token,
      onNewToken: _setFirebaseToken,
    });

    await registerUser(wpTokenData.data.user.id, data.team.code, data.role_id)
      .then(() => dispatch(setHasBeenRegistered(true)))
      .catch((err) => {
        console.error(err);
        console.error("Error registering user into firebase [UAP-96]");
      });

    dispatch(
      init({
        ...gameDataRes.data.data,
        roles: getTranslatedRoles(gameDataRes.data.data.roles, language),
        rawRoles: gameDataRes.data.data.roles,
      }),
    );

    dispatch(setDisabledMantap(Boolean(data.team.disable_mantap_siap)));
    dispatch(
      setDisabledMantapPostGame(
        Boolean(data.team.disable_mantap_siap_post_game ?? false),
      ),
    );
    dispatch(
      setHasCompletedMantapPreGame(
        Boolean(data.team.disable_mantap_siap ?? false),
      ),
    );
    dispatch(setDisabledTypeform(Boolean(data.team.disable_typeform ?? false)));

    dispatch(
      loginUser({
        firebaseToken: data.fb_token,
        wpToken: data.wp_token,
        userId: wpTokenData.data.user.id,
        mantapUrl: data.mantap_url,
      }),
    );

    dispatch(
      setTeam({
        teamId: data.team.id,
        teamCode: data.team.code,
        isGameTest: Boolean(data.team.is_game_test),
        disabledLanguageSwitcher: Boolean(data.team.disable_lang_switch),
      }),
    );

    if (initLang) {
      setInitLanguage(initLang, data.team.code)
        .then(() => dispatch(setUseMyInitialLanguage(false)))
        .catch((err) => console.error("Error initializing language [UAP-126]"));
    }

    dispatch(markConnected());
    setInitHasCompletedMantapPreGame(
      data.team.code,
      wpTokenData.data.user.id,
      Boolean(data.team.disable_mantap_siap ?? false),
    );

    // Rejoin user
    if (isRejoinUser) {
      updateRejoinUser(wpTokenData.data.user.id, data.team.code);
      dispatch(
        rejoin({ isRejoinUser: true, reJoinAtRound: data.current_round }),
      );
    }
  };

  /**
   * Clear / remove all auth data from redux storage & Firebase app instance.
   *
   * NOTE:
   * This function used to be a dependency for useAxiosInterceptors, thus it
   * needs to be wrapped inside a useCallback to avoid unnecessary
   * re-evaluation
   */
  const logout = useCallback(() => {
    dispatch(resetCombineHistory());
    dispatch(resetGameProgress());
    dispatch(resetGameData());
    dispatch(resetFirebase());
    dispatch(resetUser());
    dispatch(resetLanguage());
    destroy();
    // TODO: is it correct?
    // eslint-disable-next-line
  }, [dispatch]);

  return { login, logout };
};

export default useAuthProcess;
