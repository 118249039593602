/**
 * Get user by particular roleId, will return false if turns out nobody use
 * or selected this roleId
 * @param {ApiRoleType["id"]} roleId
 * @param {StoreGameProgressType['players']} players
 */
export const getUserByRoleId = (roleId, players) => {
  const player = players.filter((p) => p.roleId === roleId)?.[0] || false;
  return player;
};

/**
 * Get user by particular userId, will return false if not found
 * @param {ApiPlayerType["userId"]} userId
 * @param {StoreGameProgressType['players']} players
 */
export const getUserByUserId = (userId, players) => {
  const player = players.filter((p) => p.userId === userId)?.[0] || false;
  return player;
};
