import { getDatabase, ref, set, onValue, off } from "firebase/database";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router";
import { useHistory } from "react-router-dom";
import useSound from "use-sound";
import clsx from "clsx";

import WelcomeLoading from "@bd-components/WelcomeLoading";
import SetupProfile from "@bd-components/SetupProfile";
import JoinTeam from "@bd-components/JoinTeam";

import s from "./ContinuousWelcome.module.scss";
import useStateWelcome from "./useStateWelcome";
import Popup from "@bd-components/Popup";
import Folder from "@bd-components/Folder";
import Button from "@bd-components/Button";
import Snackbar from "@bd-components/Snackbar";
import CreateTeam from "@bd-components/CreateTeam";
import TeamStatus from "@bd-components/TeamStatus";
import TeamStatusBigSize from "@bd-components/TeamStatusBigSize";
import sfp from "@bd-components/SmallFolderPopup/SmallFolderPopup.module.scss";
import useLobbyState from "@bd-hooks/useLobbyState";
import useStore from "@bd-hooks/useStore";
import useContinuousWelcomeRequirement from "@bd-hooks/useContinuousWelcomeRequirement";

const ContinuousWelcomeChecker = () => {
  const status = useContinuousWelcomeRequirement();
  if (!status.allowed) return <Redirect to={status.redirect} />;
  return <ContinuousWelcome />;
};

const ContinuousWelcome = () => {
  const { t } = useTranslation();
  const store = useStore();
  const { gameProgress, gameData, firebase } = store;
  const { game_audio } = gameData.config;
  const state = useStateWelcome();
  const lobby = useLobbyState();
  const removeTitle = t(
    "Are you sure you want to remove {player} from the game?",
  );
  const [hasCountdownStarted, setHasCountdownStarted] = useState(false);
  const [counter, setCounter] = useState(3);

  let serverTimeOffset = 0;
  firebase.isConnected &&
    onValue(ref(getDatabase(), ".info/serverTimeOffset"), (snap) => {
      serverTimeOffset = snap.val();
    });

  const getServerTime = () => {
    return Date.now() + serverTimeOffset;
  };

  const [play, { stop }] = useSound(game_audio?.["60_second_timer"], {
    volume: 0.5,
  });

  const onStartCountdown = () => {
    lobby.forcePopup.close();
    lobby.startCountdown();

    const countdownEndTime = getServerTime() + 3000;
    firebase.isConnected &&
      set(
        ref(getDatabase(), `${gameProgress.teamCode}/countdownEndTime`),
        countdownEndTime,
      );
  };

  useEffect(() => {
    if (!firebase.isConnected) return;

    const dbRef = ref(
      getDatabase(),
      `${gameProgress.teamCode}/countdownEndTime`,
    );

    const listener = onValue(dbRef, (snap) => {
      const countdownEndTime = snap.val();
      const currentTime = getServerTime();

      if (countdownEndTime && currentTime < countdownEndTime) {
        setCounter(Math.round((countdownEndTime - currentTime) / 1000));
        setHasCountdownStarted(true);
      }
    });

    return () => {
      off(dbRef, listener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firebase.isConnected]);

  useEffect(() => {
    if (hasCountdownStarted) {
      stop();
      play();
      counter > 0 &&
        setTimeout(() => {
          setCounter(counter - 1);
        }, 1000);
      if (counter === 0) {
        stop();
        lobby.start();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counter, hasCountdownStarted]);

  const history = useHistory();
  const redirectRejoinUser = () => {
    history.push("/");
  };

  return (
    <div className={s.wrapper}>
      <picture>
        <source srcSet="/images/background-funnel.webp" type="image/webp" />
        <source srcSet="/images/background-funnel.jpg" type="image/jpeg" />
        <img
          className={clsx(s.img, s.isBottomAligned)}
          src="/images/background-funnel.jpg"
          alt={t("Login image background")}
        />
      </picture>
      <div
        className={clsx(
          s.countdown__overlay,
          !hasCountdownStarted && s["is-hidden"],
        )}
      >
        <div className={s.countdown}>
          <span className={s.countdown__title}>{t("Game starting in")}</span>
          <span className={s.countdown__counter}>{counter}</span>
        </div>
      </div>
      <div
        className={clsx(s.setProfile, hasCountdownStarted && s["is-hidden"])}
      >
        <div
          className={clsx(
            s.drawer,
            state.isPlayerStatuses && s["is-darken-layer"],
          )}
        >
          <div
            className={clsx(
              s.drawer__main,
              state.isPlayerStatuses && s["is-shrinked"],
            )}
          >
            <picture>
              <source srcSet="/images/backcover.webp" type="image/webp" />
              <source srcSet="/images/backcover.png" type="image/png" />
              <img
                className={s.drawer__backcover}
                src="/images/backcover.png"
                alt={t("Backcover of a book")}
              />
            </picture>
            <picture>
              <source srcSet="/images/papers.webp" type="image/webp" />
              <source srcSet="/images/papers.png" type="image/png" />
              <img
                className={s.drawer__papers}
                src="/images/papers.png"
                alt={t("Book papers")}
              />
            </picture>
            <button
              className={s.drawer__editProfile}
              onClick={state.editProfile}
              data-testid="setup_editProfileBtn"
            >
              <picture>
                <source
                  srcSet="/images/edit-profile-button.webp"
                  type="image/webp"
                />
                <source
                  srcSet="/images/edit-profile-button.png"
                  type="image/png"
                />
                <img
                  className={s.drawer__editProfile__img}
                  src="/images/edit-profile-button.png"
                  alt={t("Paper textures")}
                />
              </picture>
              <span>{t("Edit Profile")}</span>
            </button>
            <WelcomeLoading
              text={t("Checking magic link")}
              isOpen={state.isCheckingToken}
            />
            <WelcomeLoading
              text={
                state.isChangingLanguage
                  ? t("Changing Language")
                  : t("Authenticating")
              }
              isOpen={state.isLoading && !state.isCheckingToken}
              isInverted={state.prev?.isJoinTeamPage && state.isLoading}
            />
            <JoinTeam
              isOpen={state.isJoinTeam}
              isInverted={
                (state.prev?.isLoading && state.isJoinTeam) ||
                (state.prev?.isCheckingToken && state.isJoinTeam)
              }
            />
            <CreateTeam
              isOpen={state.isCreateTeam}
              isInverted={
                (state.prev?.isLoading && state.isCreateTeam) ||
                (state.prev?.isCheckingToken && state.isCreateTeam) ||
                (state.prev?.isJoinTeamPage && state.isCreateTeam) ||
                (state.prev?.isCreateTeamPage && state.isJoinTeam)
              }
            />
            <SetupProfile
              isOpen={state.isSetupProfile}
              isInverted={
                (state.prev?.isLoading && state.isSetupProfile) ||
                (state.prev?.isCheckingToken && state.isSetupProfile)
              }
            />
          </div>
        </div>
        <button
          className={clsx(s.exitGame, state.isPlayerStatuses && s["is-shown"])}
          onClick={lobby.onExit}
        >
          {t("Exit game")}
        </button>
        <TeamStatus
          isOpen={!state.isLoading && !state.isPlayerStatuses}
          isRejoinUser={lobby.isRejoinUser}
        />
        <TeamStatusBigSize
          isOpen={state.isPlayerStatuses}
          onStartGame={lobby.onForceStart}
          isStartingGame={lobby.isTryingToStart}
          onEditProfile={state.editProfile}
          isForceable={lobby.isForceable}
          onRemovePlayer={lobby.onRemovePlayer}
          isRejoinUser={lobby.isRejoinUser}
        />

        {/* Force start popup */}
        <Popup isOpen={lobby.forcePopup.isOpen}>
          <Folder size="small">
            <Folder.Inner>
              <div className={s.prompt}>
                <div className={s.prompt__inner}>
                  <h1 className={sfp.smallFolderPopup__title}>
                    {lobby.isRejoinUser
                      ? t(`Are you sure you want to continue the game?`)
                      : t(`Are you sure you want to start the game?`)}
                  </h1>
                  <div className={s.prompt__actions}>
                    <div className={s.prompt__actions__item}>
                      <Button
                        testId="game_cancelStartButton"
                        text={t("Cancel")}
                        size="jumbo"
                        disabled={lobby.isTryingToStart}
                        onClick={lobby.forcePopup.close}
                      />
                    </div>
                    <div className={s.prompt__actions__item}>
                      <Button
                        testId="game_confirmStartButton"
                        text={
                          lobby.isRejoinUser
                            ? t("Continue game")
                            : t("Start game")
                        }
                        size="jumbo"
                        isLoading={lobby.isTryingToStart}
                        onClick={
                          lobby.isRejoinUser
                            ? redirectRejoinUser
                            : onStartCountdown
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Folder.Inner>
          </Folder>
        </Popup>

        {/* Exit game popup */}
        <Popup isOpen={lobby.exitPopup.isOpen}>
          <Folder size="small">
            <Folder.Inner>
              <div className={s.prompt}>
                <div className={s.prompt__inner}>
                  <h1 className={sfp.smallFolderPopup__title}>
                    {t("Are you sure you want to leave the game?")}
                  </h1>
                  <div className={s.prompt__actions}>
                    <div className={s.prompt__actions__item}>
                      <Button
                        text={t("Cancel")}
                        size="jumbo"
                        onClick={lobby.exitPopup.close}
                      />
                    </div>
                    <div className={s.prompt__actions__item}>
                      <Button
                        text={t("Yes, Exit game")}
                        size="jumbo"
                        onClick={lobby.exitGame}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Folder.Inner>
          </Folder>
        </Popup>

        {/* Remove player popup */}
        <Popup isOpen={lobby.removePlayerPopup.isOpen}>
          <Folder size="small">
            <Folder.Inner>
              <div className={s.prompt}>
                <div className={s.prompt__inner}>
                  <h1 className={sfp.smallFolderPopup__title}>
                    {removeTitle.replace(
                      "{player}",
                      lobby.playerWillBeRemoved.name,
                    )}
                  </h1>
                  <div className={s.prompt__actions}>
                    <div className={s.prompt__actions__item}>
                      <Button
                        text={t("Cancel")}
                        size="jumbo"
                        onClick={lobby.removePlayerPopup.close}
                      />
                    </div>
                    <div className={s.prompt__actions__item}>
                      <Button
                        text={t("Remove")}
                        size="jumbo"
                        onClick={lobby.removePlayer}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Folder.Inner>
          </Folder>
        </Popup>

        <Snackbar
          isUsingPortal
          withOverlay
          title={lobby.snackbar.title}
          message={lobby.snackbar.message}
          status={lobby.snackbar.status}
          onClose={lobby.snackbar.close}
          isOpen={lobby.snackbar.isOpen}
        />
      </div>
    </div>
  );
};

export default ContinuousWelcomeChecker;
