import { appRoutes } from "@bd-constants/routes";
import useRequirementHelpers from "./useRequirementHelpers";

const useGameplayRequirement = () => {
  const {
    hasLoggedIn,
    hasStartedGame,
    hasSeenRoundIntroVideo,
    hasSeenPreIntuition,
  } = useRequirementHelpers();

  return {
    allowed:
      hasLoggedIn &&
      hasStartedGame &&
      hasSeenRoundIntroVideo &&
      !hasSeenPreIntuition,
    redirect: !hasLoggedIn
      ? appRoutes.welcome
      : !hasStartedGame
      ? appRoutes.waitingRoom
      : !hasSeenRoundIntroVideo
      ? appRoutes.gameIntro
      : hasSeenPreIntuition
      ? appRoutes.finalQuestion
      : null,
  };
};

export default useGameplayRequirement;
