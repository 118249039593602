import React from "react";

import Tooltip from "@bd-components/Tooltip";

import { useTranslation } from "react-i18next";

import useStore from "@bd-hooks/useStore";
import useActiveRoundData from "@bd-hooks/useActiveRoundData";

import s from "./Goals.module.scss";

const Goals = () => {
  const { t } = useTranslation();
  const round = useActiveRoundData();
  const { gameData } = useStore();

  return (
    <div className={s.goals__wrapper}>
      <div className={s.goals__head}>
        <picture>
          <source srcSet="/images/board-clip.webp" type="image/webp" />
          <source srcSet="/images/board-clip.png" type="image/png" />
          <img
            className={s.goals__clips}
            src="/images/board-clip.png"
            alt="Board Clip"
          />
        </picture>
        <picture>
          <source srcSet="/images/board-clip.webp" type="image/webp" />
          <source srcSet="/images/board-clip.png" type="image/png" />
          <img
            className={s.goals__clips}
            src="/images/board-clip.png"
            alt="Board Clip"
          />
        </picture>
      </div>

      <div className={s.goals__body}>
        <div className={s.goals__inner}>
          <div className={s.goals__content}>
            <Tooltip
              text={t("The task to accomplish")}
              top="25px"
              left="100px"
              hoverDirection="bottom"
            />
            <h3
              className={s.goals__title}
              style={{ backgroundImage: "url(/images/goals-curve.svg)" }}
            >
              {t("Goals")}
            </h3>
            <div className={s.goals__text}>
              {t(
                gameData?.rounds[round.index - 1]?.goal ??
                  "Find the unidentified agent's accomplice and their wrongdoing",
              )}
            </div>
          </div>
        </div>
      </div>

      <div className={s.goals__foot}></div>
    </div>
  );
};

export default Goals;
