import useStore from "@bd-hooks/useStore";
import { getRoleByRoleId } from "@bd-utils/roles";
import { ReactComponent as IconUser } from "@bd-icons/icon-user-solid.svg";
import s from "./RoleAvatar.module.scss";

/**
 * @param {object} param0
 * @param {ApiPlayerType} param0.player
 */
const RoleAvatar = ({ player }) => {
  const store = useStore();
  return (
    <div className={s.RoleAvatar}>
      {player.roleId ? (
        <div>
          <img
            className={s.RoleAvatar__img}
            src={
              /** @type {string} */ (
                getRoleByRoleId(player.roleId, store.gameData.roles).avatar
              )
            }
            alt={
              getRoleByRoleId(player.roleId, store.gameData.roles).name || ""
            }
            title={player.name}
          />
        </div>
      ) : (
        <div className={s.RoleAvatar__placeholder}>
          <IconUser className={s.RoleAvatar__placeholder__svg} />
        </div>
      )}
    </div>
  );
};

export default RoleAvatar;
