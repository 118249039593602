import useStore from "./useStore";

/**
 * @param {ApiRoundType[]} rounds
 * @param {ApiActionsType["id"]} roundId
 */
const getRoundIndex = (rounds, roundId) => {
  return rounds.findIndex((r) => r.id === roundId);
};

const useActiveRoundData = () => {
  const { gameData, gameProgress } = useStore();
  const roundIndex = getRoundIndex(
    gameData.rounds,
    gameProgress.roundProgress?.roundId,
  );
  const round = gameData.rounds[roundIndex];
  return round;
};

export default useActiveRoundData;
