import React from "react";

import s from "./Projector.module.scss";

/**
 * @type {import("./Projector").ProjectorType}
 */
const Projector = ({ children, onImageLoaded }) => {
  return (
    <div className={s.projector}>
      <div className={s.projector__ruler}>
        <picture>
          <source srcSet="/images/projector.webp" type="image/webp" />
          <source srcSet="/images/projector.png" type="image/png" />
          <img
            className={s.projector__img}
            src="/images/projector.png"
            alt="Projector background"
            onLoad={onImageLoaded}
          />
        </picture>
        <div className={s.projector__inner}>
          <div className={s.projector__aspectRatio}>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default Projector;
