import { createSlice } from "@reduxjs/toolkit";

/**
 * @type {StoreGameDataType}
 */
const INITIAL_STATE = {
  config: {
    firebase: {
      api_key: null,
      auth_domain: null,
      database_url: null,
      app_id: null,
      measurement_id: null,
    },
  },
  rounds: [],
  roles: [],
  individual_reflection: null,
  group_reflection: null,
  initialized: false,
  fetched: false,
};

const gameDataSlice = createSlice({
  name: "gameData",
  initialState: INITIAL_STATE,
  reducers: {
    /** @type {UtilReducer<StoreGameDataType, StoreGameDataType>}*/
    init: (state, action) => {
      const roundsSortedByIndexProp = action.payload.rounds.sort(
        (a, b) => a.index - b.index,
      );
      action.payload.rounds = roundsSortedByIndexProp;
      action.payload.initialized = true;
      return action.payload;
    },

    /** @type {UtilReducer<StoreGameDataType, null>}*/
    reset: (state, action) => {
      return INITIAL_STATE;
    },
  },
});

export const { init, reset } = gameDataSlice.actions;
export default gameDataSlice.reducer;
