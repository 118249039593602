import getRoleIntuitions from "@bd-utils/getRoleIntuitions";
import useActiveRoundData from "./useActiveRoundData";
import useStore from "./useStore";

const useIntuitions = () => {
  const round = useActiveRoundData();
  const { currentPlayerRole } = useStore();
  const roleIntuitions = getRoleIntuitions(
    round?.intuitions,
    currentPlayerRole.id,
  );

  return {
    intuitions: roleIntuitions,
  };
};

export default useIntuitions;
