import { off, getDatabase } from "firebase/database";
import { initializeApp, getApps, getApp } from "firebase/app";
import { signInWithCustomToken, initializeAuth } from "firebase/auth";
import axios from "axios";
import goAsync from "./goAsync";

// TODO: Investigate why we need wpToken passed here? idealy it should be
// handled by the useAxiosInterceptors
export const getNewFirebaseToken = async ({ userId, teamId, wpToken }) => {
  /** @type {UtilAxiosPost<ApiRequestGetFirebaseToken,ApiResponseGetFirebaseToken>} */
  const request = axios.post;
  const [response, requestErr] = await goAsync(
    request(
      "/member/get-firebase-custom-token",
      { team_id: teamId, user_id: userId },
      { headers: { Authorization: `Bearer ${wpToken}` } },
    ),
  );
  if (requestErr) return console.error("Failed to get new firebase token");
  return response.data.data.firebase_token;
};

/**
 * Init firebase app and returning firebase db
 * @param {object} params
 * @param {ApiFirebaseType} params.config
 * @param {StoreUserType["firebaseToken"]} params.token
 * @param {StoreUserType["userId"]} params.userId
 * @param {StoreGameProgressType["teamId"]} params.teamId
 * @param {StoreUserType["wpToken"]} params.wpToken
 * @param {(token: string) => void} params.onNewToken this will be called if current token is
 * expired and we get new token
 */
export const initFirebase = async ({
  config,
  token,
  userId,
  teamId,
  wpToken,
  onNewToken,
}) => {
  if (getApps().length === 0) {
    initializeApp({
      apiKey: config.api_key,
      authDomain: config.auth_domain,
      databaseURL: config.database_url,
      appId: config.app_id,
      measurementId: config.measurement_id,
      projectId: config.project_id,
    });
  }

  /**
   * NOTE: IMPORTANT
   * Avoid using promise.catch() for every firebase auth, you should always use
   * try catch block instead.
   * see:
   * https://github.com/firebase/firebase-js-sdk/issues/4242
   * https://github.com/firebase/firebase-js-sdk/issues/1881
   */
  try {
    await signInWithCustomToken(initializeAuth(getApp()), token);
  } catch (err) {
    // in case error happen, Get new token and retry to initFirebase again
    const newToken = await getNewFirebaseToken({ userId, teamId, wpToken });
    onNewToken(newToken);
    return await initFirebase({
      config,
      token: newToken,
      userId,
      teamId,
      wpToken,
      onNewToken,
    });
  }

  const db = getDatabase();

  return db;
};

/**
 * Safe firebase event listener cleanup for side-effect
 * @param {*} ref Firebase database ref
 * @param {*} func Event handler
 * @param {import("firebase/database").EventType} event Event name
 * @returns
 */
export const effectCleanup =
  (ref, func, event = "value") =>
  () => {
    try {
      if (!ref) return;
      off(ref, event, func);
    } catch (e) {
      console.error("Nothing to cleanup, database doesn't exist");
    }
  };
