/**
 * Check whether a Draggable is combinable with a Draggable below it
 * @param {{
 *  offsetLeft: Number,
 *  offsetTop: Number,
 *  width: Number,
 *  height: Number
 * }} active
 * @param {{
 *  offsetLeft: Number,
 *  offsetTop: Number,
 *  width: Number,
 *  height: Number
 * }} over
 * @returns {Boolean}
 */
const checkCombinable = (active, over) => {
  if (!active) return false;
  if (!over) return false;

  const a = active;
  const o = over;

  const combinableHeight = a.height / 5;
  const combinableWidth = a.width / 5;

  // What is x<number> and y<number> ?
  // this is an imaginary coordinate gather from those 2 object
  // x1 is the very left coordinate from those 2 object, x4 is the very right
  // y1 is the very top coordinate from those 2 object, y4 is the very bottom

  const x2 = Math.max(a.offsetLeft, o.offsetLeft);
  const x3 = Math.min(a.offsetLeft + a.width, o.offsetLeft + o.width);
  const y2 = Math.max(a.offsetTop, o.offsetTop);
  const y3 = Math.min(a.offsetTop + a.height, o.offsetTop + o.height);

  const isCombinableHorizontally = x3 - x2 > combinableWidth;
  const isCombinableVertically = y3 - y2 > combinableHeight;
  const isCombinable = isCombinableHorizontally && isCombinableVertically;

  if (isCombinable) {
    return true;
  } else if (!isCombinable) {
    return false;
  }
};

export default checkCombinable;
