import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import useSound from "use-sound";
import useStore from "@bd-hooks/useStore";

import s from "./Button.module.scss";
import { stylesCombinerFn } from "@bd-utils/stylesCombiner";
import { ReactComponent as IconLoading } from "@bd-icons/icon-loading.svg";
import { ReactComponent as Shape } from "./shape.svg";

const Text = ({ className, children }) => {
  return <span className={clsx(s.button__text, className)}>{children}</span>;
};

Text.propTypes = {
  className: PropTypes.string,
};

const DisabledText = ({ className, children }) => {
  return (
    <span className={clsx(s.button__disabledText, className)}>{children}</span>
  );
};

const Icon = ({ className, svg: Svg }) => {
  return (
    <div className={clsx(s.button__icon, className)}>
      <Svg />
    </div>
  );
};

const Loading = ({ className }) => {
  return (
    <div
      className={clsx(
        "g-animation-spin-with-centering",
        s.button__loading,
        className,
      )}
    >
      <IconLoading className={clsx(s.button__loadingSvg)} />
    </div>
  );
};

const Button = ({
  size,
  variant = "contained",
  color = "primary",
  as = "button",
  className,
  classNames = {},
  isLoading,
  text,
  disabledText,
  iconStart,
  iconEnd,
  children,
  testId,
  ...props
}) => {
  const ElementType = as;
  const c = stylesCombinerFn(s, classNames);
  const { gameData } = useStore();
  const { game_audio } = gameData.config;

  const [play] = useSound(game_audio?.button_basic, {
    volume: 0.5,
  });

  return (
    <ElementType
      {...props}
      className={c(
        "button",
        size && `size--${size}`,
        color && `color--${color}`,
        variant && `variant--${variant}`,
        isLoading && "is-loading",
        props.disabled && "is-disabled",
        className,
      )}
      onMouseDown={() => !props.disabled && play()}
      disabled={props?.disabled || isLoading}
      data-testid={testId}
    >
      <div className={c("button__flex")}>
        <Shape className={c("button__shape")} />
        {children ? (
          typeof children === "function" ? (
            children({ Loading, Icon, Text })
          ) : (
            children
          )
        ) : (
          <>
            {isLoading ? (
              <Loading className={classNames.button__loading} />
            ) : null}
            {iconStart ? (
              <Icon
                className={c("button__icon", "button__iconStart")}
                svg={iconStart}
              />
            ) : null}
            {text ? (
              <Text className={classNames.button__text}>{text}</Text>
            ) : null}
            {disabledText ? (
              <DisabledText className={classNames.button__disabledText}>
                {disabledText}
              </DisabledText>
            ) : null}
            {iconEnd ? (
              <Icon
                className={c("button__icon", "button__iconEnd")}
                svg={iconEnd}
              />
            ) : null}
          </>
        )}
      </div>
    </ElementType>
  );
};

Button.Text = Text;
Button.Icon = Icon;
Button.Loading = Loading;

Button.propTypes = {
  size: PropTypes.string,
  variant: PropTypes.string,
  color: PropTypes.string,
  isLoading: PropTypes.bool,
  text: PropTypes.string,
  disabledText: PropTypes.string,
  iconStart: PropTypes.elementType,
  iconEnd: PropTypes.elementType,
  className: PropTypes.string,
  classNames: PropTypes.shape({
    button: PropTypes.string,
    button__text: PropTypes.string,
    button__icon: PropTypes.string,
    button__iconStart: PropTypes.string,
    button__shape: PropTypes.string,
  }),
};

export default Button;
