import React from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

import s from "./SetupProfile.module.scss";
import sj from "@bd-components/JoinTeam/JoinTeam.module.scss";
import Button from "@bd-components/Button";
import TextField from "@bd-components/TextField";
import WelcomeTransition from "@bd-components/WelcomeTransition";
import { ReactComponent as IconChevronRight } from "@bd-icons/icon-chevron-right-solid.svg";

import useStateSetupProfile from "./useStateSetupProfile";

const SetupProfile = ({ isOpen, isInverted }) => {
  const { t } = useTranslation();
  const state = useStateSetupProfile();

  return (
    <WelcomeTransition
      className={clsx(sj.root, s.root)}
      isOpen={isOpen}
      isInverted={isInverted}
      elementType="form"
      elementProps={{
        onSubmit: state.onSubmit,
      }}
    >
      <div className={s.header}>
        <h3 className={sj.heading} data-testid="setup_title">
          {t("Setup Profile")}
        </h3>
      </div>
      <div className={sj.description}>{t("Setup profile description")}</div>
      <div className={s.label}>{t("Your name")}</div>
      <TextField
        testId="setup_input"
        classNames={s}
        color="black"
        name="name"
        placeholder={t("Your name")}
        autoComplete="name"
        value={state.name}
        error={state.nameErr}
        onChange={state.onChangeName}
      />
      <Button
        testId="setup_submit"
        className={sj.button}
        type="submit"
        variant="contained"
        color="yellow"
        size="medium"
        text={t("Save")}
        iconEnd={IconChevronRight}
        isLoading={state.isSubmiting}
      />
      {/* <div className={s.SetupProfile}>
          <div className={s.SetupProfile__header}>
            <h3 className={s.SetupProfile__title}>{t("Setup Profile")}</h3>
          </div>
          SetupProfile
        </div> */}
    </WelcomeTransition>
  );
};

export default SetupProfile;
