import { useTranslation } from "react-i18next";

import s from "./TeamStatus.module.scss";
import useStore from "@bd-hooks/useStore";
// import LangSwitcher from "@bd-components/LangSwitcher";
import WelcomeTransition from "@bd-components/WelcomeTransition";
import TeamStatusPlayer from "@bd-components/TeamStatusPlayer";
import LangSwitcherWithFlag from "@bd-components/LangSwitcherWithFlag";

const TeamStatus = ({ isOpen: propIsOpen, isRejoinUser }) => {
  const { t } = useTranslation();
  const store = useStore();
  const isOpen = Boolean(store.gameProgress.teamCode) && propIsOpen;

  const totalReadyPlayers = store.gameProgress.players.filter(
    (p) => p.ready && typeof p.roleId === "number",
  ).length;

  return (
    <WelcomeTransition isOpen={isOpen} className={s.TeamStatus}>
      <div className={s.TeamStatus__header}>
        <h5 className={s.TeamStatus__code}>
          {t("Team-Id")} {store.gameProgress.teamCode}
        </h5>
        <p className={s.TeamStatus__waiting}>
          {t("Waiting for players")} ({totalReadyPlayers}/
          {store.gameProgress.players.length})
        </p>
      </div>
      {/* <div className={s.TeamStatus__language}>
        <LangSwitcher />
      </div> */}
      <div className={s.TeamStatus__body}>
        <div className={s.TeamStatus__players}>
          {store.gameProgress.players.map((p, i) => (
            <TeamStatusPlayer key={p.userId} index={i} player={p} />
          ))}
        </div>
      </div>
      <div className={s.TeamStatus__languageWithFlag}>
        {!isRejoinUser ? <LangSwitcherWithFlag /> : null}
      </div>
    </WelcomeTransition>
  );
};

export default TeamStatus;
