import React, { useCallback, useEffect, useRef } from "react";
import { Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";

import WallBackground from "@bd-components/WallBackground";
import Projector from "@bd-components/Projector";
import VideoPlayer from "@bd-components/VideoPlayer";
import Button from "@bd-components/Button";
import useStore from "@bd-hooks/useStore";
import useRoundIntroVideoRequirement from "@bd-hooks/useRoundIntroVideoRequirement";
import useActiveRoundData from "@bd-hooks/useActiveRoundData";
import useFirebaseActions from "@bd-hooks/useFirebaseActions";

import s from "./RoundIntroVideo.module.scss";

const RoundIntroVideoChecker = () => {
  const status = useRoundIntroVideoRequirement();
  if (!status.allowed) return <Redirect to={status.redirect} />;
  return <RoundIntroVideo />;
};

const RoundIntroVideo = () => {
  const { t } = useTranslation();
  const round = useActiveRoundData();
  const { firebase: fb } = useStore();
  const { setSeenRoundIntroVideo } = useFirebaseActions();
  const refIsFrameLoaded = useRef(false);

  /**
   * @type {import("react").MutableRefObject<HTMLVideoElement | null>}
   */
  const refVideoPlayer = useRef(null);

  const decidePlayVideo = () => {
    if (!fb.isConnected) return;
    if (!refIsFrameLoaded.current) return;
    if (!refVideoPlayer.current.paused) return;

    refVideoPlayer.current
      ?.play()
      .catch((e) => {
        console.error("Can't auto-play video");
      })
      .then(() => {
        const video = refVideoPlayer.current;
        !video?.paused &&
          // quick fix for dynamic import issue
          document.querySelector(".video-js")?.classList.add("vjs-has-started");
      });
  };

  const onProjectorLoaded = () => {
    refIsFrameLoaded.current = true;
    decidePlayVideo();
  };

  const onVideoEnd = useCallback(() => {
    setSeenRoundIntroVideo();
  }, [setSeenRoundIntroVideo]);

  /**
   * Intro video availability checker
   */
  useEffect(() => {
    if (!fb.isConnected) return;
    if (round?.level_intro_video) return;

    // if there's no intro video, immediately end the video to bring user go to
    // the gameplay
    onVideoEnd();
  }, [fb.isConnected, round?.level_intro_video, onVideoEnd]);

  useEffect(() => {
    decidePlayVideo();
    // eslint-disable-next-line
  }, [fb.isConnected]);

  return (
    <WallBackground>
      <Projector onImageLoaded={onProjectorLoaded}>
        {fb.isConnected ? (
          <VideoPlayer
            playOrPauseTestId="round_playIntroVideo"
            ref={refVideoPlayer}
            autoplay={false}
            url={round?.level_intro_video}
            type="video/mp4"
            onEnded={onVideoEnd}
          />
        ) : null}
      </Projector>
      <Button
        className={s["roundIntroVideo__skip"]}
        text={t("Skip")}
        onClick={onVideoEnd}
        size="normal"
      />
    </WallBackground>
  );
};

export default RoundIntroVideoChecker;
