import axios from "axios";
import goAsync from "@bd-utils/goAsync";

/**
 * Get available language from the backend
 *
 * @param {string} lang selected language
 * @param {import("axios").AxiosRequestConfig} [config]
 */
const getGameData = async (lang, config = {}) => {
  /**
   * @type {UtilAxiosPost<ApiRequestGameData, ApiResponseGameData>}
   */
  const request = axios.post;
  const [response, err] = await goAsync(
    request("/game/game-data", { lang }, config),
  );

  if (err) {
    console.error("Failed to get game data", { err });
    return Promise.reject();
  }

  return response;
};

export default getGameData;
