import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import s from "./ErrorMessage.module.scss";

const ErrorMessage = ({ className, children }) => {
  return <div className={clsx(s.errorMessage, className)}>{children}</div>;
};

ErrorMessage.propTypes = {
  className: PropTypes.string,
};

export default ErrorMessage;
