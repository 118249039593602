import { appRoutes } from "@bd-constants/routes";
import useRequirementHelpers from "./useRequirementHelpers";

const useFinalQuestionRequirement = () => {
  const { hasLoggedIn, hasSeenPreIntuition, hasFinishedGame } =
    useRequirementHelpers();

  return {
    allowed: hasLoggedIn && hasSeenPreIntuition && !hasFinishedGame,
    redirect: !hasLoggedIn
      ? appRoutes.welcome
      : !hasSeenPreIntuition
      ? appRoutes.home
      : hasFinishedGame
      ? appRoutes.reflection
      : null,
  };
};

export default useFinalQuestionRequirement;
