import React from "react";
import { useTranslation } from "react-i18next";

import s from "./LobbyCard.module.scss";
import { stylesCombinerFn } from "@bd-utils/stylesCombiner";
import { ReactComponent as IconCheck } from "./LobbyCardCheck.svg";
import { ReactComponent as IconStar } from "./LobbyCardStar.svg";

const LobbyCard = ({
  className,
  classNames,
  name,
  color,
  withAction,
  isReady,
  isLeader,
  isPlaceholder,
  isWithChecklist = true,
  onRemove,
}) => {
  const { t } = useTranslation();
  const c = stylesCombinerFn(s, classNames);
  const SvgIcon = isLeader ? IconStar : IconCheck;

  return (
    <div
      className={c(
        "lobbyCard",
        isLeader && "is-leader",
        isReady && "is-ready",
        isPlaceholder && "is-placeholder",
        className,
      )}
    >
      <div
        className={c("lobbyCard__name")}
        style={isPlaceholder ? {} : { backgroundColor: color }}
      >
        <div className={c("lobbyCard__text")}>
          {isPlaceholder ? "..." : name}
        </div>
        {isWithChecklist ? (
          <div className={c("lobbyCard__status")}>
            <SvgIcon className={c("lobbyCard__check")} />
          </div>
        ) : null}
      </div>
      {isPlaceholder || !withAction ? null : (
        <button className={c("lobbyCard__remove")} onClick={onRemove}>
          ({t("Remove")})
        </button>
      )}
    </div>
  );
};

export default LobbyCard;
