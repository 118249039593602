import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import s from "./Checkbox.module.scss";
import Fieldset from "@bd-components/Fieldset";

const Core = ({ className, isActive }) => {
  return (
    <div className={clsx(s.core, isActive && s["is-active"], className)}>
      <picture>
        <source srcSet="/images/checkbox-filler.webp" type="image/webp" />
        <source srcSet="/images/checkbox-filler.png" type="image/png" />
        <img
          className={s.core__filler}
          src="/images/checkbox-filler.png"
          alt="checkbox check illustration"
        />
      </picture>
    </div>
  );
};

const Checkbox = ({
  className,
  label,
  value,
  disabled = false,
  onChange = () => {},
}) => {
  return (
    <button
      className={clsx(
        s.checkbox,
        value && s["is-active"],
        disabled && s["is-disabled"],
        className,
      )}
      onClick={() => (disabled ? null : onChange(!value))}
    >
      <Fieldset
        classNames={{
          fieldset: s.checkbox__fieldset,
          fieldset__label: s.checkbox__fieldset__label,
        }}
        label={label}
      >
        <Core isActive={value} />
      </Fieldset>
    </button>
  );
};

Checkbox.Core = Core;

Checkbox.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.bool,
  onChange: PropTypes.func,
};

export default Checkbox;
