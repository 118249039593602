import { createStore } from "redux";
import { createMigrate, persistStore, persistReducer } from "redux-persist";
import { storeVersion } from "@bd-constants/version";
import storage from "redux-persist/lib/storage";

import rootReducer from "./_root-reducers";

const migrations = {
  2: (state) => {
    /**
     * Migration from v1 to v2
     * - Remove `gameProgress.roundProgress.finalResponse` from v1
     * - Add language
     */

    /**
     * Check in case roundProgress is still null or undefined, if so keep it
     * that way, if not then prepare for `finalResponse` removal
     */
    let newRoundProgress = state.roundProgress
      ? { ...state.roundProgress }
      : state.roundProgress;

    // If `finalResponse` has truthy value then remove it from hydration
    if (newRoundProgress?.finalResponse) {
      newRoundProgress["finalResponse"] = undefined;
    }

    return {
      ...state,
      gameProgress: {
        ...state.gameProgress,
        roundProgress: newRoundProgress,
      },
    };
  },
  6: (state) => {
    /**
     * Migration from v5 to v6
     * - Add `language.hasChosenLanguageManually`
     */
    return {
      ...state,
      language: {
        ...state.language,
        hasChosenLanguageManually: false,
      },
    };
  },
};

/**
 * @type {import("redux-persist/es/types").PersistConfig<StoreRootType>}
 */
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["gameProgress", "gameData", "user", "combineHistory"],

  // language is a nested persist, so it shouldn't be persisted in root
  blacklist: ["language"],

  version: storeVersion,
  migrate: createMigrate(migrations, { debug: false }),
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = () => {
  let _store = createStore(
    persistedReducer,

    /**
     * If you want to debug redux you have to access the url with
     * `?redux-devtools` (without backtick)
     */
    window.location.search.includes("redux-devtools") ||
      process.env.NODE_ENV === "development"
      ? window?.__REDUX_DEVTOOLS_EXTENSION__?.()
      : undefined,
  );
  let persistor = persistStore(_store);
  return { store: _store, persistor };
};

export default store;
