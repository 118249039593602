import { useEffect } from "react";

let pressedKeys = {};

/**
 * @param {(snapshot: Record<string | number, true>) => void} checker
 * @param {import("react").DependencyList} [deps]
 */
const useMultipleKeydown = (checker, deps = []) => {
  useEffect(() => {
    /**
     * @param {KeyboardEvent} e
     */
    const keydown = (e) => {
      pressedKeys[e.keyCode] = true;
      const snapshot = { ...pressedKeys };
      const pressed = Object.keys(snapshot).filter((k) => snapshot[k] === true);
      const pressedObject = pressed.reduce((a, k) => ({ ...a, [k]: true }), {});
      checker(pressedObject);
    };

    /**
     * @param {KeyboardEvent} e
     */
    const keyup = (e) => {
      pressedKeys[e.keyCode] = undefined;
    };

    const keyupEverything = () => {
      Object.keys(pressedKeys).forEach((key) => {
        pressedKeys[key] = undefined;
      });
    };

    document.addEventListener("keydown", keydown);
    document.addEventListener("keyup", keyup);
    document.addEventListener("blur", keyupEverything);
    window.addEventListener("blur", keyupEverything);

    return () => {
      document.removeEventListener("keydown", keydown);
      document.removeEventListener("keyup", keyup);
      document.removeEventListener("blur", keyupEverything);
      window.removeEventListener("blur", keyupEverything);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};

export default useMultipleKeydown;
