/**
 * Sort sources so that the `people` type will be above any source with
 * `object` type
 * @param {ApiSourceType[]} sources
 * @param {ApiSourceType["type"]} [only] return only certain type
 * @returns {ApiSourceType[]}
 */
const sortSources = (sources, only = null) => {
  if (only) return sources.filter((s) => s.type === only);

  const peopleSources = sources.filter((s) => s.type === "people");
  const documentSources = sources.filter((s) => s.type === "document");
  const objectSources = sources.filter((s) => s.type === "object");
  const sortedSources = [
    ...peopleSources,
    ...documentSources,
    ...objectSources,
  ];

  return sortedSources;
};

export default sortSources;
