/**
 * @description ### Returns Go / Lua like responses(data, err)
 * when used with await
 *
 * - Example response [ data, undefined ]
 * - Example response [ undefined, Error ]
 *
 *
 * When used with Promise.all([req1, req2, req3])
 * - Example response [ [data1, data2, data3], undefined ]
 * - Example response [ undefined, Error ]
 *
 *
 * When used with Promise.race([req1, req2, req3])
 * - Example response [ data, undefined ]
 * - Example response [ undefined, Error ]
 *
 * @param {Promise} promise
 * @returns {[undefined |  any, Error |  undefined]} [ data, err ]
 */
const goAsync = (promise) => {
  return promise
    .then((data) => [data, undefined])
    .catch((err) =>
      Promise.resolve([
        undefined,

        // if error happen but we got falsy value for the `error` variable, then
        // return true, so we can catch the error in the next code
        err || true,
      ]),
    );
};

export default goAsync;
