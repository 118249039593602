import useStore from "./useStore";

/**
 * Get playersFinalAnswer from the gameProgress with additional dummy players
 * if needed
 * @param {Boolean} withDummyPlayers
 */
const usePlayersFinalAnswer = () => {
  const { gameProgress } = useStore();
  const { playersFinalAnswer } = gameProgress.roundProgress;
  return playersFinalAnswer;
};

export default usePlayersFinalAnswer;
