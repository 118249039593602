import axios from "axios";
import useStore from "./useStore";
import useFirebaseActions from "./useFirebaseActions";

const useHelpers = () => {
  const { gameProgress } = useStore();
  const { setNewRound } = useFirebaseActions();

  /**
   * Set current round and do request to firebase & backend server
   * @param {ApiRoundType} round
   */
  const setRound = (round) => {
    setNewRound(round);

    /**
     * @type {UtilAxiosPost<ApiRequestSetCurrentRound, ApiResponseSetCurrentRound>}
     */
    const request = axios.post;
    request("/game/set-current-round", {
      team_id: gameProgress.teamId,
      current_round: round.index,
    })
      .then()
      .catch((err) => console.error("Failed to set current round", { err }));
  };

  const setFinishedGameAt = () => {
    // firebase
  };

  return {
    setRound,
    setFinishedGameAt,
  };
};

export default useHelpers;
