import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import s from "./Landing.module.scss";
import Button from "@bd-components/Button";
import { appRoutes } from "@bd-constants/routes";

const Landing = () => {
  const { t } = useTranslation();

  return (
    <div className={s.Landing}>
      <picture>
        <source srcSet="/images/background-funnel.webp" type="image/webp" />
        <source srcSet="/images/background-funnel.jpg" type="image/jpeg" />
        <img
          className={s.Landing__background}
          src="/images/background-funnel.jpg"
          alt={t("Login image background")}
        />
      </picture>
      <div className={s.Landing__table}>
        <div className={s.Landing__tableCell}>
          <div className={s.Landing__inner}>
            <div className={s.Landing__box}>
              <h3 className={s.Landing__title}>{t("Welcome To Blindspot")}</h3>
              <div className={s.Landing__description}>
                {t("Landing page description")}
              </div>
              <div className={s.Landing__buttonWrapper}>
                <Button
                  className={s.Landing__button}
                  text={t("Join Game")}
                  size="jumbo"
                  as={Link}
                  to={appRoutes.welcome}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Landing;
