import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import s from "./DebriefingQuestion.module.scss";
import Popup from "@bd-components/Popup";
import Button from "@bd-components/Button";
import TeamStatusPlayer from "@bd-components/TeamStatusPlayer";
import useStore from "@bd-hooks/useStore";
import useTimer from "@bd-hooks/useTimer";
import getNowEpoch from "@bd-utils/getNowEpoch";
import millisecondsToTimeObject from "@bd-utils/millisecondsToTimeObject";
// import { ReactComponent as IconStopwatch } from "@bd-icons/icon-stopwatch-regular.svg";

const Timer = ({ shouldFinishQuestionAt, isEnabled, onTimeout }) => {
  const duration = useMemo(
    () => ((shouldFinishQuestionAt ?? 0) - getNowEpoch()) * 1000,
    // eslint-disable-next-line
    [shouldFinishQuestionAt, isEnabled],
  );

  const remainingTime = useTimer({
    duration,
    onTimeout,
    enabled: isEnabled,
  });

  const time = millisecondsToTimeObject(remainingTime);

  return (
    <div className={s.DebriefingQuestion__time}>
      <p className={s.DebriefingQuestion__time__text}>
        {time.m}:{time.s}
      </p>
    </div>
  );
};

/**
 * @type {import("./DebriefingQuestion").DebriefingQuestionType}
 */
const DebriefingQuestion = (props) => {
  const {
    isOpen,
    data,
    setCurrentHistoryIndex,
    isForcingFinish,
    onForceFinish,
  } = props;

  const { t } = useTranslation();

  const store = useStore();
  const categories = store.gameData.config.team_connector.categories;
  const category = categories.find((c) => c.cat_id === data?.categoryId);
  const question = category?.questions.find((q) => q.id === data?.questionId);
  const onTimeout = () => setCurrentHistoryIndex(undefined);

  const player = store.gameProgress.players.find(
    (p) => p.userId === data?.userId,
  );

  const isAssignedToMe = data?.userId === store.currentPlayer.userId;

  return (
    <Popup isOpen={isOpen} className={s.DebriefingQuestion}>
      <div className={s.DebriefingQuestion__box}>
        {question ? (
          <div>
            <picture className={s.DebriefingQuestion__bg}>
              <source
                srcSet={`/images/discussion-paper.webp`}
                type="image/webp"
              />
              <source
                srcSet={`/images/discussion-paper.png`}
                type="image/png"
              />
              <img
                src={`/images/discussion-paper.webp`}
                alt="Folder background"
              />
            </picture>
            <div className={s.DebriefingQuestion__body}>
              <div className={s.DebriefingQuestion__header}>
                <div className={s.DebriefingQuestion__category}>
                  <img
                    className={s.DebriefingQuestion__category_postit}
                    src={`/images/postit.webp`}
                    alt="Post-it"
                  />
                  <img
                    className={s.DebriefingQuestion__category_paperclip}
                    src={`/images/paperclip.webp`}
                    alt="Paperclip"
                  />
                  <h5>{category.cat_name}</h5>
                </div>
                <div className={s.DebriefingQuestion__spacer} />
                <h5 className={s.DebriefingQuestion__preinfo}>
                  {t("Question For")}
                </h5>
                <Timer
                  shouldFinishQuestionAt={data.shouldFinishQuestionAt}
                  isEnabled={isOpen}
                  onTimeout={onTimeout}
                />
              </div>
              <div className={s.DebriefingQuestion__content}>
                {player ? (
                  <div className={s.DebriefingQuestion__player}>
                    <TeamStatusPlayer player={player} withReadyCheck={false} />
                  </div>
                ) : null}
                <h3 className={s.DebriefingQuestion__title}>
                  {question.question}
                </h3>
              </div>
              <div className={s.DebriefingQuestion__footer}>
                {isAssignedToMe && (
                  <Button
                    color="primary"
                    text={t("Continue")}
                    isLoading={isForcingFinish}
                    onClick={onForceFinish}
                  />
                )}
              </div>
            </div>
          </div>
        ) : null}
      </div>
      {/* <div className={s.DebriefingQuestion__box}>
        {question ? (
          <>
            <div className={s.DebriefingQuestion__body}>
              <div className={s.DebriefingQuestion__body__header}>
                <div className={s.DebriefingQuestion__body__header__main}>
                  <h4 className={s.DebriefingQuestion__category}>
                    {category.cat_name}
                  </h4>
                  <h3 className={s.DebriefingQuestion__title}>
                    {question.question}
                  </h3>
                </div>
                <Timer
                  shouldFinishQuestionAt={data.shouldFinishQuestionAt}
                  isEnabled={isOpen}
                  onTimeout={onTimeout}
                />
              </div>
              <div
                className={s.DebriefingQuestion__description}
                dangerouslySetInnerHTML={{ __html: question.content }}
              ></div>
            </div>
            <div className={s.DebriefingQuestion__footer}>
              <h5 className={s.DebriefingQuestion__preinfo}>
                {t("Question For")}
              </h5>
              <div className={s.DebriefingQuestion__info}>
                {player ? (
                  <div className={s.DebriefingQuestion__player}>
                    <TeamStatusPlayer player={player} withReadyCheck={false} />
                  </div>
                ) : null}

                {isAssignedToMe ? (
                  <Button
                    color="primary"
                    text={t("Continue")}
                    isLoading={isForcingFinish}
                    onClick={onForceFinish}
                  />
                ) : null}
              </div>
            </div>
          </>
        ) : null}
      </div> */}
    </Popup>
  );
};

export default DebriefingQuestion;
