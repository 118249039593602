/**
 * @param {StoreGameDataType['rawRoles']} rawRoles
 * @param {StoreLanguageType} language
 */
const getTranslatedRoles = (rawRoles, language) => {
  const langCode = language.current.value;
  const fallbackLang = language.defaultLanguage;
  const fallbackRoles = rawRoles[fallbackLang] ?? [];
  const translatedRoles = fallbackRoles.map((r) => {
    const translated = rawRoles[langCode]?.find(
      (t) => t.parent_id === r.parent_id,
    );
    if (!translated) return r;
    return translated;
  });
  return translatedRoles;
};

export default getTranslatedRoles;
