import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import clsx from "clsx";
import s from "./QuestionaireOverlay.module.scss";
import useStore from "@bd-hooks/useStore";
import useMultipleKeydown from "@bd-hooks/useMultipleKeydown";
import AppLoader from "@bd-components/AppLoader";
import Button from "@bd-components/Button";
import Popup from "@bd-components/Popup";
import {
  setHasCompletedMantapPreGame,
  setHasCompletedMantapPostGame,
} from "@bd-redux/user";

import useFirebaseActions from "@bd-hooks/useFirebaseActions";

const QuestionaireOverlay = () => {
  const { t } = useTranslation();
  const { userProgress, language, gameProgress } = useStore();
  const [isLoaded, setIsLoaded] = useState(false);
  const [isError, setIsError] = useState(false);
  const [timestamp, setTimestamp] = useState(new Date(Date.now()).getTime());
  const dispatch = useDispatch();
  const onIFrameLoaded = () => setIsLoaded(true);
  const onIFrameError = () => setIsError(true);
  const onIFrameReload = () => setTimestamp(new Date(Date.now()).getTime());

  const isPreGame = !gameProgress.finishedGameAt;
  const isCompleted = isPreGame
    ? userProgress.hasCompletedMantapPreGame
    : userProgress.hasCompletedMantapPostGame;

  const { setHasCompletedMantapPreGame: setCompletedMantapPreGame } =
    useFirebaseActions();

  const completedQuestionare = () => {
    dispatch(
      isPreGame
        ? setHasCompletedMantapPreGame(true)
        : setHasCompletedMantapPostGame(true),
    );

    if (isPreGame) setCompletedMantapPreGame(true);
  };

  const isLoggedIn = userProgress.firebaseToken;

  /**
   * Hit ALT + SHIFT + P + M to proceed without filling the questionare
   */
  useMultipleKeydown((snapshot) => {
    const alt = snapshot[18];
    const shift = snapshot[16];
    const p = snapshot[80];
    const m = snapshot[77];
    const isPressedAll = alt && shift && p && m;
    if (isPressedAll) completedQuestionare();
  });

  useEffect(() => {
    window.addEventListener("message", (e) => {
      if (e.data !== "bd-close") return;
      completedQuestionare();
    });
    // eslint-disable-next-line
  }, []);

  return isLoggedIn ? (
    <Popup isOpen={!isCompleted} unmountOnExit>
      <div className={clsx(s.QuestionaireOverlay, isCompleted && s.hide)}>
        <div className={s.QuestionaireOverlay__inner}>
          <div className={s.QuestionaireOverlay__content}>
            {isError ? (
              <div className={s.QuestionaireOverlay__error}>
                <div>
                  <div className={s.QuestionaireOverlay__errorText}>
                    {t("Something went wrong, please try again")}
                  </div>
                  <Button
                    className={s.QuestionaireOverlay__errorButton}
                    text={t("Reload iFrame")}
                    onClick={onIFrameReload}
                  />
                </div>
              </div>
            ) : null}
            {!isLoaded ? <AppLoader classNames={s} isLoading /> : null}
            <iframe
              key={timestamp}
              className={clsx(
                s.QuestionaireOverlay__iframe,
                isLoaded && s.isLoaded,
              )}
              width="1240"
              height="800"
              src={`${userProgress.mantapUrl}&lang=${language.current.value}`}
              title="Blindpot narrative game"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              onLoad={onIFrameLoaded}
              onError={onIFrameError}
            />
          </div>
        </div>
      </div>
    </Popup>
  ) : null;
};

export default QuestionaireOverlay;
