/**
 * Get value of percentage number
 * @param {Number} percent
 * @param {Number} total
 * @returns {Number}
 */
export const percentToValue = (percent, total) => (percent * total) / 100;

/**
 * Get percentage number of value
 * @param {Number} value
 * @param {Number} total
 * @returns {Number}
 */
export const valueToPercent = (value, total) => (value / total) * 100;
