import { useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";

import validateEmail from "@bd-utils/validateEmail";
import useSnackbarState from "@bd-hooks/useSnackbarState";

const useStateCreateTeam = () => {
  const { t } = useTranslation();
  const snackbar = useSnackbarState();
  const [email, setEmail] = useState("");
  const [isSubmiting, setIsSubmiting] = useState(false);

  /**
   * @type {UtilState<string | undefined>}
   */
  const [err, setErr] = useState(undefined);

  const onChangeEmail = (e) => {
    if (isSubmiting) return;
    setErr(undefined);
    setEmail(e.target.value);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (!email.length) return setErr(t("Email is required"));
    if (!validateEmail(email)) return setErr(t("Invalid email"));
    setIsSubmiting(true);
    snackbar.close();

    /**
     * @type {ApiRequestEmailLink}
     */
    const data = {
      user_email: email,
      site_url: `${window.location.protocol}//${window.location.host}`,
    };

    /** @type {UtilAxiosPost<ApiRequestEmailLink, ApiResponseEmailLink>} */
    const request = axios.post;
    request("/public/email-link", data).then((res) => {
      setIsSubmiting(false);

      if (!res.data.success) {
        snackbar.error({ message: res.data.error });
        return false;
      }

      snackbar.open({
        message: t(
          "Token has been sent to your email. Please check your inbox.",
        ),
      });
    });
  };

  return {
    email,
    err,
    onChangeEmail,
    onSubmit,
    isSubmiting,
    snackbar,
  };
};

export default useStateCreateTeam;
