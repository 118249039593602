import useStore from "@bd-hooks/useStore";
import Tooltip from "@bd-components/Tooltip";
import convertObjToArr from "@bd-utils/convertObjToArr";
import useDerivedTutorial from "@bd-hooks/useDerivedTutorial";

import { useState } from "react";
import { useTranslation } from "react-i18next";

import clsx from "clsx";
import s from "./IntelButton.module.scss";
import useIntuitions from "@bd-hooks/useIntuitions";
import Intuitions from "src/pages/Gameplay/Gameplay_Intuitions";

/**
 * @type {import("./IntelButton").IntelButtonType}
 */
const IntelButton = (props) => {
  const { t } = useTranslation();
  const state = useIntuitions();
  const { userProgress } = useStore();
  const { tutorialData } = useDerivedTutorial();
  const { isGlowing } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [isSeen, setIsSeen] = useState(false);
  const [intuitionIndex, setIntuitionIndex] = useState(0);

  const tutorialDataArr = convertObjToArr(tutorialData);

  const handleIntuitions = (index) => {
    setIntuitionIndex(index);
    setIsOpen(true);
  };

  const handleIntuitionClose = () => {
    setIsOpen(false);
    setIsSeen(true);
  };

  return (
    <>
      <Intuitions
        isOpen={isOpen}
        onClose={handleIntuitionClose}
        isFirstTime={!isSeen}
        index={intuitionIndex}
      />
      <div
        className={clsx(
          s.wrapper,
          userProgress.currentTutorialIndex < tutorialDataArr.length &&
            "display-none",
        )}
      >
        <Tooltip
          text={t("Research into biases")}
          top="48px"
          left="0%"
          hoverDirection="left"
        />
        <span className="Gameplay_BoardPlay-module__boardPlay__label">
          {t("Intel")}
        </span>

        <div className={s.bias}>
          {state.intuitions.map((intuition, index) => {
            return (
              <button
                className={clsx(
                  s.intelButton,
                  isGlowing && index === 0 && !isSeen && s["is-glowing"],
                )}
                key={intuition.id}
                onClick={() => handleIntuitions(index)}
              >
                <span className={s.intelButton__text}>{t("Bias")}</span>
                <picture>
                  <source srcSet="/images/bias.webp" type="image/webp" />
                  <source srcSet="/images/bias.png" type="image/png" />
                  <img
                    className={s.intelButton__icon}
                    src="/images/bias.png"
                    alt="Folder"
                  />
                </picture>
              </button>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default IntelButton;
