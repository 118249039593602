import { useState } from "react";
import { useTranslation } from "react-i18next";

import useStore from "@bd-hooks/useStore";

import { ReactComponent as IconCopy } from "@bd-icons/icon-copy.svg";
import { ReactComponent as IconLogo } from "@bd-icons/icon-logo.svg";

import copyToClipboard from "@bd-utils/copyToClipboard";

import clsx from "clsx";
import s from "./BoardHeading.module.scss";

const BoardHeading = () => {
  const { t } = useTranslation();
  const store = useStore();
  const [isCopied, setIsCopied] = useState(false);

  const onCopyCode = () => {
    copyToClipboard(store.gameProgress.teamCode);

    if (copyToClipboard(store.gameProgress.teamCode)) {
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 1500);
    }
  };

  return (
    <div className={s.BoardHeading}>
      <IconLogo />
      <div className={s.BoardHeading__teamCode}>
        <span className={s["title"]}>{t("Team-Code")}</span>
        <div className={s.BoardHeading__teamCode__code}>
          <span className={s["code"]}>{store.gameProgress.teamCode}</span>
          <button
            className={clsx(s["copy-button"], isCopied && s["is-copied"])}
            onClick={onCopyCode}
          >
            <IconCopy />
            <span>{t(isCopied ? "Copied" : "Copy")}</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default BoardHeading;
