import clsx from "clsx";

/**
 * Combiner function generator
 * @param {object} baseStyles
 * @param {object} extendStyles
 * @returns {((...classNames: Array<import("clsx").ClassValue>) => string) & { getAll: () => {} }}
 */
const stylesCombinerFn = (baseStyles, extendStyles) => {
  const runner = (...classNames) => {
    // This class also contain non-string format `undefined | false | true`
    // therefore we need clsx to cleanup those value
    const combinedClassNames = classNames.reduce((result, className) => {
      if (typeof className === "undefined") return result;
      if (className === null) return result;
      if (className === false) return result;
      if (className === true) return result;

      const isKeyExist = baseStyles?.[className] || extendStyles?.[className];

      // If key didn't exist, just combine with the raw string, because it's
      // likely that the author want to add compiled string from CSS modules
      const c = isKeyExist
        ? [baseStyles?.[className], extendStyles?.[className]]
        : [className];

      return [...result, ...c];
    }, []);
    return clsx(...combinedClassNames);
  };

  runner.getAll = () => {
    let result = {};
    Object.keys(baseStyles).forEach((key) => (result[key] = runner(key)));
    return result;
  };

  return runner;
};

const stylesCombiner = (styles1, styles2, className, ...classNames) => {
  return stylesCombinerFn(styles1, styles2)(className, ...classNames);
};

export { stylesCombinerFn };
export default stylesCombiner;
