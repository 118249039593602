import axios from "axios";

/**
 * Post API to combine 2 source
 *
 * @param {ApiRequestCombineSource} data
 */
const combineSource = (data) =>
  axios.post("/game/combine-source", data).then((res) => {
    /**
     * @type {ApiResponseCombineSource}
     */
    const data = res.data;
    return data.data;
  });

export default combineSource;
