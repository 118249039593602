import React from "react";
import clsx from "clsx";
import useStore from "@bd-hooks/useStore";
import useDerivedTutorial from "@bd-hooks/useDerivedTutorial";

import BoardHeading from "@bd-components/BoardHeading";

import s from "./Board.module.scss";
import convertObjToArr from "@bd-utils/convertObjToArr";

const Board = ({ children, isOtherDragging }) => {
  const { userProgress } = useStore();
  const { tutorialData } = useDerivedTutorial();

  const tutorialDataArr = convertObjToArr(tutorialData);

  return (
    <div className={clsx(s.board, isOtherDragging && s["is-other-dragging"])}>
      <div
        className={s.board__inner}
        style={{ backgroundImage: "url('/images/playingfield.webp')" }}
        k
      >
        <div
          className={clsx(s.board__frame, s["frameH"], s["frameH--top"])}
          style={{ backgroundImage: "url('/images/boardframeH.webp')" }}
        ></div>
        <div
          className={clsx(s.board__frame, s["frameH"], s["frameH--bottom"])}
          style={{ backgroundImage: "url('/images/boardframeH.webp')" }}
        ></div>
        <div
          className={clsx(s.board__frame, s["frameV"], s["frameV--left"])}
          style={{ backgroundImage: "url('/images/boardframeV.webp')" }}
        ></div>
        <div
          className={clsx(s.board__frame, s["frameV"], s["frameV--right"])}
          style={{ backgroundImage: "url('/images/boardframeV.webp')" }}
        ></div>
        <picture>
          <source srcSet="/images/board-clip.webp" type="image/webp" />
          <source srcSet="/images/board-clip.png" type="image/png" />
          <img
            className={s.board__clips}
            src="/images/board-clip.png"
            alt="Board Clip"
          />
        </picture>
        <picture>
          <source srcSet="/images/board-clip.webp" type="image/webp" />
          <source srcSet="/images/board-clip.png" type="image/png" />
          <img
            className={s.board__clips}
            src="/images/board-clip.png"
            alt="Board Clip"
          />
        </picture>
        <BoardHeading />
        <div
          className={clsx(
            s.board__ruler,
            "board",
            userProgress.currentTutorialIndex < tutorialDataArr.length &&
              "display-none",
          )}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default Board;
