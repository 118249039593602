const getAllQueryString = (location) => {
  const query = new URLSearchParams(location);
  const result = {};
  query.forEach((value, key) => {
    result[key] = value;
  });
  return result;
};

export default getAllQueryString;
