import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import s from "./Intuition.module.scss";
import { ReactComponent as Shape } from "./post-it.svg";

const Intuition = ({ className, label, content }) => {
  return (
    <div
      className={clsx(s.intuition, className)}
      style={{ backgroundImage: 'url("/images/intuition-paper.png")' }}
    >
      <div className={s.intuition__title}>
        <Shape className={s["intuition__note--shadow"]} />
        <Shape className={s.intuition__note} />
        <span className={s.intuition__label}>{label}</span>
      </div>
      <div className={s.intuition__spacer} />
      <span className={s.intuition__text}>{content}</span>
    </div>
  );
};

Intuition.propTypes = {
  label: PropTypes.string,
  content: PropTypes.string,
  value: PropTypes.bool,
  onChange: PropTypes.func,
};

export default Intuition;
