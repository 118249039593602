/**
 * Get random integet (not float) within a range of min and max
 * @param {number} min
 * @param {number} max
 * @returns {number}
 */
const randomMinMax = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export default randomMinMax;
