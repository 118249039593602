import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { useDroppable } from "@dnd-kit/core";

import s from "./Droppable.module.scss";

/**
 * @type {React.FC<{
 *  className: String,
 *  classNames: Object,
 *  source: ApiSourceType
 * }>}
 */
const Droppable = ({ className, classNames = {}, source, children }) => {
  const { setNodeRef, active } = useDroppable({ id: source.id });
  const isDraggingMyChild = active?.id === source.id;

  const cn = clsx(
    {
      [s.droppable]: true,
      [s["is-dragging-child"]]: isDraggingMyChild,
      [className]: className,
    },
    s[`droppable--t-${source.type}`],
    classNames[`droppable--t-${source.type}`],
  );

  return (
    <div ref={setNodeRef} className={cn} dataid={source.id}>
      {children}
    </div>
  );
};

Droppable.propTypes = {
  className: PropTypes.string,
  classNames: PropTypes.objectOf(PropTypes.string),
  source: PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.oneOf(["people", "object", "document"]),
  }),
};

export default Droppable;
