import { useState, useCallback, useMemo } from "react";

import clsx from "clsx";
import s from "./StringClue.module.scss";
import useClues from "@bd-hooks/useClues";
import useViewportWidth from "@bd-hooks/useViewportWidth";
import useViewportHeight from "@bd-hooks/useViewportHeight";
import { useTranslation } from "react-i18next";

/**
 * @type {import("./StringClue").StringClueType}
 */
const StringClue = (props) => {
  const {
    onClick,
    angle,
    distance,
    topPos,
    leftPos,
    activeSource,
    overSource,
    activeName,
    overName,
    className,
  } = props;

  const { t } = useTranslation();

  const cluesState = useClues();

  const [hovered, setHovered] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [isActiveImageLoaded, setIsActiveImageLoaded] = useState(false);
  const [isOverImageLoaded, setIsOverImageLoaded] = useState(false);

  const isStringLong = useViewportWidth(1400) | useViewportHeight(800);

  const handleClue = useCallback(() => {
    setIsClicked(true);
    onClick();
  }, [onClick]);

  const handleMouseEnter = useCallback(() => {
    setHovered(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setHovered(false);
  }, []);

  const baseStringStyles = useMemo(() => {
    return {
      width: `${
        isStringLong
          ? distance
          : angle < 0 && Math.abs(angle) < 100
          ? distance - 10
          : distance + 10
      }px`,
      transform: "rotate(" + angle + "deg)",
      top: `${topPos}px`,
      left: `${
        isStringLong
          ? leftPos
          : angle > 0
          ? leftPos
          : Math.abs(angle) > 100
          ? leftPos
          : leftPos + 10
      }px`,
    };
  }, [isStringLong, distance, angle, topPos, leftPos]);

  return (
    <>
      <aside
        className={clsx(
          { [className]: className },
          s.stringClue,
          s["stringClue--base"],
        )}
        style={baseStringStyles}
      />
      <aside
        className={clsx(
          { [className]: className },
          s.stringClue,
          s["stringClue--transparent"],
        )}
        style={baseStringStyles}
      >
        <button
          onClick={handleClue}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          className={clsx(
            s.stringClue__button,
            hovered && s["hovered"],
            cluesState.totalClue === 1 && !isClicked && s["first-clue"],
          )}
          style={{
            transform: hovered
              ? `translate(-50%, -50%) scale(0.95) rotate(${
                  angle < 0 ? Math.abs(angle) : -angle
                }deg)`
              : `translate(-50%, -50%) rotate(${
                  angle < 0 ? Math.abs(angle) : -angle
                }deg)`,
          }}
        >
          <div className={s["stringClue__button--popup"]}>
            <p>{t("See Clue")}</p>
            <p className={s["names"]}>
              {activeName} -&gt; {overName}
            </p>
          </div>
          <picture className={s["stringClue__button--active"]}>
            {!isActiveImageLoaded && (
              <img
                src="/images/placeholder.webp"
                alt="placeholder"
                style={{
                  width: "100%",
                  height: "100%",
                  opacity: "0.5",
                  filter: "blur(10px)",
                }}
              />
            )}
            <img
              src={activeSource}
              alt="active"
              style={{ display: isActiveImageLoaded ? "block" : "none" }}
              onLoad={() => setIsActiveImageLoaded(true)}
            />
          </picture>
          <picture className={s["stringClue__button--over"]}>
            {!isOverImageLoaded && (
              <img
                src="/images/placeholder.webp"
                alt="placeholder"
                style={{
                  width: "100%",
                  height: "100%",
                  opacity: "0.5",
                  filter: "blur(10px)",
                }}
              />
            )}
            <img
              src={overSource}
              alt="over"
              style={{ display: isOverImageLoaded ? "block" : "none" }}
              onLoad={() => setIsOverImageLoaded(true)}
            />
          </picture>
        </button>
      </aside>
    </>
  );
};

export default StringClue;
