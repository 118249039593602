import React from "react";
import s from "./TextField.module.scss";
import { stylesCombinerFn } from "@bd-utils/stylesCombiner";

/**
 * @type {import('./TextField').TextFieldType}
 */
const TextField = (props) => {
  const { className, classNames, error, testId, color, ...propsForInput } =
    props;
  const c = stylesCombinerFn(s, classNames);
  return (
    <div
      className={c(
        "TextField",
        color ? `color-${color}` : undefined,
        className,
      )}
    >
      <input
        {...propsForInput}
        className={c("TextField__input", Boolean(error) && s.isError)}
        data-testid={testId}
      />
      {Boolean(error) ? (
        <div className={c("TextField__error")}>{error}</div>
      ) : null}
    </div>
  );
};

export default TextField;
