import React from "react";
import s from "./AppLoader.module.scss";
import { ReactComponent as IconLoading } from "@bd-icons/icon-loading.svg";
import { stylesCombinerFn } from "@bd-utils/stylesCombiner";

const AppLoader = ({ classNames, isLoading }) => {
  const c = stylesCombinerFn(s, classNames);
  return (
    <div className={c("AppLoader", isLoading && "is-loading")}>
      <IconLoading className={c("AppLoader__loader", "g-animation-spin")} />
    </div>
  );
};

export default AppLoader;
