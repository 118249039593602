import { useEffect, useState } from "react";
import { Group, Image, Text, Wedge } from "react-konva";

/**
 * @type {import("./WheelItem").WheelItemType}
 */
const WheelItem = ({
  radius,
  diameter,
  totalItems,
  index,
  data,
  textStyle,
}) => {
  const [state, setState] = useState({
    image: null,
    isLoaded: false,
    imageRatio: 1,
  });

  const rotation = (360 / totalItems) * (index - 1);
  const halfAngleRadius = Math.PI / totalItems;
  const halfAngleDeg = 360 / (totalItems * 2);

  // formula: 2πr
  const circumference = 2 * Math.PI * radius;

  const text_color = data.text_color;
  const text_size = radius / 16;

  /**
   * Set width for the text, to prevent text overlap, 50% total width of
   * this circumference slice,
   * formula: (circumference / totalSlice) * 60%
   */
  const text_width = ((circumference / totalItems) * 60) / 100;
  const text_X = 0.55 * radius * Math.cos(halfAngleRadius);
  const text_Y_withIcon = 0.5 * radius * Math.sin(halfAngleRadius);

  // prettier-ignore
  const text_Y_withoutIcon = 0.62 * radius * Math.sin(halfAngleRadius)
  const text_Y = state.image ? text_Y_withIcon : text_Y_withoutIcon;

  const text_offsetX = text_width / 2;
  const text_offsetY = radius / 7;
  const text_rotation = 90 + halfAngleDeg;
  const icon_size = radius / 6;
  const icon_width = icon_size * state.imageRatio;

  // Why X & Y equal to text calculation? somehow it's fit :)
  const icon_X = text_X;
  const icon_Y = text_Y;
  const icon_offsetX = icon_width / 2;
  const icon_offsetY = radius / 2.8;
  const icon_rotation = 90 + halfAngleDeg;

  const onMount = () => {
    if (!data.icon) return false;

    /**
     * Why set image here instead of inside the render function? to make sure
     * it only load once, and not making any request anymore
     */
    let image = new window.Image();
    image.src = data.icon;

    // Set image while it's loaded, and it'll re-Draw canvas when image loaded
    image.onload = function (e) {
      setState({
        image: image,
        isLoaded: true,
        imageRatio: this.width / this.height,
      });
    };
  };

  useEffect(() => {
    onMount();
    // eslint-disable-next-line
  }, []);

  return (
    <Group
      x={radius}
      y={radius}
      rotation={rotation}
      name={`${data.category_id}`}
      preventDefault={false}
      // rotation={360 / index}
      // rotation={-150}
    >
      {/* <Arc
        x={0}
        y={0}
        innerRadius={radius / 2}
        outerRadius={radius}
        angle={360 / totalItems}
        // angle={360}
        // rotation={360 - 360 / totalItems}
        fill="#ffffff"
        name={data.category_id.toString()}
        preventDefault={false}
      /> */}
      <Wedge
        x={0}
        y={0}
        radius={radius}
        angle={360 / totalItems}
        fill={data.color !== "" ? data.color : "rgba(8,110,92,0.8)"} // for some reason an empty string doesn't count as falsy, so we hardcode the boolean instead
        name={data.category_id.toString()}
        preventDefault={false}
      />

      {state.image ? (
        <Image
          image={state.image}
          rotation={icon_rotation}
          x={icon_X}
          y={icon_Y}
          offset={{ x: icon_offsetX, y: icon_offsetY }}
          width={icon_width}
          height={icon_size}
          name={data.category_id.toString()}
          preventDefault={false}
        />
      ) : null}

      <Text
        rotation={text_rotation}
        width={text_width}
        x={text_X}
        y={text_Y}
        fill={text_color !== "" ? text_color : "white"} // same as above
        fontSize={textStyle?.fontSize || text_size}
        text={
          textStyle?.textTransform === "uppercase"
            ? data.category_name.toUpperCase()
            : data.category_name
        }
        align="center"
        fontFamily={textStyle?.fontFamily ?? "Scripto, sans-serif"}
        offset={{ x: text_offsetX, y: text_offsetY }}
        name={data.category_id.toString()}
        preventDefault={false}
      />
    </Group>
  );
};

export default WheelItem;
