import React from "react";
import clsx from "clsx";

import s from "./Gameplay_BoardRight.module.scss";
import Timer from "@bd-components/Timer";
import useStore from "@bd-hooks/useStore";
import getNowEpoch from "@bd-utils/getNowEpoch";
import TimerStatic from "@bd-components/TimerStatic";

/**
 * @type {React.FC<{
 *  clues: ReturnType<import("../../hooks/useClues.js").default>,
 *  hideOnQuestion: boolean,
 *  isFinish: boolean,
 *  isIntuitionQuestion: boolean,
 *  onForceFinishRound: () => void,
 *  onTimeout: () => void,
 * }>}
 */
const BoardRight = ({ isFinish, onTimeout }) => {
  const { gameProgress } = useStore();
  const hasStartedTimer = Boolean(gameProgress.roundProgress.shouldFinishAt);
  // const isWarning = gameProgress.roundProgress.combinationCounter <= 3;

  // temporary code
  const tempStyle = {
    position: "absolute",
    bottom: "0",
    left: "0",
    opacity: "0.2",
  };

  return (
    <div className={s.boardRight}>
      {hasStartedTimer ? (
        <div className={clsx(s.boardRight__fade, isFinish && s["is-hidden"])}>
          <div
            /* remove this div once moved */ style={
              tempStyle
            } /* remove this div once moved */
          >
            <Timer
              duration={
                (gameProgress.roundProgress.shouldFinishAt - getNowEpoch()) *
                1000
              }
              onTimeout={onTimeout}
            />
          </div>
        </div>
      ) : (
        <div className={clsx(s.boardRight__fade, isFinish && s["is-hidden"])}>
          <TimerStatic />
        </div>
      )}

      {/* <div
        className={clsx(
          s.boardRight__fade,
          isIntuitionQuestion && hideOnQuestion && s["is-hidden"],
        )}
      >
        <Clues
          className={s.boardRight__clues}
          activeClueIndex={clues.activeClueIndex}
          totalClue={clues.totalClue}
          nextClue={clues.nextClue}
          prevClue={clues.prevClue}
          allData={clues.allData}
        />
      </div>
      <div
        className={clsx(
          s.boardRight__fade,
          isIntuitionQuestion && hideOnQuestion && s["is-hidden"],
        )}
      >
        {isIntuitionQuestion ? (
          <div className={styles.gameplay__remaining}>
            {t("Use the hints in your clues to answer the questions")}
          </div>
        ) : (
          <div
            className={clsx(
              styles.gameplay__remaining,
              isWarning && styles["is-warning"],
            )}
          >
            <div className={styles.gameplay__warningBar}></div>
            <div
              className={styles.gameplay__remainingText}
              data-testid="game_remainingCombinationCounter"
            >
              {t("You have")}{" "}
              <b>{gameProgress.roundProgress.combinationCounter}</b>{" "}
              {t("combinations left")}.
            </div>
          </div>
        )}
      </div> */}
    </div>
  );
};

export default BoardRight;
