export const appRoutes = {
  home: "/",
  login: "/login",
  welcome: "/welcome",
  // lobby: "/lobby",
  waitingRoom: "/waiting-room",
  gameIntro: "/game-intro",
  finalQuestion: "/final-question",
  levelIntro: "/intro",
  reflection: "/reflection",
  debriefing: "/debriefing",
  reset: "/reset",
  landing: "/landing",
  resetGame: "/reset-game",
  resetLevel: "/reset-level",
  howToPlay: "/how-to-play",
};
