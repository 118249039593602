import { useState } from "react";
import { useTranslation } from "react-i18next";

const useSnackbarState = () => {
  const { t } = useTranslation();
  const [state, setState] = useState({
    isOpen: false,
    title: null,
    message: null,
    status: null,
  });

  const open = ({ title, message, status }) => {
    setState({
      isOpen: true,
      message: message,
      title: title ? title : null,
      status: status ? status : null,
    });
  };

  const error = ({
    title,
    message = t("Something went wrong. Please try again!"),
    timeout = null,
  } = {}) => {
    open({ title, message, status: "error" });

    if (timeout) {
      let t;
      if (t) {
        clearTimeout(t);
      }

      t = setTimeout(() => {
        close();
      }, timeout);
    }
  };

  const close = () => {
    setState((prev) => ({ ...prev, isOpen: false }));
  };

  return {
    ...state,
    error,
    open,
    close,
  };
};

export default useSnackbarState;
