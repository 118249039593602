import { useState, useLayoutEffect } from "react";

const useViewportHeight = (size) => {
  const [height, setHeight] = useState(0);

  useLayoutEffect(() => {
    const handleResize = () => {
      setHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [setHeight]);

  return height > size;
};

export default useViewportHeight;
