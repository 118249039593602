import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
// import clsx from "clsx";

import s from "./Snackbar.module.scss";
import Button from "@bd-components/Button";
import { stylesCombinerFn } from "@bd-utils/stylesCombiner";
import { ReactComponent as IconTimesLight } from "@bd-icons/icon-times-light.svg";
import { ReactComponent as IconCheckRegular } from "@bd-icons/icon-check-regular.svg";

const SnackbarMarkup = ({
  className,
  classNames,
  title,
  message,
  status,
  onClose,
  isOpen = false,
  isUsingPortal = false,
  withOverlay = false,
}) => {
  const c = stylesCombinerFn(s, classNames);
  return (
    <div
      className={c(
        "snackbar",
        { [s["is-open"]]: isOpen },
        { [s["is-portal"]]: isUsingPortal },
        { [s[`is-status-${status}`]]: status },
        { [s["with-overlay"]]: withOverlay },
        className,
      )}
    >
      <div className={c("snackbar__container")}>
        <div className={c("snackbar__inner")}>
          <div className={c("snackbar__body")}>
            {title ? <div className={c("snackbar__title")}>{title}</div> : null}
            <div className={c("snackbar__content")}>{message}</div>
          </div>
          <div className={c("snackbar__action")}>
            <Button
              className={c("snackbar__close")}
              variant="fab-circle"
              color="primary"
              iconStart={
                status === "success" ? IconCheckRegular : IconTimesLight
              }
              onClick={onClose}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const Snackbar = ({ isUsingPortal = false, ...props }) => {
  return isUsingPortal ? (
    ReactDOM.createPortal(
      <SnackbarMarkup isUsingPortal={isUsingPortal} {...props} />,
      document.getElementById("snackbarRoot"),
    )
  ) : (
    <SnackbarMarkup isUsingPortal={isUsingPortal} {...props} />
  );
};

SnackbarMarkup.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  message: PropTypes.string,
  status: PropTypes.string,
  onClose: PropTypes.func,
  isOpen: PropTypes.bool,
  isUsingPortal: PropTypes.bool,
};

Snackbar.propTypes = SnackbarMarkup.propTypes;

export default Snackbar;
