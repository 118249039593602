import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import s from "../ForceReset/ForceReset.module.scss";
import useStore from "@bd-hooks/useStore";
import Button from "@bd-components/Button";
import useAuthProcess from "@bd-hooks/useAuthProcess";
import axios from "axios";

const ResetGame = () => {
  const authProcess = useAuthProcess();
  const { gameProgress } = useStore();

  useEffect(
    () => {
      const resetGameProgress = async () => {
        const request = axios.post;
        await request("/game/reset-game", {
          team_id: gameProgress.teamId,
        }).then((res) => {
          if (!res.data.success) {
            console.error("Failed to reset game");
          }
        });

        authProcess.logout();
      };

      resetGameProgress();
    },
    // eslint-disable-next-line
    [],
  );

  return gameProgress.teamId ? (
    <div className={s.forceReset}>
      <div className={s.forceReset__content}>Reseting local data ...</div>
    </div>
  ) : (
    <div className={s.forceReset}>
      <div className={s.forceReset__content}>
        Reset has been completed successfully
      </div>
      <Button
        className={s.forceReset__btn}
        text="Start Game"
        as={Link}
        to="/"
      />
    </div>
  );
};

export default ResetGame;
