/**
 * @param {StoreGameDataType} gameData
 * @param {StoreGameProgressType} gameProgress
 */
const getNextQuestions = (gameData, gameProgress) => {
  const categories = gameData.config.team_connector.categories;
  const histories = gameProgress.teamConnectorHistory;
  const questions = categories
    .map((c) => c.questions.map((q) => ({ ...q, categoryId: c.cat_id })))
    .flat();

  const questionsIds = questions.map((q) => q.id);
  const lastGroupTotal = histories.length % questions.length;
  const lastGroupStartIndex = 0 - lastGroupTotal;
  const questionsLastGroup = histories.slice(lastGroupStartIndex);

  const questionIdsLastGroup = questionsLastGroup.map((q) => q.questionId);
  const nextQuestions = questions.filter(
    (q) => !questionIdsLastGroup.includes(q.id),
  );
  const nextQuestionIds = nextQuestions.map((q) => q.id);
  if (nextQuestionIds.length < 1) return questionsIds;

  return nextQuestionIds;
};

export default getNextQuestions;
