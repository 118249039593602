import React, { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";
import { generateDimmedClasses } from "./generateDimmedClasses";
import Watson from "@bd-components/Watson";
import useSound from "use-sound";
import useStore from "@bd-hooks/useStore";

import s from "./Warning.module.scss";
import useDerivedWarning from "@bd-hooks/useDerivedWarning";

/**
 * @type {import("./Warning").WarningType}
 */
const Warning = ({
  warningClass,
  warningKey,
  warningStandardText,
  placement,
  topPos,
  leftPos,
  isDynamic,
  withTooltip,
  onClick,
  closeOnClick = true,
}) => {
  const { t } = useTranslation();
  const { warningData } = useDerivedWarning();
  const { gameData } = useStore();
  const { game_audio } = gameData.config;
  const [hasPlayedAudio, setHasPlayedAudio] = useState(false);

  const [play] = useSound(game_audio?.notification_watson, {
    volume: 0.5,
  });

  // eslint-disable-next-line no-unused-vars
  const [selectors, setSelectors] = useState(undefined);
  const [enabled, setEnabled] = useState(true);
  const [isWarningEnd, setIsWarningEnd] = useState(false);

  const [warningTitle, setWarningTitle] = useState("");
  const [warningText, setWarningText] = useState("");

  useEffect(() => {
    if (warningData.some((obj) => Object.values(obj).includes(warningKey))) {
      setWarningTitle(warningData.find((obj) => obj.id === warningKey).title);
      setWarningText(warningData.find((obj) => obj.id === warningKey).text);
    }

    const currentDimmed =
      warningClass === "root" ? "#root" : generateDimmedClasses(warningClass);
    setSelectors(currentDimmed);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [warningKey]);

  useEffect(() => {
    if (warningStandardText) {
      setWarningText(warningStandardText);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [warningStandardText]);

  useEffect(() => {
    if (!hasPlayedAudio) {
      play();
      setTimeout(() => {
        setHasPlayedAudio(true);
      }, 100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameData]);

  const closeWarning = () => {
    if (closeOnClick) {
      setIsWarningEnd(true);
      setTimeout(() => {
        setEnabled(false);
      }, 425);
    }

    if (onClick) {
      onClick();
    }
  };

  return enabled && warningText
    ? createPortal(
        <>
          <style>
            {/*
                If current step doesn't have any target, then we will dim the
                #root only, why exclude anything else? to make sure the 
                brightness effect is not overlapped from one element to the other
                otherwise, it will result in unbalance dim effect where some spot
                is darker than the other element
              */}

            {`${selectors} {
                filter: brightness(0.2);
                pointer-events: none;
              }`}
          </style>

          <p className={s.Warning__text}>{t("Click anywhere to continue")}</p>

          <button
            className={s.Warning__next}
            id="warning"
            type="button"
            onClick={closeWarning}
          />

          <Watson
            title={warningTitle}
            text={warningText}
            placement={placement}
            top={topPos}
            left={leftPos}
            isDynamic={isDynamic}
            isEnd={isWarningEnd}
            withTooltip={withTooltip}
          />
        </>,
        document.getElementById("tutorialRoot"),
      )
    : null;
};

export default Warning;
