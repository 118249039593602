import { createSlice } from "@reduxjs/toolkit";

/**
 * @type {StoreGameProgressType}
 */
const INITIAL_STATE = {
  teamId: null,
  teamCode: null,
  players: [],
  playersSeenIndividualReflection: [],
  playersReadyDebriefing: [],
  playersSeenDebriefing: [],
  teamConnectorHistory: [],
  roundProgress: null,
  completedRound: [],
  startedAt: null,
  startedCountdownAt: null,
  startedGameAt: null,
  finishedGameAt: null,
  finishedReflectionAt: null,
  seenTutorials: [],
  isGameTest: false,
  disabledMantap: false,
  disabledMantapPostGame: false,
  disabledTypeform: false,
  removePlayer: [],
  rejoinPlayer: [],
};

/**
 * @type {StoreGameProgressType["roundProgress"]}
 */
const INITIAL_ROUND_PROGRESS = {
  roundId: null,
  forceFinishRound: false,
  combinationCounter: null,
  startedAt: null,
  clues: [],
  playersSeenRoundIntro: [],
  playersSeenIntro: [],
  playersSeenPreIntuition: [],
  playersFinalAnswer: [],
  playersSeenOutro: [],
  finalAnswer: [],
  shouldFinishAt: null,
  finishedOutroAt: null,
};

const gameProgressSlice = createSlice({
  name: "gameProgress",
  initialState: INITIAL_STATE,
  reducers: {
    /** @type {UtilReducer<StoreGameProgressType, StoreGameProgressType>} */
    init: (state, action) => {
      action.payload.roundProgress = {
        ...INITIAL_ROUND_PROGRESS,
        ...action.payload.roundProgress,
      };
      return { ...state, ...action.payload };
    },

    /** @type {UtilReducer<StoreGameProgressType, Pick<StoreGameProgressType, "teamId" | "teamCode" | 'isGameTest' | 'disabledLanguageSwitcher' | 'disabledMantap'>} */
    setTeam: (state, action) => ({
      ...state,
      ...action.payload,
    }),

    /** @type {UtilReducer<StoreGameProgressType, StoreGameProgressType["players"]>} */
    setPlayers: (state, action) => {
      state.players = action.payload;
      return state;
    },

    /** @type {UtilReducer<StoreGameProgressType, StoreGameProgressType["playersSeenDebriefing"]>} */
    setPlayersSeenDebriefing: (state, action) => {
      state.playersSeenDebriefing = action.payload;
      return state;
    },

    /** @type {UtilReducer<StoreGameProgressType, StoreGameProgressType["playersReadyDebriefing"]>} */
    setPlayersReadyDebriefing: (state, action) => {
      state.playersReadyDebriefing = action.payload;
      return state;
    },

    /** @type {UtilReducer<StoreGameProgressType, StoreGameProgressType["teamConnectorHistory"]>} */
    setTeamConnectorHistory: (state, action) => {
      state.teamConnectorHistory = action.payload;
      return state;
    },

    /** @type {UtilReducer<StoreGameProgressType, StoreGameProgressType["startedAt"]>} */
    setStartedAt: (state, action) => {
      state.startedAt = action.payload;
      return state;
    },

    /** @type {UtilReducer<StoreGameProgressType, StoreGameProgressType["startedCountdownAt"]>} */
    setStartedCountdownAt: (state, action) => {
      state.startedCountdownAt = action.payload;
      return state;
    },

    /** @type {UtilReducer<StoreGameProgressType, StoreGameProgressType["startedGameAt"]>} */
    setStartedGameAt: (state, action) => {
      state.startedGameAt = action.payload;
      return state;
    },

    /** @type {UtilReducer<StoreGameProgressType, StoreGameProgressType['seenTutorials']>} */
    setSeenTutorials: (state, action) => {
      state.seenTutorials = action.payload;
      return state;
    },

    /** @type {UtilReducer<StoreGameProgressType, StoreGameProgressType["roundProgress"]>} */
    setRoundProgress: (state, action) => {
      const roundProgress = {
        ...INITIAL_ROUND_PROGRESS,
        ...action.payload,
      };
      state.roundProgress = roundProgress;
      return state;
    },

    /** @type {UtilReducer<StoreGameProgressType, StoreGameProgressType["finishedGameAt"]>} */
    setFinishedGameAt: (state, action) => {
      state.finishedGameAt = action.payload;
      return state;
    },

    /** @type {UtilReducer<StoreGameProgressType, StoreGameProgressType["playersSeenIndividualReflection"]>} */
    setPlayersSeenIndividualReflection: (state, action) => {
      state.playersSeenIndividualReflection = action.payload;
      return state;
    },

    /** @type {UtilReducer<StoreGameProgressType, null>} */
    setFinishReflection: (state, action) => {
      state.finishedReflectionAt = parseInt(new Date().getTime() / 1000);
      return state;
    },

    /** @type {UtilReducer<StoreGameProgressType, StoreGameProgressType['isGameTest']>} */
    setIsGameTest: (state, action) => ({
      ...state,
      isGameTest: action.payload,
    }),

    /** @type {UtilReducer<StoreGameProgressType, StoreGameProgressType['disabledMantap']>} */
    setDisabledMantap: (state, action) => ({
      ...state,
      disabledMantap: action.payload,
    }),

    /** @type {UtilReducer<StoreGameProgressType, StoreGameProgressType['disabledMantap']>} */
    setDisabledMantapPostGame: (state, action) => ({
      ...state,
      disabledMantapPostGame: action.payload,
    }),

    /** @type {UtilReducer<StoreGameProgressType, StoreGameProgressType['disabledTypeform']>} */
    setDisabledTypeform: (state, action) => ({
      ...state,
      disabledTypeform: action.payload,
    }),

    /** @type {UtilReducer<StoreGameProgressType, StoreGameProgressType['removePlayer']>} */
    setRemovePlayer: (state, action) => ({
      ...state,
      removePlayer: action.payload,
    }),

    /** @type {UtilReducer<StoreGameProgressType, StoreGameProgressType['rejoinPlayer']>} */
    setRejoinPlayer: (state, action) => ({
      ...state,
      rejoinPlayer: action.payload,
    }),

    testing__resetPlayerSeen: (state, action) => {
      state.roundProgress.playersHasSeenSourceOutro = [];
      state.roundProgress.playersHasSeenActionOutro = [];
      state.roundProgress.playersHasSeenGeneralOutro = [];
      return state;
    },

    /** @type {UtilReducer<StoreGameProgressType, null>} */
    reset: (state, action) => {
      return INITIAL_STATE;
    },
  },
});

export const {
  init,
  setTeam,
  setPlayers,
  setStartedAt,
  setStartedCountdownAt,
  setStartedGameAt,
  setSeenTutorials,
  setRoundProgress,
  setFinishedGameAt,
  setPlayersSeenDebriefing,
  setPlayersReadyDebriefing,
  setTeamConnectorHistory,
  setPlayersSeenIndividualReflection,
  setFinishReflection,
  setIsGameTest,
  setDisabledMantap,
  setDisabledMantapPostGame,
  setDisabledTypeform,
  testing__resetPlayerSeen,
  reset,
  setRemovePlayer,
  setRejoinPlayer,
} = gameProgressSlice.actions;
export default gameProgressSlice.reducer;
