import { useCallback, useEffect, useState } from "react";
import { usePrevious } from "react-use";
import { useDispatch } from "react-redux";
import { logEvent } from "firebase/analytics";
import { useLocation, useRouteMatch } from "react-router-dom";
import axios from "axios";

import useStore from "@bd-hooks/useStore";
import useHelpers from "@bd-hooks/useHelpers";
import useNextRound from "@bd-hooks/useNextRound";
import useAuthProcess from "@bd-hooks/useAuthProcess";
import useSnackbarState from "@bd-hooks/useSnackbarState";
import useDerivedLoading from "@bd-hooks/useDerivedLoading";
import useFirebaseActions from "@bd-hooks/useFirebaseActions";
import useFirebaseAnalytics from "@bd-hooks/useFirebaseAnalytics";
import { appRoutes } from "@bd-constants/routes";
import {
  setIsBackEditingSetupName,
  setIsTryingToStartGame,
} from "@bd-redux/temporal";
import { setUseMyInitialLanguage } from "@bd-redux/firebase";

const useStateWelcome = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const authProcess = useAuthProcess();
  const snackbar = useSnackbarState();
  const store = useStore();
  const actions = useFirebaseActions();
  const analytics = useFirebaseAnalytics();
  const helpers = useHelpers();
  const nextRound = useNextRound();
  const token1 = new window.URLSearchParams(location.search).get("token_1");
  const token2 = new window.URLSearchParams(location.search).get("token_2");
  const languageCode = store.language.current.value;
  const isJoinTeamPage =
    Boolean(useRouteMatch(appRoutes.welcome)) &&
    !store.userProgress.firebaseToken;
  const isCreateTeamPage = Boolean(useRouteMatch(appRoutes.login));
  const background = store.gameData.config.pages?.waiting_room.background_image;

  /**
   * @type {UtilState<number | undefined>}
   */
  const [selectedRole, setSelectedRole] = useState(
    store.currentPlayer?.roleId ? store.currentPlayer.roleId : undefined,
  );

  const [isCheckingToken, setIsCheckingToken] = useState(
    token1 && token2 ? true : false,
  );

  const hasLoggedIn = typeof store.userProgress.userId === "number";
  const hasSetupProfile = Boolean(store.currentPlayer?.name);
  const isSetupProfile =
    hasLoggedIn && (!hasSetupProfile || store.temporal.isBackEditingSetupName);

  const isLoading = useDerivedLoading();

  const onStartGame = useCallback(() => {
    // Just to minimize potential human error from devs in the future :D
    if (!store.currentPlayer) return;
    if (!store.gameProgress.teamCode) return;
    if (!nextRound) return;

    /** @type {UtilAxiosPost<ApiRequestSetGameStarted, ApiResponseSetGameStarted>}*/
    const request = axios.post;
    dispatch(setIsTryingToStartGame(true));

    if (analytics) {
      logEvent(analytics, "start_game_clicked", {
        actor: store.currentPlayer.userId,
      });
    }

    request("/game/set-game-started", {
      team_id: store.gameProgress.teamId,
    }).then((res) => {
      if (!res.data.success) {
        console.error("Failed to start game in the server");
        snackbar.error({ message: res.data.error });
        return;
      }

      helpers.setRound(nextRound);
      actions
        .setStartGame()
        .finally(() => dispatch(setIsTryingToStartGame(false)));
    });

    // eslint-disable-next-line
  }, [store.firebase.teamPath, store.gameProgress.teamId, snackbar.error]);

  /**
   * NOTE: I know that you can use it without a state, and just store a
   * condition directly in a variable, but this has something to do with
   * animation, the reason we keep this in a state is to keep track of the
   * changes so CSSTransition can animation the in/out state gracefully.
   *
   * Otherwise if at some point the animation is not needed anymore, you can
   * remove the state and remove the effect too
   */
  const [isPlayerStatuses, setIsPlayerStatuses] = useState(false);

  // isPlayerStatuses effect
  useEffect(() => {
    const newIsPlayerStatuses =
      hasLoggedIn &&
      hasSetupProfile &&
      !store.temporal.isBackEditingSetupName &&
      !isCheckingToken &&
      !isLoading;
    setIsPlayerStatuses(newIsPlayerStatuses);
  }, [
    hasLoggedIn,
    hasSetupProfile,
    store.temporal.isBackEditingSetupName,
    store.currentPlayer?.name,
    isCheckingToken,
    isLoading,
  ]);

  useEffect(() => {
    if (!isCreateTeamPage) return;
    if (!token1 || !token2) return;

    /** @type {UtilAxiosPost<ApiRequestLogin, ApiResponseLogin>} */
    const request = axios.post;
    request("/public/login", { token_1: token1, token_2: token2 }).then(
      (res) => {
        if (!res.data.success) {
          setIsCheckingToken(false);
          snackbar.error({ message: res.data.error });
          return false;
        }

        const team = res.data.data.team;
        const lang = team.disable_lang_switch ? team.lang : languageCode;

        dispatch(setUseMyInitialLanguage(lang));
        authProcess.login(res.data.data, lang);
      },
    );
    // eslint-disable-next-line
  }, [token1, token2]);

  const prev = usePrevious({
    isLoading,
    isCheckingToken,
    isJoinTeamPage,
    isCreateTeamPage,
    isSetupProfile,
    isPlayerStatuses,
    isSnackbar: snackbar.isOpen,
  });

  /**
   * Why keep it in a state? to prevent glitch in the UI when use unselect the
   * role, so we can gracefully animate it
   * @type {UtilState<ApiRoleType | undefined>}
   */
  const [detail, setDetail] = useState(undefined);

  useEffect(() => {
    if (typeof selectedRole === "undefined") return;
    const detailRole = store.gameData.roles.find((r) => r.id === selectedRole);
    setDetail(detailRole);
  }, [selectedRole, store.gameData.roles]);

  const editProfile = () => {
    actions
      .updateReadiness(false)
      .then(() => dispatch(setIsBackEditingSetupName(true)));
  };

  const isChangingLanguage = store.language.isChangingLanguage;

  const values = {
    isLoading,
    isCheckingToken,
    isJoinTeam: isJoinTeamPage && !isCheckingToken && !isLoading,
    isCreateTeam: isCreateTeamPage && !isCheckingToken && !isLoading,
    isSetupProfile: isSetupProfile && !isCheckingToken && !isLoading,
    isPlayerStatuses,
    snackbar,
    prev,
    selectedRole,
    setSelectedRole,
    detail,
    onStartGame,
    background,
    editProfile,
    isChangingLanguage,
  };

  return values;
};

export default useStateWelcome;
