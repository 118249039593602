import { useState, useRef, useEffect } from "react";
import useSound from "use-sound";
import useStore from "@bd-hooks/useStore";

import s from "./BackgroundMusic.module.scss";

const BackgroundMusic = () => {
  const buttonRef = useRef(null);
  const [playing, setPlaying] = useState(false);
  const [ready, setReady] = useState(false);
  const { gameData, gameProgress } = useStore();
  const { game_audio } = gameData.config;
  const { startedGameAt } = gameProgress;

  const [play, { stop }] = useSound(game_audio?.city_ambiance, {
    loop: true,
  });

  useEffect(() => {
    document.addEventListener("click", () => {
      if (!ready) {
        setReady(true);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (startedGameAt && ready) {
      buttonRef.current.click();
    }
  }, [startedGameAt, ready]);

  const handleBackgroundMusic = () => {
    if (!playing) {
      setPlaying(true);
      play();
    } else {
      setPlaying(false);
      stop();
    }
  };

  return (
    <button
      ref={buttonRef}
      className={s.BackgroundMusic}
      onClick={handleBackgroundMusic}
    />
  );
};

export default BackgroundMusic;
