import { useState } from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

import s from "./TeamStatusBigSize.module.scss";
import useStore from "@bd-hooks/useStore";
import Button from "@bd-components/Button";
import WelcomeTransition from "@bd-components/WelcomeTransition";
import TeamStatusPlayer from "@bd-components/TeamStatusPlayer";
import LangSwitcherWithFlag from "@bd-components/LangSwitcherWithFlag";
import { ReactComponent as IconCopy } from "@bd-icons/icon-copy.svg";
import copyToClipboard from "@bd-utils/copyToClipboard";

const TeamStatusBigSize = ({
  isOpen: propIsOpen,
  isStartingGame,
  isEditingProfile,
  onStartGame,
  isForceable,
  onRemovePlayer,
  isRejoinUser,
}) => {
  const { t } = useTranslation();
  const store = useStore();
  const isStarting = store.temporal.isTryingToStartGame;
  const isOpen = Boolean(store.gameProgress.teamCode) && propIsOpen;
  const amIReady = store.currentPlayer?.ready ?? false;
  const hasSelectedRole = typeof store.currentPlayer?.roleId === "number";
  const isRejoinPlayer = store.currentPlayer?.isRejoinUser ?? false;
  const isMultilanguage = !store.gameProgress?.disabledLanguageSwitcher;
  const [isCopied, setIsCopied] = useState(false);

  const totalReadyPlayers = store.gameProgress.players.filter(
    (p) => p.ready && typeof p.roleId === "number",
  ).length;

  const onCopyCode = () => {
    copyToClipboard(store.gameProgress.teamCode);

    if (copyToClipboard(store.gameProgress.teamCode)) {
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 1500);
    }
  };

  return (
    <WelcomeTransition
      isOpen={isOpen}
      isInverted
      className={s.TeamStatusBigSize}
    >
      <div className={s.TeamStatusBigSize__translation}>
        <div className={s.TeamStatusBigSize__header}>
          <picture>
            <source srcSet="/images/clipper-single.webp" type="image/webp" />
            <source srcSet="/images/clipper-single.png" type="image/png" />
            <img
              className={s.TeamStatusBigSize__clipper}
              src="/images/clipper-single.png"
              alt={t("Clipper")}
            />
          </picture>
          <picture>
            <source srcSet="/images/clipper-single.webp" type="image/webp" />
            <source srcSet="/images/clipper-single.png" type="image/png" />
            <img
              className={clsx(
                s.TeamStatusBigSize__clipper,
                s["is-align-right"],
              )}
              src="/images/clipper-single.png"
              alt={t("Clipper")}
            />
          </picture>
          <h5 className={s.TeamStatusBigSize__teamCode__label}>
            {t("Team-Id")}
          </h5>
          <h4 className={s.TeamStatusBigSize__teamCode__value}>
            {store.gameProgress.teamCode}
          </h4>
          <button
            className={clsx(
              s.TeamStatusBigSize__teamCode__copy,
              isCopied && s["is-copied"],
            )}
            onClick={onCopyCode}
          >
            <IconCopy />
            <span>{t(isCopied ? "Copied" : "Copy code")}</span>
          </button>
        </div>
        <div className={s.TeamStatusBigSize__body}>
          <div className={s.TeamStatusBigSize__players}>
            {store.gameProgress.players.map((p, i) => (
              <TeamStatusPlayer
                key={p.userId}
                index={i}
                player={p}
                // Only enable remove player for others, hide the remove button
                // for current user
                onRemove={
                  !isRejoinUser && store.currentPlayer?.userId !== p.userId
                    ? () => onRemovePlayer(p.userId)
                    : undefined
                }
              />
            ))}
          </div>
        </div>
        <div className={s.TeamStatusBigSize__language}>
          {!isRejoinUser ? <LangSwitcherWithFlag withTestId /> : null}
        </div>
        <p className={s.TeamStatusBigSize__waiting}>
          {t("Waiting for players")} ({totalReadyPlayers}/
          {store.gameProgress.players.length})
        </p>
        <Button
          testId="game_startButton"
          className={s.TeamStatusBigSize__button}
          classNames={s}
          color="yellow"
          text={isRejoinUser ? t("Continue game") : t("Start game")}
          disabledText={t("Select a language")}
          isLoading={isStartingGame}
          onClick={onStartGame}
          disabled={
            !isRejoinPlayer &&
            (!hasSelectedRole ||
              isStarting ||
              !amIReady ||
              isEditingProfile ||
              !isForceable ||
              (isMultilanguage && !store.language.hasChosenLanguageManually))
          }
        />
      </div>
    </WelcomeTransition>
  );
};

export default TeamStatusBigSize;
