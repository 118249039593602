import React from "react";
import clsx from "clsx";

import s from "./WelcomeLoading.module.scss";
import { ReactComponent as IconLoading } from "@bd-icons/icon-loading.svg";
import WelcomeTransition from "@bd-components/WelcomeTransition";

/**
 * @type {import("./WelcomeLoading").WelcomeLoadingType}
 */
const WelcomeLoading = ({ isOpen, isInverted, text }) => {
  return (
    <WelcomeTransition
      className={s.WelcomeLoading}
      isOpen={isOpen}
      isInverted={isInverted}
    >
      <div>
        <IconLoading
          className={clsx(s.WelcomeLoading__loader, "g-animation-spin")}
        />
      </div>
      <div>{text}</div>
    </WelcomeTransition>
  );
};

export default WelcomeLoading;
