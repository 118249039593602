import { Redirect } from "react-router";

/**
 * @type {import('./PagePermission').PagePermissionType}
 */
const PagePermission = (hook, Component) => () => {
  const status = hook();
  if (!status.allowed) return <Redirect to={status.redirect} />;
  return <Component />;
};

export default PagePermission;
