import SmallFolderPopup from "@bd-components/SmallFolderPopup";

// This is just an alias component, for readability
const RoundIntro = (props) => {
  return (
    <SmallFolderPopup
      {...props}
      analyticEventName="intro_button_clicked"
      titleTestId="round_introTitle"
      buttonTestId="round_introSubmit"
    />
  );
};

export default RoundIntro;
