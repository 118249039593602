import React from "react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";

import s from "./WaitingRoom.module.scss";

import LobbyCard from "@bd-components/LobbyCard";
import FunnelBase from "@bd-components/FunnelBase";
import QuestionaireOverlay from "@bd-components/QuestionaireOverlay";
import useWaitingRoomRequirement from "@bd-hooks/useWaitingRoomRequirement";
import useStateWaitingRoom from "./useStateWaitingRoom";

const WaitingRoomChecker = () => {
  const status = useWaitingRoomRequirement();
  if (!status.allowed) return <Redirect to={status.redirect} />;
  return <WaitingRoom />;
};

const WaitingRoom = () => {
  const { t } = useTranslation();
  const state = useStateWaitingRoom();

  const isAllReady = state.totalReadyPlayers === state.totalPlayers;
  const fieldsetLabel = `${
    isAllReady ? t("All player is ready") : t("Waiting for players")
  } (${state.totalReadyPlayers}/${state.totalPlayers})`;

  return (
    <FunnelBase classNames={{ funnelBase: s.lobby }}>
      {state.banner ? (
        <img
          className={s.lobby__banner}
          src={state.banner}
          alt={t("Banner image")}
        />
      ) : null}
      <FunnelBase.Box
        fieldsetProps={{ legendTestId: "waiting_title" }}
        classNames={{ box: clsx(s.lobby__box, s["is-open"]) }}
        as="div"
        fieldsetLabel={fieldsetLabel}
        buttonProps={{
          text: t("Continue"),
          onClick: state.onContinue,
          disabled: state.isTryingToContinue || !isAllReady,
          testId: "waiting_submit",
        }}
      >
        <div className={s.lobby__cards}>
          {state.players.map((player, i) => (
            <LobbyCard
              key={i}
              className={s.lobby__cards__item}
              name={player.name}
              color={state.colors[i]}
              withAction={false}
              isReady={state.readyPlayers.includes(player.userId)}
              onRemove={() => state.onRemovePlayer(player.userId)}
            />
          ))}
        </div>
      </FunnelBase.Box>

      {state.isMantapEnabled ? <QuestionaireOverlay /> : null}
    </FunnelBase>
  );
};

export default WaitingRoomChecker;
