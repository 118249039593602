import { useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import axios from "axios";

import useStore from "@bd-hooks/useStore";
import useAuthProcess from "@bd-hooks/useAuthProcess";
import useSnackbarState from "@bd-hooks/useSnackbarState";
import { setCurrentLanguage } from "@bd-redux/language";

const useJoinTeamState = () => {
  const { t } = useTranslation();
  const [teamCode, setTeamCode] = useState("");

  /**
   * @type {UtilState<string | undefined>}
   */
  const [teamCodeErr, setTeamCodeErr] = useState(undefined);

  const [isSubmiting, setIsSubmiting] = useState(false);
  const { language } = useStore();
  const dispatch = useDispatch();
  const snackbar = useSnackbarState();
  const authProcess = useAuthProcess();

  const onChangeTeamCode = (e) => {
    if (isSubmiting) return;
    setTeamCodeErr(undefined);
    setTeamCode(e.target.value?.toUpperCase());
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (!teamCode.length) return setTeamCodeErr(t("Please fill the team code"));
    setIsSubmiting(true);
    snackbar.close();

    /** @type {UtilAxiosPost<ApiRequestJoinTeam, ApiResponseJoinTeam>} */
    const request = axios.post;
    request("/public/join-team", { team_code: teamCode }).then((res) => {
      setIsSubmiting(false);

      if (!res.data.success) {
        snackbar.error({ message: res.data.error });
        return false;
      }

      const team = res.data.data.team;
      const defaultLang = language.options.find((l) => l.value === team.lang);
      const activeLang =
        team.disable_lang_switch && defaultLang
          ? defaultLang
          : language.current ?? defaultLang;

      // Change language into the default language if multi-lang is disabled
      if (team.disable_lang_switch && defaultLang) {
        dispatch(setCurrentLanguage(activeLang));
      }

      const isFirstPlayer = res.data.data.team.members.length === 1;
      authProcess.login(
        res.data.data,
        isFirstPlayer ? activeLang.value : undefined,
        res.data.data.is_game_started,
      );
    });
  };

  return {
    teamCode,
    teamCodeErr,
    onChangeTeamCode,
    onSubmit,
    isSubmiting,
    snackbar,
  };
};

export default useJoinTeamState;
