import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as IconFAQ } from "@bd-icons/icon-faq.svg";
import { ReactComponent as IconCross } from "@bd-icons/icon-times-solid.svg";

import useStore from "@bd-hooks/useStore";

import clsx from "clsx";
import s from "./FAQ.module.scss";

/**
 * @type {import("./FAQ").FAQType}
 */
const FAQ = (props) => {
  const { t } = useTranslation();
  const { gameData } = useStore();
  const { faq } = gameData.config;
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [faqAnswer, setFaqAnswer] = useState("");
  const { isOpen, onClick } = props;

  const handlePopup = (answer) => {
    setFaqAnswer(answer);
    setIsPopupOpen(true);
  };

  return (
    <div className={clsx(s["FAQ--overlay"], isOpen && s["is-open"])}>
      <button
        className={clsx(s.FAQ__exit, isPopupOpen && s["is-active"])}
        onClick={onClick}
      >
        <IconCross />
      </button>
      <div className={s.FAQ}>
        <i className={s.FAQ__icon}>
          <IconFAQ />
        </i>
        <div className={s.FAQ__header}>
          <h2>{t(faq?.title)}</h2>
        </div>
        <ul className={clsx(s.FAQ__list, isPopupOpen && s["is-hidden"])}>
          {faq?.items?.map((item) => (
            <li
              className={s["FAQ__list--item"]}
              key={item.question}
              onClick={() => handlePopup(item.answer)}
            >
              {t(item.question)}
            </li>
          ))}
        </ul>
        <div className={clsx(s.FAQ__popup, isPopupOpen && s["is-open"])}>
          <span className={s["FAQ__popup--text"]}>{faqAnswer}</span>
        </div>
        <button
          className={clsx(s.FAQ__close, isPopupOpen && s["is-hidden"])}
          onClick={onClick}
        >
          {t("Close")}
        </button>
        <button
          className={clsx(s.FAQ__back, isPopupOpen && s["is-active"])}
          onClick={() => setIsPopupOpen(false)}
        >
          {t("Back")}
        </button>
      </div>
    </div>
  );
};

export default FAQ;
