import { useState } from "react";
import { logEvent } from "firebase/analytics";
import axios from "axios";

import useStore from "@bd-hooks/useStore";
import useSnackbarState from "@bd-hooks/useSnackbarState";
import useActiveRoundData from "@bd-hooks/useActiveRoundData";
import useFirebaseActions from "@bd-hooks/useFirebaseActions";
import indexedObjectToArray from "@bd-utils/indexedObjectToArray";
import useFirebaseAnalytics from "@bd-hooks/useFirebaseAnalytics";

const useFinalQuestionState = () => {
  const round = useActiveRoundData();
  const { gameProgress, userProgress } = useStore();
  const { changePlayerFinalAnswer, submitFinalAnswer } = useFirebaseActions();
  const [isSubmiting, setIsSubmiting] = useState(false);
  const snackbar = useSnackbarState();
  const analytics = useFirebaseAnalytics();
  const onAnswerChange = changePlayerFinalAnswer;

  const { playersFinalAnswer } = gameProgress.roundProgress;
  const { players } = gameProgress;
  const questions = round.final_question.questions;

  /**
   * TODO: document proper type from global.d.ts
   *
   * @type {{[key: string]: string}}
   */
  const answers = questions.reduce((result, question) => {
    const newResult = { ...result };
    const { id } = question;
    newResult[id] = playersFinalAnswer?.[id]?.[userProgress.userId];
    return newResult;
  }, {});

  const hasAgreedAnswers = (() => {
    if (!questions.length) return false;

    // quick check if there's a missing key, if true meaning the answer is not
    // complete yet, then return false
    let isAllKeyExist = true;
    questions.every((question) => {
      if (!playersFinalAnswer?.[question.id]) {
        isAllKeyExist = false;
        return false;
      } else {
        return true;
      }
    });

    if (!isAllKeyExist) return false;

    let result = true;
    Object.keys(playersFinalAnswer).every((questionId) => {
      const _answers = indexedObjectToArray(playersFinalAnswer[questionId]);
      const isAllPlayerHasAnswered = _answers.length === players.length;

      if (!isAllPlayerHasAnswered) {
        result = false;
        return false;
      }

      let prevAnswerId;
      _answers.every((answer) => {
        if (!prevAnswerId) prevAnswerId = answer;
        if (prevAnswerId !== answer) {
          result = false;
          return false;
        }
        return true;
      });

      return true;
    });

    return result;
  })();

  /**
   * Whether or not current player have answered all the existing question
   */
  const isPlayerAnswered = (() => {
    let result = true;
    questions.every((question) => {
      const { id } = question;
      if (!playersFinalAnswer?.[id]?.[userProgress.userId]) {
        result = false;
        return false;
      } else {
        return true;
      }
    });

    return result;
  })();

  const onSubmitAnswer = () => {
    if (analytics) {
      logEvent(analytics, "final_question_submited", {
        actor: userProgress.userId,
      });
    }

    setIsSubmiting(true);
    submitFinalAnswer(answers).catch((err) => {
      setIsSubmiting(false);
      snackbar.error();
      console.error("onSubmitAnswer firebase error", { err });
      return;
    });

    /**
     * @type {UtilAxiosPost<ApiRequestSubmitFinalQuestion,ApiResponseSubmitFinalQuestion>}
     */
    const request = axios.post;
    request("/game/submit-final-question", {
      id: round.final_question.id,
      team_id: gameProgress.teamId,
      answers: Object.keys(answers).map((questionId) => ({
        question_id: questionId,
        answer_id: answers[questionId],
      })),
    })
      .then((res) => {
        if (!res.data.success) {
          setIsSubmiting(false);
          snackbar.error({ message: res.data.error });
          console.error("error update to `/submit-final-question`");
          return;
        }
      })
      .catch((err) => {
        setIsSubmiting(false);
        snackbar.error();
        console.error("onSubmitAnswer", { err });
      });
  };

  return {
    answers,
    onAnswerChange,
    isSubmiting,
    onSubmitAnswer,
    snackbar,
    hasAgreedAnswers,
    isPlayerAnswered,
  };
};

export default useFinalQuestionState;
