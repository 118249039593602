import { createSlice } from "@reduxjs/toolkit";

/**
 * @type {StoreCombineHistoryType}
 */
const INITIAL_STATE = {
  data: [],
};

const combineHistorySlice = createSlice({
  name: "combineHistory",
  initialState: INITIAL_STATE,
  reducers: {
    /** @type {UtilReducer<StoreCombineHistoryType, StoreCombineHistoryType["data"]>} */
    setCombineHistory: (state, action) => {
      state.data = action.payload;
      return state;
    },

    /** @type {UtilReducer<StoreCombineHistoryType, null>} */
    reset: (state, action) => {
      return INITIAL_STATE;
    },
  },
});

export const { setCombineHistory, reset } = combineHistorySlice.actions;
export default combineHistorySlice.reducer;
