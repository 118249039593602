import useStore from "./useStore";

const useRequirementHelpers = () => {
  const { gameProgress, userProgress, currentPlayer, gameData } = useStore();

  /**
   * Whether current player has logged in or not
   */
  const hasLoggedIn = Boolean(userProgress.userId);

  /**
   * Whether current player has setup profile or not
   */
  const hasSetupName = (() => {
    if (!currentPlayer) return false;
    if (!currentPlayer.name) return false;
    return currentPlayer.name.length > 0;
  })();

  /**
   * Whether team has started or not (counted from waiting-room submission)
   */
  const hasStarted = Boolean(gameProgress.startedAt);

  /**
   * Whether player has seen game intro or not
   */
  const hasSeenIntro = (() => {
    if (!currentPlayer) return false;
    if (!currentPlayer.hasSeenIntro) return false;
    return currentPlayer.hasSeenIntro;
  })();

  /**
   * Whether team has started the game or not (counted from select-role
   * submission)
   */
  const hasStartedGame = Boolean(gameProgress.startedGameAt);

  /**
   * Whether player has seen round intro video or not
   */
  const hasSeenRoundIntroVideo = (() => {
    if (!currentPlayer) return false;
    if (!gameProgress.roundProgress) return false;
    if (!gameProgress.roundProgress.playersSeenRoundIntroVideo) return false;
    const { playersSeenRoundIntroVideo } = gameProgress.roundProgress;
    return playersSeenRoundIntroVideo.includes(currentPlayer.userId);
  })();

  /**
   * Whether player has seen round intro or not
   */
  const hasSeenRoundIntro = (() => {
    if (!currentPlayer) return false;
    if (!gameProgress.roundProgress) return false;
    if (!gameProgress.roundProgress.playersSeenRoundIntroVideo) return false;
    const { playersSeenRoundIntro } = gameProgress.roundProgress;
    return playersSeenRoundIntro.includes(currentPlayer.userId);
  })();

  /**
   * Whether current player has seen pre-intuition question or not
   */
  const hasSeenPreIntuition = (() => {
    if (!currentPlayer) return false;
    if (!gameProgress.roundProgress) return false;
    const { playersSeenPreIntuition } = gameProgress.roundProgress;
    return playersSeenPreIntuition.includes(currentPlayer.userId);
  })();

  /**
   * Whether team has finished game or not (except reflection)
   */
  const hasFinishedGame = gameProgress.finishedGameAt;

  const isRejoinUser = userProgress.isRejoinUser;

  const isTeamConnectorEnabled = gameData?.config?.team_connector?.is_enabled;

  const hasFinishedDebriefing = !isTeamConnectorEnabled
    ? true
    : gameProgress.playersSeenDebriefing?.includes(currentPlayer?.userId);

  return {
    hasLoggedIn,
    hasSetupName,
    hasStarted,
    hasSeenIntro,
    hasStartedGame,
    hasSeenRoundIntroVideo,
    hasSeenRoundIntro,
    hasSeenPreIntuition,
    hasFinishedGame,
    isRejoinUser,
    isTeamConnectorEnabled,
    hasFinishedDebriefing,
  };
};

export default useRequirementHelpers;
