import { ReactComponent as IconWatson } from "@bd-icons/icon-watson.svg";
import Tooltip from "@bd-components/Tooltip";

import clsx from "clsx";
import s from "./Watson.module.scss";
import sd from "./WatsonDynamic.module.scss";
import { useTranslation } from "react-i18next";

/**
 * @type {import("./Watson").WatsonType}
 */
const Watson = (props) => {
  const {
    text,
    title,
    placement = "left",
    isDynamic = false,
    isEnd = false,
    top,
    left,
    withTooltip = true,
  } = props;
  const { t } = useTranslation();

  return isDynamic ? (
    <div
      className={clsx(
        sd.WatsonDynamic,
        placement === "right" && sd["is-right"],
        isEnd && sd["is-end"],
      )}
      style={{ top: `${top}px`, left: `${left}px` }}
    >
      {withTooltip && (
        <Tooltip
          text={t("Trusted advisor")}
          top="0%"
          left={placement === "left" ? "0%" : "100%"}
          hoverDirection={placement}
        />
      )}
      <i className={sd.WatsonDynamic__icon}>
        <IconWatson />
      </i>
      <div className={sd.WatsonDynamic__text}>
        <h3 className={sd["WatsonDynamic__text--title"]}>{title}</h3>
        <p dangerouslySetInnerHTML={{ __html: text }} />
      </div>
    </div>
  ) : (
    <div
      className={clsx(
        s.Watson,
        placement === "right" && s["is-right"],
        isEnd && s["is-end"],
      )}
    >
      {withTooltip && (
        <Tooltip
          text={t("Trusted advisor")}
          top="20%"
          left={placement === "left" ? "90%" : "10%"}
          hoverDirection={placement === "left" ? "right" : "left"}
        />
      )}
      <IconWatson className={s.Watson__icon} />
      <div className={s.Watson__text}>
        <h3 className={s["Watson__text--title"]}>{title}</h3>
        <p>{text}</p>
      </div>
    </div>
  );
};

export default Watson;
