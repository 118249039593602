import { createSlice } from "@reduxjs/toolkit";

/**
 * @type {StoreDraggingType}
 */
const INITIAL_STATE = {
  user: false,
};

const draggingSlice = createSlice({
  name: "dragging",
  initialState: INITIAL_STATE,
  reducers: {
    /** @type {UtilReducer<StoreDraggingType, StoreDraggingType>} */
    setDragging: (state, action) => {
      return action.payload;
    },
  },
});

export const { setDragging } = draggingSlice.actions;
export default draggingSlice.reducer;
