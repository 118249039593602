import React, { useEffect, useRef } from "react";
import { Redirect } from "react-router-dom";

import WallBackground from "@bd-components/WallBackground";
import Projector from "@bd-components/Projector";
import VideoPlayer from "@bd-components/VideoPlayer";
import useStore from "@bd-hooks/useStore";
import useFirebaseActions from "@bd-hooks/useFirebaseActions";
import useIntroRequirement from "@bd-hooks/useIntroRequirement";
import setCurrentPage from "@bd-apis/setCurrentPage";
import Snackbar from "@bd-components/Snackbar";
import useLeaveAndRejoinGameNotif from "@bd-hooks/useLeaveAndRejoinGameNotif";

import { useTranslation } from "react-i18next";
import Button from "@bd-components/Button";
import s from "../RoundIntroVideo/RoundIntroVideo.module.scss";

const IntroChecker = () => {
  const status = useIntroRequirement();
  if (!status.allowed) return <Redirect to={status.redirect} />;
  return <Intro />;
};

const Intro = () => {
  const { t } = useTranslation();
  const { gameProgress, gameData, firebase: fb, temporal } = useStore();
  const { setSeenIntro } = useFirebaseActions();
  const refIsFrameLoaded = useRef(false);
  const leaveAndRejoinGameNotif = useLeaveAndRejoinGameNotif();

  /**
   * @type {import("react").MutableRefObject<HTMLVideoElement | null>}
   */
  const refVideoPlayer = useRef(null);

  const onProjectorLoaded = () => {
    refIsFrameLoaded.current = true;
  };

  const onVideoEnd = () => {
    setSeenIntro();
  };

  useEffect(() => {
    if (!temporal.isInterceptorReady) return;
    setCurrentPage("Intro", gameProgress.teamId);
  }, [gameProgress.teamId, temporal.isInterceptorReady]);

  return (
    <WallBackground>
      <Projector onImageLoaded={onProjectorLoaded}>
        {fb.isConnected ? (
          <VideoPlayer
            playOrPauseTestId="gameIntro_playVideo"
            ref={refVideoPlayer}
            autoplay={false}
            url={gameData.config.game_intro_video}
            type="video/mp4"
            onEnded={onVideoEnd}
          />
        ) : null}
        <Snackbar
          isUsingPortal
          title={leaveAndRejoinGameNotif.snackbar.title}
          message={leaveAndRejoinGameNotif.snackbar.message}
          status={leaveAndRejoinGameNotif.snackbar.status}
          onClose={leaveAndRejoinGameNotif.snackbar.close}
          isOpen={leaveAndRejoinGameNotif.snackbar.isOpen}
        />
      </Projector>
      <Button
        className={s["roundIntroVideo__skip"]}
        text={t("Skip")}
        onClick={onVideoEnd}
        size="normal"
      />
    </WallBackground>
  );
};

export default IntroChecker;
