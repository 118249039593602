import axios from "axios";

/**
 * Set the state of the current page to game progress in the backend
 *
 * @param {string} pageName the name of a new page
 * @param {string} teamId
 */
const setCurrentPage = (pageName, teamId) =>
  axios
    .post("/game/set-current-page", { current_page: pageName, team_id: teamId })
    .catch((err) => console.error("Can't update current page", { err }));

export default setCurrentPage;
