import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import s from "./LangSwitcherWithFlag.module.scss";
import useStore from "@bd-hooks/useStore";
import useFirebaseActions from "@bd-hooks/useFirebaseActions";
import { setIsConnecting } from "@bd-redux/firebase";
import { stylesCombinerFn } from "@bd-utils/stylesCombiner";
import { ReactComponent as FlagDutch } from "@bd-icons/icon-flag-dutch.svg";
import { ReactComponent as FlagEnglish } from "@bd-icons/icon-flag-english.svg";
import { ReactComponent as IconCheck } from "@bd-icons/icon-check-regular.svg";

const LangSwitcherWithFlag = ({ classNames, withTestId = false }) => {
  const { t } = useTranslation();
  const { language, gameProgress } = useStore();
  const { changeLanguage } = useFirebaseActions();
  const c = stylesCombinerFn(s, classNames);
  const dispatch = useDispatch();
  const isMultilanguage =
    gameProgress.teamId && !gameProgress.disabledLanguageSwitcher;

  const hasChosen = language.hasChosenLanguageManually;
  const isEnglishExist = language.options.some((l) => l.value === "en");
  const isDutchExist = language.options.some((l) => l.value === "nl");
  const isSelectable = isEnglishExist && isDutchExist;
  const isEnglishSelected = hasChosen && language.current.value === "en";
  const isDutchSelected = hasChosen && language.current.value === "nl";

  const englishOption = language.options.find((l) => l.value === "en");
  const dutchOption = language.options.find((l) => l.value === "nl");

  /**
   * @param {LangType} newValue
   */
  const onChangeLanguage = (newValue) => {
    const shouldUpdate =
      language.current.value !== newValue.value ||
      !language.hasChosenLanguageManually;
    if (!shouldUpdate) return;

    dispatch(setIsConnecting(true));

    changeLanguage(newValue.value).catch((err) => {
      dispatch(setIsConnecting(false));
      console.error("Can't update language in Firebase", { err });
    });
  };

  return isMultilanguage && isSelectable ? (
    <div
      className={c("LangSwitcherWithFlag")}
      data-testid="setup_currentLanguage"
      data-lang={language.current.value}
    >
      <div className={c("LangSwitcherWithFlag__label")}>
        {t("Select Language")}
      </div>
      <div className={c("LangSwitcherWithFlag__flags")}>
        <button
          {...(withTestId ? { "data-testid": "setup_changeLanguage_nl" } : {})}
          className={c(
            "LangSwitcherWithFlag__flag",
            isDutchSelected && "isSelected",
          )}
          onClick={() => onChangeLanguage(dutchOption)}
        >
          <div className={c("LangSwitcherWithFlag__flag__wrapper")}>
            <FlagDutch />
          </div>
          <div className={c("LangSwitcherWithFlag__flag__check")}>
            <IconCheck />
          </div>
        </button>
        <button
          {...(withTestId ? { "data-testid": "setup_changeLanguage_en" } : {})}
          className={c(
            "LangSwitcherWithFlag__flag",
            isEnglishSelected && "isSelected",
          )}
          onClick={() => onChangeLanguage(englishOption)}
        >
          <div className={c("LangSwitcherWithFlag__flag__wrapper")}>
            <FlagEnglish />
          </div>
          <div className={c("LangSwitcherWithFlag__flag__check")}>
            <IconCheck />
          </div>
        </button>
      </div>
    </div>
  ) : null;
};

export default LangSwitcherWithFlag;
