import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

// import s from "./IntuitionQuestion.module.scss";
import PreIntuitionQuestion from "@bd-components/PreIntuitionQuestion";
import useIntuitionQuestion from "@bd-hooks/useIntuitionQuestion";
import useStore from "@bd-hooks/useStore";

/**
 * @type {React.FC<{
 *  isOpen: boolean,
 *  intuitions: ApiIntuitionType[],
 * }>}
 */
const IntuitionQuestion = ({ style, isOpen, intuitions }) => {
  const { t } = useTranslation();
  const { gameData } = useStore();
  const state = useIntuitionQuestion({ intuitions });
  return (
    <PreIntuitionQuestion
      isOpen={isOpen}
      title={gameData.config.pre_bias.title}
      content={gameData.config.pre_bias.body}
      fieldsetLabel={t("Investigation")}
      onClose={state.onHidePreIntuition}
    />
  );
};

IntuitionQuestion.propTypes = {
  isOpen: PropTypes.bool,
};

export default IntuitionQuestion;
