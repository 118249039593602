/** @jsxRuntime classic */
// IMPORTANT: We need these comment above to fix IE support, moreover see:
// https://github.com/facebook/create-react-app/issues/9906#issuecomment-719488780
import "./polyfills";
import "./Debug";

// Translations
import "./i18n";

import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root"),
);

document.querySelector(".criticalLoading").remove();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
