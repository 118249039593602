import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import Button from "@bd-components/Button";
import Snackbar from "@bd-components/Snackbar";
import TextField from "@bd-components/TextField";
import WelcomeTransition from "@bd-components/WelcomeTransition";
import { appRoutes } from "@bd-constants/routes";
import { ReactComponent as IconChevronRight } from "@bd-icons/icon-chevron-right-solid.svg";

import s from "./JoinTeam.module.scss";
import useStateJoinTeam from "./useStateJoinTeam";

const JoinTeam = ({ isOpen, isInverted }) => {
  const { t } = useTranslation();
  const state = useStateJoinTeam();

  return (
    <WelcomeTransition
      className={s.root}
      isOpen={isOpen}
      isInverted={isInverted}
      elementType="form"
      elementProps={{ onSubmit: state.onSubmit }}
    >
      <h3 className={s.heading} data-testid="login_title">
        {t("Welcome to Blindspot")}
      </h3>
      <div className={s.description} data-testid="login_description">
        {t("Insert your team id here to join the game")}
      </div>

      <TextField
        testId="login_input"
        className={s.textField}
        color="black"
        name="teamCode"
        placeholder={t("Team ID")}
        autoComplete="off"
        value={state.teamCode}
        error={state.teamCodeErr}
        onChange={state.onChangeTeamCode}
      />

      <Button
        testId="login_submit"
        className={s.button}
        type="submit"
        variant="contained"
        color="yellow"
        size="medium"
        text={t("Join Game")}
        iconEnd={IconChevronRight}
        isLoading={state.isSubmiting}
      />

      <div className={s.option}>
        {t("Or")}{" "}
        <Link className={s.link} to={appRoutes.login}>
          {t("Create Game")}
        </Link>
      </div>

      <Snackbar
        isUsingPortal
        title={state.snackbar.title}
        message={state.snackbar.message}
        status={state.snackbar.status}
        onClose={state.snackbar.close}
        isOpen={state.snackbar.isOpen && isOpen}
      />
    </WelcomeTransition>
  );
};

export default JoinTeam;
