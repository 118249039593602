import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { useMeasure } from "react-use";
import { logEvent } from "firebase/analytics";
import { useTranslation } from "react-i18next";
import { Redirect, useHistory } from "react-router-dom";
import clsx from "clsx";

import s from "./Gameplay.module.scss";
import slb from "src/pages/Lobby/Lobby.module.scss";
import sfp from "@bd-components/SmallFolderPopup/SmallFolderPopup.module.scss";
import { ReactComponent as IconPlay } from "@bd-icons/icon-play.svg";
import { ReactComponent as IconPaused } from "@bd-icons/icon-paused.svg";

// Components
import Popup from "@bd-components/Popup";
import Button from "@bd-components/Button";
import Folder from "@bd-components/Folder";
import Snackbar from "@bd-components/Snackbar";
import Tutorial from "@bd-components/Tutorial";
// import PlayerInfo from "@bd-components/PlayerInfo";
import RoundIntro from "@bd-components/RoundIntro";
import IntelButton from "@bd-components/IntelButton";
import WallBackground from "@bd-components/WallBackground";
import IntuitionQuestion from "@bd-components/IntuitionQuestion";
import Goals from "@bd-components/Goals";

// Hooks
import useSound from "use-sound";
import useStore from "@bd-hooks/useStore";
import useClues from "@bd-hooks/useClues";
import usePopup from "@bd-hooks/usePopup";
import useBoardPlay from "@bd-hooks/useBoardPlay";
import useIntuitions from "@bd-hooks/useIntuitions";
import useActiveRoundData from "@bd-hooks/useActiveRoundData";
import useRoundIntroState from "@bd-hooks/useRoundIntroState";
import useGameplayRequirement from "@bd-hooks/useGameplayRequirement";
import useLeaveAndRejoinGameNotif from "@bd-hooks/useLeaveAndRejoinGameNotif";

// Local Sub-components
import BoardPlay from "./Gameplay_BoardPlay";
import BoardRight from "./Gameplay_BoardRight";
import setCurrentPage from "@bd-apis/setCurrentPage";
import useFirebaseActions from "@bd-hooks/useFirebaseActions";
import useFirebaseAnalytics from "@bd-hooks/useFirebaseAnalytics";

// Others
import setRoundFinished from "@bd-apis/setRoundFinished";
import Warning from "@bd-components/Warning";
import Actions from "./Gameplay_Actions";

const GameplayChecker = () => {
  const status = useGameplayRequirement();
  if (!status.allowed) return <Redirect to={status.redirect} />;
  return <Gameplay />;
};

const Gameplay = () => {
  const { t } = useTranslation();
  const { forceFinishRound: fbForceFinishRound } = useFirebaseActions();
  const round = useActiveRoundData();
  const cluesState = useClues();
  const questionState = usePopup();
  const history = useHistory();
  const roundIntroState = useRoundIntroState();
  const analytics = useFirebaseAnalytics();
  const boardState = useBoardPlay({ onCombineSucceeded: cluesState.onAddClue });
  const { intuitions } = useIntuitions();
  const { gameData, gameProgress, currentPlayer, temporal } = useStore();
  const [refCluesWrapper, { width: cluesWrapperWidth }] = useMeasure();
  const { game_audio } = gameData.config;
  const forceFinishPopup = usePopup();
  const actionCount = gameProgress.roundProgress.combinationCounter;
  const initialActionCount = round.combination_counter; // localStorage.getItem("InitialActionCount");

  const leaveAndRejoinGameNotif = useLeaveAndRejoinGameNotif();

  const solveMysterySound = useSound(game_audio?.button_solve_mystery, {
    volume: 0.5,
  });
  const noMovesSound = useSound(game_audio?.notification_no_more_moves, {
    volume: 0.5,
  });

  const hasSeenPreIntuition =
    gameProgress.roundProgress.playersSeenPreIntuition.includes(
      currentPlayer.userId,
    );
  const isIntuitionQuestion = questionState.isOpen && hasSeenPreIntuition;

  const onForceFinishRound = () => {
    forceFinishPopup.open();
  };

  const finishRound = () => {
    setRoundFinished(gameProgress.teamId, round.id);
  };

  const forceFinishRound = () => {
    if (analytics) {
      logEvent(analytics, "force_finish_round_clicked", {
        actor: currentPlayer.userId,
        round_id: round.id,
      });
    }

    roundIntroState.close();
    forceFinishPopup.close();
    fbForceFinishRound();
    finishRound();
  };

  const onTimeout = useCallback(() => {
    boardState.disable();
    questionState.open();
    finishRound();

    if (analytics) {
      logEvent(analytics, "timeout_round", {
        actor: currentPlayer.userId,
        team_id: gameProgress.teamId,
        round_id: round.id,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boardState, questionState]);

  const onAnswered = () => {
    history.push("/final-question");
  };

  /**
   * @type {import("react").MutableRefObject<HTMLAudioElement>}
   */
  const refAudio = useRef();
  const [isPlayingAudio, setIsPlayingAudio] = useState(false);
  const onToggleAudio = () => {
    if (!isPlayingAudio) {
      refAudio.current.play();
      setIsPlayingAudio(true);

      if (analytics) {
        logEvent(analytics, "play_audio", {
          actor: currentPlayer.userId,
        });
      }
    } else {
      refAudio.current.pause();
      setIsPlayingAudio(false);

      if (analytics) {
        logEvent(analytics, "pause_audio", {
          actor: currentPlayer.userId,
        });
      }
    }
  };

  useEffect(() => {
    // Immediately finish the board if user force it to finish via `Finish Round`
    if (gameProgress.roundProgress.forceFinishRound) onTimeout();
  }, [gameProgress.roundProgress.forceFinishRound, onTimeout]);

  /**
   * Autoplay audio
   */
  useLayoutEffect(() => {
    if (!refAudio.current) return;
    if (!refAudio.current.paused) return setIsPlayingAudio(true);

    try {
      refAudio.current
        .play()
        .then(() => {
          setIsPlayingAudio(true);
          console.log("audio auto-played programmatically");
        })
        .catch(() => {
          console.error("Can't auto-play audio");

          if (analytics) {
            logEvent(analytics, "no_audio_autoplay", {
              actor: currentPlayer.userId,
            });
          }
        });
    } catch (err) {
      console.error("Can't auto-play audio");

      if (analytics) {
        logEvent(analytics, "no_audio_autoplay", {
          actor: currentPlayer.userId,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!temporal.isInterceptorReady) return;
    setCurrentPage(`Gameplay - Round ${round.index}`, gameProgress.teamId);
  }, [round.index, gameProgress.teamId, temporal.isInterceptorReady]);

  // set initial counter in localstorage to persist max actions on page reload
  useEffect(() => {
    if (!localStorage.getItem("InitialActionCount")) {
      localStorage.setItem("InitialActionCount", actionCount);
    }
  }, [actionCount]);

  // audio for actions left
  useEffect(() => {
    if (actionCount + 3 === initialActionCount) {
      solveMysterySound[0]();
    } else if (actionCount === 0) {
      noMovesSound[0]();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionCount]);

  const renderRightSide = (hideOnQuestion = false) => (
    <BoardRight
      clues={cluesState}
      hideOnQuestion={hideOnQuestion}
      isIntuitionQuestion={isIntuitionQuestion}
      isFinish={questionState.isOpen}
      onForceFinishRound={onForceFinishRound}
      onTimeout={onTimeout}
    />
  );

  return (
    <WallBackground>
      <Tutorial />
      <div className={s.gameplay__wrapper}>
        {/* <div className={s.gameplay__left}>
          <PlayerInfo
            className={s.gameplay__playerInfo}
            role={currentPlayerRole}
          /> */}
        {/* </div> */}
        <div className={s.gameplay__center}>
          {/* <h1 className={s.gameplay__title}>{round.goal}</h1> */}
          {round.background_audio ? (
            <>
              <audio ref={refAudio} loop>
                <source src={round.background_audio} type="audio/mp3" />
              </audio>
              <div>
                <Button
                  className={s.gameplay__audio}
                  iconStart={isPlayingAudio ? IconPaused : IconPlay}
                  text={isPlayingAudio ? t("Pause Audio") : t("Play Audio")}
                  onClick={onToggleAudio}
                  disabled={boardState.isDisabled}
                />
              </div>
            </>
          ) : null}
          <BoardPlay
            sources={boardState.sources}
            onDragStart={boardState.onDragStart}
            onDragEnd={boardState.onDragEnd}
            setActiveNode={boardState.setActiveNode}
            forbiddenTargetsIds={boardState.forbiddenTargetsIds}
            isCombining={boardState.isCombining}
            // isDejavu={boardState.isDejavu}
            isOtherDragging={boardState.dragging.isOtherDragging}
            combine={boardState.combine}
            boardStringValues={boardState.boardStringValues}
          />
          <Actions
            actionCount={actionCount}
            initialActionCount={initialActionCount}
            isFinish={questionState.isOpen}
            onTimeout={onTimeout}
            sources={boardState.sources}
          />
        </div>
        <IntelButton isGlowing={actionCount === initialActionCount - 1} />
        <div className={s.gameplay__right} ref={refCluesWrapper}>
          {/* {renderRightSide(true)} */}
          <Goals />
          <Button
            testId="game_forceFinish"
            className={clsx(
              s.gameplay__solveButton,
              actionCount + 3 <= initialActionCount ? "" : "display-none",
              actionCount === 0 && s["out-of-actions"],
            )}
            text={t("Finish Round")}
            onClick={onForceFinishRound}
            disabled={questionState.isOpen}
            size="jumbo"
          />
        </div>
      </div>
      <div className={clsx(s.question, isIntuitionQuestion && s["is-open"])}>
        <div className={s.question__flex}>
          <IntuitionQuestion
            style={{ width: `calc(100% - ${cluesWrapperWidth}px)` }}
            isOpen={questionState.isOpen}
            intuitions={intuitions}
            onAnswered={onAnswered}
          />
          {questionState.isOpen && hasSeenPreIntuition ? (
            <div
              className={s.question__cluesWrapper}
              style={{ width: cluesWrapperWidth }}
            >
              <div className={s.question__justifier}>{renderRightSide()}</div>
            </div>
          ) : null}
        </div>
      </div>
      {/** Warnings */}
      {actionCount === 2 && round.index === 1 && (
        <Warning
          warningClass=".Gameplay_Actions-module__actions--counter"
          warningKey="action_left_reminder"
          placement="right"
        />
      )}
      {actionCount + 3 === initialActionCount && round.index === 1 && (
        <Warning
          warningClass=".Gameplay-module__gameplay__solveButton"
          warningKey="solve_mystery"
          placement="right"
          isDynamic={true}
          topPos={
            document
              .querySelector(".Gameplay-module__gameplay__solveButton")
              ?.getBoundingClientRect().top - 50
          }
          leftPos={
            document
              .querySelector(".Gameplay-module__gameplay__solveButton")
              ?.getBoundingClientRect().left - 50
          }
        />
      )}

      {actionCount + 2 === initialActionCount && round.index === 1 && (
        <Warning
          warningClass=".Gameplay_Actions-module__actions--bar-button--bg"
          warningKey="action_log"
          placement="left"
          isDynamic={true}
          withTooltip={false}
          topPos={
            document
              .querySelector(
                ".Gameplay_Actions-module__actions--bar-button--bg",
              )
              ?.getBoundingClientRect().top - 70
          }
          leftPos={
            document
              .querySelector(
                ".Gameplay_Actions-module__actions--bar-button--bg",
              )
              ?.getBoundingClientRect().left + 20
          }
        />
      )}

      <RoundIntro
        title={round.level_intro.title}
        content={round.level_intro.body}
        label="CASE-2876-AB-1X"
        isOpen={roundIntroState.isOpen}
        onClose={roundIntroState.close}
      />
      <Popup isOpen={forceFinishPopup.isOpen}>
        <Folder size="small">
          <Folder.Inner>
            <div className={slb.prompt}>
              <div className={slb.prompt__inner}>
                <h1 className={sfp.smallFolderPopup__title}>
                  {t("Are you sure want to finish the round?")}
                </h1>
                <div className={slb.prompt__actions}>
                  <div className={slb.prompt__actions__item}>
                    <Button
                      testId="game_cancelForceFinishConfirmation"
                      text={t("Cancel")}
                      size="jumbo"
                      onClick={forceFinishPopup.close}
                    />
                  </div>
                  <div className={slb.prompt__actions__item}>
                    <Button
                      testId="game_confirmForceFinishConfirmation"
                      text={t("Finish")}
                      size="jumbo"
                      onClick={forceFinishRound}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Folder.Inner>
        </Folder>
      </Popup>
      <Snackbar
        isUsingPortal
        title={boardState.snackbar.title}
        message={boardState.snackbar.message}
        status={boardState.snackbar.status}
        onClose={boardState.snackbar.close}
        isOpen={boardState.snackbar.isOpen}
      />

      <Snackbar
        isUsingPortal
        title={leaveAndRejoinGameNotif.snackbar.title}
        message={leaveAndRejoinGameNotif.snackbar.message}
        status={leaveAndRejoinGameNotif.snackbar.status}
        onClose={leaveAndRejoinGameNotif.snackbar.close}
        isOpen={leaveAndRejoinGameNotif.snackbar.isOpen}
      />
    </WallBackground>
  );
};

export default GameplayChecker;
