/**
 * Mapping CSS Transition classNames into our styles CSS Modules object
 * @param {Object} [s] the styles object
 * @param {string} [suffix]
 */
const csstmap = (s = undefined, suffix = "") => ({
  appear: s ? s[`is-appear${suffix}`] : "is-appear",
  appearActive: s ? s[`is-appear-active${suffix}`] : "is-appear-active",
  appearDone: s ? s[`is-appear-done${suffix}`] : "is-appear-done",
  enter: s ? s[`is-enter${suffix}`] : "is-enter",
  enterActive: s ? s[`is-enter-active${suffix}`] : "is-enter-active",
  enterDone: s ? s[`is-enter-done${suffix}`] : "is-enter-done",
  exit: s ? s[`is-exit${suffix}`] : "is-exit",
  exitActive: s ? s[`is-exit-active${suffix}`] : "is-exit-active",
  exitDone: s ? s[`is-exit-done${suffix}`] : "is-exit-done",
});

export default csstmap;
