import { createSlice } from "@reduxjs/toolkit";

/**
 * Here are the data that need to be stored but doesn't need to be persisted
 *
 * @type {StoreTemporalType}
 */
const INITIAL_STATE = {
  isInterceptorReady: false,
  retrievedRoleId: null,
  isBackEditingSetupName: false,
  isTryingToStartGame: false,
};

const firebaseSlice = createSlice({
  name: "firebase",
  initialState: INITIAL_STATE,
  reducers: {
    /** @type {UtilReducer<StoreTemporalType, StoreTemporalType["isInterceptorReady"]>} */
    setIsInterceptorReady: (state, action) => ({
      ...state,
      isInterceptorReady: action.payload,
    }),

    /** @type {UtilReducer<StoreTemporalType, StoreTemporalType["isBackEditingSetupName"]>} */
    setIsBackEditingSetupName: (state, action) => ({
      ...state,
      isBackEditingSetupName: action.payload,
    }),

    /** @type {UtilReducer<StoreTemporalType, StoreTemporalType["isTryingToStartGame"]>} */
    setIsTryingToStartGame: (state, action) => ({
      ...state,
      isTryingToStartGame: action.payload,
    }),

    /** @type {UtilReducer<StoreTemporalType, StoreTemporalType["retrievedRoleId"]>} */
    setRetrievedRoleId: (state, action) => ({
      ...state,
      retrievedRoleId: action.payload,
    }),

    /** @type {UtilReducer<StoreTemporalType, null>} */
    reset: (state, action) => ({
      ...INITIAL_STATE,
    }),
  },
});

export const {
  setIsInterceptorReady,
  setRetrievedRoleId,
  setIsBackEditingSetupName,
  setIsTryingToStartGame,
  reset,
} = firebaseSlice.actions;
export default firebaseSlice.reducer;
