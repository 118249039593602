/**
 * To filter all unnecessary elements that we don't really care about for the
 * selectors styling
 *
 * @param {HTMLElement} el
 * @returns {boolean}
 */
function nodeFilter(el) {
  // https://developer.mozilla.org/en-US/docs/Web/API/Node/nodeType
  if (el.nodeType === 3) return false; // text node
  if (el.nodeType === 4) return false; // CDATA node
  if (el.nodeType === 7) return false; // Processing instruction node
  if (el.nodeType === 8) return false; // comment node
  if (el.nodeType === 9) return false; // Document node
  if (el.nodeType === 10) return false; // Document node
  if (el.nodeName === "HTML") return false;
  if (el.nodeName === "BODY") return false;
  if (el.nodeName === "STYLE") return false;
  if (el.nodeName === "SCRIPT") return false;
  if (el.nodeName === "NOSCRIPT") return false;
  if (el.nodeName === "HEAD") return false;
  if (el.nodeName === "AUDIO") return false;
  if (el.nodeName === "IFRAME") return false;
  return true;
}

function getNextSiblings(el, filter) {
  const sibs = [];
  while ((el = el.nextSibling)) {
    if (!nodeFilter(el)) continue;
    if (!filter || filter(el)) sibs.push(el);
  }
  return sibs;
}

function getPreviousSiblings(el, filter) {
  const sibs = [];
  while ((el = el.previousSibling)) {
    if (!nodeFilter(el)) continue;
    if (!filter || filter(el)) sibs.push(el);
  }
  return sibs;
}

/**
 * Get a specific identifier for current node
 * @param {HTMLElement} el
 */
function getIdentifier(el) {
  const withDot = (c) => `.${c}`;
  const parsed = [...(el.classList ? el.classList : [])];
  const index = [...el.parentNode.children].indexOf(el);

  const selector =
    parsed.length > 0
      ? `${parsed.map(withDot).join("")}:nth-child(${index + 1})`
      : el.id
      ? `#${el.id}`
      : el.localName
      ? `> ${el.localName}:nth-child(${index + 1})`
      : "";

  return selector;
}

function generateSelector(el) {
  let parents = [];
  let classes = "";
  let currentTarget = el;

  while (currentTarget) {
    if (nodeFilter(currentTarget)) {
      const identifier = getIdentifier(currentTarget);
      classes = `${identifier} ${classes}`;
    }

    parents.unshift(currentTarget);
    currentTarget = currentTarget.parentNode;
  }

  const result = classes.trim();

  return result;
}

export function generateDimmedClasses(target) {
  const dimmed = [];
  const root = "root";
  let el = document.querySelector(target);

  while (el) {
    const shouldStop = el?.id === root;

    if (!shouldStop) {
      const prevSiblings = getPreviousSiblings(el);
      const nextSiblings = getNextSiblings(el);
      dimmed.push(...prevSiblings);
      dimmed.push(...nextSiblings);
    }

    el = el.parentNode;
  }

  return dimmed
    .map((d) => generateSelector(d))
    .filter((c) => c.length > 0)
    .join(", ");
}
