import { useState } from "react";
import { useUpdateEffect } from "react-use";

import useStore from "./useStore";
import useActiveRoundData from "./useActiveRoundData";
import useFirebaseActions from "./useFirebaseActions";

const useClues = () => {
  const { gameProgress } = useStore();
  const { addCombination } = useFirebaseActions();
  const clues = gameProgress.roundProgress.clues;
  const currentRound = useActiveRoundData();

  const [activeClueIndex, setActiveClueIndex] = useState(0);

  const prevClue = () => {
    if (clues.length === 0) return;
    setActiveClueIndex((prev) => (prev === 0 ? clues.length - 1 : prev - 1));
  };

  const nextClue = () => {
    if (clues.length === 0) return;
    setActiveClueIndex((prev) => (prev === clues.length - 1 ? 0 : prev + 1));
  };

  /**
   * @param {CombineSucceededArgs} e
   */
  const onAddClue = (e) => {
    addCombination(e, currentRound);
  };

  useUpdateEffect(() => {
    setActiveClueIndex(clues.length - 1);
  }, [clues.length]);

  return {
    allData: clues,
    activeClueIndex,
    totalClue: clues.length,
    prevClue,
    nextClue,
    onAddClue,
  };
};

export default useClues;
