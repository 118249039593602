import { ref, getDatabase } from "firebase/database";
import { useSelector } from "react-redux";
import { getRoleByUserId } from "@bd-utils/roles";

const useStore = () => {
  /**
   * @type {StoreRootType}
   */
  const {
    gameData: rawGameData,
    gameProgress,
    user: userProgress,
    firebase: _fb,
    dragging,
    combineHistory,
    kickedSnapshot,
    language,
    temporal,
  } = useSelector((state) => state);

  const allowedRounds = rawGameData.rounds.filter((round) =>
    gameProgress.isGameTest
      ? round.status === "draft" || round.status === "publish"
      : round.status === "publish",
  );

  /**
   * Why bother to sort the round? because admin has to set index in each round
   * in the backend, and it is error prone, for example .. it is possible for
   * admin to create rounds with index [3,7,101], which is nonsense
   */
  const sortedRound = allowedRounds.sort((a, b) => a.index - b.index);

  /**
   * After round has been sorted from lowest index to highest, we should format
   * the indices with the proper index based on it's array index.
   *
   * Why don't use array index directly? because some of the code rely on
   * `round.index`, ideally it's should be array index, it's just because the
   * author was lazy at the moment :)
   */
  const properIndexedRounds = sortedRound.map((round, index) => ({
    ...round,
    index: index + 1,
  }));

  /**
   * @type {StoreGameDataType}
   */
  const gameData = {
    ...rawGameData,
    rounds: properIndexedRounds,
  };

  const { players } = gameProgress;
  const { roles } = gameData;

  /**
   * Current player's data
   */
  const currentPlayer = (() => {
    if (!gameProgress.players) return null;
    const { userId } = userProgress;
    const players = gameProgress.players.filter((p) => p.userId === userId);
    if (!players.length) return null;
    return players[0];
  })();

  /**
   * Current player role's data
   */
  const currentPlayerRole = getRoleByUserId(
    currentPlayer?.userId,
    players,
    roles,
  );

  /**
   * Firebase redux store data and path helper
   */
  const firebase = (() => {
    const { teamCode, roundProgress } = gameProgress;
    const teamPath = `${teamCode?.toUpperCase()}`;
    const playersPath = `${teamPath}/players`;
    const languagePath = `${teamPath}/language`;
    const manualLanguagePath = `${teamPath}/hasChosenLanguageManually`;
    const draggingPath = `${teamPath}/dragging`;
    const combineHistoryPath = `${teamPath}/combineHistory`;

    /**
     * Get particular player path inside current team
     * @param {ApiPlayerType["userId"]} userId
     */
    const getPlayerPath = (userId) => `${playersPath}/${userId}`;

    /**
     * Get particular roundId path
     * @param {ApiRoundType["id"]} roundId
     */
    const getRoundPath = (roundId) => `${teamPath}/rounds/${roundId}`;

    /**
     * Get current player path for particular node / child of current active round
     * @param {String} nodePrefix
     */
    const getPlayerNodePath = (nodePrefix) => {
      return `${getRoundPath(roundProgress.roundId)}/${nodePrefix}/${
        userProgress.userId
      }`;
    };

    /**
     * Get combine history path
     * @param {CombineSucceededArgs["from"]} from
     * @param {CombineSucceededArgs["to"]} to
     */
    const getCombineHistoryPath = (from, to) => {
      return `${teamPath}/combineHistory/${from}${to}`;
    };

    /**
     * Get final answer of current active round for a specific question
     * @param {FinalQuestionItemType['id']} questionId
     * @returns
     */
    const getPlayerFinalAnswerPath = (questionId) => {
      return `${getRoundPath(
        roundProgress.roundId,
      )}/playersFinalAnswer/${questionId}/${userProgress.userId}`;
    };

    /**
     * @param {ApiTutorialType['id']} id
     */
    const getTutorialPath = (id) =>
      `${teamPath}/seenTutorials/${id}/${userProgress.userId}`;

    const seenDebriefingPath = `${teamPath}/playersSeenDebriefing`;
    const readyDebriefingPath = `${teamPath}/playersReadyDebriefing`;

    const seenTutorialsPath = `${teamPath}/seenTutorials`;
    const individualReflectionPath = `${teamPath}/playersSeenIndividualReflection`;
    const finishedReflectionAtPath = `${teamPath}/finishedReflectionAt`;
    const myIndividualReflectionPath = `${teamPath}/playersSeenIndividualReflection/${userProgress.userId}`;
    const currentPlayerPath = getPlayerPath(userProgress.userId);
    const currentRoundPath = roundProgress?.roundId
      ? getRoundPath(roundProgress.roundId)
      : null;

    const getTeamRef = () => ref(getDatabase(), teamPath);
    const getRoundRef = (id) => ref(getDatabase(), getRoundPath(id));
    const getCurrentRoundRef = () => ref(getDatabase(), currentRoundPath);
    const getCurrentPlayerRef = () => ref(getDatabase(), currentPlayerPath);

    /**
     * @param {ApiPlayerType["userId"]} id
     */
    const getPlayerRef = (id) => ref(getDatabase(), getPlayerPath(id));

    const getTutorialRef = (id) => ref(getDatabase(), getTutorialPath(id));
    const getManualLanguagePath = () => ref(getDatabase(), manualLanguagePath);
    const getLanguageRef = () => ref(getDatabase(), languagePath);
    const getPlayersRef = () => ref(getDatabase(), playersPath);
    const getDraggingRef = () => ref(getDatabase(), draggingPath);
    const getCombineHistoryRef = () => ref(getDatabase(), combineHistoryPath);
    const getSeenTutorialsRef = () => ref(getDatabase(), seenTutorialsPath);

    const getSeenDebriefingRef = () => ref(getDatabase(), seenDebriefingPath);
    const getReadyDebriefingRef = () => ref(getDatabase(), readyDebriefingPath);
    const getIndividualSeenDebriefingRef = () =>
      ref(getDatabase(), `${seenDebriefingPath}/${currentPlayer.userId}`);
    const getIndividualReadyDebriefingRef = () =>
      ref(getDatabase(), `${readyDebriefingPath}/${currentPlayer.userId}`);

    /**
     * @param {number} [index]
     */
    const getTeamConnectorHistoryRef = (index = undefined) =>
      ref(
        getDatabase(),
        `${teamPath}/teamConnectorHistory${
          typeof index === "number" ? `/${index}` : ""
        }`,
      );

    const getIndividualReflectionRef = () =>
      ref(getDatabase(), individualReflectionPath);
    const getPlayerFinalAnswerRef = (id) =>
      ref(getDatabase(), getPlayerFinalAnswerPath(id));
    const getPlayerNodeRef = (path) =>
      ref(getDatabase(), getPlayerNodePath(path));

    return {
      ..._fb,
      getPlayerPath,
      getRoundPath,
      getTutorialPath,
      getPlayerNodePath,
      getCombineHistoryPath,
      getPlayerFinalAnswerPath,
      getTeamRef,
      getRoundRef,
      getPlayerRef,
      getPlayersRef,
      getTutorialRef,
      getLanguageRef,
      getDraggingRef,
      getPlayerNodeRef,
      getCurrentRoundRef,
      getSeenTutorialsRef,
      getCurrentPlayerRef,
      getCombineHistoryRef,
      getManualLanguagePath,
      getPlayerFinalAnswerRef,
      getIndividualReflectionRef,
      finishedReflectionAtPath,
      teamPath,
      playersPath,
      languagePath,
      draggingPath,
      currentRoundPath,
      seenTutorialsPath,
      currentPlayerPath,
      combineHistoryPath,
      individualReflectionPath,
      myIndividualReflectionPath,
      getSeenDebriefingRef,
      getReadyDebriefingRef,
      getTeamConnectorHistoryRef,
      getIndividualSeenDebriefingRef,
      getIndividualReadyDebriefingRef,
    };
  })();

  return {
    gameData,
    gameProgress,
    userProgress,
    currentPlayer,
    currentPlayerRole,
    firebase,
    dragging,
    combineHistory,
    kickedSnapshot,
    language,
    temporal,
  };
};

export default useStore;
