import { appRoutes } from "@bd-constants/routes";
import useRequirementHelpers from "@bd-hooks/useRequirementHelpers";

/**
 * @returns {import("@bd-components/PagePermission/PagePermission").PagePermissionHookReturn}
 */
const usePermissionDebriefing = () => {
  const {
    hasLoggedIn,
    hasFinishedGame,
    isTeamConnectorEnabled,
    hasFinishedDebriefing,
  } = useRequirementHelpers();

  return {
    allowed:
      hasLoggedIn &&
      hasFinishedGame &&
      isTeamConnectorEnabled &&
      !hasFinishedDebriefing,
    redirect: !hasLoggedIn
      ? appRoutes.login
      : !hasFinishedGame
      ? appRoutes.finalQuestion
      : !isTeamConnectorEnabled || hasFinishedDebriefing
      ? appRoutes.reflection
      : null,
  };
};

export default usePermissionDebriefing;
