import React from "react";

import s from "./SeekAnswer.module.scss";
import { stylesCombinerFn } from "@bd-utils/stylesCombiner";

const SeekAnswer = ({ className, classNames, colors }) => {
  const c = stylesCombinerFn(s, classNames);

  return (
    <div className={c("seekAnswer", className)}>
      {colors.map((color) => (
        <div
          key={color}
          className={c("seekAnswer__item")}
          style={{ backgroundColor: color }}
        ></div>
      ))}
    </div>
  );
};

export default SeekAnswer;
