import axios from "axios";

/**
 * Set a round to be finished in the backend
 *
 * @param {StoreGameProgressType['teamId']} teamId
 * @param {ApiRoundType['id']} roundId
 */
const setRoundFinished = (teamId, roundId) =>
  axios
    .post("/game/set-round-finished", { team_id: teamId, round_id: roundId })
    .catch((err) => console.error("Can't update finished round", { err }));

export default setRoundFinished;
