import { useEffect, useRef, useState } from "react";
import { useMeasure } from "react-use";
import Konva from "konva";

import s from "./Wheel.module.scss";
import WheelItem from "./WheelItem";
import NameRoulette from "@bd-components/NameRoulette";
import WheelLever from "@bd-components/WheelLever";
import WheelArrow from "@bd-components/WheelArrow";
import WheelSpinner from "@bd-components/WheelSpinner";
import getNowEpoch from "@bd-utils/getNowEpoch";
import getPercentageOf from "@bd-utils/getPercentageOf";
import getValueOfPercentage from "@bd-utils/getValueOfPercentage";

const easeOutQuart = (x) => 1 - Math.pow(1 - x, 4);

/**
 * Return `rotate` & `totalAngle`
 *
 * `rotate` is a difference in a particular step against previous step
 * (it's not a cumulative rotation)
 *
 * `angle` is a cumulative value of whole rotation in a particular step
 *
 * @param {object} param0
 * @param {number} param0.time
 * @param {number} param0.duration
 * @param {number} param0.targetAngle
 * @param {number} param0.prevAngle
 */
const getRotate = ({ time, duration, targetAngle, prevAngle }) => {
  const progress = getPercentageOf(time, duration) / 100;
  const eased = easeOutQuart(progress);
  const totalAngle = getValueOfPercentage(eased * 100, targetAngle);
  const rotate = totalAngle - prevAngle;
  return { rotate, totalAngle };
};

/**
 * @type {import("./Wheel").WheelType}
 */
const Wheel = (props) => {
  // eslint-disable-next-line no-unused-vars
  const [refWrapper, { width }] = useMeasure();
  const {
    diameter = 500,
    duration,
    data,
    onSelect = () => {},
    textStyle,
    onClickSpin,
    value,
    shouldFinishSpinAt,
    isOpenQuestion,
    historyData,
    isLevel,
    leverForceRef,
  } = props;

  /**
   * @type {UtilRef<import("konva/lib/Layer").Layer>}
   */
  const refAnimateLayer = useRef();

  /**
   * @type {UtilRef<import("konva/lib/Group").Group>}
   */
  const refAnimateGroup = useRef();
  const refTarget = useRef(0);
  const refCounter = useRef(0);
  const refCurrentIndex = useRef(0);

  /**
   * @type {UtilRef<import("konva/lib/Animation").Animation>}
   */
  const refKonvaAnimation = useRef();

  /**
   * Overall diameter of a spinner, everything attached to this size, so if
   * you change this it will also maintain the ratio of all the item inside
   * the canvas
   */
  // eslint-disable-next-line no-unused-vars
  const [wheelDiameter, setWheelDiameter] = useState(diameter);
  const [isSpinning, setIsSpinning] = useState(false);
  const radius = diameter / 2;
  const totalItems = data.length;

  // const updateDiameter = () => {
  //   /**
  //    * configDiameter is the desired diameter passed from props, not from
  //    * the current state, if the desired diameter is too big then resize
  //    * canvas to maximum possible layout instead
  //    */
  //   const configDiameter = diameter;
  //   const wrapperSize = width;

  //   if (!wrapperSize) {
  //     const newDiameter = 500;
  //     setWheelDiameter(newDiameter);
  //   } else {
  //     const newDiameter = Math.min(configDiameter, wrapperSize);
  //     const shouldUpdate = newDiameter !== diameter;

  //     if (shouldUpdate) {
  //       setWheelDiameter(newDiameter);
  //     }
  //   }
  // };

  /**
   * @param {number} target selected data index
   */
  const spin = (target) => {
    if (!refAnimateLayer.current) return;
    if (!refAnimateGroup.current) return;

    setIsSpinning(true);

    const animateLayer = refAnimateLayer.current;
    const animateGroup = refAnimateGroup;

    const animationPromise = new Promise((resolve, reject) => {
      const rotationItem = 360 / data.length;
      const normalizedIndex = data.length - target + refCurrentIndex.current;

      /**
       * The amount of spin to hit the target (this will always less than 1
       * full spin)
       */
      const oneTargetHit = normalizedIndex * rotationItem;
      const totalSpin = 2;
      const targetAngle = 360 * totalSpin + oneTargetHit;

      refTarget.current = refTarget.current + targetAngle;
      refCounter.current = refCounter.current + 1;
      refCurrentIndex.current = target;

      let prevAngle = 0;
      const animation = new Konva.Animation(function (frame) {
        const { rotate, totalAngle } = getRotate({
          time: frame.time,
          duration,
          targetAngle,
          prevAngle,
        });

        prevAngle = totalAngle;

        /**
         * if time value have not reach or higher than the duration, then
         * continue to spin, otherwise times is up and stop spinning
         */
        if (frame.time < duration) {
          // console.log({ rotate127: rotate });
          if (animateGroup.current) animateGroup.current.rotate(rotate);
        } else {
          /**
           * Because we're animating based on FPS (Frame per-second), so it's
           * expected to not reach the full progress (100% out of 100%),
           * therefore we need to make to rotate into full progress after the
           * animation is completed
           *
           * Otherwise it will be accumulated into a bigger angle difference
           * resulting to inaccurate rotation
           */
          const completion = getRotate({
            time: duration,
            duration,
            targetAngle,
            prevAngle,
          });

          this.stop();

          if (animateGroup.current) {
            // console.log({ rotate149: completion.rotate });
            animateGroup.current.rotate(completion.rotate);
          }

          setIsSpinning(false);
          resolve(onSelect(target));
        }
      }, animateLayer);

      refKonvaAnimation.current = animation;

      animation.start();
    });

    return animationPromise;
  };

  const renderWheelItems = data.map((item, index) => (
    <WheelItem
      key={index}
      diameter={wheelDiameter}
      radius={radius}
      totalItems={totalItems}
      index={index + 1}
      data={item}
      textStyle={textStyle}
    />
  ));

  useEffect(() => {
    const shouldSpin = shouldFinishSpinAt - 0.2 >= getNowEpoch();
    if (!shouldSpin) return;
    if (typeof value !== "number") return;
    if (isSpinning) return;
    if (refKonvaAnimation.current) refKonvaAnimation.current.stop();
    spin(value);

    return () => {
      if (refKonvaAnimation.current) refKonvaAnimation.current.stop();
    };
    // eslint-disable-next-line
  }, [value, shouldFinishSpinAt]);

  // ---- commenting out this hook due to canvas bug with Konva.Wedge
  // useEffect(() => {
  //   updateDiameter();
  //   // eslint-disable-next-line
  // }, [width]);

  return (
    <>
      <div
        className={s.Wheel}
        ref={refWrapper}
        style={{ width: `${wheelDiameter}px` }}
      >
        <div className={s["Wheel__left-side"]}>
          <NameRoulette
            duration={duration}
            data={historyData}
            isSpinning={isSpinning}
          />
          <WheelArrow isSpinning={isSpinning} />
        </div>
        <WheelSpinner
          diameter={wheelDiameter}
          isSpinning={isSpinning}
          animateLayer={refAnimateLayer}
          radius={radius}
          animateGroup={refAnimateGroup}
          renderWheelItems={renderWheelItems}
        />
        <WheelLever
          className={isSpinning ? s.active : ""}
          onClick={onClickSpin}
          disabled={isSpinning}
          isSpinning={isSpinning}
          isLevel={isLevel}
          isOpenQuestion={isOpenQuestion}
          ref={leverForceRef}
        />
      </div>
    </>
  );
};

export default Wheel;
