import React, { Suspense } from "react";
import { Provider } from "react-redux";
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
  useRouteMatch,
} from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import axios from "axios";

import AppLoader from "@bd-components/AppLoader";
import ResScreen from "@bd-components/ResScreen";
import BackgroundMusic from "@bd-components/BackgroundMusic";
import useLanguage from "@bd-hooks/useLanguage";
import useResetCheat from "@bd-hooks/useResetCheat";
import useDerivedLoading from "@bd-hooks/useDerivedLoading";
import useInitializeGame from "@bd-hooks/useInitializeGame";
import useAxiosInterceptors from "@bd-hooks/useAxiosInterceptors";
import configureStore from "@bd-redux/_configure-store";
import API_URL from "@bd-utils/api-url";
import { appRoutes } from "@bd-constants/routes";
import Intro from "./pages/Intro";
// import Lobby from "./pages/Lobby";
// import Login from "./pages/Login";
// import Welcome from "./pages/Welcome";
import Landing from "./pages/Landing";
import Gameplay from "./pages/Gameplay";
import RoundIntroVideo from "./pages/RoundIntroVideo";
import ForceReset from "./pages/ForceReset";
import Reflection from "./pages/Reflection";
import WaitingRoom from "./pages/WaitingRoom";
import FinalQuestion from "./pages/FinalQuestion";
import ContinuousWelcome from "./pages/ContinuousWelcome";
import ResetGame from "./pages/ResetGame";
import ResetLevel from "./pages/ResetLevel";
import Debriefing from "./pages/Debriefing";

import "./styles/styles.scss";

const { persistor, store } = configureStore();

// Set base URL for the whole app
axios.defaults.baseURL = API_URL + "/wp-json/bd/v1";

const AppWithStore = () => {
  useAxiosInterceptors();
  useInitializeGame();
  useResetCheat();
  useLanguage();

  const isLandingPage = useRouteMatch(appRoutes.landing);
  const isLoginPage = useRouteMatch(appRoutes.login);
  const isWelcomePage = useRouteMatch(appRoutes.welcome);
  const isWaitingRoomPage = useRouteMatch(appRoutes.waitingRoom);
  const isSetupPage = isWelcomePage || isWaitingRoomPage;
  const isFirebaseReady = useDerivedLoading();
  const isLoading = isFirebaseReady && !isSetupPage;

  return (
    <>
      <Switch>
        <Route exact path={appRoutes.home} component={Gameplay} />
        {/* <Route exact path={appRoutes.login} component={Login} /> */}
        {/* <Route exact path={appRoutes.welcome} component={Welcome} /> */}
        <Route
          exact
          path={[appRoutes.welcome, appRoutes.login]}
          component={ContinuousWelcome}
        />
        <Route path={appRoutes.waitingRoom} component={WaitingRoom} />
        {/* <Route exact path={appRoutes.lobby} component={Lobby} /> */}
        <Route exact path={appRoutes.finalQuestion} component={FinalQuestion} />
        <Route exact path={appRoutes.levelIntro} component={RoundIntroVideo} />
        <Route exact path={appRoutes.gameIntro} component={Intro} />
        <Route exact path={appRoutes.reflection} component={Reflection} />
        <Route exact path={appRoutes.reset} component={ForceReset} />
        <Route exact path={appRoutes.landing} component={Landing} />
        <Route exact path={appRoutes.resetGame} component={ResetGame} />
        <Route exact path={appRoutes.resetLevel} component={ResetLevel} />
        <Route exact path={appRoutes.debriefing} component={Debriefing} />
        <Route path="*">
          <Redirect to="/" />
        </Route>
      </Switch>
      <AppLoader isLoading={isLoading} />
      <ResScreen />
      {!isLandingPage && !isLoginPage && !isWelcomePage && <BackgroundMusic />}
      {isFirebaseReady ? null : (
        <div data-testid="test_ready" style={{ display: "none" }} />
      )}
    </>
  );
};

function App() {
  return (
    <Suspense fallback={<AppLoader />}>
      <Provider store={store}>
        <PersistGate loading={<AppLoader />} persistor={persistor}>
          <BrowserRouter>
            <AppWithStore />
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </Suspense>
  );
}

export default App;

// TODO: remove this, this comment is for testing purposes only
