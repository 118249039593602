import React, { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Redirect, useLocation } from "react-router-dom";
import { useMeasure } from "react-use";

import s from "./FinalQuestion.module.scss";
import sd from "./FinalQuestionAnswered.module.scss";
import clsx from "clsx";
import WallBackground from "@bd-components/WallBackground";
import SuspectQuestion from "@bd-components/SuspectQuestion";
import ActionQuestion from "@bd-components/ActionQuestion";
import Button from "@bd-components/Button";
import Snackbar from "@bd-components/Snackbar";
import { ReactComponent as IconArrowRight } from "@bd-icons/icon-arrow-right.svg";
import useDerivedLoading from "@bd-hooks/useDerivedLoading";
import WaitingChecklist from "@bd-components/WaitingChecklist";
import Warning from "@bd-components/Warning";
import useFinalQuestionRequirement from "@bd-hooks/useFinalQuestionRequirement";
import useFinalQuestionState from "@bd-hooks/useFinalQuestionState";
import useActiveRoundProgress from "@bd-hooks/useActiveRoundProgress";
import useActiveRoundData from "@bd-hooks/useActiveRoundData";
import useStore from "@bd-hooks/useStore";
import setCurrentPage from "@bd-apis/setCurrentPage";
import getAllQueryString from "@bd-utils/getAllQueryString";
import getSentenceFromInput from "@bd-utils/getSentenceFromInput";
import parse from "html-react-parser";
import { ReactComponent as IconNotAllowed } from "@bd-icons/icon-not-allowed.svg";
import { ReactComponent as IconChevronLeft } from "@bd-icons/icon-chevron-left-solid.svg";
import { ReactComponent as IconChevronRight } from "@bd-icons/icon-chevron-right-solid.svg";
import useLeaveAndRejoinGameNotif from "@bd-hooks/useLeaveAndRejoinGameNotif";
import useRoundOutro from "@bd-hooks/useRoundOutro";
import useTimer from "@bd-hooks/useTimer";
import getNowEpoch from "@bd-utils/getNowEpoch";
import getFutureEpoch from "@bd-utils/getFutureEpoch";
import millisecondsToTimeObject from "@bd-utils/millisecondsToTimeObject";
import useSound from "use-sound";

const ReflectionTimer = () => {
  const duration = useMemo(
    () => (getFutureEpoch({ extension: 2 * 60 * 1000 }) - getNowEpoch()) * 1000,
    // eslint-disable-next-line
    [],
  );

  const remainingTime = useTimer({
    duration,
  });

  const time = millisecondsToTimeObject(remainingTime);

  return (
    <p className={s.paper__timer}>
      {time?.m}:{time?.s}
    </p>
  );
};

/**
 * @type {import("./FinalQuestion").FinalQuestionImageAnswerType}
 */
const ImageAnswer = ({
  isCorrect,
  isTalking,
  src,
  alt,
  tag,
  hidden,
  talkText,
}) => {
  return (
    <div
      className={clsx(
        sd["finalQuestionAnswered__image--container"],
        tag && isCorrect && sd["is-correct"],
        tag && isCorrect === false && sd["is-incorrect"],
        hidden && sd["is-hidden"],
        isTalking && sd["is-talking"],
      )}
    >
      <img
        className={sd.finalQuestionAnswered__image}
        src={src}
        alt={alt}
        title={alt}
      />
      {tag && (
        <span className={sd["finalQuestionAnswered__image--tag"]}>{tag}</span>
      )}
      {talkText && (
        <div className={sd["finalQuestionAnswered__image--speech"]}>
          <p>{talkText}</p>
        </div>
      )}
    </div>
  );
};

const FinalQuestionChecker = () => {
  const status = useFinalQuestionRequirement();
  if (!status.allowed) return <Redirect to={status.redirect} />;
  return <FinalQuestion />;
};

/**
 * @type {import("./FinalQuestion").FinalQuestionPaperType}
 */
const Paper = ({ children, isHidden, isFeedback }) => {
  const [ref, { width, height }] = useMeasure();

  return (
    <div
      className={clsx(
        s.paper,
        isFeedback && s["is-feedback"],
        isHidden && s["is-hidden"],
      )}
    >
      <div className={s.paper__heightRuler}>
        <picture>
          <source
            srcSet="/images/final-question-paper.webp"
            type="image/webp"
          />
          <source srcSet="/images/final-question-paper.png" type="image/png" />
          <img
            ref={ref}
            className={s.paper__img}
            src="/images/final-question-paper.png"
            alt="Discussion paper background"
          />
        </picture>
        <div
          className={s.paper__ruler}
          style={{
            width: `${width}px`,
            height: `${height}px`,
          }}
        >
          <div className={s.paper__inner}>
            <ReflectionTimer />
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

const FinalQuestion = () => {
  const { t } = useTranslation();
  const { gameData, gameProgress, temporal, currentPlayer } = useStore();
  const { game_audio } = gameData.config;
  const round = useActiveRoundData();
  const currentRoundProgress = useActiveRoundProgress();
  const isFirebaseReady = useDerivedLoading();
  const state = useFinalQuestionState();
  const answeredState = useRoundOutro();
  const roundState = answeredState.response?.answerFeedbacks;
  const generalState = answeredState.response?.generalFeedback.feedback;
  const [isTalking, setIsTalking] = useState(false);
  const [isHidden, setIsHidden] = useState(false);
  const [watsonOpen, setWatsonOpen] = useState(false);
  const [feedbackStep, setFeedbackStep] = useState(1);
  const [feedbackStepType, setFeedbackStepType] = useState("image");
  const [isFeedbackAtEnd, setIsFeedbackAtEnd] = useState(false);
  const [feedbackIndex, setFeedbackIndex] = useState(0);
  const [feedbackArray, setFeedbackArray] = useState([]);

  const [userAnswerFeedbackTextIndex, setUserAnswerFeedbackTextIndex] =
    useState(0);
  const [userAnswerFeedbackTextArray, setUserAnswerFeedbackTextArray] =
    useState([]);
  const [userAnswerFeedbackText, setUserAnswerFeedbackText] = useState("");
  const leaveAndRejoinGameNotif = useLeaveAndRejoinGameNotif();

  const selectSuspect = useSound(game_audio?.button_suspect_selected, {
    volume: 0.5,
  });
  const correctSuspect = useSound(game_audio?.suspect_accused_correct, {
    volume: 0.5,
  });
  const incorrectSuspect = useSound(game_audio?.suspect_accused_wrong, {
    volume: 0.5,
  });

  // Just to make it short, no other reason, but should be noted this could be
  // undefined as well
  const x = currentRoundProgress.playersFinalAnswer;

  /**
   * @type {Array<{questionId: string; answerId: string}>}
   */
  const myAnswers = x
    ? Object.keys(x).map((questionId) => ({
        questionId: questionId,
        answerId: x[questionId][currentPlayer.userId],
      }))
    : [];

  const location = useLocation();
  const keys = Object.keys(getAllQueryString(location.search));
  const isTesting = keys.includes("test-y0u-d0nt-kn0w");

  const handleFeedbackSteps = () => {
    setIsHidden(true);
    const unhide = () => {
      setIsTalking(false);

      setTimeout(() => {
        setIsHidden(false);
        setTimeout(() => {
          setIsTalking(true);
        }, 650);
      }, 100);
    };

    const nextFeedbackIndex = feedbackIndex + 1;
    setFeedbackIndex(nextFeedbackIndex);
    setFeedbackStep(nextFeedbackIndex + 1);
    setFeedbackStepType(feedbackArray?.[nextFeedbackIndex].type);

    unhide();
  };

  const handleSingleFeedbackStep = (step, type) => {
    setFeedbackStep(step);
    setFeedbackStepType(type);
  };

  const handleLeftFeedbackMotion = () => {
    setFeedbackIndex(feedbackIndex - 1);
  };

  const handleRightFeedbackMotion = () => {
    setFeedbackIndex(feedbackIndex + 1);
  };

  const setActiveSourceFeedback = (index) => {
    setFeedbackIndex(index);
  };

  const setUserFeedbackNextText = () => {
    const nextIndex = userAnswerFeedbackTextIndex + 1;
    if (userAnswerFeedbackTextArray[nextIndex]) {
      setUserAnswerFeedbackTextIndex(nextIndex);
    } else {
      setIsTalking(true);
    }
  };

  useEffect(() => {
    if (!userAnswerFeedbackTextArray.length) {
      return;
    }

    setUserAnswerFeedbackText(
      userAnswerFeedbackTextArray[userAnswerFeedbackTextIndex],
    );
  }, [userAnswerFeedbackTextArray, userAnswerFeedbackTextIndex]);

  useEffect(() => {
    if (isFeedbackAtEnd) {
      handleSingleFeedbackStep(
        feedbackArray[feedbackIndex].step,
        feedbackArray[feedbackIndex].type,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feedbackIndex]);

  useEffect(() => {
    if (feedbackIndex === feedbackArray.length - 1) {
      setIsFeedbackAtEnd(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feedbackArray, feedbackIndex]);

  useEffect(() => {
    if (!answeredState.hasAnsweredFinalQuestion) {
      return;
    }

    let feedback = [];
    const first = answeredState.allFeedback.find((f) => {
      return f.id === roundState?.[0]?.answerId;
    });
    feedback.push(first);
    setUserAnswerFeedbackTextArray(
      getSentenceFromInput("", first.feedback.text ?? ""),
    );

    if (roundState?.[0]?.is_correct) {
      correctSuspect[0]();
    } else {
      incorrectSuspect[0]();
    }

    let second = null;
    if (!roundState?.[0]?.is_correct) {
      second = answeredState.allFeedback.find((f) => {
        return f.is_correct;
      });
      feedback.push(second);
    }

    let rest = answeredState.allFeedback.filter((f) => {
      return f.id !== first?.id && f.id !== second?.id;
    });

    rest = rest.filter((f) => f.feedback?.bias_unchosen_answer);

    feedback = [...feedback, ...rest];
    const feedbackImage = feedback?.map((f, i) => {
      return {
        step: i + 1,
        type: "image",
        correct: f.is_correct,
        imageSrc: f.image,
        imageAlt: f.name,
        tag: f.is_correct ? t("Perpetrator") : t("Wrong Person"),
        talkText:
          f.id === roundState?.[0]?.answerId
            ? f.feedback?.bias
            : f.feedback?.bias_unchosen_answer,
        index: i,
        feedback: f.feedback,
      };
    });

    let feedbackPaper = [];
    if (generalState.length) {
      for (let i = 0; i < generalState.length; i++) {
        feedbackPaper.push({
          step: feedbackImage.length + (i + 1),
          type: "notebook",
          text: generalState[i]?.text,
          index: feedbackImage.length + i,
          imageSrc: generalState[i]?.icon,
          imageAlt: t("General Feedback"),
        });
      }
    }

    const allFeedback = [...feedbackImage, ...feedbackPaper];
    setFeedbackArray(allFeedback);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answeredState.hasAnsweredFinalQuestion]);

  useEffect(() => {
    if (!temporal.isInterceptorReady) return;
    setCurrentPage(
      `Final Question - Round ${round.index}`,
      gameProgress.teamId,
    );
  }, [round.index, gameProgress.teamId, temporal.isInterceptorReady]);

  useEffect(() => {
    if (!isFirebaseReady && answeredState.hasAnsweredFinalQuestion) {
      setTimeout(() => {
        setTimeout(() => {
          setWatsonOpen(true);
        }, 650);
      }, 650);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFirebaseReady, roundState]);

  return (
    <WallBackground>
      <picture className={s["lamp"]}>
        <source srcSet="/images/lamp.webp" type="image/webp" />
        <source srcSet="/images/lamp.png" type="image/png" />
        <img src="/images/lamp.png" alt="Lamp" />
      </picture>
      {!answeredState.hasAnsweredFinalQuestion ? (
        <div className={s.finalQuestion}>
          <div className={s.finalQuestion__wrapper}>
            <div className={s.finalQuestion__paper}>
              <Paper>
                {round.final_question.questions.map((question, index) =>
                  question.type === "source_question" ? (
                    <SuspectQuestion
                      key={question.id}
                      index={index}
                      questionId={question.id}
                      value={state.answers[question.id]}
                      options={question.sources}
                      onChange={(val) => {
                        selectSuspect[0]();
                        state.onAnswerChange(question.id, val.id);
                      }}
                      title={question.source_question}
                      subtitle={question.source_question_description}
                      isLoading={state.isSubmiting}
                    />
                  ) : (
                    <ActionQuestion
                      key={question.id}
                      questionId={question.id}
                      value={state.answers[question.id]}
                      options={question.actions}
                      onChange={(val) =>
                        state.onAnswerChange(question.id, val.id)
                      }
                      title={question.action_question}
                      isLoading={state.isSubmiting}
                    />
                  ),
                )}
                {state.hasAgreedAnswers ? (
                  <Button
                    testId="finalQuestion_submit"
                    className={s.finalQuestion__btn}
                    text={t("Submit")}
                    size="jumbo"
                    onClick={state.onSubmitAnswer}
                    isLoading={state.isSubmiting}
                  />
                ) : (
                  <div className={s.finalQuestion__guide}>
                    <IconNotAllowed className={s.finalQuestion__guide__icon} />
                    {state.isPlayerAnswered ? (
                      <span>{t("Select the same answers to continue")}</span>
                    ) : (
                      <span>{t("Answer the question to continue")}</span>
                    )}
                  </div>
                )}
              </Paper>
            </div>
          </div>

          <Snackbar
            isUsingPortal
            title={state.snackbar.title}
            message={state.snackbar.message}
            status={state.snackbar.status}
            onClose={state.snackbar.close}
            isOpen={state.snackbar.isOpen}
          />
        </div>
      ) : (
        <div className={sd.finalQuestionAnswered}>
          <div className={sd.finalQuestionAnswered__wrapper}>
            <div className={sd.finalQuestionAnswered__container}>
              {/* eslint-disable-next-line array-callback-return */}
              {feedbackArray.map((block, i) => {
                if (
                  /* block.step === feedbackStep */ block.index ===
                    feedbackIndex &&
                  block.type === feedbackStepType
                ) {
                  return feedbackStepType === "image" ? (
                    <ImageAnswer
                      isCorrect={block.correct}
                      isTalking={isTalking}
                      src={block.imageSrc}
                      alt={block.imageAlt}
                      tag={
                        feedbackIndex === 0 ||
                        (feedbackIndex === 1 && !roundState?.[0]?.is_correct)
                          ? block.tag
                          : ""
                      }
                      hidden={isHidden}
                      talkText={block.talkText}
                      key={i}
                    />
                  ) : (
                    <Paper isFeedback={true} isHidden={isHidden} key={i}>
                      {parse(block.text)}
                    </Paper>
                  );
                }
              })}

              {feedbackStepType === "image" &&
                !isFirebaseReady &&
                watsonOpen &&
                !isFeedbackAtEnd && (
                  <Warning
                    warningClass="root"
                    warningStandardText={userAnswerFeedbackText}
                    placement="left"
                    onClick={() => setUserFeedbackNextText()}
                    closeOnClick={
                      userAnswerFeedbackTextIndex ===
                      userAnswerFeedbackTextArray.length - 1
                    }
                  />
                )}
            </div>
            {isFeedbackAtEnd && (
              <>
                <ul className={sd.finalQuestionAnswered__list}>
                  {feedbackArray.map((block, i) => (
                    <li key={block.index}>
                      <button
                        disabled={
                          !isFeedbackAtEnd ||
                          (feedbackStep === block.step &&
                            feedbackStepType === block.type)
                        }
                        onClick={() => setActiveSourceFeedback(i)}
                        style={{ cursor: "pointer" }}
                      >
                        <img src={block.imageSrc} alt={block.imageAlt} />
                      </button>
                    </li>
                  ))}
                </ul>
                <div className={sd.finalQuestionAnswered__arrows}>
                  <button
                    className={sd.left}
                    onClick={handleLeftFeedbackMotion}
                    disabled={feedbackIndex === 0}
                  >
                    <IconChevronLeft />
                  </button>
                  <button
                    className={sd.right}
                    onClick={handleRightFeedbackMotion}
                    disabled={feedbackIndex === feedbackArray.length - 1}
                  >
                    <IconChevronRight />
                  </button>
                </div>
              </>
            )}
            <Button
              className={sd.finalQuestionAnswered__continue}
              text={t("Continue")}
              onClick={handleFeedbackSteps}
              size="normal"
              iconEnd={IconArrowRight}
              disabled={isFeedbackAtEnd || !isTalking || isHidden}
            />
            <Button
              className={sd.finalQuestionAnswered__finish}
              text={t("Continue")}
              onClick={() =>
                answeredState.closeItem(answeredState.displayStates.length - 1)
              }
              size="normal"
              iconEnd={IconArrowRight}
              disabled={!isFeedbackAtEnd}
            />
          </div>
        </div>
      )}

      {/* <RoundOutro /> */}

      <WaitingChecklist
        readyUserIds={answeredState.readyPlayerIds}
        isOpen={answeredState.isWaiting}
      />

      {/* Render all current player answer for testing purpose, so we can make 
      sure those answer option has been saved into firebase */}
      {isTesting ? (
        <div className="onlytest">
          {myAnswers.map((a) => {
            const id = `finalQuestion_question-${a.questionId}_answer-${a.answerId}_succeed`;
            return <div key={id} data-testid={id} />;
          })}
        </div>
      ) : null}

      <Snackbar
        isUsingPortal
        title={leaveAndRejoinGameNotif.snackbar.title}
        message={leaveAndRejoinGameNotif.snackbar.message}
        status={leaveAndRejoinGameNotif.snackbar.status}
        onClose={leaveAndRejoinGameNotif.snackbar.close}
        isOpen={leaveAndRejoinGameNotif.snackbar.isOpen}
      />
    </WallBackground>
  );
};

export default FinalQuestionChecker;
