/**
 * Logic to convert object of objects to array of objects
 */
const convertObjToArr = (arr) => {
  return Object.entries(arr).map(([id, obj]) => ({
    id,
    ...obj,
  }));
};

export default convertObjToArr;
