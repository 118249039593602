import i18n from "i18next";
import LoadFromBackend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import API_URL from "@bd-utils/api-url";

i18n
  .use(LoadFromBackend)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    backend: {
      loadPath: `${API_URL}/wp-json/bd/v1/translations/{{lng}}`,
      // Parse the response.body and prepare it to be use in the translations
      parse: (data) => {
        data = JSON.parse(data);
        return data.data;
      },
    },
    fallbackLng: "en",
    nsSeparator: false,
    keySeparator: false,
    interpolation: {
      // React already handle string escaping
      escapeValue: false,
    },
  });

export default i18n;
