/**
 * @param {ApiSourceType[]} sources
 */
const getPossibleCombinations = (sources) => {
  const combinationsPerKey = sources.map((a) =>
    sources.filter((b) => b.id !== a.id).map((b) => `${a.id}-${b.id}`),
  );
  const possibleCombinations = combinationsPerKey.flat();
  return possibleCombinations;
};

export default getPossibleCombinations;
