import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import TimerVisual from "./TimerVisual";

import { useTranslation } from "react-i18next";

import s from "./Timer.module.scss";
import useTimer from "@bd-hooks/useTimer";
import millisecondsToTimeObject from "@bd-utils/millisecondsToTimeObject";
import Tooltip from "@bd-components/Tooltip";

const Timer = ({ duration, warningThreshold = duration * 0.15, onTimeout }) => {
  const { t } = useTranslation();
  const remaining = useTimer({ duration, onTimeout });
  const time = millisecondsToTimeObject(remaining);
  return (
    <>
      <div className={s.timer}>
        <TimerVisual
          isWarning={remaining < warningThreshold && remaining !== 0}
        />
        <Tooltip
          top="10%"
          left="100%"
          text={t("Time's almost up!")}
          hoverDirection="right"
        />
        <div
          className={clsx(s.timer__value, {
            [s["is-warning-sign"]]:
              remaining < warningThreshold && remaining !== 0,
          })}
        >
          <span>{time.m}</span>
          <span>{time.s}</span>
        </div>
      </div>
    </>
  );
};

Timer.propTypes = {
  duration: PropTypes.number,
  warningThreshold: PropTypes.number,
  onTimeout: PropTypes.func.isRequired,
};

export default Timer;
