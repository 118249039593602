import { combineReducers } from "redux";
import gameData from "./game-data";
import gameProgress from "./game-progress";
import user from "./user";
import firebase from "./firebase";
import dragging from "./dragging";
import combineHistory from "./combine-history";
import kickedSnapshot from "./kicked-snapshot";
import language from "./language";
import temporal from "./temporal";

export default combineReducers({
  gameData: gameData,
  gameProgress: gameProgress,
  user: user,
  firebase: firebase,
  dragging: dragging,
  combineHistory: combineHistory,
  kickedSnapshot: kickedSnapshot,
  language: language,
  temporal: temporal,
});
