import {
  update,
  remove,
  set,
  get,
  runTransaction,
  getDatabase,
  ref,
} from "firebase/database";
import { deleteApp, getApp, getApps } from "firebase/app";
import getNowEpoch from "@bd-utils/getNowEpoch";
import setRoundStarted from "@bd-apis/setRoundStarted";
import useStore from "./useStore";
import indexedObjectToArray from "@bd-utils/indexedObjectToArray";

const useFirebaseActions = () => {
  const { firebase, gameProgress, currentPlayer, gameData } = useStore();
  const {
    getTeamRef,
    getRoundRef,
    getPlayerRef,
    getPlayersRef,
    getTutorialRef,
    getPlayerNodeRef,
    getCurrentRoundRef,
    getCurrentPlayerRef,
    getPlayerFinalAnswerRef,
    getCombineHistoryRef,
    getTeamConnectorHistoryRef,
    getIndividualSeenDebriefingRef,
    getIndividualReadyDebriefingRef,
  } = firebase;

  /**
   * @param {LangType['value']} language
   * @param {string} teamCode
   */
  const setLanguage = (language, teamCode) =>
    update(ref(getDatabase(), teamCode), { language });

  /**
   * This will only be called on the first player that is logged in into the
   * team
   *
   * @param {LangType['value']} language
   * @param {string} teamCode
   */
  const setInitLanguage = (language, teamCode) =>
    update(ref(getDatabase(), teamCode), {
      language,
      hasChosenLanguageManually: false,
    });

  /**
   * @param {ApiPlayerType['userId']} userId
   * @param {string} teamCode
   * @param {ApiPlayerType['roleId']} [roleId]
   */
  const registerUser = (userId, teamCode, roleId = false) =>
    update(ref(getDatabase(), `${teamCode}/players/${userId}`), {
      userId,
      name: "",
      roleId,
      ready: false,
      currentTutorialIndex: 0,
      hasSeenFirstClue: false,
      // hasCompletedMantapPreGame: gameProgress.disabledMantap,
    });

  /**
   * @param {ApiPlayerType['name']} name
   */
  const updateName = (name) =>
    update(getCurrentPlayerRef(), {
      name,
      ready: true,
    });

  /**
   * @param {boolean} ready
   */
  const updateReadiness = (ready) => update(getCurrentPlayerRef(), { ready });

  /**
   * @param {ApiPlayerType["userId"]} id
   */
  const removePlayer = (id) => remove(getPlayerRef(id));

  /**
   * Update `startedAt` value, the one that indicate user has started and move
   * from lobby / waiting room
   *
   * @param {number} startedAt
   */
  const updateStartedAt = (startedAt) => update(getTeamRef(), { startedAt });

  /**
   * @param {ApiRoleType['id'] | false} roleId
   */
  const selectRole = (roleId) => update(getCurrentPlayerRef(), { roleId });

  /**
   * Update `startedCountdownAt` value, which indicates the countdown in the lobby
   * has commenced
   *
   * @param {number} startedCountdownAt
   */
  const updateStartedCountdownAt = (startedCountdownAt) =>
    update(getTeamRef(), { startedCountdownAt });

  const setStartedEntireGame = () => {
    const startedGameAt = getNowEpoch();
    return update(getTeamRef(), { startedGameAt });
  };

  const getTeam = () => get(getTeamRef());
  const getPlayers = () => get(getPlayersRef());

  const setSeenIntro = () =>
    update(getCurrentPlayerRef(), { hasSeenIntro: true });

  const setSeenRoundIntro = () =>
    set(getPlayerNodeRef("playersSeenRoundIntro"), true);

  const setSeenRoundIntroVideo = () =>
    set(getPlayerNodeRef("playersSeenRoundIntroVideo"), true);

  /**
   * @param {ApiTutorialType['id']} id last tutorial id that has been set to true
   */
  const goPrevTutorial = (id) => remove(getTutorialRef(id));

  /**
   * @param {ApiTutorialType['id']} id current active tutorial id
   */
  const goNextTutorial = (id) => set(getTutorialRef(id), true);

  /**
   * @param {ApiRoundType['id']} id
   * @param {ApiRoundType['timer']} timer
   */
  const updateRoundTimestamp = (id, timer) => {
    const startedAt = parseInt(`${new Date().getTime() / 1000}`);
    const shouldFinishAt = startedAt + timer;

    return update(getRoundRef(id), { startedAt, shouldFinishAt }).catch(
      (err) => {
        if (err.code === "PERMISSION_DENIED") {
          console.error("Denied, `shouldFinishAt` has been set");
          return;
        }

        console.error("error to update `shouldFinishAt` in the firebase", {
          err,
          code: err.code,
        });
      },
    );
  };

  /**
   * @param {number} index
   */
  const setPlayersSeenOutro = (index) =>
    set(getPlayerNodeRef(`playersSeenOutro/${index}`), true);

  const setFinishedOutro = () => {
    const finishedOutroAt = getNowEpoch();
    return update(getCurrentRoundRef(), { finishedOutroAt });
  };

  const setReadyDebriefing = (ready = true) =>
    set(getIndividualReadyDebriefingRef(), ready || null);

  /**
   * TODO: shouldFinishAt
   * @param {object} param0
   * @param {object} param0.index
   * @param {number} param0.categoryId
   * @param {number} param0.questionId
   * @param {number} param0.userId
   * @param {number} param0.spinDuration in seconds
   * @param {number} param0.questionDuration in seconds
   */
  const addTeamConnectorHistory = ({
    index,
    categoryId,
    questionId,
    userId,
    spinDuration,
    questionDuration,
  }) => {
    const now = getNowEpoch();
    const shouldFinishSpinAt = now + spinDuration;
    const shouldFinishQuestionAt = shouldFinishSpinAt + questionDuration;

    return set(getTeamConnectorHistoryRef(index), {
      categoryId,
      questionId,
      userId,
      spinDuration,
      questionDuration,
      shouldFinishSpinAt,
      shouldFinishQuestionAt,
    });
  };

  /**
   * @param {number} index
   */
  const forceFinishTeamConnectorHistory = (index) =>
    update(getTeamConnectorHistoryRef(index), {
      forceFinished: true,
    });

  /**
   * @param {true | null} [hasSeenDebriefing]
   */
  const setSeenDebriefing = (hasSeenDebriefing = true) =>
    set(getIndividualSeenDebriefingRef(), hasSeenDebriefing);

  const forceFinishRound = () =>
    update(getCurrentRoundRef(), { forceFinishRound: true });

  const setFinishedEntireGame = () => {
    const finishedGameAt = getNowEpoch();
    return update(getTeamRef(), { finishedGameAt });
  };

  /**
   * @param {ApiRoundType} round
   */
  const setNewRound = (round) => {
    const shouldSetTimestamp = !round.level_intro && !round.level_intro_video;
    const start = getNowEpoch();
    const startedAt = shouldSetTimestamp ? start : null;
    const shouldFinishAt = startedAt ? startedAt + round.timer : null;

    return set(getRoundRef(round.id), {
      roundId: round.id,
      startedAt,
      shouldFinishAt,
      combinationCounter: round.combination_counter,
    });
  };

  /**
   * @param {ApiPlayerType['userId']} userId
   */
  const startDragging = (userId) =>
    update(getTeamRef(), { dragging: userId }).catch((err) => {
      if (err.code === "PERMISSION_DENIED") {
        console.error("there's other player dragging the board");
      } else {
        console.error("startDragging", { err, code: err.code });
      }

      return Promise.reject(err);
    });

  const stopDragging = () =>
    update(getTeamRef(), { dragging: false }).catch((err) => {
      console.error("stopDragging error", { err });
      return Promise.reject(err);
    });

  /**
   * @param {LangType['value']} language
   */
  const changeLanguage = (language) =>
    update(getTeamRef(), { language, hasChosenLanguageManually: true });

  const hidePreIntuition = () =>
    set(getPlayerNodeRef("playersSeenPreIntuition"), true);

  /**
   * @param {FinalQuestionItemType['id']} id question id
   * @param {FinalActionQuestionType['actions'][0]['id'] | FinalSourceQuestionType['sources'][0]['id']} value
   */
  const changePlayerFinalAnswer = (id, value) =>
    set(getPlayerFinalAnswerRef(id), value);

  /**
   * TODO: document proper `finalAnswer` type from global.d.ts
   *
   * @param {{[key: string]: string}} finalAnswer
   */
  const submitFinalAnswer = (finalAnswer) =>
    update(getCurrentRoundRef(), { finalAnswer });

  const destroy = () => {
    if (!getApps().length) return;
    deleteApp(getApp()).catch((err) =>
      console.error("Error deleting firebase app", err),
    );
  };

  /**
   * @param {CombineSucceededArgs} e
   * @param {ApiRoundType} round
   */
  const addCombination = (e, round) => {
    runTransaction(
      getTeamRef(),
      (
        /** @type {FirebaseTeamType} */
        teamData,
      ) => {
        teamData = decreaseCombinationCounter(teamData, round.id);
        teamData = addNewClue(teamData, round.id, e);
        teamData = addCombineHistory(teamData, e, currentPlayer);

        // Update `shouldFinishAt` and `startedAt`
        if (!teamData.rounds[round.id].shouldFinishAt) {
          const duration = round.timer;
          const startedAt = getNowEpoch();
          const shouldFinishAt = startedAt + duration;
          teamData = updateTimestamp(
            teamData,
            round.id,
            startedAt,
            shouldFinishAt,
          );
          setRoundStarted(gameProgress.teamId, round.id);
        }

        return teamData;
      },
    );
  };

  /**
   * @param {number} tutorialIndex
   */
  const setCurrentTutorialIndex = (tutorialIndex) =>
    update(getCurrentPlayerRef(), { currentTutorialIndex: tutorialIndex });

  const clearCombinations = () => {
    runTransaction(
      getTeamRef(),
      (
        /** @type {FirebaseTeamType} */
        teamData,
      ) => {
        teamData = clearCombineHistory(teamData);

        return teamData;
      },
    );
  };

  /**
   * @param {boolean} hasSeenFirstClue
   */
  const setHasSeenFirstClue = (hasSeen) =>
    update(getCurrentPlayerRef(), { hasSeenFirstClue: hasSeen });

  /**
   * @param {boolean} hasCompletedMantapPreGame
   */
  const setHasCompletedMantapPreGame = (hasCompleted) => {
    update(getCurrentPlayerRef(), { hasCompletedMantapPreGame: hasCompleted });
  };

  /**
   * @param {boolean} hasCompletedMantapPreGame
   */
  const setInitHasCompletedMantapPreGame = (teamCode, userId, hasCompleted) => {
    update(ref(getDatabase(), `${teamCode}/players/${userId}`), {
      hasCompletedMantapPreGame: hasCompleted,
    });
  };

  /**
   * @param {boolean} onlineState
   */
  const setPlayerOnlineState = (onlineState) => {
    update(getCurrentPlayerRef(), {
      onlineState,
    });
  };

  const updateRejoinUser = async (userId, teamCode) => {
    const gameSnapshot = await get(ref(getDatabase(), `${teamCode}`));
    const gameData = gameSnapshot.val();
    const rounds = gameData.rounds;
    let currentRound = null;
    if (rounds) {
      const roundsArr = indexedObjectToArray(rounds);
      currentRound = roundsArr[roundsArr.length - 1];
    }

    update(ref(getDatabase(), `${teamCode}/players/${userId}`), {
      // ready: true,
      currentTutorialIndex: gameData.startedGameAt ? 4 : 0,
      hasSeenFirstClue: gameData.startedGameAt ? true : false,
      hasSeenIntro: true,
      isRejoinUser: true,
    });

    if (currentRound && currentRound.startedAt) {
      set(
        ref(
          getDatabase(),
          `${teamCode}/rounds/${currentRound.roundId}/playersSeenRoundIntro/${userId}`,
        ),
        true,
      );
      set(
        ref(
          getDatabase(),
          `${teamCode}/rounds/${currentRound.roundId}/playersSeenRoundIntroVideo/${userId}`,
        ),
        true,
      );
    }
  };

  const resetLevel = () => {
    const { roundProgress } = gameProgress;
    if (!roundProgress.roundId) {
      return;
    }

    const currentRoundRef = getCurrentRoundRef();

    const { rounds } = gameData;
    const round = Object.values(rounds).find(
      (r) => r.id === roundProgress.roundId,
    );

    if (!round) {
      return;
    }

    set(currentRoundRef, {
      combinationCounter: round.combination_counter,
      roundId: roundProgress.roundId,
    });

    set(getCombineHistoryRef(), {});
  };

  return {
    destroy,
    getTeam,
    getPlayers,
    selectRole,
    updateName,
    setLanguage,
    setNewRound,
    removePlayer,
    registerUser,
    stopDragging,
    setSeenIntro,
    startDragging,
    goPrevTutorial,
    goNextTutorial,
    changeLanguage,
    addCombination,
    setInitLanguage,
    updateStartedAt,
    updateStartedCountdownAt,
    updateReadiness,
    hidePreIntuition,
    setFinishedOutro,
    forceFinishRound,
    setSeenRoundIntro,
    submitFinalAnswer,
    setPlayersSeenOutro,
    setStartedEntireGame,
    updateRoundTimestamp,
    setFinishedEntireGame,
    setSeenRoundIntroVideo,
    changePlayerFinalAnswer,
    setCurrentTutorialIndex,
    clearCombinations,
    setHasSeenFirstClue,
    setHasCompletedMantapPreGame,
    setInitHasCompletedMantapPreGame,
    setPlayerOnlineState,
    updateRejoinUser,
    resetLevel,
    setReadyDebriefing,
    addTeamConnectorHistory,
    forceFinishTeamConnectorHistory,
    setSeenDebriefing,
  };
};

/**
 * @param {FirebaseTeamType} team
 * @param {StoreRoundProgressType["roundId"]} roundId
 */
const decreaseCombinationCounter = (team, roundId) => {
  if (!team.rounds || !team.rounds[roundId]) return team;
  if (!team.rounds[roundId].combinationCounter) return team;
  team.rounds[roundId].combinationCounter--;
  return team;
};

/**
 * @param {FirebaseTeamType} team
 * @param {StoreRoundProgressType["roundId"]} roundId
 * @param {StoreRoundProgressType["shouldFinishAt"]} startedAt
 * @param {StoreRoundProgressType["shouldFinishAt"]} shouldFinishAt
 */
const updateTimestamp = (team, roundId, startedAt, shouldFinishAt) => {
  if (!team.rounds || !team.rounds[roundId]) return team;
  team.rounds[roundId].startedAt = startedAt;
  team.rounds[roundId].shouldFinishAt = shouldFinishAt;
  return team;
};

/**
 * @param {FirebaseTeamType} team
 * @param {StoreRoundProgressType["roundId"]} roundId
 * @param {CombineSucceededArgs} clueEvent
 */
const addNewClue = (team, roundId, clueEvent) => {
  const isArray = Array.isArray;
  if (clueEvent.status === "not_found_new_clue") return team;
  if (!team.rounds || !team.rounds[roundId]) return team;

  if (!isArray(team.rounds[roundId].clues)) team.rounds[roundId].clues = [];
  team.rounds[roundId].clues.unshift({
    ...clueEvent.clue,
    from: clueEvent.from,
    to: clueEvent.to,
  });

  return team;
};

/**
 * @param {FirebaseTeamType} team
 * @param {CombineSucceededArgs} clueEvent
 * @param {ApiPlayerType} player
 */
const addCombineHistory = (team, clueEvent, player) => {
  const newId = `${clueEvent.from}-${clueEvent.to}`;
  const newHistory = {
    from: parseInt(clueEvent.from),
    to: parseInt(clueEvent.to),
    timestamp: getNowEpoch(),
    player: player,
  };

  const isObject = typeof team.combineHistory === "object";
  if (!team.combineHistory || !isObject) team.combineHistory = {};
  team.combineHistory[newId] = newHistory;

  return team;
};

/**
 * @param {FirebaseTeamType} team
 */
const clearCombineHistory = (team) => {
  team.combineHistory = {};

  return team;
};

export default useFirebaseActions;
