// import { useDispatch } from "react-redux";
// import { reset } from "@bd-redux/combine-history";
import setRoundStarted from "@bd-apis/setRoundStarted";
import useStore from "./useStore";
import usePopup from "./usePopup";
import useActiveRoundData from "./useActiveRoundData";
import useFirebaseActions from "./useFirebaseActions";

const useRoundIntroState = () => {
  const { updateRoundTimestamp, setSeenRoundIntro /*, clearCombinations */ } =
    useFirebaseActions();
  const { gameProgress, currentPlayer, userProgress } = useStore();
  const { playersSeenRoundIntro: seenIntro } = gameProgress.roundProgress;
  // const dispatch = useDispatch();
  const hasSeenRoundIntro = seenIntro.includes(currentPlayer.userId);
  const popupState = usePopup(!hasSeenRoundIntro);
  const round = useActiveRoundData();

  const close = () => {
    const isFirstRound =
      round.index === 1 && (userProgress.currentTutorialIndex ?? 0) < 4;

    popupState.close();
    if (!isFirstRound) {
      updateRoundTimestamp(round.id, round.timer);
      setRoundStarted(gameProgress.teamId, round.id);
    }

    /**
     * Why do we clear the combineHistory slice AND clear the Firebase combineHistory data?
     *
     * If we only clear the Firebase data, the state does not get updated until after our next combination.
     * To avoid this issue, we simply reset our state here.
     *
     * Prast : it's buggy here : clear combination histories on close intro? how if there are already combination then other players click close intro popup?
     */
    if (!userProgress.isRejoinUser) {
      // dispatch(reset());
      // clearCombinations();
    }

    setSeenRoundIntro();
  };

  return { ...popupState, close };
};

export default useRoundIntroState;
