import React from "react";
import clsx from "clsx";

import s from "./AnnouncerPopup.module.scss";
import Popup from "@bd-components/Popup";
import Button from "@bd-components/Button";

const AnnouncerPopup = ({ isOpen, content }) => {
  return (
    <Popup className={s.announcerPopup} isOpen={isOpen} unmountOnExit={false}>
      <div className={s.announcerPopup__inner}>
        <Button
          as="div"
          className={clsx(s.announcerPopup__element, isOpen && s["is-open"])}
        >
          <Button.Loading
            className={clsx(s.announcerPopup__loader, "g-animation-spin")}
          />
          <Button.Text>{content}</Button.Text>
        </Button>
      </div>
    </Popup>
  );
};

export default AnnouncerPopup;
