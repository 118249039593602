import { useEffect } from "react";
import useStore from "./useStore";
import useSnackbarState from "./useSnackbarState";
import { useDispatch } from "react-redux";
import useAuthProcess from "./useAuthProcess";
// import { setSnapshot } from "@bd-redux/kicked-snapshot";
import { useTranslation } from "react-i18next";
import { setRemovePlayer, setRejoinPlayer } from "@bd-redux/game-progress";

const useLeaveAndRejoinGameNotif = () => {
  const { t } = useTranslation();
  const { gameProgress: realGameProgress, userProgress } = useStore();

  const snackbar = useSnackbarState(3000);
  const dispatch = useDispatch();
  const authProcess = useAuthProcess();

  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  useEffect(
    () => {
      const showNotif = async () => {
        if (snackbar.isOpen) {
          await sleep(3000);
        }

        if (realGameProgress.removePlayer?.length) {
          realGameProgress.removePlayer.forEach((player, i) => {
            if (player.name && player.id !== userProgress.userId) {
              snackbar.error({
                message: (player.name ?? t("Someone")) + t(" left the game"),
                timeout: 2500,
              });
            }

            const removePlayer = realGameProgress.removePlayer.filter(
              (p) => p.id !== player.id,
            );

            dispatch(setRemovePlayer(removePlayer));
            return false;
          });
        }

        if (realGameProgress.rejoinPlayer?.length) {
          realGameProgress.rejoinPlayer.forEach((player, i) => {
            if (player.name && player.id !== userProgress.userId) {
              snackbar.error({
                message:
                  (player.name ?? t("Someone")) + t(" is joining to the game"),
                timeout: 2500,
              });
            }

            const rejoinPlayer = realGameProgress.rejoinPlayer.filter(
              (p) => p.id !== player.id,
            );

            dispatch(setRejoinPlayer(rejoinPlayer));
            return false;
          });
        }
      };

      if (userProgress.hasBeenKicked) {
        snackbar.error({ message: t("You've been removed from the game") });
        // dispatch(
        //   setSnapshot({
        //     gameProgress: realGameProgress,
        //     user: userProgress,
        //     notify: true,
        //   }),
        // );
        authProcess.logout();
      }

      showNotif();
    },
    // eslint-disable-next-line
    [
      userProgress.hasBeenKicked,
      realGameProgress.removePlayer,
      realGameProgress.rejoinPlayer,
    ],
  );

  return { snackbar };
};

export default useLeaveAndRejoinGameNotif;
