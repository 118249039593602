import React, { forwardRef } from "react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

import s from "./FunnelBase.module.scss";
import Button from "@bd-components/Button";
import Fieldset from "@bd-components/Fieldset";
import ErrorMessage from "@bd-components/ErrorMessage";
import useStore from "@bd-hooks/useStore";
import { stylesCombinerFn } from "@bd-utils/stylesCombiner";
import { ReactComponent as IconQuestionMark } from "@bd-icons/icon-question-mark.svg";

const FunnelBase = ({ classNames, children }) => {
  const { t } = useTranslation();
  const c = stylesCombinerFn(s, classNames);
  return (
    <div className={c("funnelBase")}>
      <picture>
        <source srcSet="/images/background-funnel.webp" type="image/webp" />
        <source srcSet="/images/background-funnel.jpg" type="image/jpeg" />
        <img
          className={c("funnelBase__img")}
          src="/images/background-funnel.jpg"
          alt={t("Office desk")}
        />
      </picture>
      <div className={c("funnelBase__wrapper")}>{children}</div>
      <div className={c("funnelBase__bottom")}>
        <div className={c("funnelBase__bottom__body")}>
          <a href="https://frisseblikken.com" target="_blank" rel="noreferrer">
            <img
              className={c("funnelBase__frisseblikkenImg")}
              src="/images/frisseblikken-logo.png"
              alt="Frisseblikken"
            />
          </a>
        </div>
        <Button
          classNames={{
            button: c("faq"),
            button__shape: c("faq__shape"),
            button__icon: c("faq__icon"),
          }}
          variant="fab-circle"
          iconStart={IconQuestionMark}
        />
      </div>
    </div>
  );
};

const Title = ({ className, children }) => {
  return <h1 className={clsx(s.title, className)}>{children}</h1>;
};

const Description = ({ className, children }) => {
  return <div className={clsx(s.description, className)}>{children}</div>;
};

const Box = ({
  classNames,
  fieldsetLabel,
  buttonProps,
  children,
  onSubmit,
  as,
  withoutButton,
  fieldsetProps,
}) => {
  const c = stylesCombinerFn(s, classNames);
  const Element = as || "form";
  return (
    <Element className={c("box")} onSubmit={onSubmit}>
      <Fieldset
        {...fieldsetProps}
        className={c("box__fieldset")}
        label={fieldsetLabel}
      >
        {children}
      </Fieldset>
      {withoutButton ? null : (
        <Button
          classNames={{ button: c("box__button") }}
          size="jumbo"
          onClick={onSubmit}
          {...buttonProps}
          type="submit"
        />
      )}
    </Element>
  );
};

const Input = forwardRef(
  ({ className, placeholder, onChange, value, name, isInvalid }, ref) => {
    return (
      <input
        ref={ref}
        className={clsx(s.input, isInvalid && s["is-invalid"], className)}
        name={name}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
      />
    );
  },
);

const Readiness = ({ className, hidePlayerCounter }) => {
  const { t } = useTranslation();
  const { gameProgress } = useStore();
  const teamId = gameProgress.teamCode;
  const totalPlayers = gameProgress.players.length;
  const totalReadyPlayers = gameProgress.players.filter((p) => p.ready).length;
  const isAllReady = totalReadyPlayers === totalPlayers;

  return (
    <div className={clsx(s.readiness, className)}>
      <div className={s.readiness__title}>
        {t("Team-Id")} {teamId}
      </div>
      {hidePlayerCounter ? null : (
        <div className={s.readiness__subtitle}>
          {isAllReady ? t("All player is ready") : t("Waiting for players")} (
          {totalReadyPlayers} / {totalPlayers})
        </div>
      )}
    </div>
  );
};

const ThisErrorMessage = ({ children }) => (
  <ErrorMessage className={s.errorMessage}>{children}</ErrorMessage>
);

const RightPosition = ({ children }) => (
  <div className={s.rightPosition}>hai</div>
);

FunnelBase.Title = Title;
FunnelBase.Description = Description;
FunnelBase.Box = Box;
FunnelBase.Input = Input;
FunnelBase.Readiness = Readiness;
FunnelBase.ErrorMessage = ThisErrorMessage;
FunnelBase.RightPosition = RightPosition;

export default FunnelBase;
