import React from "react";
import { useTranslation } from "react-i18next";
import useSound from "use-sound";

import useStore from "@bd-hooks/useStore";
import Tooltip from "@bd-components/Tooltip";
import { getRoleByUserId } from "@bd-utils/roles";
import truncateString from "@bd-utils/truncateString";

import s from "./GamePlay_Players.module.scss";

const GamePlayers = () => {
  const { t } = useTranslation();
  const { gameData, gameProgress } = useStore();

  const { players } = gameProgress;
  const { roles } = gameData;
  const { game_audio } = gameData.config;

  const [play, { stop }] = useSound(game_audio?.paper_1, {
    volume: 0.5,
  });

  let playerArr = [];

  players.forEach((player) =>
    playerArr.push({
      name: player?.name,
      data: getRoleByUserId(player?.userId, players, roles),
    }),
  );

  return (
    <div className={s.GamePlayers}>
      {playerArr.map((player, idx) => {
        return (
          <div
            className={s.Player}
            onMouseEnter={() => play()}
            onMouseLeave={() => stop()}
            key={player?.data?.userId ?? idx}
          >
            <div className={s.Player__Avatar}>
              <img
                src={player?.data?.avatar ?? "/images/sample-avatar.png"}
                alt={t(`${player?.name}`) ?? "Name"}
              />
            </div>
            <div className={s.Player__detail}>
              {truncateString(t(`${player?.name}`), 25) ?? "Name"}{" "}
              <span>{t(`${player?.data?.name}`) ?? "Bias"}</span>
            </div>
          </div>
        );
      })}
      <Tooltip
        text={t("All current players")}
        top="30px"
        left="100%"
        hoverDirection="left"
      />
    </div>
  );
};

export default GamePlayers;
