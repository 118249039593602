import { useTranslation } from "react-i18next";

import s from "./TeamStatusPlayer.module.scss";
import RoleAvatar from "@bd-components/RoleAvatar";
import useStore from "@bd-hooks/useStore";
import { getRoleByRoleId } from "@bd-utils/roles";
import { ReactComponent as IconTimes } from "@bd-icons/icon-xmark-solid.svg";

const HIDE_ROLE = true;

/**
 * @type {import("./TeamStatusPlayer").TeamStatusPlayerType}
 */
const TeamStatusPlayer = ({
  index = 0,
  player,
  withReadyCheck = true,
  onRemove,
}) => {
  const { t } = useTranslation();
  const store = useStore();

  return (
    <div className={s.TeamStatusPlayer}>
      <RoleAvatar player={player} />
      <div className={s.TeamStatusPlayer__body}>
        {withReadyCheck && player.ready ? (
          <div className={s.TeamStatusPlayer__readyStatus}>{t("Ready")}</div>
        ) : null}
        <h6 className={s.TeamStatusPlayer__name}>
          {player.name ? player.name : `${t("Player")} ${index + 1}`}
        </h6>
        {HIDE_ROLE ? null : (
          <>
            {player.roleId ? (
              <p className={s.TeamStatusPlayer__role}>
                {getRoleByRoleId(player.roleId, store.gameData.roles).name}
              </p>
            ) : player.name ? (
              <p className={s.TeamStatusPlayer__rolePlaceholder}>
                {t("Haven't chosen a role yet")}
              </p>
            ) : (
              <p className={s.TeamStatusPlayer__rolePlaceholder}>
                {t("Haven't setup profile yet")}
              </p>
            )}
          </>
        )}
      </div>
      {onRemove ? (
        <button className={s.TeamStatusPlayer__remove} onClick={onRemove}>
          <IconTimes className={s.TeamStatusPlayer__remove__icon} />
        </button>
      ) : null}
    </div>
  );
};

export default TeamStatusPlayer;
