import React, { forwardRef, useRef } from "react";
import ReactDOM from "react-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useClickAway } from "react-use";
import { CSSTransition } from "react-transition-group";

import s from "./Popup.module.scss";
import csstmap from "@bd-utils/csstmap";

// TODO: abillity to extends classNames

const PopupCore = forwardRef(
  (
    { className, unmountOnExit, style, isOpen, withoutMarkup, children },
    ref,
  ) => (
    <div
      ref={ref}
      className={clsx(
        s.popup,
        {
          [s["is-open"]]: isOpen,
          [s["is-unmount-on-exit"]]: unmountOnExit,
        },
        className,
      )}
      style={style}
    >
      {withoutMarkup ? (
        children
      ) : (
        <div className={s.popup__wrapper}>
          <div className={s.popup__inner}>
            <div className={s.popup__element}>{children}</div>
          </div>
        </div>
      )}
    </div>
  ),
);

const Popup = ({
  children,
  className,
  withoutPortal,
  withoutMarkup,
  isOpen = false,
  style,
  unmountOnExit,
  onClickAway = () => {},
}) => {
  const boxRef = useRef();
  const popupRef = useRef();

  useClickAway(
    boxRef,
    () => {
      if (isOpen) onClickAway();
    },
    ["mousedown", "touchstart"],
  );

  const render = () =>
    unmountOnExit ? (
      <CSSTransition
        classNames={csstmap()}
        in={isOpen}
        unmountOnExit={true}
        timeout={275}
        nodeRef={popupRef}
      >
        <PopupCore
          ref={popupRef}
          className={className}
          unmountOnExit={unmountOnExit}
          isOpen={isOpen}
          style={style}
          withoutMarkup={withoutMarkup}
        >
          {children}
        </PopupCore>
      </CSSTransition>
    ) : (
      <PopupCore
        className={className}
        unmountOnExit={unmountOnExit}
        isOpen={isOpen}
        style={style}
        withoutMarkup={withoutMarkup}
      >
        {children}
      </PopupCore>
    );

  return withoutPortal
    ? render()
    : ReactDOM.createPortal(render(), document.getElementById("popupRoot"));
};

Popup.propTypes = {
  className: PropTypes.string,
  classNameBox: PropTypes.string,
  classNameScroller: PropTypes.string,
  unmountOnExit: PropTypes.bool,
  withoutPortal: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClickAway: PropTypes.func,
};

Popup.whyDidYouRender = {
  customName: "Popup",
};

export default Popup;
