import { createSlice } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

/**
 * @type {LangType}
 */
export const defaultLanguage = {
  value: "en",
  label: "English",
};

/**
 * @type {StoreLanguageType}
 */
export const INITIAL_STATE = {
  current: defaultLanguage,
  initialized: false,
  options: [],
  defaultLanguage: defaultLanguage.value,
  hasChosenLanguageManually: false,
  isChangingLanguage: false,
};

const languageSlice = createSlice({
  name: "language",
  initialState: INITIAL_STATE,
  reducers: {
    /** @type {UtilReducer<StoreLanguageType, StoreLanguageType['current']>} */
    setCurrentLanguage: (state, action) => ({
      ...state,
      current: action.payload,
      initialized: false,
    }),

    /** @type {UtilReducer<StoreLanguageType, StoreLanguageType['initialized']>} */
    setInitializedLanguage: (state, action) => ({
      ...state,
      initialized: action.payload,
    }),

    /** @type {UtilReducer<StoreLanguageType, StoreLanguageType['options']>} */
    setLanguageOptions: (state, action) => ({
      ...state,
      options: action.payload,
    }),

    /** @type {UtilReducer<StoreLanguageType, StoreLanguageType['hasChosenLanguageManually']>} */
    setHasChosenLanguageManually: (state, action) => ({
      ...state,
      hasChosenLanguageManually: action.payload,
    }),

    /** @type {UtilReducer<StoreLanguageType, StoreLanguageType['isChangingLanguage']>} */
    setChangingLanguage: (state, action) => ({
      ...state,
      isChangingLanguage: action.payload,
    }),

    /** @type {UtilReducer<StoreLanguageType, StoreLanguageType['options']>} */
    reset: (state, action) => ({
      ...INITIAL_STATE,
    }),
  },
});

/**
 * @type {import("redux-persist").PersistConfig}
 */
const languagePersistConfig = {
  key: languageSlice.name,
  storage,
  blacklist: ["initialized", "options"],
};

export const {
  setCurrentLanguage,
  setInitializedLanguage,
  setLanguageOptions,
  setHasChosenLanguageManually,
  setChangingLanguage,
  reset,
} = languageSlice.actions;

export default persistReducer(languagePersistConfig, languageSlice.reducer);
