import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { onValue, onDisconnect } from "firebase/database";

import {
  setFinishedGameAt,
  setPlayers,
  setPlayersSeenIndividualReflection,
  setRoundProgress,
  setSeenTutorials,
  setStartedAt,
  setStartedCountdownAt,
  setStartedGameAt,
  setRemovePlayer,
  setRejoinPlayer,
  setPlayersReadyDebriefing,
  setPlayersSeenDebriefing,
  setTeamConnectorHistory,
} from "@bd-redux/game-progress";
import {
  setHasBeenKicked,
  setHasBeenRegistered,
  setCurrentTutorialIndex,
  setHasSeenFirstClue,
  setHasCompletedMantapPreGame,
} from "@bd-redux/user";
import { setDragging } from "@bd-redux/dragging";
import {
  setFirebaseLanguage,
  setUseMyInitialLanguage,
} from "@bd-redux/firebase";
import {
  setCombineHistory,
  reset as resetCombineHistory,
} from "@bd-redux/combine-history";
import {
  setCurrentLanguage,
  setHasChosenLanguageManually,
  setChangingLanguage,
} from "@bd-redux/language";
import { effectCleanup } from "@bd-utils/firebase";
import indexedObjectToArray, {
  indexedObjectToArrayKeys,
} from "@bd-utils/indexedObjectToArray";
import useStore from "./useStore";
import useFirebaseActions from "./useFirebaseActions";
import axios from "axios";

export const useIsFirebaseConected = () => {
  const { firebase, userProgress, gameProgress } = useStore();
  if (!firebase.isConnected) return false;
  if (!userProgress.userId) return false;
  if (!gameProgress.teamCode) return false;
  return true;
};

const useRegisterUser = () => {
  const { firebase, userProgress, temporal, gameProgress } = useStore();
  const { setLanguage, registerUser } = useFirebaseActions();
  const dispatch = useDispatch();
  const isConnected = useIsFirebaseConected();

  useEffect(() => {
    if (!isConnected) return;
    if (userProgress.hasBeenRegistered) return;

    if (firebase.useMyInitialLanguage) {
      setLanguage(firebase.useMyInitialLanguage, gameProgress.teamCode)
        .then(() => dispatch(setUseMyInitialLanguage(false)))
        .catch((err) => console.error("Error initializing language [UFL-50]"));
    }

    registerUser(userProgress.userId, temporal.retrievedRoleId)
      .then(() => dispatch(setHasBeenRegistered(true)))
      .catch((err) =>
        console.error("Error registering user into firebase [UFL-55]"),
      );
    // eslint-disable-next-line
  }, [
    isConnected,
    firebase.teamPath,
    firebase.currentPlayerPath,
    userProgress.userId,
    userProgress.hasBeenRegistered,
    dispatch,
  ]);
};

const useUpdateLanguage = () => {
  const { firebase, language } = useStore();
  const { getLanguageRef } = firebase;
  const dispatch = useDispatch();
  const isConnected = useIsFirebaseConected();

  useEffect(() => {
    if (!isConnected) return;

    const updateLanguage = (snapshot) => {
      /**
       * @type {FirebaseTeamType['language']}
       */
      const value = snapshot.val();
      if (!value) return;

      dispatch(setFirebaseLanguage(value));

      const shouldUpdate = value !== language.current.value;
      if (!shouldUpdate) return;

      const selected = language.options.find((lang) => lang.value === value);
      if (!selected) return;

      dispatch(setChangingLanguage(true));
      dispatch(setCurrentLanguage(selected));
    };

    const ref = getLanguageRef();
    onValue(ref, updateLanguage);
    return effectCleanup(ref, updateLanguage);
    // eslint-disable-next-line
  }, [
    isConnected,
    firebase.teamPath,
    firebase.languagePath,
    language.current.value,
    dispatch,
  ]);
};

const useUpdateManualLanguage = () => {
  const { firebase, language } = useStore();
  const { getManualLanguagePath } = firebase;
  const dispatch = useDispatch();
  const isConnected = useIsFirebaseConected();

  useEffect(() => {
    if (!isConnected) return;

    const updateManualLanguage = (snapshot) => {
      /**
       * @type {FirebaseTeamType['language']}
       */
      const value = snapshot.val();
      if (!value) return;

      const shouldUpdate = value !== language.hasChosenLanguageManually;
      if (!shouldUpdate) return;

      dispatch(setHasChosenLanguageManually(value));
    };

    const ref = getManualLanguagePath();
    onValue(ref, updateManualLanguage);
    return effectCleanup(ref, updateManualLanguage);
    // eslint-disable-next-line
  }, [
    isConnected,
    firebase.teamPath,
    firebase.languagePath,
    language.hasChosenLanguageManually,
    dispatch,
  ]);
};

const useUpdatePlayerOrKickMe = () => {
  const { userProgress, firebase, gameProgress } = useStore();
  const { getPlayersRef } = firebase;
  const dispatch = useDispatch();
  const isConnected = useIsFirebaseConected();

  useEffect(() => {
    if (!isConnected) return;

    /**
     * Update redux store gameProgress.players if there's players data changes,
     * But if current user (me) has been removed, kick the player and don't
     * update the players data anymore.
     * @param {FirebaseSnapshot} snapshot
     */
    const updatePlayersOrKickMe = (snapshot) => {
      /**
       * @type {FirebaseTeamType['players']}
       */
      const value = snapshot.val();

      /**
       * @type {ApiPlayerType[]}
       */
      const players = indexedObjectToArray(value)?.filter((p) => p.userId);

      const isCurrentPlayerExist =
        players.filter((p) => p.userId === userProgress.userId).length > 0
          ? true
          : false;

      const isKicked = !isCurrentPlayerExist && userProgress.hasBeenRegistered;
      const { stringify } = JSON;
      const currentPlayers = gameProgress.players;

      const shouldUpdate = stringify(players) !== stringify(currentPlayers);

      if (isKicked) return dispatch(setHasBeenKicked(true));
      if (shouldUpdate) {
        // Rejoin player
        const playerNames = currentPlayers.map((s) => s.name);
        const rejoinPlayer = players
          .filter(
            (p) =>
              p.name !== "" &&
              p.isRejoinUser &&
              p.userId !== userProgress.userId &&
              p.userId > userProgress.userId,
          )
          ?.find((q) => !playerNames.includes(q.name));

        if (rejoinPlayer) {
          let rejoinPlayerArr = Array.isArray(gameProgress.rejoinPlayer)
            ? gameProgress.rejoinPlayer.map((p) => p)
            : [];
          rejoinPlayerArr.push({
            id: rejoinPlayer.userId,
            name: rejoinPlayer.name,
          });

          dispatch(setRejoinPlayer(rejoinPlayerArr));
        }

        return dispatch(setPlayers(players));
      }
    };

    const ref = getPlayersRef();
    onValue(ref, updatePlayersOrKickMe);
    return effectCleanup(ref, updatePlayersOrKickMe);
    // eslint-disable-next-line
  }, [
    isConnected,
    firebase.playersPath,
    dispatch,
    userProgress.userId,
    userProgress.hasBeenRegistered,
    gameProgress.players,
  ]);
};

const useUpdateDragging = () => {
  const { firebase, dragging } = useStore();
  const { getDraggingRef } = firebase;
  const dispatch = useDispatch();
  const isConnected = useIsFirebaseConected();

  useEffect(() => {
    if (!isConnected) return;

    /**
     * @param {FirebaseSnapshot} snapshot
     */
    const updateDragging = (snapshot) => {
      const value = snapshot.val() ?? false;
      const shouldUpdate = dragging.user !== value;
      if (shouldUpdate) return dispatch(setDragging({ user: value }));
    };

    const ref = getDraggingRef();
    onValue(ref, updateDragging);
    return effectCleanup(ref, updateDragging);
    // eslint-disable-next-line
  }, [isConnected, firebase.draggingPath, dragging.user, dispatch]);
};

const useUpdateStartedAt = () => {
  const { firebase, gameProgress } = useStore();
  const { getTeamRef } = firebase;
  const dispatch = useDispatch();
  const isConnected = useIsFirebaseConected();

  useEffect(() => {
    if (!isConnected) return;

    /**
     * @param {FirebaseSnapshot} snapshot
     */
    const updateStartedAt = (snapshot) => {
      const value = snapshot.val();
      if (!value?.startedAt) return;

      const shouldUpdate = value.startedAt !== gameProgress.startedAt;
      if (!shouldUpdate) return;
      dispatch(setStartedAt(value.startedAt));
    };

    const ref = getTeamRef();
    onValue(ref, updateStartedAt);
    return effectCleanup(ref, updateStartedAt);
    // eslint-disable-next-line
  }, [isConnected, firebase.teamPath, gameProgress.startedAt, dispatch]);
};

const useUpdateStartedCountdownAt = () => {
  const { firebase, gameProgress } = useStore();
  const { getTeamRef } = firebase;
  const dispatch = useDispatch();
  const isConnected = useIsFirebaseConected();

  useEffect(() => {
    if (!isConnected) return;

    /**
     * @param {FirebaseSnapshot} snapshot
     */
    const updateStartedCountdownAt = (snapshot) => {
      /**
       * @type {FirebaseTeamType}
       */
      const value = snapshot.val();
      const startedCountdownAt = value?.startedCountdownAt;
      if (!startedCountdownAt) return;

      const shouldUpdate =
        startedCountdownAt !== gameProgress.startedCountdownAt;
      if (!shouldUpdate) return;

      dispatch(setStartedCountdownAt(value.startedCountdownAt));
    };

    const ref = getTeamRef();
    onValue(ref, updateStartedCountdownAt);
    return effectCleanup(ref, updateStartedCountdownAt);
    // eslint-disable-next-line
  }, [
    isConnected,
    firebase.teamPath,
    gameProgress.startedCountdownAt,
    dispatch,
  ]);
};

const useUpdateStartedGameAt = () => {
  const { firebase, gameProgress } = useStore();
  const { getTeamRef } = firebase;
  const dispatch = useDispatch();
  const isConnected = useIsFirebaseConected();

  useEffect(() => {
    if (!isConnected) return;

    /**
     * @param {FirebaseSnapshot} snapshot
     */
    const updateStartedGameAt = (snapshot) => {
      /**
       * @type {FirebaseTeamType}
       */
      const value = snapshot.val();
      const startedGameAt = value?.startedGameAt;
      if (!startedGameAt) return;

      const shouldUpdate = startedGameAt !== gameProgress.startedGameAt;
      if (!shouldUpdate) return;
      dispatch(setStartedGameAt(value.startedGameAt));
    };

    const ref = getTeamRef();
    onValue(ref, updateStartedGameAt);
    return effectCleanup(ref, updateStartedGameAt);
    // eslint-disable-next-line
  }, [isConnected, firebase.teamPath, gameProgress.startedGameAt, dispatch]);
};

const useUpdateRoundProgress = () => {
  const { firebase, gameProgress, combineHistory } = useStore();
  const { getTeamRef } = firebase;
  const dispatch = useDispatch();
  const isConnected = useIsFirebaseConected();
  const isAnyCombineHistory = combineHistory.data?.length > 0 ?? false;
  const currentShouldFinishAt =
    gameProgress?.roundProgress?.shouldFinishAt ?? false;

  useEffect(() => {
    if (!isConnected) return;

    /**
     * @param {FirebaseSnapshot} snapshot
     */
    const updateRoundProgress = (snapshot) => {
      /**
       * @type {FirebaseTeamType}
       */
      const value = snapshot.val();
      if (!value) return;

      /**
       * Rounds with firebase indexed key
       */
      const roundsIndexedKey = value.rounds;
      if (!roundsIndexedKey) return;

      /**
       * @type {[FirebaseTeamType["rounds"][0]]}
       */
      const parsedRounds = indexedObjectToArray(roundsIndexedKey);

      const k = indexedObjectToArrayKeys;
      const rounds = parsedRounds.map((round) => {
        let result = { ...round };
        result.clues = (round.clues ?? []).reverse();
        result.finalAnswer = parseFinalAnswerToArray(round.finalAnswer);
        result.playersSeenRoundIntroVideo = k(round.playersSeenRoundIntroVideo);
        result.playersSeenRoundIntro = k(round.playersSeenRoundIntro);
        result.playersSeenPreIntuition = k(round.playersSeenPreIntuition);
        result.playersFinalAnswer = round.playersFinalAnswer;
        result.playersSeenOutro = (() => {
          if (!round.playersSeenOutro) return undefined;
          let parsed = {};
          Object.keys(round.playersSeenOutro).forEach((keyIndex) => {
            parsed[keyIndex] = indexedObjectToArrayKeys(
              round.playersSeenOutro[keyIndex],
            );
          });
          return parsed;
        })();

        return result;
      });

      const updatedCurrentRound = rounds[rounds.length - 1];

      dispatch(setRoundProgress(updatedCurrentRound));
    };

    const ref = getTeamRef();
    onValue(ref, updateRoundProgress);
    return effectCleanup(ref, updateRoundProgress);
    // eslint-disable-next-line
  }, [
    isConnected,
    firebase.teamPath,
    dispatch,
    isAnyCombineHistory,
    currentShouldFinishAt,
  ]);
};

const useUpdateFinishedGameAt = () => {
  const { firebase, gameProgress } = useStore();
  const { getTeamRef } = firebase;
  const dispatch = useDispatch();
  const isConnected = useIsFirebaseConected();

  useEffect(() => {
    if (!isConnected) return;

    /**
     * @param {FirebaseSnapshot} snapshot
     */
    const updateFinishedGameAt = (snapshot) => {
      /**
       * @type {FirebaseTeamType}
       */
      const value = snapshot.val();
      const finishedGameAt = value?.finishedGameAt;
      if (!finishedGameAt) return;

      const shouldUpdate = finishedGameAt !== gameProgress.finishedGameAt;
      if (!shouldUpdate) return;
      dispatch(setFinishedGameAt(value.finishedGameAt));
    };

    const ref = getTeamRef();
    onValue(ref, updateFinishedGameAt);
    return effectCleanup(ref, updateFinishedGameAt);
    // eslint-disable-next-line
  }, [isConnected, firebase.teamPath, gameProgress.finishedGameAt, dispatch]);
};

const useUpdateCombineHistory = () => {
  const { firebase } = useStore();
  const { getCombineHistoryRef } = firebase;
  const dispatch = useDispatch();
  const isConnected = useIsFirebaseConected();

  useEffect(() => {
    if (!isConnected) return;

    /**
     * @param {FirebaseSnapshot} snapshot
     */
    const updateCombineHistory = (snapshot) => {
      /**
       * @type {FirebaseTeamType["combineHistory"]}
       */
      const value = snapshot.val();
      if (!value) {
        dispatch(resetCombineHistory());
        return;
      }
      const histories = indexedObjectToArray(value);
      dispatch(setCombineHistory(histories));
    };

    const ref = getCombineHistoryRef();
    onValue(ref, updateCombineHistory);
    return effectCleanup(ref, updateCombineHistory);
    // eslint-disable-next-line
  }, [isConnected, firebase.combineHistoryPath, dispatch]);
};

const useUpdatePlayersSeenReflection = () => {
  const { firebase } = useStore();
  const { getIndividualReflectionRef } = firebase;
  const dispatch = useDispatch();
  const isConnected = useIsFirebaseConected();

  useEffect(() => {
    if (!isConnected) return;

    /**
     * @param {FirebaseSnapshot} snapshot
     */
    const updateReflection = (snapshot) => {
      /** @type {FirebaseTeamType["playersSeenIndividualReflection"]} */
      const value = snapshot.val();
      if (!value) return;
      const playerIds = indexedObjectToArrayKeys(value);
      dispatch(setPlayersSeenIndividualReflection(playerIds));
    };

    const ref = getIndividualReflectionRef();
    onValue(ref, updateReflection);
    return effectCleanup(ref, updateReflection);
    // eslint-disable-next-line
  }, [isConnected, firebase.individualReflectionPath, dispatch]);
};

const useUpdateSeenTutorials = () => {
  const { firebase, gameProgress } = useStore();
  const { getSeenTutorialsRef } = firebase;
  const dispatch = useDispatch();
  const isConnected = useIsFirebaseConected();

  useEffect(() => {
    if (!isConnected) return;
    if (!gameProgress.startedGameAt) return;

    /**
     * @param {FirebaseSnapshot} snapshot
     */
    const useUpdateSeenTutorials = (snapshot) => {
      /** @type {FirebaseTeamType["seenTutorials"]} */
      const value = snapshot.val() ?? {};
      let parsedSeenTutorials = {};
      Object.keys(value).forEach((tutorialId) => {
        const playerIds = indexedObjectToArrayKeys(value[tutorialId]);
        parsedSeenTutorials[tutorialId] = playerIds;
      });
      dispatch(setSeenTutorials(parsedSeenTutorials));
    };

    const ref = getSeenTutorialsRef();
    onValue(ref, useUpdateSeenTutorials);
    return effectCleanup(ref, useUpdateSeenTutorials);
    // eslint-disable-next-line
  }, [
    isConnected,
    firebase.seenTutorialsPath,
    gameProgress.startedGameAt,
    dispatch,
  ]);
};

const useUpdateCurrentTutorialIndex = () => {
  const { userProgress, firebase } = useStore();
  const { getPlayersRef } = firebase;
  const dispatch = useDispatch();
  const isConnected = useIsFirebaseConected();

  useEffect(() => {
    if (!isConnected) return;

    /**
     * Update redux store user.currenttutorialIndex
     * @param {FirebaseSnapshot} snapshot
     */
    const updateCurrentTutorialIndex = (snapshot) => {
      /**
       * @type {FirebaseTeamType['players']}
       */
      const value = snapshot.val();

      /**
       * @type {ApiPlayerType[]}
       */
      const players = indexedObjectToArray(value);
      const currentPlayer = players.find(
        (p) => p.userId === userProgress.userId,
      );

      if (currentPlayer) {
        dispatch(
          setCurrentTutorialIndex(currentPlayer.currentTutorialIndex ?? 0),
        );
        dispatch(setHasSeenFirstClue(currentPlayer.hasSeenFirstClue));
        dispatch(
          setHasCompletedMantapPreGame(
            currentPlayer.hasCompletedMantapPreGame ?? false,
          ),
        );
      }
    };

    const ref = getPlayersRef();
    onValue(ref, updateCurrentTutorialIndex);
    return effectCleanup(ref, updateCurrentTutorialIndex);
    // eslint-disable-next-line
  }, [isConnected, firebase.playersPath, dispatch, userProgress.userId]);
};

const usePlayerOnlineState = () => {
  const { firebase } = useStore();
  const { getCurrentPlayerRef } = firebase;
  const { setPlayerOnlineState } = useFirebaseActions();

  const isConnected = useIsFirebaseConected();

  useEffect(() => {
    if (!isConnected) return;

    const ref = getCurrentPlayerRef();
    setPlayerOnlineState(true);
    onDisconnect(ref)
      .update({ onlineState: false, lastSeen: Math.floor(Date.now() / 1000) })
      .then(() => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConnected /* , getCurrentPlayerRef, setPlayerOnlineState */]);
};

const useRemovePlayers = () => {
  const isConnected = useIsFirebaseConected();
  const { firebase, gameProgress } = useStore();
  const { getPlayersRef } = firebase;
  const { removePlayer } = useFirebaseActions();
  const dispatch = useDispatch();

  const [latestPlayers, setLatestPlayers] = useState({});
  const [offlinePlayersState, setOfflinePlayersState] = useState(null);

  useEffect(() => {
    if (!isConnected || gameProgress.finishedGameAt) return;

    const onPlayersOffline = (snapshot) => {
      const value = snapshot.val();
      if (!value) {
        return;
      }

      setLatestPlayers(value);
      const offlinePlayers = Object.keys(value).filter(
        (k) => !value[k].onlineState && value[k].userId,
      );
      if (!offlinePlayers.length) {
        return;
      }

      setOfflinePlayersState(offlinePlayers);
    };

    const ref = getPlayersRef();
    onValue(ref, onPlayersOffline);
    return effectCleanup(ref, onPlayersOffline);
    // eslint-disable-next-line
  }, [isConnected, gameProgress.finishedGameAt]);

  useEffect(() => {
    let removeOfflinePlayerTimeout = setTimeout(function () {
      if (
        !offlinePlayersState ||
        !offlinePlayersState.length ||
        gameProgress.finishedGameAt ||
        !latestPlayers ||
        !isConnected
      ) {
        return;
      }

      offlinePlayersState.forEach((player_id) => {
        if (latestPlayers[player_id]?.onlineState === false) {
          console.warn("remove offline player with id " + player_id);
          const playerName = gameProgress.players.find(
            (p) => p.userId === parseInt(player_id),
          )?.name;

          let removePlayerArr = Array.isArray(gameProgress.removePlayer)
            ? gameProgress.removePlayer.map((p) => p)
            : [];
          removePlayerArr.push({
            id: player_id,
            name: playerName,
          });

          dispatch(setRemovePlayer(removePlayerArr));

          removePlayer(player_id);

          /** @type {UtilAxiosPost<ApiRequestRemovePlayer, ApiResponseRemovePlayer>}*/
          const request = axios.post;
          request("/member/remove-player", {
            team_id: gameProgress.teamId,
            user_id: player_id,
          }).then((res) => {
            if (!res.data.success) {
              console.error("Failed to remove user in the server");
            }
          });
        } else if (latestPlayers[player_id]?.onlineState) {
          console.warn(
            "player backs to online, cancel remove player : ",
            player_id,
          );
        }
      });
    }, 15000);

    return () => {
      clearTimeout(removeOfflinePlayerTimeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isConnected,
    latestPlayers,
    offlinePlayersState,
    gameProgress.finishedGameAt /* , gameProgress.teamId, removePlayer */,
  ]);
};

const useUpdateSeenDebriefing = () => {
  const { firebase, gameProgress } = useStore();
  const { getSeenDebriefingRef } = firebase;
  const dispatch = useDispatch();
  const isConnected = useIsFirebaseConected();

  useEffect(() => {
    if (!isConnected) return;
    if (!gameProgress.startedGameAt) return;

    /**
     * @param {FirebaseSnapshot} snapshot
     */
    const updateSeenDebriefing = (snapshot) => {
      /** @type {FirebaseTeamType["playersSeenDebriefing"]} */
      const value = snapshot.val() ?? {};
      const parsedSeenDebriefing = indexedObjectToArrayKeys(value);
      dispatch(setPlayersSeenDebriefing(parsedSeenDebriefing));
    };

    const ref = getSeenDebriefingRef();
    onValue(ref, updateSeenDebriefing);
    return effectCleanup(ref, updateSeenDebriefing);
    // eslint-disable-next-line
  }, [
    isConnected,
    firebase.seenTutorialsPath,
    gameProgress.startedGameAt,
    dispatch,
  ]);
};

const useUpdateReadyDebriefing = () => {
  const { firebase, gameProgress } = useStore();
  const { getReadyDebriefingRef } = firebase;
  const dispatch = useDispatch();
  const isConnected = useIsFirebaseConected();

  useEffect(() => {
    if (!isConnected) return;
    if (!gameProgress.startedGameAt) return;

    /**
     * @param {FirebaseSnapshot} snapshot
     */
    const updateReadyDebriefing = (snapshot) => {
      /** @type {FirebaseTeamType["playersReadyDebriefing"]} */
      const value = snapshot.val() ?? {};
      const parsedReadyDebriefing = indexedObjectToArrayKeys(value);
      dispatch(setPlayersReadyDebriefing(parsedReadyDebriefing));
    };

    const ref = getReadyDebriefingRef();
    onValue(ref, updateReadyDebriefing);
    return effectCleanup(ref, updateReadyDebriefing);
    // eslint-disable-next-line
  }, [
    isConnected,
    firebase.seenTutorialsPath,
    gameProgress.startedGameAt,
    dispatch,
  ]);
};

const useUpdateTeamConnectorHistory = () => {
  const { firebase, gameProgress } = useStore();
  const { getTeamConnectorHistoryRef } = firebase;
  const dispatch = useDispatch();
  const isConnected = useIsFirebaseConected();

  useEffect(() => {
    if (!isConnected) return;
    if (!gameProgress.startedGameAt) return;

    /**
     * @param {FirebaseSnapshot} snapshot
     */
    const updateTeamConnectorHistory = (snapshot) => {
      /** @type {FirebaseTeamType["teamConnectorHistory"]} */
      const value = snapshot.val() ?? {};
      const parsedTeamConnectorHistory = indexedObjectToArray(value);
      dispatch(setTeamConnectorHistory(parsedTeamConnectorHistory));
    };

    const ref = getTeamConnectorHistoryRef();
    onValue(ref, updateTeamConnectorHistory);
    return effectCleanup(ref, updateTeamConnectorHistory);
    // eslint-disable-next-line
  }, [
    isConnected,
    firebase.seenTutorialsPath,
    gameProgress.startedGameAt,
    dispatch,
  ]);
};

const useFirebaseListener = () => {
  useRegisterUser();
  useUpdatePlayerOrKickMe();
  useUpdateDragging();
  useUpdateStartedAt();
  useUpdateStartedCountdownAt();
  useUpdateStartedGameAt();
  useUpdateRoundProgress();
  useUpdateFinishedGameAt();
  useUpdateCombineHistory();
  useUpdatePlayersSeenReflection();
  useUpdateSeenTutorials();
  useUpdateLanguage();
  useUpdateManualLanguage();
  useUpdateCurrentTutorialIndex();
  usePlayerOnlineState();
  useRemovePlayers();
  useUpdateSeenDebriefing();
  useUpdateReadyDebriefing();
  useUpdateTeamConnectorHistory();
};

/**
 *
 * @param {FirebaseTeamType['rounds'][0]['finalAnswer']} raw
 * @returns
 */
const parseFinalAnswerToArray = (raw) =>
  raw && typeof raw === "object"
    ? Object.keys(raw).map((questionId) => ({
        questionId,
        answerId: raw[questionId],
      }))
    : [];

export default useFirebaseListener;
