import React from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

import s from "./WaitingChecklist.module.scss";
import LobbyCard from "@bd-components/LobbyCard";
import FunnelBase from "@bd-components/FunnelBase";
import useStore from "@bd-hooks/useStore";
import { getRoleByUserId } from "@bd-utils/roles";

/**
 * @type {import("./WaitingChecklist").WaitingChecklistType}
 */
const WaitingChecklist = ({ readyUserIds = [], isOpen = true }) => {
  const { t } = useTranslation();
  const { gameProgress, gameData } = useStore();
  const { players } = gameProgress;
  const isWaiting = players.length !== readyUserIds.length;

  const waitingText = `${t("Waiting other players")} (${readyUserIds.length}/${
    players.length
  })`;

  return (
    <div className={s.WaitingChecklist}>
      <FunnelBase.Box
        as="div"
        withoutButton
        classNames={{
          box: clsx(s.funnelBaseBox, isWaiting && isOpen && s["is-waiting"]),
          box__fieldset: s.funnelBaseBox__fieldset,
        }}
        fieldsetLabel={waitingText}
      >
        <div className={s.funnelBaseBox__list}>
          {gameProgress.players.map((player, i) => (
            <LobbyCard
              key={player.userId}
              className={s.funnelBaseBox__item}
              name={player.name}
              color={
                getRoleByUserId(
                  player.userId,
                  gameProgress.players,
                  gameData.roles,
                ).color
              }
              isReady={readyUserIds.includes(player.userId) ?? false}
            />
          ))}
        </div>
      </FunnelBase.Box>
    </div>
  );
};

export default WaitingChecklist;
