import { useTranslation } from "react-i18next";

import useWindowSize from "@bd-hooks/useWindowSize";

import clsx from "clsx";
import s from "./ResScreen.module.scss";

const ResScreen = () => {
  const { t } = useTranslation();
  const windowSize = useWindowSize();
  const isViewable = windowSize.height < 600 || windowSize.width < 1000;

  return (
    <div className={clsx(s.ResScreen, isViewable && s.viewable)}>
      <h1 className={s.ResScreen__title}>
        {t(
          "Your resolution isn't supported. Please open this app on a supported device.",
        )}
      </h1>
    </div>
  );
};

export default ResScreen;
