import { ReactComponent as IconQuestion } from "@bd-icons/icon-question.svg";

import clsx from "clsx";
import s from "./Tooltip.module.scss";

/**
 * @type {import("./Tooltip").TooltipType}
 */
const Tooltip = (props) => {
  const { text, top = "0%", left = "0%", hoverDirection = "left" } = props;

  return (
    <div className={s.Tooltip} style={{ top: top, left: left }}>
      <i className={s.Tooltip__icon}>
        <IconQuestion />
      </i>
      <div className={clsx(s.Tooltip__text, s[`is-${hoverDirection}`])}>
        <p>{text}</p>
      </div>
    </div>
  );
};

export default Tooltip;
