import SmallFolderPopup from "@bd-components/SmallFolderPopup";

// This is just an alias component, for readability
const PreIntuitionQuestion = (props) => {
  return (
    <SmallFolderPopup
      {...props}
      analyticEventName="pre_final_question_button_clicked"
      titleTestId="game_preFinalQuestionTitle"
      buttonTestId="game_preFinalQuestionButton"
    />
  );
};

export default PreIntuitionQuestion;
