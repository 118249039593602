import { useState } from "react";
import useStore from "./useStore";
import useFirebaseActions from "./useFirebaseActions";

/**
 * @param {{
 *  intuitions: ApiIntuitionType[],
 *  onAnswered: Function
 * }} params
 */
const useIntuitionQuestion = ({ intuitions: intuitionsData = [] }) => {
  const { gameProgress, currentPlayer } = useStore();
  const { hidePreIntuition } = useFirebaseActions();
  const initIntuitions = intuitionsData.map((i) => ({
    ...i,
    /** @type {boolean | null} */
    isTrue: null,
    isBiased: false,
  }));
  const [intuitions, setIntuitions] = useState(initIntuitions);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const showPreIntuition =
    !gameProgress.roundProgress.playersSeenPreIntuition.includes(
      currentPlayer.userId,
    );

  const onHidePreIntuition = () => {
    hidePreIntuition();
  };

  const onAnswerChange = ({ id, isTrue, isBiased }) => {
    setIntuitions((prev) => {
      const newIntuitions = prev.map((i) =>
        i.id === id ? { ...i, isTrue, isBiased } : i,
      );
      return newIntuitions;
    });
  };

  const submitIntuition = () => {
    if (isSubmiting) return;
    setIsSubmiting(true);
  };

  return {
    intuitions,
    onAnswerChange,
    submitIntuition,
    isSubmiting,
    showPreIntuition,
    onHidePreIntuition,
  };
};

export default useIntuitionQuestion;
