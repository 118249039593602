import { useState } from "react";
import PropTypes from "prop-types";

import s from "./Avatar.module.scss";
import Button from "@bd-components/Button";
import { stylesCombinerFn } from "@bd-utils/stylesCombiner";

const Avatar = ({
  className,
  classNames = {},
  url,
  name,
  label,
  size = "normal",
}) => {
  const c = stylesCombinerFn(s, classNames);
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  return (
    <div
      className={c(
        "avatar",
        s[`is-size-${size}`],
        label && name && s["with-label-and-name"],
        className,
      )}
    >
      <div className={c("avatar__inner")}>
        {url ? (
          <>
            {!isImageLoaded && (
              <img
                className={c("avatar__img")}
                src="/images/placeholder.webp"
                alt="placeholder"
                style={{
                  width: "100%",
                  height: "100%",
                  opacity: "0.5",
                  filter: "blur(10px)",
                }}
              />
            )}
            <img
              className={c("avatar__img")}
              src={url}
              alt="avatar"
              style={{ display: isImageLoaded ? "block" : "none" }}
              onLoad={() => setIsImageLoaded(true)}
            />
          </>
        ) : (
          <picture>
            <source srcSet="/images/default-avatar.webp" type="image/webp" />
            <source srcSet="/images/default-avatar.png" type="image/png" />
            <img
              className={c("avatar__img")}
              src="/images/default-avatar.png"
              alt="avatar"
            />
          </picture>
        )}

        {name ? <div className={c("avatar__name")}>{name}</div> : null}
        {label ? (
          <Button
            as="div"
            size="small"
            className={c("avatar__label")}
            classNames={{
              button__shape: c("avatar__label__shape"),
            }}
            text={label}
            style={{ pointerEvents: "none" }}
          />
        ) : null}
      </div>
    </div>
  );
};

Avatar.propTypes = {
  className: PropTypes.string,
  classNames: PropTypes.shape({
    avatar: PropTypes.string,
    avatar__inner: PropTypes.string,
    avatar__img: PropTypes.string,
  }),
  url: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  name: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.oneOf(["small", "normal", "jumbo"]),
};

export default Avatar;
