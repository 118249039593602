import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import s from "./Folder.module.scss";
import { stylesCombinerFn } from "@bd-utils/stylesCombiner";

const Folder = ({ className, classNames = {}, size, children }) => {
  const c = stylesCombinerFn(s, classNames);

  // folder--small.png or folder.png
  // This comment above is only for searchability

  const src = `/images/folder${size ? `--${size}` : ""}`;

  return (
    <div className={c("folder", size && `size-${size}`, className)}>
      <div className={c("folder__ruler")}>
        <picture>
          <source srcSet={`${src}.webp`} type="image/webp" />
          <source srcSet={`${src}.png`} type="image/png" />
          <img className={c("folder__img")} src={src} alt="Folder background" />
        </picture>
        {children}
      </div>
    </div>
  );
};

Folder.propTypes = {
  className: PropTypes.string,
  classNames: PropTypes.shape({
    folder: PropTypes.string,
    folder__ruler: PropTypes.string,
    folder__img: PropTypes.string,
  }),
  size: PropTypes.string,
};

const Label = ({ className, children }) => {
  return <div className={clsx(s.label, className)}>{children}</div>;
};

Label.propTypes = {
  className: PropTypes.string,
};

const Inner = forwardRef(({ className, children }, ref) => {
  return (
    <div ref={ref} className={clsx(s.inner, className)}>
      {children}
    </div>
  );
});

Inner.propTypes = {
  className: PropTypes.string,
};

Folder.Label = Label;
Folder.Inner = Inner;
export default Folder;
