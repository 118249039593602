/**
 * Parse HTML text
 * @param {String} text
 */
function parseHTML(text) {
  let sanitizedText = new DOMParser().parseFromString(text, "text/html");

  return sanitizedText.body.textContent || "";
}

export default parseHTML;
