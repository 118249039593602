import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import s from "./ActionQuestion.module.scss";
import Fieldset from "@bd-components/Fieldset";
import Checkbox from "@bd-components/Checkbox";
import SeekAnswer from "@bd-components/SeekAnswer";
import usePlayersFinalAnswer from "@bd-hooks/usePlayersFinalAnswer";
import useStore from "@bd-hooks/useStore";
import getAllQueryString from "@bd-utils/getAllQueryString";
import { getRoleByUserId } from "@bd-utils/roles";

/**
 * @type {React.FC<{
 *  questionId: string,
 *  title: string,
 *  isLoading: boolean,
 *  options: ActionQuestionOption[],
 *  onChange: (id: ActionQuestionOption["id"]) => void,
 *  value: ActionQuestionOption
 * }>}
 */
const ActionQuestion = ({
  questionId,
  title,
  options,
  onChange,
  value,
  isLoading,
}) => {
  const { t } = useTranslation();

  const location = useLocation();
  const keys = Object.keys(getAllQueryString(location.search));
  const isTesting = keys.includes("test-y0u-d0nt-kn0w");

  const answers = usePlayersFinalAnswer();
  const { gameProgress, gameData } = useStore();
  const { players } = gameProgress;
  const { roles } = gameData;
  const getRole = (userId) => getRoleByUserId(userId, players, roles);
  const isMultiPlayer = players.length > 1;

  const getColorsForValue = (value) => {
    const colors = Object.keys(answers?.[questionId] ?? {})
      .filter((userId) => answers?.[questionId]?.[userId] === value)
      .map((userId) => getRole(parseInt(userId)).color);
    return colors;
  };

  return (
    <div className={s.question}>
      <h1
        className={s.question__title}
        data-testid={
          isTesting ? `finalQuestion_question-${questionId}_title` : undefined
        }
      >
        {title}
      </h1>
      <Fieldset
        classNames={{
          fieldset: s.question__fieldset,
          fieldset__label: s.question__fieldsetLabel,
        }}
        label={t("Actions")}
      >
        {options.map((option) => (
          <div key={option.id} className={s.item}>
            <div className={s.item__body}>
              <div className={s.item__action}>{option.action}</div>
              {isMultiPlayer ? (
                <SeekAnswer
                  className={s.item__seekAnswer}
                  colors={getColorsForValue(option.id)}
                />
              ) : null}
            </div>
            <button
              data-testid={
                isTesting
                  ? `finalQuestion_question-${questionId}_answer-${option.id}`
                  : undefined
              }
              className={s.item__checkbox}
              disabled={isLoading}
              onClick={() => onChange(option)}
            >
              <Checkbox.Core
                className={s.item__checkboxCore}
                isActive={value === option.id}
              />
            </button>
          </div>
        ))}
      </Fieldset>
    </div>
  );
};

ActionQuestion.propTypes = {
  title: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      value: PropTypes.bool,
    }),
  ).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ActionQuestion;
