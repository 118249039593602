import { useEffect, useState } from "react";
import animationInterval from "@bd-utils/animationInterval";
import useIsFirstMount from "@bd-hooks/useIsFirstMount";

/**
 * @type {import("./useTimer").UseTimerType}
 */
const useTimer = ({ duration, onTimeout = () => {} }) => {
  const isFirstMount = useIsFirstMount();
  const initDeadline = new Date().getTime() + duration;
  const [deadline, setDeadline] = useState(initDeadline);
  const getNewTime = () => Math.max(deadline - new Date().getTime(), 0);
  const [remainingTime, setRemainingTime] = useState(getNewTime());

  /**
   * @param {AbortController} controller
   */
  const onTick = (controller) => {
    const newTime = getNewTime();
    const isTimeout = newTime <= 0;
    setRemainingTime(newTime);
    if (isTimeout) {
      controller.abort();
      onTimeout();
    }
  };

  // Update deadline if duration changed
  useEffect(() => {
    if (isFirstMount) return;
    setDeadline(new Date().getTime() + duration);
    setRemainingTime(getNewTime());
    // eslint-disable-next-line
  }, [duration]);

  useEffect(() => {
    // Check if it already reach the deadline before setting interval
    if (getNewTime() <= 0) return onTimeout();

    const controller = new AbortController();
    animationInterval(1000, controller.signal, () => onTick(controller));
    return () => controller.abort();
    // eslint-disable-next-line
  }, [duration]);

  return remainingTime;
};

export default useTimer;
